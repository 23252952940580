import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: max-content;
  gap: ${theme.spacing(2)};
  padding: ${theme.spacing(1, 2)};
  height: 100%;
  overflow: auto;
`;
