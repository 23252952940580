import { useMemo } from 'react';
import { cloneDeep } from 'lodash-es';
import { BASE_URL_ANALYTICS } from '@frontend/api-analytics';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore, useScopedAppFlagStore } from '@frontend/scope';
import { featureFlags } from '../../../../feature-flags';
import { REVENUE_MULTIPLIER } from '../../constants';
import { useFetchRoiData } from '../../hooks';
import { RoiApiPayload } from '../../types';
import {
  generateNoShowRateLocationWiseDemoData,
  generateNoShowRateTimelineDemoData,
  generateOverviewDemoData,
} from '../demo-data';
import { AROverviewData } from '../types';

type APIParams = {
  payload: RoiApiPayload;
};

interface Props {
  apiParams: APIParams;
  fetchNoShowRate?: boolean;
  isEnabled?: boolean;
}

type Overview = {
  appointment_reminders_sent_patients?: number;
  appointment_reminders_sent?: number;
  appointments_completed_patients?: number;
  appointments_completed?: number;
  appointments_confirmed_patients?: number;
  appointments_confirmed?: number;
  location_id: string;
  total_appointments_completed?: number;
};

export type OverviewResponse = Overview[];

type NoShowRateTimeline = {
  key: string;
  subscribed?: number;
  unsubscribed?: number;
};

export type NoShowRateTimelineResponse = {
  dates: NoShowRateTimeline[];
  rate_difference?: number;
};

type NoshowRateLocation = {
  location_id: string;
  no_show_rate?: number;
};

export type NoShowRateLocationWiseResponse = {
  locations: NoshowRateLocation[];
  unsubscribed?: number;
};

type LocationData = {
  appointmentsCompletedByReminders: number;
  noShowRate: number;
  totalAppointmentsCompleted: number;
};

interface UseFetchARROIOOverview {
  aggregated: AROverviewData;
  isLoading?: boolean;
  locationsData: {
    breakup: {
      [locationId: string]: LocationData & { location_name: string };
    };
    noShowRateOfLocationsNotUsingReminders: number;
  };
}

const defaultAggregated: AROverviewData = {
  appointmentsCompleted: {
    appointmentsCompletedByReminders: 0,
    production: 0,
    totalAppointmentsCompleted: 0,
  },
  appointmentsConfirmed: {
    appointmentsCount: 0,
    patientsCount: 0,
  },
  noShowRate: {
    difference: 0,
    groups: [],
  },
  remindersSent: {
    patientsCount: 0,
    remindersCount: 0,
  },
};

const api = async <T>({ payload }: APIParams, urlSuffix: string) => {
  const response = await http.post<{ data: T }>(
    `${BASE_URL_ANALYTICS}/roi/v4/appointmentreminder/${urlSuffix}`,
    payload
  );
  return response.data;
};

export const useFetchARROIOOverview = ({
  fetchNoShowRate = true,
  isEnabled = true,
  ...rest
}: Props): UseFetchARROIOOverview => {
  const { t } = useTranslation('analytics');
  const { accessibleLocationData } = useAppScopeStore();
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isDemoAccount = getFeatureFlagValue(featureFlags.appointmentRemindersRoiDemoData);

  const { data: overviewData, isLoading: isLoadingOverviewData } = useFetchRoiData<APIParams, OverviewResponse>({
    ...rest,
    api: (params) => api<OverviewResponse>(params, 'getapptreminderaggregates'),
    errorMessage: t("Couldn't fetch the appointment reminders conversion overview details. Please try again."),
    isEnabled: isEnabled && !isDemoAccount,
    uniqueId: 'ar-roi-overview',
  });

  const { data: noShowRateTimeline, isLoading: isLoadingNoShowRateTimeline } = useFetchRoiData<
    APIParams,
    NoShowRateTimelineResponse
  >({
    ...rest,
    api: (params) => api<NoShowRateTimelineResponse>(params, 'getnoshowrate'),
    errorMessage: t("Couldn't fetch the appointment reminders no show rate details. Please try again."),
    isEnabled: isEnabled && fetchNoShowRate && !isDemoAccount,
    uniqueId: 'ar-roi-no-show-rate-timeline',
  });

  const { data: noShowRateLocationWiseData, isLoading: isLoadingNoShowRateLocationWise } = useFetchRoiData<
    APIParams,
    NoShowRateLocationWiseResponse
  >({
    ...rest,
    api: (params) => api<NoShowRateLocationWiseResponse>(params, 'getnoshowrate/aggregated'),
    errorMessage: t("Couldn't fetch the appointment reminders no show rate details. Please try again."),
    isEnabled: isEnabled && fetchNoShowRate && !isDemoAccount,
    uniqueId: 'ar-roi-no-show-rate-location-wise',
  });

  const processedLocationsNoShowRateData = useMemo(() => {
    const dataToUse = isDemoAccount
      ? generateNoShowRateLocationWiseDemoData(rest.apiParams.payload.location_id ?? [])
      : noShowRateLocationWiseData;

    return {
      processed: dataToUse?.locations?.reduce<Record<string, number>>(
        (acc, { location_id, no_show_rate = 0 }) => ({ ...acc, [location_id]: no_show_rate * 100 }),
        {}
      ),
      rawData: dataToUse,
    };
  }, [isDemoAccount, noShowRateLocationWiseData, rest.apiParams.payload.location_id]);

  const processedOverviewData = useMemo(() => {
    const aggregated: UseFetchARROIOOverview['aggregated'] = cloneDeep(defaultAggregated);
    const dataToUse = isDemoAccount ? generateOverviewDemoData(rest.apiParams.payload.location_id ?? []) : overviewData;

    if (!dataToUse || !Array.isArray(dataToUse)) {
      return {
        aggregated,
        locationsData: {
          breakup: {},
          noShowRateOfLocationsNotUsingReminders: 0,
        },
      };
    }

    const locationsData = dataToUse.reduce<UseFetchARROIOOverview['locationsData']>(
      (
        acc,
        {
          appointment_reminders_sent = 0,
          appointment_reminders_sent_patients = 0,
          appointments_completed = 0,
          appointments_confirmed = 0,
          appointments_confirmed_patients = 0,
          location_id,
          total_appointments_completed = 0,
        }
      ) => {
        aggregated.appointmentsCompleted.appointmentsCompletedByReminders += appointments_completed;
        aggregated.appointmentsCompleted.totalAppointmentsCompleted += total_appointments_completed;

        aggregated.appointmentsConfirmed.appointmentsCount += appointments_confirmed;
        aggregated.appointmentsConfirmed.patientsCount += appointments_confirmed_patients;

        aggregated.remindersSent.patientsCount += appointment_reminders_sent_patients;
        aggregated.remindersSent.remindersCount += appointment_reminders_sent;

        if (location_id) {
          acc.breakup[location_id] = {
            appointmentsCompletedByReminders: appointments_completed,
            location_name: accessibleLocationData[location_id]?.name || location_id,
            noShowRate: processedLocationsNoShowRateData?.processed?.[location_id] || 0,
            totalAppointmentsCompleted: total_appointments_completed,
          };
        }

        return acc;
      },
      {
        breakup: {},
        noShowRateOfLocationsNotUsingReminders: (processedLocationsNoShowRateData?.rawData?.unsubscribed || 0) * 100,
      }
    );

    return {
      aggregated: {
        ...aggregated,
        appointmentsCompleted: {
          ...aggregated.appointmentsCompleted,
          production: aggregated.appointmentsCompleted.appointmentsCompletedByReminders * REVENUE_MULTIPLIER,
        },
      },
      locationsData,
    };
  }, [
    accessibleLocationData,
    isDemoAccount,
    overviewData,
    processedLocationsNoShowRateData,
    rest.apiParams.payload.location_id,
  ]);

  const processedNoShowRateTimeline = useMemo(() => {
    const noShowRate: UseFetchARROIOOverview['aggregated']['noShowRate'] = {
      difference: 0,
      enableNoShowRateBestPractices: false,
      groups: [],
    };

    const dataToUse = isDemoAccount ? generateNoShowRateTimelineDemoData() : noShowRateTimeline;

    if (!dataToUse) {
      return {
        noShowRate,
      };
    }

    noShowRate.difference = (dataToUse.rate_difference || 0) * 100;
    noShowRate.groups = dataToUse.dates.map(({ key, subscribed = 0, unsubscribed = 0 }) => {
      return {
        name: key,
        values: {
          yourPractice: subscribed * 100,
          noRemindersPractice: unsubscribed * 100,
        },
      };
    });

    // A positive difference means the practice is doing better than the unsubscribed locations (lower no show rate)
    // Hence show the best practices when the difference is negative
    noShowRate.enableNoShowRateBestPractices = noShowRate.difference <= 0;

    return {
      noShowRate,
    };
  }, [isDemoAccount, noShowRateTimeline]);

  return {
    ...processedOverviewData,
    aggregated: {
      ...processedOverviewData.aggregated,
      ...processedNoShowRateTimeline,
    },
    isLoading: isLoadingOverviewData || isLoadingNoShowRateTimeline || isLoadingNoShowRateLocationWise,
  };
};
