import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { css, Interpolation } from '@emotion/react';
import useResizeObserver from '@react-hook/resize-observer';
import { TaskStatus, TaskType } from '@weave/schema-gen-ts/dist/schemas/task-center/shared/v1/enums.pb';
import dayjs from 'dayjs';
import { debounce } from 'lodash-es';
import { InfiniteData } from 'react-query';
import { AnalyticsCommonTypes, CallIntelligenceApi } from '@frontend/api-analytics';
import { CallIntelMutations, CallIntelQueries, CallIntelTypes } from '@frontend/api-call-intel';
import { AdaptoActions } from '@frontend/adapto-actions';
import { getUser } from '@frontend/auth-helpers';
import { Actions as ContactActions } from '@frontend/contact-actions';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useLastUsedVerticalShallowStore } from '@frontend/location-helpers';
import { breakpoints } from '@frontend/responsiveness';
import { useScopedInfiniteQuery, useScopedQuery } from '@frontend/scope';
import { useContactPanelStore } from '@frontend/shared';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import {
  Accordion,
  Text,
  useAlert,
  styles as designSystemStyles,
  SkeletonLoaders,
  Pagination,
  emptyStateGraphics,
  IconButton,
  usePopoverMenu,
  PopoverMenu,
  PopoverMenuItem,
  useModalControl,
  Button,
  ModalControlModalProps,
  Tray,
} from '@frontend/design-system';
import { useIsMobile } from '../../hooks';
import { queryKeys } from '../../query-keys';
import { generateTrackingId } from '../../tracking';
import { trackingIds } from '../../tracking-ids';
import { callIntelligenceUtils, generateContactId } from '../../utils';
import { LocationChip } from '../location-chip';
import { UserCard } from '../user-card';
import CallIntelChip from './call-intel-chip';
import { CallIntelSearch } from './call-intel-search-field';
import { CallIntelSortFilter } from './call-intel-sort-filter';
import { CallTakeawayPanel } from './call-takeaway-panel';
import { CallIntelMockData } from './demo-data';
import {
  useCallIntelFollowUpsShallowStore,
  useCallIntelLocations,
  useCallIntelShallowStore,
  useCallTakeawayPanelShallowStore,
} from './hooks';
import { TaskModal, TaskModalVariant } from './modal/task-modal';
import { OfficeUserName } from './office-user-name';
import { TaskStatusSwitcher } from './task-status-switcher';

type CallInfoProps = {
  call: CallIntelTypes.Call;
  isMultiLocation: boolean;
  locations: AnalyticsCommonTypes.StringRecord;
};

type ContactActionsGroupProps = {
  followUpItem: CallIntelTypes.FollowUpItem;
  customStyles?: Interpolation;
};

type EmptyStateProps = {
  label: string;
};

type FollowUpCardProps = {
  drillDownTaskType: TaskType;
  followUpItem: CallIntelTypes.FollowUpItem;
  isDemoAccount: boolean;
  isDrillDown: boolean;
  openDemoCallTakeaway: () => void;
  shouldLoadTasks: boolean;
  taskStatusFilters: TaskStatus[];
  value: string;
};

type FollowUpLoaderProps = {
  count?: number;
  disabled?: boolean;
  variant: 'followUp' | 'task' | 'taskMobile' | 'stats';
};

type FollowUpStatsProps = {
  taskStats: CallIntelTypes.TaskStats;
  taskStatusFilters: TaskStatus[];
  totalPatients: number;
};

type FollowUpTaskModalProps = {
  callId: string;
  followUpItem: CallIntelTypes.FollowUpItem;
  taskId: string;
  variant: TaskModalVariant;
  modalProps: ModalControlModalProps;
  onClose: () => void;
  onNext: () => void;
  onPrevious: () => void;
  onStatusChange: (params: StatusChangeParams) => void;
  onTaskDelete: (taskId: string) => void;
};

type OpenTaskModalParams = {
  callId: string;
  taskId: string;
  variant: TaskModalVariant;
};

type Props = {
  isDemoAccount?: boolean;
  drillDownTaskType: TaskType;
  isDrillDown: boolean;
  taskStatusFilters: TaskStatus[];
};

type StatusChangeParams = {
  callId: string;
  taskId: string;
  newStatus: TaskStatus;
  oldStatus: TaskStatus;
};

type TaskMeatballMenuProps = {
  disabled?: boolean;
  isDemoAccount?: boolean;
  onDeleteTask?: () => void;
  onViewCallTakeaways?: () => void;
  onViewTask?: () => void;
};

// Constants
const DEFAULT_CALL_LIMIT = 5;
const DEMO_LOCATIONS = CallIntelMockData.dummyLocationNames();
const MAX_EXPECTED_TASKS = 3;
const MIN_SEARCH_LENGTH = 3;
const DEFAULT_PRELOAD_COUNT = 1; // Number of follow-up contacts beyond the currently visible item to preload with tasks
const TOTAL_SELECTABLE_TASK_STATUSES = 3; // This is count of the task types which are to-do , in-progress , completed

const processFollowUpsData = (data: InfiniteData<CallIntelTypes.FollowUpResponse>) => {
  const allFollowUps: CallIntelTypes.FollowUpItem[] = [];
  const allCalls: CallIntelTypes.Call[] = [];

  let totalFollowUps = 0;

  const currentPage = data.pages[0];
  if (!currentPage) return { allFollowUps, allCalls, totalFollowUps };

  totalFollowUps = currentPage.totalFollowUps;

  currentPage.followUps.forEach((followUp) => {
    const followUpItem = {
      contact: { ...followUp.calls[0], contactId: generateContactId(followUp.calls[0]) },
      allCalls: followUp.calls,
      callTaskMapping: {},
      totalCalls: followUp.totalCalls,
      totalTasks: followUp.totalTasks,
    };

    allFollowUps.push(followUpItem);
    allCalls.push(...followUp.calls);
  });

  return { allFollowUps, allCalls, totalFollowUps };
};

const CallInfo = ({ call, isMultiLocation, locations }: CallInfoProps) => {
  const { t } = useTranslation('analytics');

  return (
    <div css={styles.callWrapper}>
      <div className='office-user-wrapper'>
        <Text color='subdued' size='medium'>
          {t('Office User')}
        </Text>
        <Text size='medium'>
          <OfficeUserName
            {...call.officeUser}
            isDataUnavailable={callIntelligenceUtils.isOfficeUserDataUnavailable(call.startTime)}
            isInactive={callIntelligenceUtils.isUserInactive(call.officeUser)}
            css={css`
              > span {
                font-size: ${theme.fontSize(14)};
              }
            `}
          />
        </Text>
      </div>
      <div className='time-stamp-wrapper'>
        <Text color='subdued' size='medium'>
          {t('Time of Call')}
        </Text>
        <Text size='medium'>{dayjs(call.startTime).format('MMM D, YYYY, h:mm A')}</Text>
      </div>
      {isMultiLocation && (
        <div className='location-wrapper'>
          <Text size='medium' color='subdued'>
            {t('Location')}
          </Text>
          <LocationChip locationName={locations[call.locationId] || call.locationId} maxWidth={300} />
        </div>
      )}
    </div>
  );
};

const ContactActionsGroup = ({ followUpItem, customStyles }: ContactActionsGroupProps) => {
  const { t } = useTranslation('analytics');

  return (
    <AdaptoActions
      enableCloseEvent
      iconColor='default'
      variant='action-buttons'
      css={[styles.actionsWrapper, customStyles]}
    >
      <ContactActions.Call
        context={{
          personId: followUpItem.contact.person?.id,
          phoneNumber: followUpItem.contact.phoneNumber?.nationalNumber,
        }}
        label={t('Call')}
        trackingIdSuffix='follow-up-contact-actions'
      />
      <ContactActions.Message
        context={{
          personId: followUpItem.contact.person?.id,
          personPhone: followUpItem.contact.phoneNumber?.nationalNumber,
          groupIds: Array.from(new Set(followUpItem.allCalls.map((call) => call.locationId))),
        }}
        label={t('Message')}
        trackingIdSuffix='follow-up-contact-actions'
      />
    </AdaptoActions>
  );
};

// Loader component for follow-ups
const FollowUpLoader = ({ count = 1, disabled = false, variant = 'followUp' }: FollowUpLoaderProps) => {
  if (variant === 'stats') {
    return <SkeletonLoaders.Loader height='120px' />;
  }

  if (variant === 'taskMobile') {
    return (
      <>
        {Array.from({ length: count }).map((_, idx) => (
          <div key={idx} css={styles.taskMobileRow}>
            <div className='meatball-menu-wrapper'>
              <TaskMeatballMenu disabled={disabled} />
            </div>

            <div className='task-content'>
              <div className='task-item-wrapper'>
                <SkeletonLoaders.Loader height='36px' />
              </div>

              <div className='task-actions'>
                <div
                  className='task-item-wrapper'
                  css={css`
                    flex: 0.4;
                  `}
                >
                  <SkeletonLoaders.Loader height='24px' />
                </div>
                <div className='task-item-wrapper'>
                  <SkeletonLoaders.Loader height='24px' />
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }

  if (variant === 'task') {
    return (
      <>
        {Array.from({ length: count }).map((_, idx) => (
          <div key={idx} css={styles.taskRow}>
            <div className='data-cols'>
              <SkeletonLoaders.Loader height='12px' />
            </div>

            <div css={styles.taskActionWrapper}>
              <div className='status-column'>
                <SkeletonLoaders.Loader height='12px' />
              </div>

              <div className='reason-column'>
                <SkeletonLoaders.Loader height='12px' />
              </div>
            </div>
            <div className='action-column'>
              <TaskMeatballMenu disabled={disabled} />
            </div>
          </div>
        ))}
      </>
    );
  }
  return (
    <div css={styles.followUpLoaderWrapper}>
      {Array.from({ length: count }).map((_, idx) => (
        <SkeletonLoaders.Loader className='row' key={idx} height={theme.spacing(10.5)} />
      ))}
    </div>
  );
};

// Empty state component for follow-ups
const EmptyState = ({ label }: EmptyStateProps) => {
  const EmptyStateGraphicNoFollowUps = emptyStateGraphics.noCallFollowUps;

  return (
    <div css={styles.emptyStateWrapper}>
      <EmptyStateGraphicNoFollowUps height={300} width={300} />
      <Text css={styles.emptyStateText}>{label}</Text>
    </div>
  );
};

// Stats component for follow-ups
const FollowUpStats = ({ taskStats, taskStatusFilters, totalPatients }: FollowUpStatsProps) => {
  const { t } = useTranslation('analytics');

  const { totalTasks, taskCompletionRate, shouldDisplayAllStatuses } = useMemo(() => {
    const computedTotalTasks =
      (taskStats?.STATUS_TODO ?? 0) + (taskStats?.STATUS_IN_PROGRESS ?? 0) + (taskStats?.STATUS_COMPLETED ?? 0);

    return {
      totalTasks: computedTotalTasks,
      taskCompletionRate: computedTotalTasks
        ? `${Math.round(((taskStats?.STATUS_COMPLETED ?? 0) / computedTotalTasks) * 100)}%`
        : '0%',
      shouldDisplayAllStatuses:
        taskStatusFilters.length === 0 || taskStatusFilters.length === TOTAL_SELECTABLE_TASK_STATUSES,
    };
  }, [taskStats, taskStatusFilters]);

  const visibleStats = useMemo(() => {
    const statsList = [{ label: t('Patients'), value: `${totalPatients}` }];

    if (shouldDisplayAllStatuses) {
      statsList.push({ label: t('Total Tasks'), value: `${totalTasks}` });
    }

    const taskStatusLabels = {
      [TaskStatus.STATUS_TODO]: t('To-Do'),
      [TaskStatus.STATUS_IN_PROGRESS]: t('In Progress'),
      [TaskStatus.STATUS_COMPLETED]: t('Completed'),
    };

    Object.entries(taskStatusLabels).forEach(([status, label]) => {
      if (taskStatusFilters.includes(status as TaskStatus) || shouldDisplayAllStatuses) {
        statsList.push({ label, value: `${taskStats[status as TaskStatus] ?? 0}` });
      }
    });

    if (shouldDisplayAllStatuses) {
      statsList.push({ label: t('Task Completion Rate'), value: taskCompletionRate });
    }

    return statsList;
  }, [taskStatusFilters, taskStats, totalPatients, shouldDisplayAllStatuses, totalTasks, taskCompletionRate]);

  return (
    <div css={styles.statsWrapper}>
      {visibleStats.map(({ label, value }) => (
        <div className='info-wrapper' key={label}>
          <Text color='subdued'>{label}</Text>
          <Text className='value' weight='bold'>
            {value}
          </Text>
        </div>
      ))}
    </div>
  );
};

const TaskMeatballMenu = ({
  disabled = false,
  isDemoAccount,
  onViewTask,
  onViewCallTakeaways,
  onDeleteTask,
}: TaskMeatballMenuProps) => {
  const { t } = useTranslation('analytics');

  const { getTriggerProps, getMenuProps, getItemProps } = usePopoverMenu({ placement: 'bottom-end' });

  return (
    <div>
      <IconButton
        {...getTriggerProps()}
        label={t('Open Menu')}
        css={styles.meatBallBtnWrapper}
        trackingId={generateTrackingId({
          component: 'follow-up-page',
          context: 'meatball-menu-click',
          isDemoAccount,
        })}
      >
        <Icon name='more' />
      </IconButton>

      {!disabled && (
        <PopoverMenu {...getMenuProps()}>
          {onViewTask && (
            <PopoverMenuItem
              {...getItemProps({
                index: 0,
                onClick: onViewTask,
              })}
              Icon={() => <Icon name='expand-small' />}
              trackingId={generateTrackingId({
                component: 'follow-up-page',
                context: 'meatball-menu-task-btn-click',
                isDemoAccount,
              })}
            >
              <Text as='span'>{t('View Task')}</Text>
            </PopoverMenuItem>
          )}

          {onViewCallTakeaways && (
            <PopoverMenuItem
              {...getItemProps({
                index: 1,
                onClick: onViewCallTakeaways,
              })}
              Icon={() => <Icon name='document-filled-small' />}
              trackingId={generateTrackingId({
                component: 'follow-up-page',
                context: 'meatball-menu-takeaway-btn-click',
                isDemoAccount,
              })}
            >
              <Text as='span'>{t('View Call Takeaways')}</Text>
            </PopoverMenuItem>
          )}

          {onDeleteTask && (
            <PopoverMenuItem
              {...getItemProps({
                index: 2,
                onClick: onDeleteTask,
              })}
              Icon={() => (
                <Icon
                  name='trash-small'
                  css={css`
                    color: ${theme.colors.critical50};
                  `}
                />
              )}
              trackingId={generateTrackingId({
                component: 'follow-up-page',
                context: 'meatball-menu-delete-btn-click',
                isDemoAccount,
              })}
            >
              <Text
                as='span'
                css={css`
                  color: ${theme.colors.critical50};
                `}
              >
                {t('Delete Task')}
              </Text>
            </PopoverMenuItem>
          )}
        </PopoverMenu>
      )}
    </div>
  );
};

const FollowUpTaskModal = ({
  callId,
  followUpItem,
  modalProps,
  onClose,
  onNext,
  onPrevious,
  onStatusChange,
  onTaskDelete,
  taskId,
  variant,
}: FollowUpTaskModalProps) => {
  const tasks = followUpItem.callTaskMapping[callId] || [];
  const idx = tasks.findIndex((t) => t.id === taskId);
  const selectedTask = idx >= 0 ? tasks[idx] : null;

  const selectedCall = followUpItem.allCalls.find((c) => c.id === callId) || null;

  if (!selectedCall || !selectedTask) return null;

  return (
    <TaskModal
      call={selectedCall}
      callParticipantsMetadata={{ caller: '', receptionist: '' }}
      defaultVariant={variant}
      modalProps={modalProps}
      onClose={onClose}
      onDeleteTaskConfirm={onTaskDelete}
      onNext={onNext}
      onPrevious={onPrevious}
      onStatusChange={(_, newStatus) => onStatusChange({ callId, taskId, newStatus, oldStatus: selectedTask.status })}
      paginateParams={{
        start: idx + 1,
        total: tasks.length,
      }}
      task={selectedTask}
    />
  );
};

const FollowUpCard = forwardRef<HTMLDivElement, FollowUpCardProps>(
  (
    {
      drillDownTaskType,
      followUpItem,
      isDemoAccount,
      isDrillDown,
      openDemoCallTakeaway,
      shouldLoadTasks,
      taskStatusFilters,
      value,
    }: FollowUpCardProps,
    ref
  ) => {
    const { t } = useTranslation('analytics');
    const alert = useAlert();
    const itemRef = useRef<HTMLDivElement | null>(null);

    const { modalProps, triggerProps, closeModal } = useModalControl();

    const { chipVariants, dataLabels, filters } = useCallIntelShallowStore('chipVariants', 'dataLabels', 'filters');
    const { setPersonId } = useContactPanelStore();
    const { panelType } = useSlidePanelShallowStore('panelType');
    const { lastUsedVertical: vertical } = useLastUsedVerticalShallowStore('lastUsedVertical');
    const { updateTaskStatus, updateCallTaskMapping } = useCallIntelFollowUpsShallowStore(
      'updateTaskStatus',
      'updateCallTaskMapping'
    );

    const {
      selectedCall,
      selectedCallMetaData,
      setSelectedCall,
      setSelectedCallMetaData,
      setDefaultAccordion,
      setSelectedDemoTasks,
    } = useCallTakeawayPanelShallowStore(
      'selectedCall',
      'selectedCallMetaData',
      'setSelectedCall',
      'selectedCallMetaData',
      'setDefaultAccordion',
      'setSelectedCallMetaData',
      'setSelectedDemoTasks'
    );
    const { locations, isMultiLocation } = useCallIntelLocations({
      demoLocations: isDemoAccount ? DEMO_LOCATIONS : undefined,
    });
    const isMobile = useIsMobile();
    const user = getUser();

    // Manage how many calls are shown
    const [callLimit, setCallLimit] = useState(DEFAULT_CALL_LIMIT);
    const [extraCallIds, setExtraCallIds] = useState<string[]>([]);

    // For task modal:
    const [selectedCallIdForModal, setSelectedCallIdForModal] = useState<string | null>(null);
    const [taskModalVariant, setTaskModalVariant] = useState<TaskModalVariant>('info');
    const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null);

    // For responsive layout
    const [size, setSize] = useState({
      isSmall: false,
      isMedium: false,
    });

    // Calculate displayed calls
    const displayedCalls = useMemo(() => {
      return followUpItem.allCalls.slice(0, callLimit);
    }, [followUpItem.allCalls, callLimit]);

    // The initial calls to fetch tasks for
    const initialCalls = useMemo(() => {
      return followUpItem.allCalls.slice(0, DEFAULT_CALL_LIMIT);
    }, [followUpItem.allCalls]);
    const initialCallIds = useMemo(() => initialCalls.map((c) => c.id), [initialCalls]);

    // A couple of derived values
    const totalCalls = followUpItem.allCalls.length;
    const canLoadMore = callLimit < totalCalls;
    const hasMoreThanOnePage = totalCalls > DEFAULT_CALL_LIMIT;
    const totalTasks = followUpItem.totalTasks;

    // Fetch tasks query for the initial calls
    const { data: taskDataRes, isFetching: isTaskLoading } = CallIntelQueries.useListTasks({
      demoData: CallIntelMockData.generateTasksForCalls(followUpItem.allCalls, vertical, {
        taskStatusFilters,
        taskTypes: !!drillDownTaskType && [drillDownTaskType],
      }),
      isDemoAccount,
      payload: {
        callIds: initialCallIds,
        locations: filters.locations || [],
        includeMetaData: true,
        taskTypes: !!drillDownTaskType && [drillDownTaskType],
        taskStatusFilters,
      },
      realQueryOptions: {
        enabled: !isDemoAccount && shouldLoadTasks,
        staleTime: 0,
        cacheTime: 0,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        onError: () => {
          alert.error(t('Failed to fetch tasks'));
        },
      },
    });

    // Fetch extra tasks fetch (when the user clicks "Load More Calls")
    const { isFetching: isExtraTasksLoading } = CallIntelQueries.useListTasks({
      isDemoAccount,
      demoData: CallIntelMockData.generateTasksForCalls(followUpItem.allCalls, vertical, {
        taskStatusFilters,
        taskTypes: !!drillDownTaskType && [drillDownTaskType],
      }),
      payload: {
        callIds: extraCallIds,
        locations: filters.locations || [],
        includeMetaData: true,
        taskTypes: !!drillDownTaskType && [drillDownTaskType],
        taskStatusFilters,
      },
      realQueryOptions: {
        enabled: !isDemoAccount && extraCallIds.length > 0,
        staleTime: 0,
        cacheTime: 0,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        onSuccess: (res) => {
          const tasksByCallId: Record<string, CallIntelTypes.Task[]> = {};

          res.tasks.forEach((task) => {
            const callId = task.sourceId;

            if (!tasksByCallId[callId]) {
              tasksByCallId[callId] = [];
            }
            tasksByCallId[callId].push(task);
          });

          const updatedCallTaskMapping = { ...followUpItem.callTaskMapping };

          Object.keys(tasksByCallId).forEach((callId) => {
            updatedCallTaskMapping[callId] = tasksByCallId[callId];
          });

          updateCallTaskMapping({
            followUpId: followUpItem.contact.contactId,
            callTaskMapping: updatedCallTaskMapping,
            calculate: false,
            shouldCleanUp: false,
            ...(drillDownTaskType && { taskType: drillDownTaskType }),
          });

          setExtraCallIds([]);
        },
        onError: () => {
          alert.error(t('Failed to fetch tasks'));
        },
      },
    });

    const { mutate: updateTask } = CallIntelMutations.useUpdateTask({
      onSuccess: (_, taskPayload) => {
        const { taskType, callId, oldStatus, status } = taskPayload;
        if (taskType && callId && oldStatus && status) {
          const shouldUpdateTaskStatus = !isDrillDown || taskType === drillDownTaskType;
          if (shouldUpdateTaskStatus) {
            updateTaskStatus({
              callId,
              followUpId: followUpItem.contact.contactId,
              newStatus: status,
              oldStatus,
              taskId: taskPayload.id,
            });
          }

          if (selectedCallMetaData) {
            const updatedTasks = selectedCallMetaData.tasks.map((task) =>
              task.id === taskPayload.id ? { ...task, status: taskPayload.status } : task
            );
            setSelectedCallMetaData({ ...selectedCallMetaData, tasks: updatedTasks as CallIntelTypes.Task[] }); // to be removed after all schema migration
          }
        }

        alert.success(t('Task updated successfully'));
      },
      onError: () => {
        alert.error(t('Unable to update task. Please try again.'));
      },
      isDemoAccount,
    });

    const { mutate: deleteTask } = CallIntelMutations.useDeleteTask({
      onSuccess: (_, { id: deletedTaskId }) => {
        // remove the task from callTaskMapping
        const updated = { ...followUpItem.callTaskMapping };

        // We need to find which call has this task
        Object.keys(updated).forEach((callId) => {
          const newTasks = updated[callId].filter((task) => task.id !== deletedTaskId);
          updated[callId] = newTasks;
        });

        // push the new mapping
        updateCallTaskMapping({
          followUpId: followUpItem.contact.contactId,
          callTaskMapping: updated,
          calculate: false,
          shouldCleanUp: true,
          ...(drillDownTaskType && { taskType: drillDownTaskType }),
        });

        alert.success(t('Successfully deleted task!'));
      },
      onError: () => {
        alert.error(t('Unable to delete task. Please try again.'));
      },
      isDemoAccount,
    });

    // If tasks came back for the first time, store them in callTaskMapping
    useEffect(() => {
      if (!Object.keys(followUpItem.callTaskMapping).length && taskDataRes?.tasks) {
        const tasksByCallId: Record<string, CallIntelTypes.Task[]> = {};

        taskDataRes.tasks.forEach((task) => {
          const callId = task.sourceId;

          if (!tasksByCallId[callId]) {
            tasksByCallId[callId] = [];
          }
          tasksByCallId[callId].push(task);
        });

        const updatedCallTaskMapping = { ...followUpItem.callTaskMapping };

        Object.keys(tasksByCallId).forEach((callId) => {
          updatedCallTaskMapping[callId] = tasksByCallId[callId];
        });

        updateCallTaskMapping({
          followUpId: followUpItem.contact.contactId,
          callTaskMapping: updatedCallTaskMapping,
          calculate: isDemoAccount ? true : false,
          ...(drillDownTaskType && !!isDemoAccount && { taskType: drillDownTaskType }),
        });
      }
    }, [isDemoAccount, followUpItem?.callTaskMapping, taskDataRes?.tasks]);

    // If the user has the "takeaway" panel open on a call that belongs to this FollowUp, keep the store in sync
    useEffect(() => {
      if (selectedCall && selectedCallMetaData) {
        const isCallInFollowUp = followUpItem.callTaskMapping[selectedCall.id];

        if (isCallInFollowUp) {
          updateCallTaskMapping({
            followUpId: followUpItem.contact.contactId,
            callTaskMapping: {
              [selectedCall.id]: selectedCallMetaData.tasks,
            },
            calculate: false,
            shouldCleanUp: true,
            ...(drillDownTaskType && { taskType: drillDownTaskType }),
          });
        }
      }
    }, [selectedCall, selectedCallMetaData, drillDownTaskType]);

    // For responsive layout (to trigger mobile layouts when task panel is open and media query dosent works this time)
    const handleResize = useCallback(
      debounce((entry: ResizeObserverEntry) => {
        const width = entry.contentRect.width;

        setSize((prevState) => {
          const newIsSmall = width < 451; // 451 is width of accordion for small
          const newIsMedium = width < 505; // 505 is width of accordion for medium

          if (newIsSmall !== prevState.isSmall || newIsMedium !== prevState.isMedium) {
            return {
              isSmall: newIsSmall,
              isMedium: newIsMedium,
            };
          }

          return prevState;
        });
      }, 200),
      []
    );

    useResizeObserver(itemRef, handleResize);

    const handleStatusChange = useCallback(
      ({ callId, taskId, newStatus, oldStatus }: StatusChangeParams) => {
        const tasks = followUpItem.callTaskMapping[callId] || [];
        const oldTask = tasks.find((task) => task.id === taskId);

        if (oldTask) {
          if (taskId && user?.userID) {
            updateTask({
              id: taskId,
              status: newStatus,
              oldStatus,
              callId,
              taskType: oldTask.type,
              updatedBy: user.userID,
            });
          }
        }
      },
      [followUpItem, updateTask, user?.userID]
    );

    const handleOpenTaskModal = useCallback(
      ({ callId, taskId, variant }: OpenTaskModalParams) => {
        setSelectedCallIdForModal(callId);
        setSelectedTaskId(taskId);
        setTaskModalVariant(variant);
        triggerProps.onClick();
      },
      [triggerProps]
    );

    const handleTaskModalClose = useCallback(() => {
      setSelectedCallIdForModal(null);
      setSelectedTaskId(null);
      closeModal();
    }, [closeModal]);

    const handleNextTask = useCallback(() => {
      if (!selectedCallIdForModal || !selectedTaskId) return;
      const tasks = followUpItem.callTaskMapping[selectedCallIdForModal] || [];
      const idx = tasks.findIndex((t) => t.id === selectedTaskId);

      if (idx >= 0 && idx < tasks.length - 1) {
        setSelectedTaskId(tasks[idx + 1].id);
      }
    }, [followUpItem, selectedCallIdForModal, selectedTaskId]);

    const handlePreviousTask = useCallback(() => {
      if (!selectedCallIdForModal || !selectedTaskId) return;
      const tasks = followUpItem.callTaskMapping[selectedCallIdForModal] || [];
      const idx = tasks.findIndex((t) => t.id === selectedTaskId);
      if (idx > 0) {
        setSelectedTaskId(tasks[idx - 1].id);
      }
    }, [followUpItem, selectedCallIdForModal, selectedTaskId]);

    const handleTaskDelete = useCallback(
      (taskId: string) => {
        deleteTask({ id: taskId });
        handleTaskModalClose();
      },
      [deleteTask, handleTaskModalClose]
    );

    const handleOpenCallTakeaways = useCallback(
      (call: CallIntelTypes.Call) => {
        if (call.id !== selectedCall?.id || panelType !== 'callTakeaway') {
          setPersonId('', false);
          setSelectedCall(call);
          setSelectedCallMetaData(null);
          setDefaultAccordion('call-summary');

          if (isDemoAccount) {
            setSelectedDemoTasks(followUpItem.callTaskMapping[call.id]);
            openDemoCallTakeaway();
          }
        }
      },
      [
        followUpItem,
        isDemoAccount,
        panelType,
        selectedCall,
        setDefaultAccordion,
        setPersonId,
        setSelectedCall,
        setSelectedCallMetaData,
        setSelectedDemoTasks,
      ]
    );

    const handleLoadMore = useCallback(() => {
      const oldLimit = callLimit;
      const newLimit = oldLimit + 5;
      setCallLimit(newLimit);

      const newlyRevealedCalls = followUpItem.allCalls.slice(oldLimit, newLimit);
      const newlyRevealedIds = newlyRevealedCalls.map((c) => c.id);

      setExtraCallIds(newlyRevealedIds);
    }, [callLimit, followUpItem]);

    const isLoadingTasks = isTaskLoading || isExtraTasksLoading;
    const isCompactLayout = isMobile || size.isMedium;

    return (
      <Accordion.Item
        value={value}
        ref={itemRef}
        trackingId={generateTrackingId({
          component: 'follow-up-page',
          context: 'accordion-click',
          isDemoAccount,
        })}
      >
        <Accordion.Header css={styles.header}>
          <div css={styles.headerContainer}>
            <UserCard
              css={styles.userCard}
              firstName={followUpItem.contact.person?.firstName}
              lastName={followUpItem.contact.person?.lastName}
              locationId={followUpItem.contact.locationId}
              openProfileOnClick={!isDemoAccount}
              phoneNumber={callIntelligenceUtils.getPhoneNumber(followUpItem.contact.phoneNumber)}
              rightElement={
                <CallIntelChip
                  chipVariants={chipVariants}
                  dataKey={followUpItem.contact.contactType ?? 'neutral'}
                  label={dataLabels.contactType?.[followUpItem?.contact?.contactType as string] ?? ''}
                />
              }
              showOnlyName
              userId={followUpItem.contact.person?.id}
            />

            <div css={styles.detailsWrapper}>
              <ContactActionsGroup
                followUpItem={followUpItem}
                customStyles={[styles.detailsWrapperActions, size.isMedium && styles.detailsWrapperActionsMobile]}
              />
              <div className='task-wrapper'>
                <Icon color='subdued' name='task-small' />
                <Text size='medium'>
                  {totalTasks > 1
                    ? t('{{ totalTasks }} Tasks', { totalTasks })
                    : t('{{ totalTasks }} Task', { totalTasks })}
                </Text>
              </div>

              <div className='calls-wrapper'>
                <Icon color='subdued' name='phone-small' />
                <Text size='medium'>
                  {t(`{{ totalCalls }} ${followUpItem.totalCalls > 1 ? 'Calls' : 'Call'}`, {
                    totalCalls: followUpItem.totalCalls,
                  })}
                </Text>
              </div>

              <div className='time-stamp-wrapper'>
                <Text color='subdued' size='medium'>
                  {t('Last Call')}
                </Text>
                <Text size='medium'>{dayjs(followUpItem.allCalls[0]?.startTime).format('MMM D, YYYY, h:mm A')}</Text>
              </div>
            </div>
          </div>
          <ContactActionsGroup
            followUpItem={followUpItem}
            customStyles={[styles.contactActions, size.isMedium && styles.contactActionsMobile]}
          />
        </Accordion.Header>

        <Accordion.Body css={styles.body} ref={ref}>
          {displayedCalls.map((call) => {
            const tasks = followUpItem.callTaskMapping[call.id] || [];

            return (
              <div
                key={call.id}
                css={css`
                  margin-bottom: ${theme.spacing(2)};
                `}
              >
                <div css={[styles.callContainer, selectedCall?.id === call.id && styles.focusedCall]}>
                  <CallInfo call={call} isMultiLocation={!!isMultiLocation} locations={locations} />
                  <IconButton
                    css={styles.viewButtonWrapper}
                    labelPlacement='right'
                    onClick={() => handleOpenCallTakeaways(call)}
                    showLabelAlways
                    trackingId={generateTrackingId({
                      component: 'follow-up-page',
                      context: 'accordion-view-takeaway-btn-click',
                      isDemoAccount,
                    })}
                    label=''
                  >
                    <Icon className='view-btn-icon' name='document-filled' />
                  </IconButton>
                </div>

                {!isCompactLayout ? (
                  <>
                    <div css={styles.taskTableHeader}>
                      <div className='data-cols'>
                        <Text size='medium' color='subdued'>
                          {t('Task')}
                        </Text>

                        <div css={styles.taskActionWrapper}>
                          <div className='status-column'>
                            <Text size='medium' color='subdued'>
                              {t('Status')}
                            </Text>
                          </div>
                          <div className='reason-column'>
                            <Text size='medium' color='subdued'>
                              {t('Follow-up Reason')}
                            </Text>
                          </div>
                        </div>
                      </div>

                      <div className='action-column' />
                    </div>

                    {tasks.map((task) => (
                      <div
                        key={task.id}
                        css={styles.taskRow}
                        onClick={() => handleOpenTaskModal({ callId: call.id, taskId: task.id, variant: 'info' })}
                        data-trackingid={generateTrackingId({
                          component: 'follow-up-page',
                          context: 'task-click',
                          isDemoAccount,
                        })}
                      >
                        <div className='data-cols'>
                          <Text css={[designSystemStyles.multilineTruncate(2)]} size='medium'>
                            <Text as='span' weight='bold' size='medium'>
                              {task.title}
                            </Text>{' '}
                            {task.description}
                          </Text>
                        </div>

                        <div css={styles.taskActionWrapper}>
                          <div className='status-column'>
                            <TaskStatusSwitcher
                              defaultOptionValue={task.status}
                              onStatusChange={(newStatus) =>
                                handleStatusChange({
                                  callId: call.id,
                                  taskId: task.id,
                                  newStatus,
                                  oldStatus: task.status,
                                })
                              }
                              trackingId={generateTrackingId({
                                component: 'follow-up-page',
                                context: 'task-status-switcher-btn-click',
                                isDemoAccount,
                              })}
                              value={task.status}
                            />
                          </div>

                          <div className='reason-column'>
                            <CallIntelChip
                              as='category'
                              chipVariants={chipVariants}
                              dataKey={task.type}
                              label={dataLabels.taskTypes?.[task.type] || task.type}
                              key={task.type}
                            />
                          </div>
                        </div>
                        <div className='action-column'>
                          <TaskMeatballMenu
                            isDemoAccount={isDemoAccount}
                            onDeleteTask={() =>
                              handleOpenTaskModal({ callId: call.id, taskId: task.id, variant: 'delete' })
                            }
                            onViewCallTakeaways={() => handleOpenCallTakeaways(call)}
                            onViewTask={() =>
                              handleOpenTaskModal({ callId: call.id, taskId: task.id, variant: 'info' })
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div css={styles.tasksMobileWrapper}>
                    {tasks.map((task) => (
                      <div key={task.id} css={styles.taskMobileRow}>
                        <div className='meatball-menu-wrapper'>
                          <TaskMeatballMenu
                            isDemoAccount={isDemoAccount}
                            onDeleteTask={() =>
                              handleOpenTaskModal({ callId: call.id, taskId: task.id, variant: 'delete' })
                            }
                            onViewCallTakeaways={() => handleOpenCallTakeaways(call)}
                            onViewTask={() =>
                              handleOpenTaskModal({ callId: call.id, taskId: task.id, variant: 'info' })
                            }
                          />
                        </div>

                        <div className='task-content'>
                          <div className='task-item-wrapper'>
                            <Text size='medium' color='subdued'>
                              {t('Task')}
                            </Text>
                            <Text css={[designSystemStyles.multilineTruncate(4)]} size='medium'>
                              <Text as='span' weight='bold' size='medium'>
                                {task.title}
                              </Text>{' '}
                              {task.description}
                            </Text>
                          </div>

                          <div className='task-actions'>
                            <div className='task-item-wrapper'>
                              <Text size='medium' color='subdued'>
                                {t('Status')}
                              </Text>

                              <TaskStatusSwitcher
                                defaultOptionValue={task.status}
                                onStatusChange={(newStatus) =>
                                  handleStatusChange({
                                    callId: call.id,
                                    taskId: task.id,
                                    newStatus,
                                    oldStatus: task.status,
                                  })
                                }
                                trackingId={generateTrackingId({
                                  component: 'follow-up-page',
                                  context: 'task-status-switcher-btn-click',
                                  isDemoAccount,
                                })}
                                value={task.status}
                              />
                            </div>
                            <div className='task-item-wrapper'>
                              <Text size='medium' color='subdued'>
                                {t('Follow-up Reason')}
                              </Text>
                              <CallIntelChip
                                as='category'
                                chipVariants={chipVariants}
                                dataKey={task.type}
                                label={dataLabels.taskTypes?.[task.type] || task.type}
                                key={task.type}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {isLoadingTasks && (
                  <FollowUpLoader
                    count={
                      followUpItem.totalTasks <= MAX_EXPECTED_TASKS
                        ? followUpItem.totalTasks
                        : Math.floor(Math.random() * MAX_EXPECTED_TASKS) + 1
                    }
                    disabled={isLoadingTasks}
                    variant={!isCompactLayout ? 'task' : 'taskMobile'}
                  />
                )}
              </div>
            );
          })}

          {hasMoreThanOnePage && (
            <div css={styles.loadMoreWrapper}>
              {canLoadMore ? (
                <Button variant='tertiary' onClick={handleLoadMore}>
                  {t('Load More Calls')}
                </Button>
              ) : (
                <Text size='small' color='subdued'>
                  {t('All calls loaded')}
                </Text>
              )}
            </div>
          )}
        </Accordion.Body>
        {selectedCallIdForModal && selectedTaskId && (
          <FollowUpTaskModal
            callId={selectedCallIdForModal}
            followUpItem={followUpItem}
            taskId={selectedTaskId}
            variant={taskModalVariant}
            modalProps={modalProps}
            onClose={handleTaskModalClose}
            onNext={handleNextTask}
            onPrevious={handlePreviousTask}
            onStatusChange={handleStatusChange}
            onTaskDelete={handleTaskDelete}
          />
        )}
      </Accordion.Item>
    );
  }
);

FollowUpCard.displayName = 'FollowUpCard';

export const FollowUpsDashboard = ({ isDemoAccount, isDrillDown, drillDownTaskType, taskStatusFilters }: Props) => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');

  const [accordions, setAccordions] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<CallIntelTypes.SearchState>({ query: '', value: '' });
  const [pageConfig, setPageConfig] = useState<AnalyticsCommonTypes.PageConfig>({
    pageNumber: 1,
    pageSize: 10,
  });
  const [sortType, setSortType] = useState<CallIntelTypes.SortTypeEnum>(CallIntelTypes.SortTypeEnum.SORT_DESC);
  const [sortBy, setSortBy] = useState<CallIntelTypes.FollowupSortFieldEnum>(
    CallIntelTypes.FollowupSortFieldEnum.SORT_LAST_CALL
  );
  const [shouldLoadTasksMap, setShouldLoadTasksMap] = useState<Record<string, boolean>>({});
  const [isListsRendered, setIsListsRendered] = useState(false);
  const [isDataProcessing, setIsDataProcessing] = useState(true);

  const nodeMapRef = useRef<Map<Element, { contactId: string; index: number }>>(new Map());
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const { followUps, totalPatients, taskStats, setFollowUps, setTotalPatients, setTaskStats } =
    useCallIntelFollowUpsShallowStore(
      'followUps',
      'totalPatients',
      'taskStats',
      'setFollowUps',
      'setTotalPatients',
      'setTaskStats'
    );
  const { dataLabels, filters } = useCallIntelShallowStore('dataLabels', 'filters');
  const { setAllCalls, setIsDemoAccount } = useCallTakeawayPanelShallowStore('setAllCalls', 'setIsDemoAccount');
  const { lastUsedVertical: vertical } = useLastUsedVerticalShallowStore('lastUsedVertical');

  const {
    modalProps: demoCallTakeawayProps,
    openModal: openDemoCallTakeaway,
    closeModal: closeDemoCallTakeaway,
  } = useModalControl();

  const followupTaskTypeFilters: CallIntelTypes.FollowupTaskTypeFilters = {
    startDate: filters.startDate,
    endDate: filters.endDate,
    locations: filters.locations,
    officeUsers: filters.officeUsers,
    contactTypes: filters.contactTypes,
    taskTypes: isDrillDown ? [drillDownTaskType] : [],
    taskStatus: taskStatusFilters,
  };

  const queryString = useMemo(
    () =>
      `calls-followups-${JSON.stringify(followupTaskTypeFilters)}-${searchQuery.query}-${pageConfig.pageSize}-${
        pageConfig.pageNumber
      }-${sortBy}-${sortType}-isDemoAccount-${isDemoAccount}`,
    [
      followupTaskTypeFilters,
      pageConfig.pageSize,
      pageConfig.pageNumber,
      searchQuery.query,
      sortBy,
      sortType,
      isDemoAccount,
    ]
  );

  const statsQueryString = useMemo(
    () =>
      `calls-followups-stats-${JSON.stringify(followupTaskTypeFilters)}-${pageConfig.pageSize}-${
        pageConfig.pageNumber
      }-${sortBy}-${sortType}-isDemoAccount-${isDemoAccount}`,
    [followupTaskTypeFilters, pageConfig.pageSize, pageConfig.pageNumber, sortBy, sortType, isDemoAccount]
  );

  const {
    data,
    hasPreviousPage,
    isFetching: isFollowUpLoading,
  } = useScopedInfiniteQuery<CallIntelTypes.FollowUpResponse, unknown>({
    queryKey: queryKeys.callIntelligence(queryString),
    queryFn: () =>
      isDemoAccount
        ? CallIntelligenceApi.noopMutationFn(
            CallIntelMockData.getFollowUps({
              filters: followupTaskTypeFilters,
              pageConfig: { pageSize: 10, pageNumber: 1 },
              sortBy: CallIntelTypes.FollowupSortFieldEnum.SORT_LAST_CALL,
              sortType: CallIntelTypes.SortTypeEnum.SORT_DESC,
              randomFollowUps: false,
              vertical,
            })
          )
        : CallIntelligenceApi.getFollowUps({
            filters: followupTaskTypeFilters,
            pageConfig,
            searchQuery: searchQuery.value,
            sortBy,
            sortType,
          }),
    getNextPageParam: (data) => ({
      pageNumber: (data?.pageConfig?.pageNumber || 0) + 1,
      pageSize: data?.pageConfig?.pageSize || 10,
    }),
    getPreviousPageParam: (data) => ({
      pageNumber: (data?.pageConfig?.pageNumber || 2) - 1,
      pageSize: data?.pageConfig?.pageSize || 10,
    }),
    onError: () => {
      alert.error(t('Failed to fetch follow-ups'));
    },
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { data: statsRes } = useScopedQuery<CallIntelTypes.FollowUpStatsResponse | undefined, unknown>({
    queryKey: queryKeys.callIntelligence(statsQueryString),
    queryFn: () =>
      isDemoAccount
        ? CallIntelligenceApi.noopMutationFn(null)
        : CallIntelligenceApi.getAllStats({
            filters: followupTaskTypeFilters,
          }),
    onError: () => {
      alert.error(t('Failed to fetch stats'));
    },
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data) {
      const { allFollowUps, allCalls, totalFollowUps } = processFollowUpsData(data);
      setAllCalls(allCalls);
      setFollowUps(allFollowUps);
      setTotalPatients(totalFollowUps);
      setAccordions(allFollowUps.map((followUp) => followUp.contact.contactId) ?? []);
      setIsDataProcessing(false);
    }
  }, [data, isDemoAccount]);

  useEffect(() => {
    if (statsRes?.stats) {
      setTaskStats({
        STATUS_TODO: statsRes.stats?.STATUS_TODO || 0,
        STATUS_IN_PROGRESS: statsRes.stats?.STATUS_IN_PROGRESS || 0,
        STATUS_COMPLETED: statsRes.stats?.STATUS_COMPLETED || 0,
      });
    }
  }, [statsRes?.stats]);

  // Intersection Observer for "lazy loading tasks"
  useEffect(() => {
    if (isDemoAccount) return;
    if (!followUps.length) return;

    // 2) Override PRELOAD_COUNT and rootMargin if pageSize is 100 for fast scrolling
    let effectivePreloadCount = DEFAULT_PRELOAD_COUNT;
    let effectiveRootMargin = '0px';
    if (pageConfig.pageSize === 100) {
      effectivePreloadCount = 5;
      effectiveRootMargin = '200px';
    }

    const observer = new IntersectionObserver(
      (entries) => {
        // We'll batch newly visible items
        const newlyVisibleNodes: Array<{ contactId: string; index: number }> = [];

        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Unobserve so we don't keep firing for the same element
            observer.unobserve(entry.target);

            // Find which contactId is associated with this DOM node
            const node = nodeMapRef.current.get(entry.target);
            if (!node) return;

            const { contactId, index } = node;

            // If it's already marked, skip
            if (shouldLoadTasksMap[contactId]) {
              return;
            }

            // We'll collect it to handle in one setState call
            newlyVisibleNodes.push({ contactId, index });
          }
        });

        // 3) After processing all intersecting entries, do ONE state update
        if (newlyVisibleNodes.length > 0) {
          setShouldLoadTasksMap((prev) => {
            const updated = { ...prev };

            // For each newly visible contactId, plus up to PRELOAD_COUNT items
            newlyVisibleNodes.forEach(({ index }) => {
              for (let i = index; i < index + effectivePreloadCount + 1 && i < followUps.length; i++) {
                const nextId = followUps[i].contact.contactId;
                updated[nextId] = true;
              }
            });

            return updated;
          });
        }
      },
      {
        root: null,
        rootMargin: effectiveRootMargin,
        threshold: 0.0, // triggers as soon as item enters the viewport
      }
    );

    if (isListsRendered) {
      followUps.forEach((followUp) => {
        const contactId = followUp.contact.contactId;

        const node = [...nodeMapRef.current.keys()].find((el) => {
          const data = nodeMapRef.current.get(el);
          return data?.contactId === contactId;
        });
        if (node) observer.observe(node);
      });
    }

    return () => observer.disconnect();
  }, [followUps, isListsRendered, isDemoAccount, pageConfig.pageSize]);

  useEffect(() => {
    setIsDemoAccount(isDemoAccount);
  }, [isDemoAccount]);

  const handleAccordionChange = useCallback((value: string[]) => {
    setAccordions(value);
  }, []);

  const handlePaginationAfterApplyingFilters = useCallback(() => {
    setPageConfig((pageConfig) => ({ ...pageConfig, pageNumber: 1 }));
  }, []);

  const handleSearchOnChange = useCallback(
    (value: string, debounced?: boolean) => {
      if (!debounced) {
        if (value == '') {
          setSearchQuery({ query: '', value: '' });
        } else {
          setSearchQuery((prev) => ({ ...prev, value }));
        }
      } else {
        if (value.length >= MIN_SEARCH_LENGTH) {
          setSearchQuery({ value, query: value });
          handlePaginationAfterApplyingFilters();
        } else {
          setSearchQuery((prev) => ({ ...prev, value }));
        }
      }
    },
    [handlePaginationAfterApplyingFilters]
  );

  const handleSortChange = useCallback(
    (sortBy: CallIntelTypes.FollowupSortFieldEnum, sortType: CallIntelTypes.SortTypeEnum) => {
      setSortBy(sortBy);
      setSortType(sortType);
    },
    []
  );

  useEffect(() => {
    if (isFollowUpLoading) {
      handleReset();
    }
  }, [isFollowUpLoading]);

  const handleReset = () => {
    setIsDataProcessing(true);
    setAccordions([]);
    setShouldLoadTasksMap({});
    setIsListsRendered(false);
    setFollowUps([]);
    setAllCalls([]);
    setTaskStats({
      STATUS_TODO: 0,
      STATUS_IN_PROGRESS: 0,
      STATUS_COMPLETED: 0,
    });

    nodeMapRef.current.clear();
    wrapperRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <div ref={wrapperRef} css={styles.wrapper}>
      {isFollowUpLoading ? (
        <FollowUpLoader variant='stats' />
      ) : (
        <FollowUpStats taskStatusFilters={taskStatusFilters} totalPatients={totalPatients} taskStats={taskStats} />
      )}
      <div css={styles.followUpWrapper}>
        <div css={styles.filterContainer}>
          <CallIntelSortFilter
            selectedSortOption='lastCallLatest'
            onSortChange={handleSortChange}
            trackingId={trackingIds.callIntel.followUpSortFilter}
          />
          <CallIntelSearch
            minSearchLength={MIN_SEARCH_LENGTH}
            onChange={handleSearchOnChange}
            value={searchQuery.value}
            trackingId={trackingIds.callIntel.followUpPageSearch}
            css={styles.searchInput}
          />
        </div>

        {isFollowUpLoading || isDataProcessing ? (
          <FollowUpLoader count={pageConfig.pageSize} variant='followUp' />
        ) : (
          <>
            {!followUps.length ? (
              <EmptyState
                label={t(`No {{taskTypeLabel}} Follow-Ups`, {
                  taskTypeLabel: dataLabels?.taskTypes?.[drillDownTaskType] ?? drillDownTaskType,
                })}
              />
            ) : (
              <Accordion
                css={styles.accordion}
                controlledValue={accordions}
                shouldRenderBody
                onChange={handleAccordionChange}
                isMulti
              >
                {followUps.map((followUp, idx) => {
                  const id = followUp.contact.contactId;
                  return (
                    <FollowUpCard
                      drillDownTaskType={drillDownTaskType}
                      followUpItem={followUp}
                      isDemoAccount={!!isDemoAccount}
                      isDrillDown={isDrillDown}
                      key={id}
                      openDemoCallTakeaway={openDemoCallTakeaway}
                      ref={(node) => {
                        if (node) {
                          nodeMapRef.current.set(node, { contactId: id, index: idx });
                        } else {
                          nodeMapRef.current.forEach((_, key) => {
                            if (key === node) nodeMapRef.current.delete(key);
                          });
                        }

                        if (idx === followUps.length - 1) {
                          setIsListsRendered(true);
                        }
                      }}
                      shouldLoadTasks={!!shouldLoadTasksMap[id]}
                      taskStatusFilters={taskStatusFilters}
                      value={id}
                    />
                  );
                })}
              </Accordion>
            )}
          </>
        )}
      </div>

      <Pagination
        handleChange={(action: 'prev' | 'next'): void => {
          if (action === 'next') {
            setPageConfig({ ...pageConfig, pageNumber: pageConfig.pageNumber + 1 });
          } else {
            setPageConfig({ ...pageConfig, pageNumber: pageConfig.pageNumber - 1 });
          }
        }}
        hasNext={data?.pages[pageConfig.pageNumber - 1]?.hasNextPage}
        hasPrevious={hasPreviousPage}
        onNumRowsChange={(num) => {
          setPageConfig({ pageNumber: 1, pageSize: num });
        }}
        page={pageConfig.pageNumber}
        rowsPerPageOptions={[10, 25, 50, 100]}
        globalTrackingId={generateTrackingId({
          component: 'follow-up-page',
          isDemoAccount,
        })}
      />

      {!!isDemoAccount && (
        <Tray
          mountTarget='[data-settings-modal-content]'
          width='medium'
          css={{ padding: 0 }}
          {...demoCallTakeawayProps}
        >
          <CallTakeawayPanel onClose={closeDemoCallTakeaway} />
        </Tray>
      )}
    </div>
  );
};

const styles = {
  accordion: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    gap: ${theme.spacing(2)};

    > div {
      box-shadow: none;
      border: 1px solid ${theme.colors.neutral20};
      margin-bottom: 0px;
    }

    > div h3 button section div svg {
      fill: ${theme.colors.neutral70};
    }

    div[data-active='true'] h3 button {
      background-color: ${theme.colors.neutral5};
      border-bottom: 1px solid ${theme.colors.neutral10};

      section div svg {
        fill: ${theme.colors.neutral90};
      }
    }
  `,

  body: css`
    padding: ${theme.spacing(2)};
  `,

  detailsWrapper: css`
    display: flex;
    gap: ${theme.spacing(2)};
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
    row-gap: ${theme.spacing(0.5)};

    .task-wrapper {
      display: flex;
      align-items: center;
      gap: ${theme.spacing(0.5)};
      width: 88px;
      p {
        white-space: nowrap;
      }
    }

    .calls-wrapper {
      display: flex;
      align-items: center;
      gap: ${theme.spacing(0.5)};
      width: 88px;
      p {
        white-space: nowrap;
      }
    }

    .time-stamp-wrapper {
      display: flex;
      gap: ${theme.spacing(0.5)};

      p {
        white-space: nowrap;
      }
    }
  `,

  userCard: css`
    width: 304px;
    min-width: 304px;

    @media screen and (max-width: 620px) {
      width: auto;
      min-width: auto;
    }

    > div {
      cursor: pointer !important;
      flex: 1;
      > figure {
        height: ${theme.spacing(4)};
        width: ${theme.spacing(4)};
        min-height: ${theme.spacing(4)};
        min-width: ${theme.spacing(4)};
        font-size: ${theme.font.size.small};
      }

      > div {
        width: auto !important;
      }

      .name-wrapper {
        flex-wrap: wrap;
        row-gap: ${theme.spacing(0.5)};
        > p {
          font-size: ${theme.font.size.h3};
          line-height: ${theme.spacing(3)};
          text-align: left;
          span {
            font-size: ${theme.font.size.h3};
          }
        }

        > div > span {
          font-size: ${theme.font.size.small};
        }
      }
    }
  `,

  emptyStateWrapper: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,

  emptyStateText: css`
    color: ${theme.colors.neutral50};
  `,

  followUpLoaderWrapper: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};

    .row {
      border-radius: ${theme.spacing(1)};
    }
  `,

  filterContainer: css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: ${theme.spacing(2)};
    flex-wrap: wrap;
  `,

  header: css`
    padding: ${theme.spacing(2)};
    gap: ${theme.spacing(2)};

    section {
      padding: ${theme.spacing(0.5)};
      border: none;
      marging-right: ${theme.spacing(0)};
      width: ${theme.spacing(4)};
      height: ${theme.spacing(4)};
    }
    .header-container {
      display: flex;
      padding: 0px;
      gap: ${theme.spacing(4)};
      justify-content: space-between;
      align-items: center;
    }
  `,

  searchInput: css`
    @media screen and (max-width: 475px) {
      width: 285px;
      > input {
        font-size: ${theme.font.size.large};
      }
    }
  `,

  statsWrapper: css`
    width: 100%;
    display: flex;
    gap: ${theme.spacing(2)};
    padding: ${theme.spacing(3)};
    border: ${theme.spacing(0.125)} solid ${theme.font.colors.disabled};
    border-radius: ${theme.borderRadius.medium};
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (max-width: ${breakpoints.medium.min}px) {
      .info-wrapper:nth-of-type(1),
      .info-wrapper:nth-of-type(2),
      .info-wrapper:nth-of-type(3),
      .info-wrapper:nth-of-type(4) {
        flex: 1 1 22%;
      }

      .info-wrapper:nth-of-type(5),
      .info-wrapper:nth-of-type(6) {
        flex: 1 1 45%;
      }
    }

    @media screen and (max-width: ${breakpoints.small.min}px) {
      gap: ${theme.spacing(1)};
      .info-wrapper:nth-of-type(1),
      .info-wrapper:nth-of-type(2),
      .info-wrapper:nth-of-type(3) {
        flex: 1 1 30%;
      }

      .info-wrapper:nth-of-type(4),
      .info-wrapper:nth-of-type(5) {
        flex: 1 1 45%;
      }

      .info-wrapper:nth-of-type(6) {
        flex: 1 1 100%;
      }
    }

    .info-wrapper {
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(1)};
      align-items: center;

      .value {
        font-size: ${theme.spacing(4.5)};
        line-height: ${theme.spacing(5)};
      }
    }
  `,

  taskActionWrapper: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
  `,

  taskRow: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacing(2)};
    // border-top: 1px solid ${theme.colors.neutral20};
    border-bottom: 1px solid ${theme.colors.neutral20};
    gap: ${theme.spacing(1)};

    .data-cols {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
    }

    .status-column {
      display: flex;
      justify-content: flex-start;
      width: 130px;
    }

    .reason-column {
      display: flex;
      justify-content: flex-start;
      width: 150px;
      > div {
        justify-content: flex-start;
      }
    }

    .action-column {
      display: flex;
      justify-content: flex-end;
      width: 32px;
    }

    :hover {
      background-color: ${theme.colors.neutral5};
      cursor: pointer;
    }
  `,

  taskMobileRow: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1)};
    padding: ${theme.spacing(0, 2, 2, 2)};

    .meatball-menu-wrapper {
      display: flex;
      padding: ${theme.spacing(1)};
      background-color: ${theme.colors.neutral5};
      justify-content: flex-end;
      border-radius: ${theme.borderRadius.small};
    }

    .task-content {
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(1)};
      padding: ${theme.spacing(2)};
    }

    .task-item-wrapper {
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(1)};
    }

    .task-actions {
      display: flex;
      gap: ${theme.spacing(1)};
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
  `,

  viewButtonWrapper: css`
    color: ${theme.colors.text.default};
    flex: 0 0 auto;
    font-weight: ${theme.font.weight.bold};
    gap: ${theme.spacing(0.5)};
    padding: ${theme.spacing(0.5)};
    min-height: ${theme.spacing(4)};
    min-width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
    border: none;
  `,

  wrapper: css`
    scroll-margin-top: 600px;
  `,
  followUpWrapper: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    margin: ${theme.spacing(5, 0)};
  `,

  callWrapper: css`
    display: flex;
    gap: ${theme.spacing(4)};
    row-gap: ${theme.spacing(0.5)};
    flex-wrap: wrap;

    .office-user-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: ${theme.spacing(1)};

      p {
        white-space: nowrap;
      }
    }

    .time-stamp-wrapper {
      display: flex;
      gap: ${theme.spacing(1)};
      justify-content: flex-start;
      align-items: center;

      p {
        white-space: nowrap;
      }
    }

    .location-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: ${theme.spacing(1)};
      // flex: 1 1 100%;
    }
  `,
  actionsWrapper: css`
    display: flex;
    gap: ${theme.spacing(2)};
    align-items: center;

    button {
      border: none !important;
      background-color: inherit !important;
      width: ${theme.spacing(4)};
      height: ${theme.spacing(4)};
      min-width: ${theme.spacing(4)};
      min-height: ${theme.spacing(4)};
      svg {
        width: ${theme.spacing(3)};
        height: ${theme.spacing(3)};
      }
    }

    button:hover {
      color: inherit !important;
    }
  `,
  headerContainer: css`
    display: flex;
    gap: ${theme.spacing(2)};
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: ${theme.spacing(1)};
  `,

  taskTableHeader: css`
    display: flex;
    padding: ${theme.spacing(2)};
    align-items: center;
    gap: ${theme.spacing(1)};
    border-bottom: 1px solid ${theme.colors.neutral20};

    .data-cols {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
    }

    .status-column {
      display: flex;
      justify-content: flex-start;
      width: 130px;
    }

    .reason-column {
      display: flex;
      justify-content: flex-start;
      width: 150px;
    }

    .action-column {
      display: flex;
      justify-content: flex-end;
      width: 32px;
    }
  `,

  focusedCall: css`
    border: 1px solid ${theme.colors.primary50};
  `,
  detailsWrapperActions: css`
    display: none;
    @media screen and (max-width: ${breakpoints.small.max}px) {
      display: flex;
      flex: 1 1 100%;
    }
  `,
  detailsWrapperActionsMobile: css`
    display: flex;
    flex: 1 1 100%;
  `,
  contactActions: css`
    display: flex;
    @media screen and (max-width: ${breakpoints.small.max}px) {
      display: none;
    }
  `,
  contactActionsMobile: css`
    display: none;
  `,
  callContainer: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${theme.colors.neutral5};
    border-radius: ${theme.borderRadius.medium};
    padding: ${theme.spacing(1, 2)};
    gap: ${theme.spacing(2)};
  `,

  loadMoreWrapper: css`
    text-align: center;
  `,
  meatBallBtnWrapper: css`
    padding: ${theme.spacing(0.5)};
    min-height: ${theme.spacing(4)};
    min-width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
    width: ${theme.spacing(4)};
  `,
  tasksMobileWrapper: css`
    margin-top: ${theme.spacing(1)};
  `,
};
