import { createProvider } from '@frontend/store';
import { FieldConditionConstants } from '../constants';
import { FieldTypes, SectionTypes, FieldConditionTypes, BuilderFormElementTypes } from '../types';
import { genUID, FieldUtils, ConditionUtils } from '../utils';
import { BuilderEngineState, BuilderEngineStore } from './types';

const RESET_TIMEOUT = 100;
const DEFAULT_BUILDER_STATE: BuilderEngineState = {
  assetId: '',
  formId: '',
  formTitle: '',
  sharedLocationIds: [],
  sectionOrder: [],
  sections: {},
  invalidSectionIds: {},
  fields: {},
  orderedFields: [],
  conditions: {},
  invalidConditions: {},
  triggerChangeDetectionForConditions: false,
  hasPaymentsField: false,
  fieldIdsToBeIgnoredForConditions: {},
  categorizedPrimaryMedicalHistoryFieldOptions: {
    allergies: {},
    medications: {},
    disease: {},
  },
};

export const { Provider: BuilderEngineStoreProvider, useStore: useBuilderEngineStore } =
  createProvider<BuilderEngineStore>()((set, get) => ({
    ...DEFAULT_BUILDER_STATE,

    initialize: (payload, reset = true) => {
      if (reset) {
        set({
          ...DEFAULT_BUILDER_STATE,
          ...payload,
        });
      }

      set(() => payload);
    },

    resetBuilderEngineState: () => set(DEFAULT_BUILDER_STATE),
    setAssetId: (assetId) => set(() => ({ assetId })),
    setFormId: (formId) => set(() => ({ formId })),
    setFormTitle: (title) => set(() => ({ formTitle: title })),

    setSharedLocationIds: (value) => set(() => ({ sharedLocationIds: [...value] })),
    toggleSharedLocationId: (locationId) =>
      set((state) => {
        const index = state.sharedLocationIds.indexOf(locationId);
        if (index === -1) {
          return {
            sharedLocationIds: [...state.sharedLocationIds, locationId],
          };
        }

        const newSharedLocationIds = [...state.sharedLocationIds];
        newSharedLocationIds.splice(index, 1);

        return {
          sharedLocationIds: newSharedLocationIds,
        };
      }),

    // ============ Section Actions ============

    createSection: (payload = {}) => {
      const { title = '', hasPaymentsField = false } = payload;
      const newSectionId = genUID();
      set((state) => {
        return {
          sectionOrder: [...state.sectionOrder, newSectionId],
          sections: {
            ...state.sections,
            [newSectionId]: {
              id: newSectionId,
              title,
              fields: [],
              hasPaymentsField,
            },
          },
        };
      });

      return newSectionId;
    },

    deleteSection: (sectionId, index) => {
      const removedPrimaryFieldKeys: BuilderFormElementTypes.PrimaryFields.PrimaryFieldKey[] = [];
      let removedSectionTemplateKey: BuilderFormElementTypes.SectionTemplates.TemplateKey | undefined = undefined;

      set((state) => {
        const newSectionOrder = index
          ? state.sectionOrder.splice(index, 1)
          : state.sectionOrder.filter((id) => id !== sectionId);

        const newSections = { ...state.sections };
        let newFields = { ...state.fields };
        let newConditions = { ...state.conditions };
        let categorizedPrimaryMedicalHistoryFieldOptionsUpdate = state.categorizedPrimaryMedicalHistoryFieldOptions;
        const sectionToDelete = newSections[sectionId];

        if (sectionToDelete.sectionTemplateKey) {
          removedSectionTemplateKey = sectionToDelete.sectionTemplateKey;
        }

        sectionToDelete.fields.forEach((fieldId) => {
          const fieldToRemove = newFields[fieldId];
          const primaryFieldKey = fieldToRemove.meta.dataKey;
          if (primaryFieldKey) {
            // Collect primary field keys that were removed from the section.
            removedPrimaryFieldKeys.push(primaryFieldKey);
          }

          const conditionIdsToRemove = [...(fieldToRemove.conditionIds || [])];

          // Remove all the conditions associated with the field.
          const sourceConditionsResult = ConditionUtils.removeConditions({
            sourceFieldId: fieldId,
            conditionIds: conditionIdsToRemove,
            fields: newFields,
            conditions: newConditions,
            removeActionsTargetingSourceField: true,
          });

          newFields = sourceConditionsResult.updatedFields;
          newConditions = sourceConditionsResult.updatedConditions;

          // Update conditions that target this field
          const targetConditionsResult = ConditionUtils.removeTargetIdFromConditions({
            conditions: newConditions,
            targetId: fieldId,
          });

          newConditions = targetConditionsResult.updatedConditions;

          // Reset the categories primary medical history options (if applicable)
          categorizedPrimaryMedicalHistoryFieldOptionsUpdate =
            FieldUtils.resetCategorizedPrimaryMedicalHistoryFieldOptions({
              field: fieldToRemove,
              categorizedPrimaryMedicalHistoryFieldOptions: categorizedPrimaryMedicalHistoryFieldOptionsUpdate,
            });

          // Remove field from fields
          delete newFields[fieldId];
        });

        // Remove section
        delete newSections[sectionId];

        return {
          sectionOrder: newSectionOrder,
          sections: newSections,
          fields: newFields,
          conditions: newConditions,
          triggerChangeDetectionForConditions: true,
          hasPaymentsField: FieldUtils.checkForPaymentFieldsInForm(newFields),
          categorizedPrimaryMedicalHistoryFieldOptions: categorizedPrimaryMedicalHistoryFieldOptionsUpdate,
        };
      });

      // Reset the triggerChangeDetectionForConditions flag after a short delay.
      setTimeout(() => {
        set(() => ({
          triggerChangeDetectionForConditions: false,
        }));
      }, RESET_TIMEOUT);

      return {
        removedPrimaryFieldKeys,
        removedSectionTemplateKey,
      };
    },

    updateSection: (section) =>
      set((state) => ({
        sections: {
          ...state.sections,
          [section.id]: section,
        },
      })),

    updateSectionTitle: (sectionId, title) =>
      set((state) => {
        const section = state.sections[sectionId];

        return {
          sections: {
            ...state.sections,
            [sectionId]: {
              ...section,
              title,
            },
          },
        };
      }),

    cloneSection: (sectionId: string) => {
      const newSectionId = genUID();

      set((state) => {
        const fieldIdsForNewSection: string[] = [];
        let newFields = { ...state.fields };
        const section = state.sections[sectionId];

        for (const fieldId of section.fields) {
          const field = state.fields[fieldId];

          if (field.meta.dataKey) {
            // We don't want to clone the primary fields.
            continue;
          }

          const newFieldId = genUID();
          fieldIdsForNewSection.push(newFieldId);

          newFields = {
            ...newFields,
            [newFieldId]: {
              ...field,
              id: newFieldId,
              conditionIds: [], // Conditions should not be copied.
            },
          };
        }

        const newSection: SectionTypes.Section = {
          id: newSectionId,
          fields: fieldIdsForNewSection,
          title: `${state.sections[sectionId].title} (copy)`,
        };
        const newSections = { ...state.sections, [newSectionId]: newSection };
        const newSectionOrder = [...state.sectionOrder, newSectionId];

        return {
          sectionOrder: newSectionOrder,
          sections: newSections,
          fields: newFields,
        };
      });

      return newSectionId;
    },

    moveSection: (sectionId: string, direction: 'up' | 'down') =>
      set((state) => {
        const sectionOrder = [...state.sectionOrder];
        const currentIndex = sectionOrder.indexOf(sectionId);

        if (direction === 'up') {
          if (currentIndex === 0) {
            return state;
          }

          sectionOrder.splice(currentIndex, 1);
          sectionOrder.splice(currentIndex - 1, 0, sectionId);
        } else {
          if (currentIndex === sectionOrder.length - 1) {
            return state;
          }

          sectionOrder.splice(currentIndex, 1);
          sectionOrder.splice(currentIndex + 1, 0, sectionId);
        }

        return {
          sectionOrder,
        };
      }),

    isSectionCloneable: (sectionId: string) => {
      const section = get().sections[sectionId];
      return section.fields.length > 0;
    },

    addSectionTemplate: ({ fields, placementIndex, title, sectionTemplateKey }) => {
      if (fields.length === 0) {
        return '';
      }

      const fieldsToAdd = fields.map(FieldUtils.generateFieldToAdd);
      const newSectionId = genUID();
      const fieldIds: string[] = [];

      for (const field of fieldsToAdd) {
        if (!field.id) {
          continue;
        }

        fieldIds.push(field.id);
      }

      const newSection: SectionTypes.Section = {
        id: newSectionId,
        fields: fieldIds,
        title,
        sectionTemplateKey,
      };

      set((state) => {
        const newSections = { ...state.sections, [newSectionId]: newSection };
        const newSectionOrder = [...state.sectionOrder];
        const newConditions = { ...state.conditions };
        newSectionOrder.splice(placementIndex, 0, newSectionId);

        const newFields = fieldsToAdd.reduce(
          (acc, field) => {
            if (field.id) {
              acc[field.id] = field;
            }
            return acc;
          },
          { ...state.fields }
        );

        // Add conditions for the fields in the "allergies" template.
        if (sectionTemplateKey === 'allergies') {
          const mainFieldId = fieldsToAdd[0].id;
          if (!mainFieldId) {
            return state;
          }

          const conditionId = genUID();

          // Create a condition that shows all the other fields when the main field is answered.
          const conditionForAllergies: FieldConditionTypes.FormCondition = {
            id: conditionId,
            operator: 'and',
            terms: [
              {
                field: mainFieldId,
                check: 'equals',
                value: 'Yes',
              },
            ],
            actions: [],
          };

          for (const field of fieldsToAdd) {
            const fieldId = field.id;
            if (!fieldId) {
              continue;
            }

            // Add condition ID to the main field
            if (fieldId === mainFieldId) {
              field.conditionIds = [conditionId];
              continue;
            }

            // Hide all the fields except the main field
            field.hidden = true;

            // Add all the fields (except the main) to the condition actions
            conditionForAllergies.actions.push({
              operation: 'show',
              target: fieldId,
              type: 'fields',
            });
          }
          newConditions[conditionId] = conditionForAllergies;
        }

        return {
          sectionOrder: newSectionOrder,
          sections: newSections,
          fields: newFields,
          conditions: newConditions,
        };
      });

      return newSectionId;
    },

    setInvalidSectionIds: (invalidSectionIds) => set(() => ({ invalidSectionIds })),

    isEmptySection: (sectionId) => {
      const state = get();
      const section = state.sections[sectionId];
      return section.fields.length === 0;
    },

    getSection: (sectionId) => {
      const state = get();
      return state.sections[sectionId];
    },

    // ============ Field Actions ============

    addField: ({ field, placementIndex, sectionId }) =>
      set((state) => {
        const section = state.sections[sectionId];
        const fieldIdsInSection = [...section.fields];
        const fieldToAdd = FieldUtils.generateFieldToAdd(field);
        const newFieldId = fieldToAdd.id;
        if (!newFieldId) {
          return state;
        }

        fieldIdsInSection.splice(placementIndex, 0, newFieldId);
        const modifiedFields = { ...state.fields, [newFieldId]: fieldToAdd };
        const hasPaymentsFieldInSection = FieldUtils.checkForPaymentFieldsInSection(fieldIdsInSection, modifiedFields);
        const fieldIdsToBeIgnoredForConditions = { ...state.fieldIdsToBeIgnoredForConditions };

        if (hasPaymentsFieldInSection) {
          fieldIdsToBeIgnoredForConditions[newFieldId] = true;
        }

        return {
          sections: {
            ...state.sections,
            [sectionId]: {
              ...section,
              fields: fieldIdsInSection,
              hasPaymentsField: hasPaymentsFieldInSection,
            },
          },
          fields: {
            ...state.fields,
            [newFieldId]: fieldToAdd,
          },
          hasPaymentsField: FieldUtils.checkForPaymentFieldsInForm(modifiedFields),
          fieldIdsToBeIgnoredForConditions,
        };
      }),

    updateFieldTitle: (fieldId, title) =>
      set((state) => {
        const field = state.fields[fieldId];

        return {
          fields: {
            ...state.fields,
            [fieldId]: {
              ...field,
              label: title,
            },
          },
        };
      }),

    markFieldAsRequired: (fieldId, isRequired) =>
      set((state) => {
        const field = state.fields[fieldId];

        return {
          fields: {
            ...state.fields,
            [fieldId]: {
              ...field,
              required: isRequired,
            },
          },
        };
      }),

    addFieldOption: (fieldId, placementIndex) =>
      set((state) => {
        const field = state.fields[fieldId] as FieldTypes.BaseOptionsField;
        const modifiedFieldOptions = [...(field.options || [])];
        // const newOptionId = genUID(); // TODO: Restore this after the BE fix.
        const newOptionId = placementIndex ? `Option ${genUID()}` : `Option ${modifiedFieldOptions.length + 1}`;

        if (placementIndex !== undefined) {
          modifiedFieldOptions.splice(placementIndex, 0, {
            label: newOptionId,
            value: newOptionId,
          });
        } else {
          modifiedFieldOptions.push({
            label: newOptionId,
            value: newOptionId,
          });
        }

        return {
          fields: {
            ...state.fields,
            [fieldId]: {
              ...field,
              options: modifiedFieldOptions,
            },
          },
        };
      }),

    updateFieldOptionLabel: (fieldId, optionIndex, label) =>
      set((state) => {
        const field = state.fields[fieldId] as FieldTypes.BaseOptionsField;
        const currentFieldOptions = field.options || [];
        const updatedOptions = [...currentFieldOptions];
        updatedOptions[optionIndex] = {
          ...updatedOptions[optionIndex],
          label,
          value: label, // TODO: Remove this after the BE fix.
        };

        return {
          fields: {
            ...state.fields,
            [fieldId]: {
              ...field,
              options: updatedOptions,
            },
          },
        };
      }),

    removeFieldOption: (fieldId, optionIndex) =>
      set((state) => {
        const field = state.fields[fieldId] as FieldTypes.BaseOptionsField;
        const currentFieldOptions = field.options || [];
        const updatedOptions = currentFieldOptions.filter((_, index) => index !== optionIndex);

        return {
          fields: {
            ...state.fields,
            [fieldId]: {
              ...field,
              options: updatedOptions,
            },
          },
        };
      }),

    moveFieldOption: ({ fieldOptionValue, newFieldId, oldFieldId, placementIndex }) => {
      if (oldFieldId === newFieldId) {
        // If the field option is moved within the same field, update the field option order.
        set((state) => {
          const field = state.fields[oldFieldId] as FieldTypes.BaseOptionsField;
          const fieldOptions = field.options || [];
          const fieldOptionIndex = fieldOptions.findIndex((option) => option.value === fieldOptionValue);
          const newFieldOptions = [...fieldOptions];
          newFieldOptions.splice(fieldOptionIndex, 1);
          newFieldOptions.splice(placementIndex, 0, fieldOptions[fieldOptionIndex]);

          return {
            fields: {
              ...state.fields,
              [oldFieldId]: {
                ...field,
                options: newFieldOptions,
              },
            },
          };
        });

        return;
      }

      // If the field option is moved to a different field, remove it from the old field and add it to the new field.
      set((state) => {
        const oldField = state.fields[oldFieldId] as FieldTypes.BaseOptionsField;
        const newField = state.fields[newFieldId] as FieldTypes.BaseOptionsField;
        const oldFieldOptions = oldField.options || [];
        const newFieldOptions = newField.options || [];
        const oldFieldOptionIndex = oldFieldOptions.findIndex((option) => option.value === fieldOptionValue);
        const newFieldOption = oldFieldOptions[oldFieldOptionIndex];
        const newFieldOptionsOrder = [...newFieldOptions];
        newFieldOptionsOrder.splice(placementIndex, 0, newFieldOption);

        return {
          fields: {
            ...state.fields,
            [oldFieldId]: {
              ...oldField,
              options: oldFieldOptions.filter((_, index) => index !== oldFieldOptionIndex),
            },
            [newFieldId]: {
              ...newField,
              options: newFieldOptionsOrder,
            },
          },
        };
      });
    },

    deleteField: (fieldId) => {
      let removedPrimaryFieldKey: string | undefined = undefined;

      set((state) => {
        const field = state.fields[fieldId];
        removedPrimaryFieldKey = field.meta.dataKey;
        const newSections = { ...state.sections };
        const fieldIdsToBeIgnoredForConditions = { ...state.fieldIdsToBeIgnoredForConditions };
        let isFieldFound = false;

        for (const sectionId of state.sectionOrder) {
          const section = { ...state.sections[sectionId] };
          const fieldIdsInSection = [...section.fields];
          for (let i = 0; i < fieldIdsInSection.length; i++) {
            if (fieldIdsInSection[i] === fieldId) {
              fieldIdsInSection.splice(i, 1);
              newSections[sectionId] = {
                ...section,
                fields: fieldIdsInSection,
                hasPaymentsField: false,
              };
              isFieldFound = true;
              break;
            }
          }

          // Check if the section has any payments field after removing the field.
          if (FieldUtils.checkForPaymentFieldsInSection(fieldIdsInSection, state.fields)) {
            newSections[sectionId].hasPaymentsField = true;
          }

          // Since the field can only be in one section, we can break out of the loop.
          if (isFieldFound) {
            delete fieldIdsToBeIgnoredForConditions[fieldId];
            break;
          }
        }

        let newFields = { ...state.fields };
        let newConditions = { ...state.conditions };
        const fieldToRemove = newFields[fieldId];
        const conditionIdsToRemove = [...(fieldToRemove.conditionIds || [])];

        // Remove all the conditions associated with the field.
        const sourceConditionsResult = ConditionUtils.removeConditions({
          sourceFieldId: fieldId,
          conditionIds: conditionIdsToRemove,
          fields: newFields,
          conditions: newConditions,
          removeActionsTargetingSourceField: true,
        });

        newFields = sourceConditionsResult.updatedFields;
        newConditions = sourceConditionsResult.updatedConditions;

        // Update conditions that target this field
        const targetConditionsResult = ConditionUtils.removeTargetIdFromConditions({
          conditions: newConditions,
          targetId: fieldId,
        });
        newConditions = targetConditionsResult.updatedConditions;
        delete newFields[fieldId];

        // Reset the categories primary medical history options (if applicable)
        const categorizedPrimaryMedicalHistoryFieldOptionsUpdate =
          FieldUtils.resetCategorizedPrimaryMedicalHistoryFieldOptions({
            field: fieldToRemove,
            categorizedPrimaryMedicalHistoryFieldOptions: state.categorizedPrimaryMedicalHistoryFieldOptions,
          });

        return {
          sections: newSections,
          fields: newFields,
          conditions: newConditions,
          triggerChangeDetectionForConditions: true,
          hasPaymentsField: FieldUtils.checkForPaymentFieldsInForm(newFields),
          fieldIdsToBeIgnoredForConditions,
          categorizedPrimaryMedicalHistoryFieldOptions: categorizedPrimaryMedicalHistoryFieldOptionsUpdate,
        };
      });

      // Reset the triggerChangeDetectionForConditions flag after a short delay.
      setTimeout(() => {
        set(() => ({
          triggerChangeDetectionForConditions: false,
        }));
      }, RESET_TIMEOUT);

      return {
        removedPrimaryFieldKey,
      };
    },

    isFieldCloneable: (fieldId: string) => {
      const field = get().fields[fieldId];
      return !field.meta.dataKey;
    },

    updateRichTextFieldValue: (fieldId, value) =>
      set((state) => {
        const field = state.fields[fieldId] as FieldTypes.BaseField;
        return {
          fields: {
            ...state.fields,
            [fieldId]: {
              ...field,
              value,
            },
          },
        };
      }),

    moveField: ({ fieldId, newSectionId, oldSectionId, placementIndex }) => {
      if (newSectionId === oldSectionId) {
        // If the field is moved within the same section, update the field order.
        set((state) => {
          const section = state.sections[newSectionId];
          const fieldIdsInSection = [...section.fields];
          const fieldIndex = fieldIdsInSection.indexOf(fieldId);
          fieldIdsInSection.splice(fieldIndex, 1);
          fieldIdsInSection.splice(placementIndex, 0, fieldId);

          return {
            sections: {
              ...state.sections,
              [newSectionId]: {
                ...section,
                fields: fieldIdsInSection,
              },
            },
          };
        });

        return;
      }

      // If the field is moved to a different section, remove it from the old section and add it to the new section.
      set((state) => {
        const fieldIdsToBeIgnoredForConditions = { ...state.fieldIdsToBeIgnoredForConditions };
        const oldSection = state.sections[oldSectionId];
        const newSection = state.sections[newSectionId];
        const oldFieldIds = [...oldSection.fields];
        const newFieldIds = [...newSection.fields];
        const oldFieldIndex = oldFieldIds.indexOf(fieldId);
        oldFieldIds.splice(oldFieldIndex, 1);
        newFieldIds.splice(placementIndex, 0, fieldId);
        const hasPaymentsFieldInOldSection = FieldUtils.checkForPaymentFieldsInSection(oldFieldIds, state.fields);
        const hasPaymentsFieldInNewSection = FieldUtils.checkForPaymentFieldsInSection(newFieldIds, state.fields);
        fieldIdsToBeIgnoredForConditions[fieldId] = hasPaymentsFieldInNewSection;

        return {
          sections: {
            ...state.sections,
            [oldSectionId]: {
              ...oldSection,
              fields: oldFieldIds,
              hasPaymentsField: hasPaymentsFieldInOldSection,
            },
            [newSectionId]: {
              ...newSection,
              fields: newFieldIds,
              hasPaymentsField: hasPaymentsFieldInNewSection,
            },
          },
          fieldIdsToBeIgnoredForConditions,
        };
      });
    },

    cloneField: (fieldId: string, sectionId: string) => {
      const newFieldId = genUID();

      set((state) => {
        const section = state.sections[sectionId];
        const field = state.fields[fieldId];
        const newField: FieldTypes.Field = {
          ...field,
          id: newFieldId,
          label: `${field.label} (copy)`,
          conditionIds: [], // Conditions should not be copied.
        };

        const newFields = {
          ...state.fields,
          [newFieldId]: newField,
        };

        // Place the cloned field right after the original field.
        const fieldIndex = section.fields.indexOf(fieldId);
        const newFieldsOrder = [...section.fields];
        newFieldsOrder.splice(fieldIndex + 1, 0, newFieldId);

        const newSection = {
          ...section,
          fields: newFieldsOrder,
        };

        return {
          sections: {
            ...state.sections,
            [sectionId]: newSection,
          },
          fields: newFields,
        };
      });

      return newFieldId;
    },

    updateOrderedFields: (orderedFields) =>
      set(() => ({
        orderedFields,
      })),

    getField: (fieldId) => {
      const state = get();
      return state.fields[fieldId];
    },

    // ============ Condition Actions ============

    addCondition: ({ sourceFieldId, targetFieldId }) => {
      const newConditionId = genUID();

      set((state) => {
        const modifiedState = { ...state };
        const newCondition: FieldConditionTypes.FormCondition = {
          id: newConditionId,
          operator: 'and',
          terms: [
            {
              field: sourceFieldId,
              check: 'answered',
              value: '',
            },
          ],
          actions: [],
        };

        if (targetFieldId) {
          newCondition.actions.push({
            operation: FieldConditionConstants.DEFAULT_CONDITION_FOR_TARGETED_FIELDS,
            target: targetFieldId,
            type: 'fields',
          });

          const targetField = state.fields[targetFieldId];
          if (targetField) {
            modifiedState.fields[targetFieldId] = ConditionUtils.modifyFieldBasedOnCondition({
              targetField,
              newFieldCondition: FieldConditionConstants.DEFAULT_CONDITION_FOR_TARGETED_FIELDS,
            });
          }
        }

        return {
          ...modifiedState,
          conditions: {
            ...state.conditions,
            [newConditionId]: newCondition,
          },
          fields: {
            ...modifiedState.fields,
            [sourceFieldId]: {
              ...modifiedState.fields[sourceFieldId],
              conditionIds: [...(state.fields[sourceFieldId].conditionIds || []), newConditionId],
            },
          },
        };
      });

      return newConditionId;
    },

    canAddCondition: () => {
      const state = get();
      const fields = state.fields;
      let numberOfFields = 0;

      for (const _ in fields) {
        numberOfFields += 1;

        if (numberOfFields > 1) {
          return true;
        }
      }

      return false;
    },

    deleteCondition: (conditionId, fieldId) =>
      set((state) => {
        const { updatedConditions, updatedFields } = ConditionUtils.removeConditionById({
          sourceFieldId: fieldId,
          conditionId,
          fields: state.fields,
          conditions: state.conditions,
        });

        return {
          conditions: updatedConditions,
          fields: updatedFields,
        };
      }),

    removeConditions: (conditionIds) => {
      set((state) => {
        let updatedFields = { ...state.fields };
        let updatedConditions = { ...state.conditions };

        for (const conditionId of conditionIds) {
          const condition = state.conditions[conditionId];
          const sourceFieldId = condition.terms[0].field;

          const result = ConditionUtils.removeConditionById({
            sourceFieldId,
            conditionId,
            fields: updatedFields,
            conditions: updatedConditions,
          });

          updatedFields = result.updatedFields;
          updatedConditions = result.updatedConditions;
        }

        return {
          fields: updatedFields,
          conditions: updatedConditions,
        };
      });
    },

    setInvalidConditions: (invalidConditions) => set(() => ({ invalidConditions })),

    markFieldAsIgnoredForConditions: (fieldId) => {
      set((state) => {
        const field = state.fields[fieldId];
        const conditionIds = field.conditionIds || [];
        const fieldIdsToBeIgnoredForConditions = { ...state.fieldIdsToBeIgnoredForConditions };
        fieldIdsToBeIgnoredForConditions[fieldId] = true;

        // Remove all the conditions associated with the field.
        const { updatedConditions, updatedFields } = ConditionUtils.removeConditions({
          sourceFieldId: fieldId,
          conditionIds,
          fields: state.fields,
          conditions: state.conditions,
          removeActionsTargetingSourceField: true,
        });

        return {
          fieldIdsToBeIgnoredForConditions,
          conditions: updatedConditions,
          fields: updatedFields,
          triggerChangeDetectionForConditions: true,
        };
      });

      // Reset the triggerChangeDetectionForConditions flag after a short delay.
      setTimeout(() => {
        set(() => ({
          triggerChangeDetectionForConditions: false,
        }));
      }, RESET_TIMEOUT);
    },

    getNumberOfConditionsTargetingField: (fieldId) => {
      const state = get();
      let count = 0;

      for (const conditionId in state.conditions) {
        const condition = state.conditions[conditionId];
        for (const action of condition.actions) {
          if (action.target === fieldId) {
            count += 1;
            break;
          }
        }
      }

      return count;
    },

    updateSourceFieldCondition: ({ conditionId, value }) =>
      set((state) => {
        const condition = state.conditions[conditionId];
        const updatedCondition = {
          ...condition,
          terms: [
            {
              ...condition.terms[0],
              check: value,
            },
          ],
        };

        return {
          conditions: {
            ...state.conditions,
            [conditionId]: updatedCondition,
          },
        };
      }),

    updateSourceFieldConditionValue: ({ conditionId, value }) =>
      set((state) => {
        const condition = state.conditions[conditionId];
        const updatedCondition = {
          ...condition,
          terms: [
            {
              ...condition.terms[0],
              value,
            },
          ],
        };

        return {
          conditions: {
            ...state.conditions,
            [conditionId]: updatedCondition,
          },
        };
      }),

    updateConditionForTargetedFields: ({ conditionId, value }) =>
      set((state) => {
        const condition = state.conditions[conditionId];
        const modifiedActions = [...condition.actions];
        const modifiedFields = { ...state.fields };

        if (modifiedActions.length === 0) {
          // If there are no actions, create a new action without a target field ID.
          modifiedActions.push({
            operation: value,
            target: '',
            type: 'fields',
          });
        } else {
          for (const action of modifiedActions) {
            action.operation = value;
            const targetFieldId = action.target;
            const targetField = modifiedFields[targetFieldId];

            if (!targetField) {
              continue;
            }

            // Update the target field based on the new condition
            modifiedFields[targetFieldId] = ConditionUtils.modifyFieldBasedOnCondition({
              targetField,
              newFieldCondition: value,
            });
          }
        }

        const modifiedCondition = {
          ...condition,
          actions: modifiedActions,
        };

        return {
          conditions: {
            ...state.conditions,
            [conditionId]: modifiedCondition,
          },
          fields: modifiedFields,
        };
      }),

    updateTargetedFieldsForCondition: ({ conditionId, targetedFieldIds }) => {
      let shouldTriggerChangeDetection = false;

      set((state) => {
        const condition = state.conditions[conditionId];
        const modifiedFields = { ...state.fields };
        let modifiedActions = [...condition.actions];
        const conditionForTargetedFields =
          modifiedActions.length > 0
            ? modifiedActions[0].operation
            : FieldConditionConstants.DEFAULT_CONDITION_FOR_TARGETED_FIELDS;

        if (modifiedActions.length === 0) {
          /**
           * The UI field that tracks the condition for the targeted fields should be
           * updated with the new value that was set as the default in this flow.
           */
          shouldTriggerChangeDetection = true;
        }

        // Revert the effects of the current condition on the target fields
        for (const action of modifiedActions) {
          const targetFieldId = action.target;
          const targetField = modifiedFields[targetFieldId];
          if (!targetField) {
            continue;
          }

          modifiedFields[targetFieldId] = ConditionUtils.removeConditionEffectsFromField(targetField);
        }

        /**
         * `targetedFieldIds` contains the field IDs that should be targeted by the condition.
         * Clear out all the existing target fields and add the new ones with the specified condition
         * for the targeted fields.
         */
        modifiedActions = [];

        for (const targetFieldId of targetedFieldIds) {
          const targetField = modifiedFields[targetFieldId];
          if (!targetField) {
            continue;
          }

          // Update the target field based on the new condition
          modifiedFields[targetFieldId] = ConditionUtils.modifyFieldBasedOnCondition({
            targetField,
            newFieldCondition: conditionForTargetedFields,
          });

          modifiedActions.push({
            operation: conditionForTargetedFields,
            target: targetFieldId,
            type: 'fields',
          });
        }

        if (modifiedActions.length === 0) {
          // If there are no actions, create a new action without a target field ID.
          modifiedActions.push({
            operation: conditionForTargetedFields,
            target: '',
            type: 'fields',
          });
        }

        return {
          conditions: {
            ...state.conditions,
            [conditionId]: {
              ...condition,
              actions: modifiedActions,
            },
          },
          fields: modifiedFields,
          triggerChangeDetectionForConditions: shouldTriggerChangeDetection,
        };
      });

      if (shouldTriggerChangeDetection) {
        // Reset the triggerChangeDetectionForConditions flag after a short delay.
        setTimeout(() => {
          set(() => ({
            triggerChangeDetectionForConditions: false,
          }));
        }, RESET_TIMEOUT);
      }
    },

    getBuilderEngineState: () => {
      const state = get();

      return {
        assetId: state.assetId,
        formId: state.formId,
        formTitle: state.formTitle,
        sharedLocationIds: state.sharedLocationIds,
        sectionOrder: state.sectionOrder,
        sections: state.sections,
        fields: state.fields,
        orderedFields: state.orderedFields,
        conditions: state.conditions,
        hasPaymentsField: state.hasPaymentsField,
        fieldIdsToBeIgnoredForConditions: state.fieldIdsToBeIgnoredForConditions,
      };
    },

    setHasPaymentsField: (hasPaymentsField) => set(() => ({ hasPaymentsField })),
    setFieldIdsToBeIgnoredForConditions: (fieldIdsToBeIgnoredForConditions) =>
      set(() => ({ fieldIdsToBeIgnoredForConditions })),

    setCategorizedPrimaryMedicalHistoryFieldOption: ({ isUsed, optionSet, optionValue }) =>
      set((state) => {
        const categorizedOptions = { ...state.categorizedPrimaryMedicalHistoryFieldOptions };
        const usedOptions = { ...categorizedOptions[optionSet] };

        if (isUsed) {
          usedOptions[optionValue] = true;
        } else {
          delete usedOptions[optionValue];
        }

        return {
          categorizedPrimaryMedicalHistoryFieldOptions: {
            ...categorizedOptions,
            [optionSet]: usedOptions,
          },
        };
      }),

    batchUpdateCategorizedPrimaryMedicalHistoryFieldOptions: ({ optionSet, optionUsageUpdate, shouldReset }) =>
      set((state) => {
        if (shouldReset) {
          return {
            categorizedPrimaryMedicalHistoryFieldOptions: {
              ...state.categorizedPrimaryMedicalHistoryFieldOptions,
              [optionSet]: optionUsageUpdate,
            },
          };
        }

        return {
          categorizedPrimaryMedicalHistoryFieldOptions: {
            ...state.categorizedPrimaryMedicalHistoryFieldOptions,
            [optionSet]: {
              ...state.categorizedPrimaryMedicalHistoryFieldOptions[optionSet],
              ...optionUsageUpdate,
            },
          },
        };
      }),

    addOptionToCategory: ({ categoryId, fieldId, option, placementIndex, optionSet }) => {
      let newCategoryId = undefined;
      set((state) => {
        const field = state.fields[fieldId] as FieldTypes.CategorizedOptionsField;
        if (!field || field.meta.type !== 'categorizedChecklist') {
          return state;
        }

        const categories = [...(field.categories || [])];
        // Create a new category if no category ID is provided
        if (categoryId === undefined) {
          newCategoryId = genUID();
          const newCategory: FieldTypes.CategorizedOption = {
            id: newCategoryId,
            name: `Category ${categories.length + 1}`,
            options: [option],
          };

          categories.push(newCategory);
        } else {
          // Find the category by ID
          const categoryIndex = categories.findIndex((cat) => cat.id === categoryId);
          if (categoryIndex === -1) {
            return state;
          }

          const category = categories[categoryIndex];
          const options = [...(category.options || [])];

          // Add the new option to the specified placement index
          if (placementIndex !== undefined) {
            options.splice(placementIndex, 0, option);
          } else {
            options.push(option);
          }

          // Update the category with the new options
          const updatedCategory = {
            ...category,
            options,
          };
          categories[categoryIndex] = updatedCategory;
        }

        // Mark the option as used
        const updatedCategorizedOptions = { ...state.categorizedPrimaryMedicalHistoryFieldOptions };
        const usedOptions = {
          ...updatedCategorizedOptions[optionSet],
          [option.value]: true,
        };
        updatedCategorizedOptions[optionSet] = usedOptions;

        return {
          fields: {
            ...state.fields,
            [fieldId]: {
              ...field,
              categories,
            },
          },
          categorizedPrimaryMedicalHistoryFieldOptions: updatedCategorizedOptions,
        };
      });
      return newCategoryId;
    },

    removeOptionFromCategory: ({ categoryId, fieldId, indexToRemoveFrom, optionSet }) => {
      set((state) => {
        const field = state.fields[fieldId] as FieldTypes.CategorizedOptionsField;
        if (!field || field.meta.type !== 'categorizedChecklist') {
          return state;
        }

        const categories = [...(field.categories || [])];
        const categoryIndex = categories.findIndex((cat) => cat.id === categoryId);
        if (categoryIndex === -1) {
          return state;
        }

        const category = categories[categoryIndex];
        const options = [...(category.options || [])];
        if (indexToRemoveFrom < 0 || indexToRemoveFrom >= options.length) {
          return state;
        }

        const optionValue = options[indexToRemoveFrom].value;
        // Remove the option at the specified index
        options.splice(indexToRemoveFrom, 1);

        // Update the category with the new options
        const updatedCategory = {
          ...category,
          options,
        };
        categories[categoryIndex] = updatedCategory;

        // Mark the option as unused
        const updatedCategorizedOptions = { ...state.categorizedPrimaryMedicalHistoryFieldOptions };
        const usedOptions = { ...updatedCategorizedOptions[optionSet] };
        delete usedOptions[optionValue];

        return {
          fields: {
            ...state.fields,
            [fieldId]: {
              ...field,
              categories,
            },
          },
          categorizedPrimaryMedicalHistoryFieldOptions: {
            ...updatedCategorizedOptions,
            [optionSet]: usedOptions,
          },
        };
      });
    },

    moveOptionToCategory: ({ sourceCategoryId, fieldId, destinationCategoryId, optionValue, placementIndex }) => {
      set((state) => {
        const field = state.fields[fieldId] as FieldTypes.CategorizedOptionsField;
        if (!field || field.meta.type !== 'categorizedChecklist') {
          return state;
        }

        const categories = [...(field.categories || [])];

        // Update the position of the option within the same category
        if (sourceCategoryId === destinationCategoryId) {
          const categoryIndex = categories.findIndex((cat) => cat.id === sourceCategoryId);
          if (categoryIndex === -1) {
            return state;
          }

          const category = categories[categoryIndex];
          const options = [...(category.options || [])];
          const optionIndex = options.findIndex((option) => option.value === optionValue);
          if (optionIndex === -1) {
            return state;
          }

          const optionToMove = options[optionIndex];
          if (placementIndex !== undefined) {
            options.splice(optionIndex, 1);
            options.splice(placementIndex, 0, optionToMove);
          }

          const updatedCategory = {
            ...category,
            options,
          };
          categories[categoryIndex] = updatedCategory;
          return {
            fields: {
              ...state.fields,
              [fieldId]: {
                ...field,
                categories,
              },
            },
          };
        }

        const oldCategoryIndex = categories.findIndex((cat) => cat.id === sourceCategoryId);
        const newCategoryIndex = categories.findIndex((cat) => cat.id === destinationCategoryId);
        if (oldCategoryIndex === -1 || newCategoryIndex === -1) {
          return state;
        }

        const oldCategory = categories[oldCategoryIndex];
        const newCategory = categories[newCategoryIndex];
        const oldCategoryOptions = [...(oldCategory.options || [])];
        const optionIndex = oldCategoryOptions.findIndex((option) => option.value === optionValue);
        if (optionIndex === -1) {
          return state;
        }

        const optionToMove = oldCategoryOptions[optionIndex];

        // Add the option to the new category at the specified placement index
        const newCategoryOptions = [...(newCategory.options || [])];
        if (placementIndex !== undefined) {
          oldCategoryOptions.splice(optionIndex, 1);
          newCategoryOptions.splice(placementIndex, 0, optionToMove);
        }

        // Update the categories with the new options
        categories[oldCategoryIndex] = {
          ...oldCategory,
          options: oldCategoryOptions,
        };
        categories[newCategoryIndex] = {
          ...newCategory,
          options: newCategoryOptions,
        };

        return {
          fields: {
            ...state.fields,
            [fieldId]: {
              ...field,
              categories,
            },
          },
        };
      });
    },

    updateCategoryLabel: ({ categoryId, fieldId, label }) => {
      set((state) => {
        const field = state.fields[fieldId] as FieldTypes.CategorizedOptionsField;
        if (!field || field.meta.type !== 'categorizedChecklist') {
          return state;
        }

        const categories = [...(field.categories || [])];
        const categoryIndex = categories.findIndex((cat) => cat.id === categoryId);
        if (categoryIndex === -1) {
          return state;
        }

        const category = categories[categoryIndex];

        // Update the category label
        const updatedCategory = {
          ...category,
          name: label,
        };
        categories[categoryIndex] = updatedCategory;

        return {
          fields: {
            ...state.fields,
            [fieldId]: {
              ...field,
              categories,
            },
          },
        };
      });
    },

    deleteCategory: ({ categoryId, fieldId, optionSet }) => {
      set((state) => {
        const field = state.fields[fieldId] as FieldTypes.CategorizedOptionsField;
        if (!field || field.meta.type !== 'categorizedChecklist') {
          return state;
        }

        const categories = [...(field.categories || [])];
        const categoryIndex = categories.findIndex((cat) => cat.id === categoryId);
        if (categoryIndex === -1) {
          return state;
        }

        // Mark the options in the category as unused
        const optionsInCategory = categories[categoryIndex].options || [];
        const updatedCategorizedOptions = { ...state.categorizedPrimaryMedicalHistoryFieldOptions };
        const usedOptions = { ...updatedCategorizedOptions[optionSet] };
        for (const option of optionsInCategory) {
          delete usedOptions[option.value];
        }
        updatedCategorizedOptions[optionSet] = usedOptions;

        // Remove the category from the list
        categories.splice(categoryIndex, 1);

        return {
          fields: {
            ...state.fields,
            [fieldId]: {
              ...field,
              categories,
            },
          },
          categorizedPrimaryMedicalHistoryFieldOptions: updatedCategorizedOptions,
        };
      });
    },

    deleteCategoryOption: ({ categoryId, fieldId, optionSet, optionValue }) =>
      set((state) => {
        const field = state.fields[fieldId] as FieldTypes.CategorizedOptionsField;
        if (!field || field.meta.type !== 'categorizedChecklist') {
          return state;
        }

        const categories = [...(field.categories || [])];
        const categoryIndex = categories.findIndex((cat) => cat.id === categoryId);
        if (categoryIndex === -1) {
          return state;
        }

        const category = categories[categoryIndex];
        const options = [...(category.options || [])];
        const optionIndex = options.findIndex((option) => option.value === optionValue);
        if (optionIndex === -1) {
          return state;
        }

        // Mark the option as unused
        const updatedCategorizedOptions = { ...state.categorizedPrimaryMedicalHistoryFieldOptions };
        const usedOptions = { ...updatedCategorizedOptions[optionSet] };
        delete usedOptions[optionValue];
        updatedCategorizedOptions[optionSet] = usedOptions;

        // Remove the option from the category
        options.splice(optionIndex, 1);

        // Update the category with the new options
        const updatedCategory = {
          ...category,
          options,
        };
        categories[categoryIndex] = updatedCategory;

        return {
          fields: {
            ...state.fields,
            [fieldId]: {
              ...field,
              categories,
            },
          },
          categorizedPrimaryMedicalHistoryFieldOptions: updatedCategorizedOptions,
        };
      }),

    getPrimaryMedicalHistoryFieldCategorizationBackup: (fieldId) => {
      const { fields, categorizedPrimaryMedicalHistoryFieldOptions } = get();
      return {
        categorizedOptions: categorizedPrimaryMedicalHistoryFieldOptions,
        field: fields[fieldId],
      };
    },

    restorePrimaryMedicalHistoryFieldCategorizationBackup: ({ field, categorizedOptions }) => {
      if (!field || !field.id) {
        return;
      }

      const fieldId = field.id;
      set((state) => ({
        fields: { ...state.fields, [fieldId]: field },
        categorizedPrimaryMedicalHistoryFieldOptions: {
          ...state.categorizedPrimaryMedicalHistoryFieldOptions,
          ...categorizedOptions,
        },
      }));
    },
  }));
