import { FeedbackType } from '@weave/schema-gen-ts/dist/schemas/office-assistant/v1/office_assistant_service.pb';
import { MessagesTypes, SchemaSMSSharedEnums, SchemaSMSSharedModels } from '@frontend/api-messaging';
import { TagSelectionHooks } from '@frontend/tag-selection';

export const CONVERSATION_IMG_ID_PREFIX = 'media-id-';
export const EXPORT_IMG_ID_PREFIX = 'export-media-id-';
export const TEXT_WRITEBACKS_RELATED_ID_TYPE = SchemaSMSSharedEnums.RelatedType.RELATED_TYPE_SMS_WRITEBACK_NOTE;
export const TEMP_WRITEBACKS_RELATED_ID: SchemaSMSSharedModels.RelatedID = {
  type: TEXT_WRITEBACKS_RELATED_ID_TYPE,
  id: 'temp-writeback-id',
};
export const HIDE_DELETE_MODAL = 'hide-sms-delete-modal';
export const PERSON_SEARCH_PAGE_SIZE = 20;

export const SMART_TAG_FEEDBACK_THRESHOLDS: Parameters<
  typeof TagSelectionHooks.useTagFeedbackFlow
>[0]['feedbackClickThreshold'] = {
  [FeedbackType.FEEDBACK_TYPE_BAD]: 3,
  [FeedbackType.FEEDBACK_TYPE_GOOD]: -1,
};

export const INBOX_PANEL_ID = 'inbox-panel';
export const THREAD_PANEL_ID = 'thread-panel';

export const MOCK_INBOX_LIST_ITEMS: MessagesTypes.InboxListItem[] = [
  {
    locationId: '9384yhdfjkd',
    autogeneratedBy: '',
    body: "Hi Donald, need to reschedule? Let us know a convenient time, and we'll do our best to accommodate!",
    createdAt: '2025-01-31T13:17:57Z',
    departmentId: '3ewfsdfsdf',
    direction: 'DIRECTION_OUTBOUND',
    id: '32894uysndhfj',
    isBlocked: false,
    numMedia: 0,
    person: {
      personId: '8394y7hsuidsf',
      firstName: 'Donald',
      lastName: 'Glover',
      preferredName: '',
    },
    personId: '8934ndjsfnkj',
    personPhone: '+1401232334454',
    smsId: 'usehfjsdf234',
    status: 'read',
    threadId: '9238yjiejr98wu98',
    uniqueTags: [
      {
        tagId: 'be595b60-115a-48f9-9b2e-04f648227ff7',
        smsId: 'f0bf0c1f-38aa-43d3-b0a6-b8011d8ce484',
        smsCreatedAt: '2024-08-29T09:21:32Z',
        appliedBy: 'sdffsd452e39b429b2bc',
      },
      {
        tagId: '797d99c3-3792-48ca-abe3-917d9e34d3c6',
        smsId: '820933de-2b4a-45ba-9c8e-55e6b6ad5a8d',
        smsCreatedAt: '2024-07-23T16:01:26Z',
        appliedBy: 'esdfsdfsdfdsf6428607a2d06',
      },
    ],
    isSearchResult: false,
    isReplied: false,
  },
  {
    locationId: 'w9834ru89w4u',
    autogeneratedBy: '2c26bf01-0088-473b-9454-45dbdc390236',
    body: 'Hi Bryan, your appointment at Bright Smile Dental is confirmed for Monday, Feb 5 at 10:30 AM. See you then',
    createdAt: '2025-01-30T21:51:41Z',
    departmentId: '4985ujsidfjiosj',
    direction: 'DIRECTION_OUTBOUND',
    id: '3298ywehfjknj',
    isBlocked: false,
    numMedia: 0,
    person: {
      personId: '239847hjhfj',
      firstName: 'Bryan',
      lastName: 'Henderson',
      preferredName: '',
    },
    personId: '',
    personPhone: '+2341320345432',
    smsId: '9834y5hnjrnjk',
    status: 'new',
    threadId: '983u5i3j4i',
    uniqueTags: [],
    isSearchResult: false,
    isReplied: true,
  },
  {
    locationId: '9238u48923u',
    autogeneratedBy: '',
    body: "Hope you're feeling better, Nixon! Let us know if you have any concerns after your visit to Bright Smile Dental.",
    createdAt: '2025-01-29T20:48:01Z',
    departmentId: '983u459jf',
    direction: 'DIRECTION_INBOUND',
    id: 'cw9erueur98',
    isBlocked: false,
    numMedia: 0,
    person: {
      personId: 'iwuefhuisdhf',
      firstName: 'Nixon',
      lastName: 'Allen',
      preferredName: '',
    },
    personId: '',
    personPhone: '+03898709383',
    smsId: 'c943875994',
    status: 'read',
    threadId: '09438509',
    uniqueTags: [],
    isSearchResult: false,
    isReplied: false,
  },
  {
    locationId: '32347b2werfsdffb',
    autogeneratedBy: '',
    body: 'Thank you for visiting Bright Smile Dental! We appreciate you. Let us know if you need anything.',
    createdAt: '2025-01-29T17:43:26Z',
    departmentId: '3423254534as3e6776c',
    direction: 'DIRECTION_INBOUND',
    id: '987348957njkd8d',
    isBlocked: false,
    numMedia: 0,
    person: {
      personId: '43525656455',
      firstName: 'Kaleb',
      lastName: 'Harris',
      preferredName: '',
    },
    personId: '',
    personPhone: '+6534300585',
    smsId: '987438asdqwe34',
    status: 'new',
    threadId: '87jhsbd7634',
    uniqueTags: [
      {
        tagId: 'cc107e87-45d8-4d22-b6d7-c317dfb109c8',
        smsId: 'cb764a4e-f4c8-4afd-88b1-c8f15c3ace3f',
        smsCreatedAt: '2024-08-09T20:39:22Z',
        appliedBy: '',
      },
    ],
    isSearchResult: false,
    isReplied: false,
  },
  {
    locationId: 'doifug9049j23467bhsd',
    autogeneratedBy: '',
    body: 'We missed you today at Bright Smile Dental! Need to reschedule? Call us at (555) 123-4567 or reply here.',
    createdAt: '2025-01-28T15:37:29Z',
    departmentId: '5jhsdfuh38c',
    direction: 'DIRECTION_OUTBOUND',
    id: 'lksdjfh398u',
    isBlocked: false,
    numMedia: 0,
    person: {
      personId: '98uye89u983',
      firstName: 'Jaimson',
      lastName: 'Brown',
      preferredName: '',
    },
    personId: '93748jdf',
    personPhone: '+34538564271',
    smsId: 'podsijfio434',
    status: 'read',
    threadId: '984798tuerj',
    uniqueTags: [],
    isSearchResult: false,
    isReplied: true,
  },
  {
    locationId: '9843573jhirw89',
    autogeneratedBy: '4938u89',
    body: 'Thank you for calling Bright Smile Dental! Sorry we missed your call. Please text us at this number or leave a voicemail and we will contact you as soon as we can! Reply STOP to unsubscribe.',
    createdAt: '2025-01-27T18:02:39Z',
    departmentId: 'ewiorhjoi32',
    direction: 'DIRECTION_OUTBOUND',
    id: '9i3u4598u3',
    isBlocked: false,
    numMedia: 0,
    person: {
      personId: 'f9ab887f-bdfb-5b29-9a3a-c12273064a75',
      firstName: 'Chip',
      lastName: 'Evans',
      preferredName: '',
    },
    personId: 'we098ru90ec12273064a75',
    personPhone: '+768340344',
    smsId: '930485nkjwehiu',
    status: 'read',
    threadId: '923859jekf',
    uniqueTags: [],
    isSearchResult: false,
    isReplied: true,
  },
  {
    locationId: '34985kjdsnd',
    autogeneratedBy: '34sdfgldkdskhj98',
    body: 'Thank you for calling Bright Smile Dental! Sorry we missed your call. Please text us at this number or leave a voicemail and we will contact you as soon as we can! Reply STOP to unsubscribe.',
    createdAt: '2025-01-24T17:49:28Z',
    departmentId: 'oi45uemrkmio',
    direction: 'DIRECTION_OUTBOUND',
    id: '094385kjneriuh',
    isBlocked: false,
    numMedia: 0,
    person: {
      personId: '34895ujnkf',
      firstName: 'Tony',
      lastName: 'Starr',
      preferredName: '',
    },
    personId: '',
    personPhone: '+6423283444',
    smsId: 'sjdfhiu389',
    status: 'read',
    threadId: 'w09485938',
    uniqueTags: [],
    isSearchResult: false,
    isReplied: true,
  },
] as const;
