import { useCallback } from 'react';
import { MessageType_Enum } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { NotificationType } from '@weave/schema-gen-ts/dist/shared/notification/notifications.pb';
import { MessagesHooks } from '@frontend/api-messaging';
import { GetWebsocketEventHandler, useWebsocketEventSubscription } from '@frontend/websocket';

/**
 * A hook that returns a function to execute when an SMS preference event is received.
 * This doesn't have any WebSocket subscription logic, just the handler. Useful for testing.
 */
export const useSMSPreferenceWSHandler = () => {
  const { update } = MessagesHooks.useUpdateCheckSMSPreference();
  const handler = useCallback<GetWebsocketEventHandler<NotificationType.MANUAL_MESSAGE_CONSENT>>(
    (payload) => {
      if (payload.method === NotificationType.MANUAL_MESSAGE_CONSENT) {
        const { consented, ...preferenceOptions } = payload.params;
        update(
          {
            messageType: MessageType_Enum.MESSAGING_MANUAL,
            ...preferenceOptions,
          },
          { consented }
        );
      }
    },
    [update]
  );

  return handler;
};

export const useSMSPreferenceEventSubscription = () => {
  const handler = useSMSPreferenceWSHandler();
  return useWebsocketEventSubscription(NotificationType.MANUAL_MESSAGE_CONSENT, handler);
};
