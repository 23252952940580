import { useMemo } from 'react';
import { QueryKey, UseQueryOptions } from 'react-query';
import { LimitedSchemaQueryOptions, SchemaQueryKey } from '@frontend/react-query-helpers';
import type { SchemaIO } from '@frontend/schema';
import { CallTaskParams, ListTaskIO, ServiceTaskStandardQueries } from '../types';
import { generateListTasksPayload } from '../utils';
import { DemoData } from './adaptive-queries/use-adaptive-query';
import { useTasksAdaptiveQuery } from './adaptive-queries/user-tasks-adaptive-query';

export interface UseListTasksArgs<E = unknown, T = ListTaskIO['output']> {
  demoData: DemoData<T>;
  demoQueryOptions?: Omit<UseQueryOptions<T, E, T>, 'queryKey' | 'queryFn'>;
  payload: CallTaskParams;
  isDemoAccount: boolean;
  realQueryOptions?: LimitedSchemaQueryOptions<
    SchemaIO<ServiceTaskStandardQueries['ListTasks']>,
    E,
    T,
    SchemaQueryKey<ServiceTaskStandardQueries, 'ListTasks'>
  >;
}

export function useListTasks<E = unknown, T = ListTaskIO['output']>({
  demoData,
  demoQueryOptions,
  payload,
  isDemoAccount,
  realQueryOptions,
}: UseListTasksArgs<E, T>) {
  const isValid = !!payload.locations?.length;

  const request = useMemo(() => {
    if (!isValid) return {} as ListTaskIO['input'];
    return generateListTasksPayload(payload) as ListTaskIO['input'];
  }, [payload, isValid]);

  return useTasksAdaptiveQuery<'ListTasks', E, T>({
    buildDemoQueryKey: ({ endpointName, request }) => ['demo', endpointName, request] as QueryKey,
    demoData,
    demoQueryEnabled: isValid,
    demoQueryOptions,
    isDemoAccount,
    realQueryArgs: {
      endpointName: 'ListTasks',
      request,
      options: realQueryOptions,
    },
    realQueryEnabled: isValid,
  });
}
