import { useMemo } from 'react';
import { QueryKey, UseQueryOptions } from 'react-query';
import { LimitedSchemaQueryOptions, SchemaQueryKey } from '@frontend/react-query-helpers';
import type { SchemaIO } from '@frontend/schema';
import { ApiOverviewStatsParams, ApiPayloadProps, GetOverviewStatsIO, ServiceStandardQueries } from '../types';
import { generateChartsPayload } from '../utils';
import { DemoData, useAdaptiveQuery } from './adaptive-queries/use-adaptive-query';

export interface UseGetOverviewStatsArgs<E = unknown, T = GetOverviewStatsIO['output']> {
  demoData: DemoData<T>;
  demoQueryOptions?: Omit<UseQueryOptions<T, E, T>, 'queryKey' | 'queryFn'>;
  payload: ApiOverviewStatsParams & ApiPayloadProps;
  isDemoAccount: boolean;
  realQueryOptions?: LimitedSchemaQueryOptions<
    SchemaIO<ServiceStandardQueries['OverviewStats']>,
    E,
    T,
    SchemaQueryKey<ServiceStandardQueries, 'OverviewStats'>
  >;
}

export function useGetOverviewStats<E = unknown, T = GetOverviewStatsIO['output']>({
  demoData,
  demoQueryOptions,
  payload,
  isDemoAccount,
  realQueryOptions,
}: UseGetOverviewStatsArgs<E, T>) {
  const { drillDownOptions, filters, includes } = payload;

  const isValidFilters = !!(filters?.startDate && filters?.endDate && filters?.locations?.length);

  const request = useMemo<GetOverviewStatsIO['input']>(() => {
    if (!isValidFilters) return {} as GetOverviewStatsIO['input'];

    return {
      ...generateChartsPayload({ drillDownOptions, filters }),
      includes: {
        opportunities: includes.opportunities,
        service_quality: includes.serviceQuality,
      },
    } as GetOverviewStatsIO['input'];
    //This is type-casted till we move all the utils to schema-gen compatible
  }, [payload]);

  return useAdaptiveQuery<'OverviewStats', E, T>({
    buildDemoQueryKey: ({ endpointName, request }) => ['demo', endpointName, request] as QueryKey,
    demoData,
    demoQueryEnabled: isValidFilters,
    demoQueryOptions,
    isDemoAccount,
    realQueryArgs: {
      endpointName: 'OverviewStats',
      request,
      options: realQueryOptions,
    },
    realQueryEnabled: isValidFilters,
  });
}
