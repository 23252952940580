import { LimitedSchemaMutationOptions, useMutation } from '@frontend/react-query-helpers';
import { SchemaCallIntelV2Service, SchemaTaskService } from '../service';
import { DeleteTaskIO, ExtendedUpdateTaskRequest, SubmitFeedbackIO, UpdateTaskIO } from '../types';
import { fakeApi } from '../utils';
import { mutationKey } from './keys';

export const useSubmitFeedback = <E = unknown, T = SubmitFeedbackIO['output']>(
  options?: LimitedSchemaMutationOptions<SubmitFeedbackIO, E, T>
) => {
  return useMutation({
    mutationKey: mutationKey.submitFeedback,
    mutationFn: (req) => SchemaCallIntelV2Service.SubmitFeedback(req),
    ...options,
  });
};

export const useUpdateTask = <E = unknown, T = UpdateTaskIO['output']>(
  options?: LimitedSchemaMutationOptions<UpdateTaskIO, E, T, never, ExtendedUpdateTaskRequest> & {
    isDemoAccount?: boolean;
  }
) => {
  return useMutation({
    mutationFn: (req) => {
      if (options?.isDemoAccount) {
        return fakeApi<UpdateTaskIO['output']>({});
      }
      const { callId, oldStatus, taskType, ...serverRequest } = req;

      return SchemaTaskService.UpdateTask(serverRequest);
    },
    ...options,
  });
};

export const useDeleteTask = <E = unknown, T = DeleteTaskIO['output']>(
  options?: LimitedSchemaMutationOptions<DeleteTaskIO, E, T> & { isDemoAccount?: boolean }
) => {
  return useMutation({
    mutationFn: (req) =>
      options?.isDemoAccount ? fakeApi<DeleteTaskIO['output']>({}) : SchemaTaskService.DeleteTask(req),
    ...options,
  });
};
