import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, Modal, TextField, useFormField } from '@frontend/design-system';

export const NewBreakModal = ({
  currentBreakNames,
  onCancel,
  onDone,
}: {
  currentBreakNames: string[];
  onCancel: () => void;
  onDone: (newName: string) => void;
}) => {
  const { t } = useTranslation('phone');

  const fieldProps = useFormField({
    type: 'text',
    required: true,
    validator: (value) => {
      const conflictExists = currentBreakNames.find((name) => {
        return name?.trim().toLowerCase() === value.value.trim().toLowerCase();
      });
      if (conflictExists) {
        return t('Break name "{{breakName}}" is already in use', { breakName: value.value });
      }
      return '';
    },
  });

  return (
    <>
      <Modal.Header onClose={onCancel}>{t('New Break')}</Modal.Header>
      <Modal.Body
        css={css`
          margin-top: ${theme.spacing(2)};
        `}
      >
        <TextField {...fieldProps} name='breakName' label='Break name' />
      </Modal.Body>

      <Modal.Footer
        css={css`
          display: flex;
          justify-content: flex-end;
        `}
      >
        <Button variant='secondary' onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button
          onClick={() => {
            onDone(fieldProps.value);
          }}
          disabled={!fieldProps.value || !!fieldProps.error}
        >
          {t('Done')}
        </Button>
      </Modal.Footer>
    </>
  );
};
