import { useEffect } from 'react';
import { TaskType } from '@weave/schema-gen-ts/dist/schemas/task-center/shared/v1/enums.pb';
import dayjs from 'dayjs';
import { isEqual } from 'lodash-es';
import { CallIntelTypes } from '@frontend/api-call-intel';
import { useTranslation } from '@frontend/i18n';
import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';
import { theme } from '@frontend/theme';
import { CategoryChipVariants, ChipVariants } from '@frontend/design-system';
import { DEFAULT_CALL_INTEL_TIME_PERIOD } from '../constants';

export type CallIntelSubViewType = 'appointment-type' | 'category' | 'sentiment' | 'service-quality';

export type CallIntelSubViewId =
  | string
  | CallIntelTypes.AppointmentTypeEnum
  | CallIntelTypes.CategoryEnum
  | CallIntelTypes.SentimentEnum;

export type CallIntelSubView = {
  id?: CallIntelSubViewId;
  type?: CallIntelSubViewType;
};

export type MultiLocationsView = 'summary' | 'betweenLocations' | 'withinLocations';

export type DataLabelsAndColors = {
  appointmentTypes?: Record<CallIntelTypes.AppointmentTypeEnum | string, string>;
  categories?: Record<CallIntelTypes.CategoryEnum | string, string>;
  contactType?: Record<CallIntelTypes.ContactTypeEnum | string, string>;
  sentiments?: Record<CallIntelTypes.SentimentEnum | string, string>;
  sentimentsWithEmoji?: Record<CallIntelTypes.SentimentEnum | string, string>;
  serviceQualityFlag?: Record<CallIntelTypes.ServiceQualityFlagEnum | string, string>;
  taskTypes?: Record<TaskType | string, string>;
};

export type CallIntelChipVariant<T extends ChipVariants | CategoryChipVariants = ChipVariants> = Record<
  string,
  { variant?: T; variantColor?: string }
>;

interface CallIntelStore {
  activeMultiView?: MultiLocationsView;
  activeTab: CallIntelTypes.CallIntelTab;
  availableAppointmentTypes: CallIntelTypes.AppointmentTypeEnum[];
  availableCategories: CallIntelTypes.CategoryEnum[];
  chartLabelColors: DataLabelsAndColors;
  chartOutlineColors: DataLabelsAndColors;
  chipVariants: CallIntelChipVariant;
  dataColors: DataLabelsAndColors;
  dataHighlightColors: DataLabelsAndColors;
  dataLabels: DataLabelsAndColors;
  defaultFilters: CallIntelTypes.Filters;
  filterHintText: string;
  filters: CallIntelTypes.Filters;
  filtersToRestore?: CallIntelTypes.Filters;
  hasCustomFilters: boolean;
  headerChipVariants: CallIntelChipVariant;
  initialQueryParamsFilters: CallIntelTypes.Filters;
  locationIds: string[];
  selectedTimePeriodKey: CallIntelTypes.SelectedTimePeriodKey;
  subView: CallIntelSubView;
  isDemoAccount: boolean;
  isDemoModal: boolean;
  timePeriodKeyToRestore: CallIntelTypes.SelectedTimePeriodKey;
  trayFilters: CallIntelTypes.Filters;

  setActiveMultiView: (activeMultiView: MultiLocationsView) => void;
  setActiveTab: (activeTab: CallIntelTypes.CallIntelTab) => void;
  setAvailableAppointmentTypes: (availableAppointmentTypes: CallIntelTypes.AppointmentTypeEnum[]) => void;
  setAvailableCategories: (availableCategories: CallIntelTypes.CategoryEnum[]) => void;
  setChartLabelColors: (chartLabelColors: DataLabelsAndColors) => void;
  setChartOutlineColors: (chartOutlineColors: DataLabelsAndColors) => void;
  setChipVariants: (chipVariants: CallIntelChipVariant) => void;
  setDataColors: (dataColors: DataLabelsAndColors) => void;
  setDataHighlightColors: (dataHighlightColors: DataLabelsAndColors) => void;
  setDataLabels: (dataLabels: DataLabelsAndColors) => void;
  setDefaultFilters: (defaultFilters: Partial<CallIntelTypes.Filters>) => void;
  setFilterHintText: (filterHintText: string) => void;
  setFilters: (filters: Partial<CallIntelTypes.Filters>) => void;
  setFiltersToRestore: (filtersToRestore?: CallIntelTypes.Filters) => void;
  setHeaderChipVariants: (headerChipVariants: CallIntelChipVariant) => void;
  setInitialQueryParamsFilters: (filters: CallIntelTypes.Filters) => void;
  setLocationIds: (locationId: string[]) => void;
  setSelectedTimePeriodKey: (selectedPeriod: CallIntelTypes.SelectedTimePeriodKey) => void;
  setSubView: (subView: CallIntelSubView) => void;
  setIsDemoAccount: (isDemoAccount: boolean) => void;
  setIsDemoModal: (isDemoModal: boolean) => void;
  setTrayFilters: (trayFilters: CallIntelTypes.Filters) => void;

  resetFilters: () => void;
  resetStore: () => void;
}

const useCallIntelStore = createStoreWithSubscribe<CallIntelStore>(
  (set, get) => ({
    activeTab: 'overview',
    availableAppointmentTypes: [],
    availableCategories: [],
    chartLabelColors: {},
    chartOutlineColors: {},
    chipVariants: {},
    dataColors: {},
    dataLabels: {},
    dataHighlightColors: {},
    defaultFilters: {},
    filterHintText: '',
    filters: {},
    filtersToRestore: undefined,
    hasCustomFilters: false,
    headerChipVariants: {},
    initialQueryParamsFilters: {},
    locationIds: [],
    selectedTimePeriodKey: DEFAULT_CALL_INTEL_TIME_PERIOD,
    subView: {},
    isDemoAccount: false,
    isDemoModal: false,
    timePeriodKeyToRestore: null,
    trayFilters: {},

    setActiveTab: (activeTab) => {
      set({ activeTab });
    },

    setActiveMultiView: (activeMultiView) => {
      set({ activeMultiView });
    },
    setAvailableAppointmentTypes: (availableAppointmentTypes) => {
      set({ availableAppointmentTypes });
    },
    setAvailableCategories: (availableCategories) => {
      set({ availableCategories });
    },
    setChartLabelColors: (chartLabelColors) => {
      set({ chartLabelColors });
    },
    setChartOutlineColors: (chartOutlineColors) => {
      set({ chartOutlineColors });
    },
    setChipVariants: (chipVariants) => {
      set({ chipVariants });
    },
    setDataColors: (dataColors) => {
      set({ dataColors });
    },
    setDataHighlightColors: (dataHighlightColors) => {
      set({ dataHighlightColors });
    },
    setDataLabels: (dataLabels) => {
      set({ dataLabels });
    },
    setDefaultFilters: (defaultFilters) => {
      set({ defaultFilters });
    },
    setFilterHintText: (filterHintText) => {
      set({ filterHintText });
    },
    setFilters: (filters) => {
      const { defaultFilters = {}, filters: currentFilters } = get();
      const newFilters: CallIntelTypes.Filters = { ...currentFilters, ...filters };

      const {
        startDate: defaultStartDate,
        endDate: defaultEndDate,
        locations: defaultLocations,
        ...otherDefaults
      } = defaultFilters;

      const areDatesEqual =
        dayjs(newFilters.startDate).isSame(dayjs(defaultStartDate), 'date') &&
        dayjs(newFilters.endDate).isSame(dayjs(defaultEndDate), 'date');

      const areLocationsEqual = isEqual(newFilters.locations?.sort(), defaultLocations?.sort());

      set({
        filters: newFilters,
        hasCustomFilters:
          !areDatesEqual ||
          !areLocationsEqual ||
          Object.keys(otherDefaults).some(
            (key) =>
              !isEqual(
                newFilters[key as keyof CallIntelTypes.Filters],
                defaultFilters[key as keyof CallIntelTypes.Filters]
              )
          ),
      });
    },
    setFiltersToRestore: (filtersToRestore) => {
      const { selectedTimePeriodKey } = get();

      set({ filtersToRestore, timePeriodKeyToRestore: selectedTimePeriodKey });
    },
    setHeaderChipVariants: (headerChipVariants) => {
      set({ headerChipVariants });
    },
    setInitialQueryParamsFilters: (filters) => {
      set({ initialQueryParamsFilters: filters });
    },
    setLocationIds: (locationIds) => {
      set({ locationIds });
    },
    setSelectedTimePeriodKey: (selectedPeriod) => {
      set({ selectedTimePeriodKey: selectedPeriod });
    },
    setSubView: (subView) => {
      set({ subView });
    },
    setTrayFilters: (trayFilters) => {
      set({ trayFilters });
    },
    resetFilters: () => {
      const { defaultFilters, setFilters } = get();
      setFilters(defaultFilters);
    },
    resetStore: () => {
      set({
        activeMultiView: undefined,
        defaultFilters: {},
        filterHintText: '',
        filters: {},
        filtersToRestore: undefined,
        initialQueryParamsFilters: {},
        hasCustomFilters: false,
        selectedTimePeriodKey: DEFAULT_CALL_INTEL_TIME_PERIOD,
        subView: {},
        timePeriodKeyToRestore: null,
        trayFilters: {},
      });
    },
    setIsDemoAccount: (isDemoAccount) => {
      set({ isDemoAccount });
    },
    setIsDemoModal: (isDemoModal) => {
      set({ isDemoModal });
    },
  }),
  {
    name: 'CallIntelStore',
    trace: true,
  }
);

export const useCallIntelShallowStore = createShallowStore<CallIntelStore>(useCallIntelStore);

export const useUpdateDefaultCallIntelStore = () => {
  const { t } = useTranslation('analytics');
  const {
    setAvailableAppointmentTypes,
    setAvailableCategories,
    setChartOutlineColors,
    setChartLabelColors,
    setChipVariants,
    setDataColors,
    setDataHighlightColors,
    setDataLabels,
    setHeaderChipVariants,
  } = useCallIntelShallowStore(
    'setAvailableAppointmentTypes',
    'setAvailableCategories',
    'setChartOutlineColors',
    'setChartLabelColors',
    'setChipVariants',
    'setDataColors',
    'setDataHighlightColors',
    'setDataLabels',
    'setHeaderChipVariants'
  );

  useEffect(() => {
    setChipVariants({
      // Unknown (default)
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_UNKNOWN]: {
        variant: 'neutral',
      },

      // Dental-specific
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_IMAGING]: {
        variantColor: theme.colors.tangerine40,
      },
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_COSMETIC]: {
        variantColor: theme.colors.secondary.seaweed30,
      },
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ORTHODONTICS]: {
        variantColor: theme.colors.secondary.eggplant30,
      },
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_PREVENTIVE]: {
        variantColor: theme.colors.rose40,
      },
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_HYGIENE]: {
        variantColor: theme.colors.indigo50,
      },
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_RESTORATIVE]: {
        variantColor: theme.colors.tangerine30,
      },

      // Deprecated: Emergency (kept for backward compatibility)
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EMERGENCY]: {
        variantColor: theme.colors.critical30,
      },

      // Vet-specific
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SICK_PET]: {
        variantColor: theme.colors.tangerine30,
      },
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_PREVENTIVE_CARE]: {
        variantColor: theme.colors.rose40,
      },
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DENTAL]: {
        variantColor: theme.colors.indigo50,
      },
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DIAGNOSTICS]: {
        variantColor: theme.colors.secondary.seaweed30,
      },
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_END_OF_LIFE_CARE]: {
        variantColor: theme.colors.secondary.eggplant30,
      },

      // Optometry-specific
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EYE_EXAM]: {
        variantColor: theme.colors.indigo50,
      },
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_VISION_CORRECTION]: {
        variantColor: theme.colors.tangerine30,
      },
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_MEDICAL_EYE_CARE]: {
        variantColor: theme.colors.rose40,
      },
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SPECIALTY_SERVICES]: {
        variantColor: theme.colors.secondary.eggplant30,
      },

      // Default appointment types
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_CONSULTATION]: {
        variantColor: theme.colors.rose40,
      },
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ROUTINE_CHECKUP]: {
        variantColor: theme.colors.indigo50,
      },
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DIAGNOSTIC_TESTS]: {
        variantColor: theme.colors.tangerine30,
      },

      // Common across all verticals
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SURGERY]: {
        variantColor: theme.colors.secondary.seaweed40,
      },
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_OTHER]: {
        variantColor: theme.colors.neutral30,
      },

      // Categories
      [CallIntelTypes.CategoryEnum.CATEGORY_BILLING]: {
        variantColor: theme.colors.success30,
      },
      [CallIntelTypes.CategoryEnum.CATEGORY_COMPLAINT]: {
        variantColor: theme.colors.warning20,
      },
      [CallIntelTypes.CategoryEnum.CATEGORY_EMERGENCY]: {
        variantColor: theme.colors.critical30,
      },
      [CallIntelTypes.CategoryEnum.CATEGORY_MEDICATION]: {
        variantColor: theme.colors.secondary.eggplant40,
      },
      [CallIntelTypes.CategoryEnum.CATEGORY_OTHER]: {
        variantColor: theme.colors.neutral30,
      },
      [CallIntelTypes.CategoryEnum.CATEGORY_SCHEDULING]: {
        variantColor: theme.colors.primary50,
      },
      [CallIntelTypes.CategoryEnum.CATEGORY_UNKNOWN]: {
        variant: 'neutral',
      },
      [CallIntelTypes.CategoryEnum.CATEGORY_VENDOR]: {
        variantColor: theme.colors.neutral40,
      },

      // Contact Types
      [CallIntelTypes.ContactTypeEnum.CONTACT_EXISTING_PATIENT]: {
        variant: 'primary',
      },
      [CallIntelTypes.ContactTypeEnum.CONTACT_NEW_PATIENT]: {
        variant: 'warn',
      },
      [CallIntelTypes.ContactTypeEnum.CONTACT_NOT_A_PATIENT]: {
        variant: 'neutral',
      },
      [CallIntelTypes.ContactTypeEnum.CONTACT_UNKNOWN]: {
        variant: 'neutral',
      },

      // Task Types
      [TaskType.TYPE_BILLING]: {
        variantColor: theme.colors.success30,
      },
      [TaskType.TYPE_INSURANCE]: {
        variantColor: theme.colors.secondary.eggplant60,
      },
      [TaskType.TYPE_OTHER]: {
        variantColor: theme.colors.neutral30,
      },
      [TaskType.TYPE_PATIENT_CARE]: {
        variantColor: theme.colors.warning30,
      },
      [TaskType.TYPE_SCHEDULING]: {
        variantColor: theme.colors.primary50,
      },
      [TaskType.TYPE_WAITLIST]: {
        variantColor: theme.colors.critical50,
      },

      // Sentiment
      [CallIntelTypes.SentimentEnum.SENTIMENT_NEGATIVE]: {
        variant: 'neutral',
      },
      [CallIntelTypes.SentimentEnum.SENTIMENT_NEUTRAL]: {
        variant: 'neutral',
      },
      [CallIntelTypes.SentimentEnum.SENTIMENT_POSITIVE]: {
        variant: 'neutral',
      },
      [CallIntelTypes.SentimentEnum.SENTIMENT_UNKNOWN]: {
        variant: 'neutral',
      },
    });

    setDataColors({
      appointmentTypes: {
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_COSMETIC]: theme.colors.secondary.seaweed30,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EMERGENCY]: theme.colors.critical30,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_HYGIENE]: theme.colors.indigo50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_IMAGING]: theme.colors.tangerine40,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ORTHODONTICS]: theme.colors.secondary.eggplant30,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_PREVENTIVE]: theme.colors.rose40,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_RESTORATIVE]: theme.colors.tangerine30,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SURGERY]: theme.colors.secondary.seaweed40,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_UNKNOWN]: theme.colors.neutral20,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SICK_PET]: theme.colors.tangerine30,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_PREVENTIVE_CARE]: theme.colors.rose40,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DENTAL]: theme.colors.indigo50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DIAGNOSTICS]: theme.colors.secondary.seaweed30,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_END_OF_LIFE_CARE]: theme.colors.secondary.eggplant30,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EYE_EXAM]: theme.colors.indigo50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_VISION_CORRECTION]: theme.colors.tangerine30,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_MEDICAL_EYE_CARE]: theme.colors.rose40,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SPECIALTY_SERVICES]: theme.colors.secondary.eggplant30,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_CONSULTATION]: theme.colors.rose40,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ROUTINE_CHECKUP]: theme.colors.indigo50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DIAGNOSTIC_TESTS]: theme.colors.tangerine30,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_OTHER]: theme.colors.neutral30,
      },

      categories: {
        [CallIntelTypes.CategoryEnum.CATEGORY_BILLING]: theme.colors.success30,
        [CallIntelTypes.CategoryEnum.CATEGORY_COMPLAINT]: theme.colors.warning20,
        [CallIntelTypes.CategoryEnum.CATEGORY_EMERGENCY]: theme.colors.critical30,
        [CallIntelTypes.CategoryEnum.CATEGORY_MEDICATION]: theme.colors.secondary.eggplant40,
        [CallIntelTypes.CategoryEnum.CATEGORY_OTHER]: theme.colors.neutral30,
        [CallIntelTypes.CategoryEnum.CATEGORY_SCHEDULING]: theme.colors.primary50,
        [CallIntelTypes.CategoryEnum.CATEGORY_UNKNOWN]: theme.colors.neutral20,
        [CallIntelTypes.CategoryEnum.CATEGORY_VENDOR]: theme.colors.neutral40,
      },

      sentiments: {
        [CallIntelTypes.SentimentEnum.SENTIMENT_NEGATIVE]: theme.colors.critical40,
        [CallIntelTypes.SentimentEnum.SENTIMENT_NEUTRAL]: theme.colors.primary20,
        [CallIntelTypes.SentimentEnum.SENTIMENT_POSITIVE]: theme.colors.success30,
        [CallIntelTypes.SentimentEnum.SENTIMENT_UNKNOWN]: theme.colors.neutral30,
      },
    });

    setChartOutlineColors({
      appointmentTypes: {
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_COSMETIC]: theme.colors.secondary.seaweed50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EMERGENCY]: theme.colors.critical50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_HYGIENE]: theme.colors.indigo70,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_IMAGING]: theme.colors.tangerine60,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ORTHODONTICS]: theme.colors.secondary.eggplant50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_PREVENTIVE]: theme.colors.rose60,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_RESTORATIVE]: theme.colors.tangerine50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SURGERY]: theme.colors.secondary.seaweed60,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_UNKNOWN]: theme.colors.neutral40,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SICK_PET]: theme.colors.tangerine50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_PREVENTIVE_CARE]: theme.colors.rose60,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DENTAL]: theme.colors.indigo70,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DIAGNOSTICS]: theme.colors.secondary.seaweed50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_END_OF_LIFE_CARE]: theme.colors.secondary.eggplant50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EYE_EXAM]: theme.colors.indigo70,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_VISION_CORRECTION]: theme.colors.tangerine50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_MEDICAL_EYE_CARE]: theme.colors.rose60,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SPECIALTY_SERVICES]: theme.colors.secondary.eggplant50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_CONSULTATION]: theme.colors.rose60,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ROUTINE_CHECKUP]: theme.colors.indigo70,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DIAGNOSTIC_TESTS]: theme.colors.tangerine50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_OTHER]: theme.colors.neutral50,
      },

      categories: {
        [CallIntelTypes.CategoryEnum.CATEGORY_BILLING]: theme.colors.success50,
        [CallIntelTypes.CategoryEnum.CATEGORY_COMPLAINT]: theme.colors.warning40,
        [CallIntelTypes.CategoryEnum.CATEGORY_EMERGENCY]: theme.colors.critical50,
        [CallIntelTypes.CategoryEnum.CATEGORY_MEDICATION]: theme.colors.secondary.eggplant60,
        [CallIntelTypes.CategoryEnum.CATEGORY_OTHER]: theme.colors.neutral50,
        [CallIntelTypes.CategoryEnum.CATEGORY_SCHEDULING]: theme.colors.primary70,
        [CallIntelTypes.CategoryEnum.CATEGORY_UNKNOWN]: theme.colors.neutral40,
        [CallIntelTypes.CategoryEnum.CATEGORY_VENDOR]: theme.colors.neutral60,
      },

      sentiments: {
        [CallIntelTypes.SentimentEnum.SENTIMENT_NEGATIVE]: theme.colors.critical60,
        [CallIntelTypes.SentimentEnum.SENTIMENT_NEUTRAL]: theme.colors.primary40,
        [CallIntelTypes.SentimentEnum.SENTIMENT_POSITIVE]: theme.colors.success50,
        [CallIntelTypes.SentimentEnum.SENTIMENT_UNKNOWN]: theme.colors.neutral50,
      },
    });

    setChartLabelColors({
      appointmentTypes: {
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_COSMETIC]: theme.colors.secondary.seaweed50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EMERGENCY]: theme.colors.critical50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_HYGIENE]: theme.colors.indigo50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_IMAGING]: theme.colors.tangerine50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ORTHODONTICS]: theme.colors.secondary.eggplant50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_PREVENTIVE]: theme.colors.rose50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_RESTORATIVE]: theme.colors.tangerine50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SURGERY]: theme.colors.secondary.seaweed50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_UNKNOWN]: theme.colors.neutral50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SICK_PET]: theme.colors.tangerine50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_PREVENTIVE_CARE]: theme.colors.rose50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DENTAL]: theme.colors.indigo50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DIAGNOSTICS]: theme.colors.secondary.seaweed50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_END_OF_LIFE_CARE]: theme.colors.secondary.eggplant50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EYE_EXAM]: theme.colors.indigo50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_VISION_CORRECTION]: theme.colors.tangerine50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_MEDICAL_EYE_CARE]: theme.colors.rose50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SPECIALTY_SERVICES]: theme.colors.secondary.eggplant50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_CONSULTATION]: theme.colors.rose50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ROUTINE_CHECKUP]: theme.colors.indigo50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DIAGNOSTIC_TESTS]: theme.colors.tangerine50,
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_OTHER]: theme.colors.neutral50,
      },

      categories: {
        [CallIntelTypes.CategoryEnum.CATEGORY_BILLING]: theme.colors.success50,
        [CallIntelTypes.CategoryEnum.CATEGORY_COMPLAINT]: theme.colors.warning50,
        [CallIntelTypes.CategoryEnum.CATEGORY_EMERGENCY]: theme.colors.critical50,
        [CallIntelTypes.CategoryEnum.CATEGORY_MEDICATION]: theme.colors.secondary.eggplant50,
        [CallIntelTypes.CategoryEnum.CATEGORY_OTHER]: theme.colors.neutral50,
        [CallIntelTypes.CategoryEnum.CATEGORY_SCHEDULING]: theme.colors.primary50,
        [CallIntelTypes.CategoryEnum.CATEGORY_UNKNOWN]: theme.colors.neutral50,
        [CallIntelTypes.CategoryEnum.CATEGORY_VENDOR]: theme.colors.neutral50,
      },
    });

    setHeaderChipVariants({
      // Task Types
      [TaskType.TYPE_BILLING]: {
        variant: 'success',
      },
      [TaskType.TYPE_INSURANCE]: {
        variant: 'eggplant',
      },
      [TaskType.TYPE_OTHER]: {
        variant: 'neutral',
      },
      [TaskType.TYPE_PATIENT_CARE]: {
        variant: 'warn',
      },
      [TaskType.TYPE_SCHEDULING]: {
        variant: 'primary',
      },
      [TaskType.TYPE_WAITLIST]: {
        variant: 'critical',
      },
    });

    setDataLabels({
      appointmentTypes: {
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_UNKNOWN]: t('Unknown'),
        // Dental-specific
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_IMAGING]: t('Imaging'),
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_COSMETIC]: t('Cosmetic'),
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ORTHODONTICS]: t('Orthodontics'),
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_PREVENTIVE]: t('Preventive'),
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_HYGIENE]: t('Hygiene'),
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_RESTORATIVE]: t('Restorative'),
        // Deprecated: Emergency (kept for backward compatibility)
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EMERGENCY]: t('Emergency'),

        // Vet-specific
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SICK_PET]: t('Sick Pet'),
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_PREVENTIVE_CARE]: t('Preventive Care'),
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DENTAL]: t('Dental'),
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DIAGNOSTICS]: t('Diagnostics'),
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_END_OF_LIFE_CARE]: t('End of Life Care'),

        // Optometry-specific
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EYE_EXAM]: t('Eye Exam'),
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_VISION_CORRECTION]: t('Vision Correction'),
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_MEDICAL_EYE_CARE]: t('Medical Eye Care'),
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SPECIALTY_SERVICES]: t('Specialty Services'),

        // Default appointment types
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_CONSULTATION]: t('Consultation'),
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ROUTINE_CHECKUP]: t('Routine Checkup'),
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DIAGNOSTIC_TESTS]: t('Diagnostic Tests'),

        // Common across all verticals
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SURGERY]: t('Surgery'),
        [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_OTHER]: t('Other'),
      },

      categories: {
        [CallIntelTypes.CategoryEnum.CATEGORY_BILLING]: t('Billing'),
        [CallIntelTypes.CategoryEnum.CATEGORY_COMPLAINT]: t('Complaint'),
        [CallIntelTypes.CategoryEnum.CATEGORY_EMERGENCY]: t('Emergency'),
        [CallIntelTypes.CategoryEnum.CATEGORY_MEDICATION]: t('Medication'),
        [CallIntelTypes.CategoryEnum.CATEGORY_OTHER]: t('Other'),
        [CallIntelTypes.CategoryEnum.CATEGORY_SCHEDULING]: t('Scheduling'),
        [CallIntelTypes.CategoryEnum.CATEGORY_UNKNOWN]: t('Unknown'),
        [CallIntelTypes.CategoryEnum.CATEGORY_VENDOR]: t('Vendor'),
      },

      contactType: {
        [CallIntelTypes.ContactTypeEnum.CONTACT_EXISTING_PATIENT]: t('Existing Patient'),
        [CallIntelTypes.ContactTypeEnum.CONTACT_NEW_PATIENT]: t('New Patient'),
        [CallIntelTypes.ContactTypeEnum.CONTACT_NOT_A_PATIENT]: t('Not a Patient'),
        [CallIntelTypes.ContactTypeEnum.CONTACT_UNKNOWN]: t('Unavailable Data'),
      },

      sentiments: {
        [CallIntelTypes.SentimentEnum.SENTIMENT_NEGATIVE]: t('Unhappy'),
        [CallIntelTypes.SentimentEnum.SENTIMENT_NEUTRAL]: t('Neutral'),
        [CallIntelTypes.SentimentEnum.SENTIMENT_POSITIVE]: t('Happy'),
        [CallIntelTypes.SentimentEnum.SENTIMENT_UNKNOWN]: t('Unknown'),
      },

      sentimentsWithEmoji: {
        [CallIntelTypes.SentimentEnum.SENTIMENT_NEGATIVE]: `😒 ${t('Unhappy')}`,
        [CallIntelTypes.SentimentEnum.SENTIMENT_NEUTRAL]: `😐 ${t('Neutral')}`,
        [CallIntelTypes.SentimentEnum.SENTIMENT_POSITIVE]: `😊 ${t('Happy')}`,
        [CallIntelTypes.SentimentEnum.SENTIMENT_UNKNOWN]: `🤔 ${t('Unknown')}`,
      },

      serviceQualityFlag: {
        [CallIntelTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION]: t('Excellent Service'),
        [CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE]: t('Unresolved'),
        [CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNKNOWN]: t('Unknown'),
      },

      taskTypes: {
        [TaskType.TYPE_BILLING]: t('Billing'),
        [TaskType.TYPE_INSURANCE]: t('Insurance'),
        [TaskType.TYPE_OTHER]: t('Other'),
        [TaskType.TYPE_PATIENT_CARE]: t('Patient Care'),
        [TaskType.TYPE_SCHEDULING]: t('Scheduling'),
        [TaskType.TYPE_WAITLIST]: t('Waitlist'),
      },
    });

    setDataHighlightColors({
      serviceQualityFlag: {
        [CallIntelTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION]: theme.colors.primary5,
        [CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE]: theme.colors.critical5,
        [CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNKNOWN]: theme.colors.primary5,
      },
    });

    setAvailableAppointmentTypes([
      CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_UNKNOWN,
      CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_IMAGING,
      CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_COSMETIC,
      CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ORTHODONTICS,
      CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SURGERY,
      CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_PREVENTIVE,
      CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_HYGIENE,
      CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_RESTORATIVE,
      CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EMERGENCY,
      CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SICK_PET,
      CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_PREVENTIVE_CARE,
      CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DENTAL,
      CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DIAGNOSTICS,
      CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_END_OF_LIFE_CARE,
      CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EYE_EXAM,
      CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_VISION_CORRECTION,
      CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_MEDICAL_EYE_CARE,
      CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SPECIALTY_SERVICES,
      CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_CONSULTATION,
      CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ROUTINE_CHECKUP,
      CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DIAGNOSTIC_TESTS,
      CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_OTHER,
    ]);

    setAvailableCategories([
      CallIntelTypes.CategoryEnum.CATEGORY_BILLING,
      CallIntelTypes.CategoryEnum.CATEGORY_COMPLAINT,
      CallIntelTypes.CategoryEnum.CATEGORY_EMERGENCY,
      CallIntelTypes.CategoryEnum.CATEGORY_MEDICATION,
      CallIntelTypes.CategoryEnum.CATEGORY_OTHER,
      CallIntelTypes.CategoryEnum.CATEGORY_SCHEDULING,
      CallIntelTypes.CategoryEnum.CATEGORY_UNKNOWN,
      CallIntelTypes.CategoryEnum.CATEGORY_VENDOR,
    ]);
  }, []);
};
