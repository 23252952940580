import { http } from '@frontend/fetch';
import { ManualTemplateResponse, UpdateManualTemplateResponse } from './types';

const manualTemplatesEndpoint = 'portal/v1/manualtemplates';

const LOCATION_ID_HEADER = 'Location-Id';
const addLocationHeader = (locationId?: string, headers: Record<string, string> = {}) => ({
  headers: { ...(locationId ? { [LOCATION_ID_HEADER]: locationId } : {}), ...headers },
});

export const getManualTemplateByType = async (type: string, locationId?: string) => {
  const data = await http.get<ManualTemplateResponse>(manualTemplatesEndpoint, addLocationHeader(locationId));
  return data.data.find((template) => template.Type === type);
};

export const updateManualTemplateByType = async (type: string, newTemplate: string, locationId?: string) => {
  const data = await http.put<UpdateManualTemplateResponse>(
    `${manualTemplatesEndpoint}/${type}`,
    {
      Template: newTemplate,
    },
    addLocationHeader(locationId)
  );
  return data;
};
