import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { LimitedSchemaQueryOptions, SchemaQueryKey } from '@frontend/react-query-helpers';
import type { SchemaIO } from '@frontend/schema';
import { defaultOptions } from '../../config';
import { ServiceQueries, ServiceStandardQueries, StandardQueryEndpointName } from '../../types';
import { fakeApi } from '../../utils';
import { useCallIntelV2Query } from '../use-call-intel-v2-query';

export type AdaptiveQueryResult<T, E = unknown> = UseQueryResult<T, E>;

interface RealQueryArgs<EndpointName extends StandardQueryEndpointName, E, T> {
  endpointName: EndpointName;
  request: SchemaIO<ServiceQueries[EndpointName]>['input'];
  options?: LimitedSchemaQueryOptions<
    SchemaIO<ServiceStandardQueries[EndpointName]>,
    E,
    T,
    SchemaQueryKey<ServiceStandardQueries, EndpointName>
  >;
}

export type DemoData<T> = T | (() => T | Promise<T>);

export interface UseAdaptiveQueryArgs<
  EndpointName extends StandardQueryEndpointName,
  E = unknown,
  T = SchemaIO<ServiceQueries[EndpointName]>['output']
> {
  buildDemoQueryKey?: (args: {
    endpointName: EndpointName;
    request: SchemaIO<ServiceQueries[EndpointName]>['input'];
  }) => QueryKey;
  demoData: DemoData<T>;
  demoDelayMs?: number;
  demoQueryEnabled?: boolean;
  demoQueryOptions?: Omit<UseQueryOptions<T, E, T>, 'queryKey' | 'queryFn'>;
  isDemoAccount: boolean;
  realQueryArgs: RealQueryArgs<EndpointName, E, T>;
  realQueryEnabled?: boolean;
}

export function useAdaptiveQuery<
  EndpointName extends StandardQueryEndpointName,
  E = unknown,
  T = SchemaIO<ServiceQueries[EndpointName]>['output']
>({
  isDemoAccount,
  realQueryArgs,
  realQueryEnabled = true,
  demoData,
  demoQueryOptions,
  demoQueryEnabled = true,
  demoDelayMs = 200,
  buildDemoQueryKey,
}: UseAdaptiveQueryArgs<EndpointName, E, T>): AdaptiveQueryResult<T, E> {
  const { endpointName, request, options: realOpts } = realQueryArgs;

  const defaultKey = ['demo', endpointName, request] as const;
  const demoQueryKey = buildDemoQueryKey ? buildDemoQueryKey({ endpointName, request }) : defaultKey;

  const demoQuery = useQuery<T, E>({
    queryKey: demoQueryKey,
    queryFn: async () => {
      const val = typeof demoData === 'function' ? await (demoData as () => T | Promise<T>)() : demoData;
      return fakeApi(val, demoDelayMs);
    },
    enabled: isDemoAccount && demoQueryEnabled,
    staleTime: Infinity,
    cacheTime: Infinity,
    ...defaultOptions,
    ...demoQueryOptions,
  });

  const realEnabled = !isDemoAccount && realQueryEnabled && (realOpts?.enabled ?? true);
  const realQuery = useCallIntelV2Query<EndpointName, E, T>({
    endpointName,
    request,
    options: {
      enabled: realEnabled,
      ...realOpts,
    },
  });

  return isDemoAccount ? (demoQuery as UseQueryResult<T, E>) : (realQuery as UseQueryResult<T, E>);
}
