import { useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { ManualTemplatesQueries, ManualTemplatesTypes } from '@frontend/api-manual-templates';
import { PersonHelpers } from '@frontend/api-person';
import { ScheduleQueries } from '@frontend/api-schedule';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { isEmpty } from '@frontend/validation';
import { useAlert } from '@frontend/design-system';
import { ReviewAppointmentRequestForm } from '../../../../../../components/ReviewAppointmentRequest';
import { getFullName, getIsIntegratedOffice, invalidateFilterForPersonSearch } from '../../../../../../utils';
import { PersonSelector, PersonSelectorTypes } from '../../../../components/PersonSelector';
import { PersonSelectorFormValues } from '../../../../components/PersonSelector/types';
import { useScheduleRequestModalContext } from '../Context/ScheduleRequestModalContext';
import { useScheduleRequestModalFormContext } from '../Context/ScheduleRequestModalFormContext';
import { ScheduleRequestsTrackingIds } from '../trackingIds';
import { convertToServerDate, getShouldRenderSourceDropDown } from '../utils';
import { SourceTenantListItem } from './types';

const { ManualTemplateTypes } = ManualTemplatesTypes;

type ScheduleRequestModalFormProps = {
  appointmentWritebackSourceTenants: SourceTenantListItem[];
  isLoadingSourceTenants: boolean;
};

export const ScheduleRequestModalForm = ({
  appointmentWritebackSourceTenants,
  isLoadingSourceTenants,
}: ScheduleRequestModalFormProps) => {
  const queryClient = useQueryClient();
  const alert = useAlert();
  const { t } = useTranslation('scheduleCalendarRequest');
  const { selectedScheduleRequest, isPaymentsEnabled } = useScheduleRequestModalContext();

  const {
    personSelectorDialogProps,
    manageScheduleRequestFormDetails,
    manageScheduleRequestModalDetails,
    selectedPerson,
    personSelectorProps,
    setTemplate,
  } = useScheduleRequestModalFormContext();

  const {
    access,
    isLoading: isLoadingModalDetails,
    isSyncAppDetailsLoading,
    providers,
    workstations,
    appointmentTypes,
    selectedAppointmentDetails,
    syncAppDataSources,
    tags,
  } = manageScheduleRequestModalDetails;

  const isIntegratedOffice = getIsIntegratedOffice(syncAppDataSources);

  const {
    scheduleRequestApprovalForm: { values, seedValues, getFieldProps, isComplete },
    customDateField,
    customTimeField,
  } = manageScheduleRequestFormDetails;

  const hasAppointmentDetails = !isEmpty(selectedAppointmentDetails);

  const { mutateAsync: addWritebackCustomer, isLoading: isLoadingAddWritebackCustomer } =
    ScheduleQueries.useAddWritebackCustomer();

  const { mutateAsync: addCustomContact, isLoading: isAddingCustomContact } = ScheduleQueries.useMutateAddCustomContact(
    selectedScheduleRequest.locationId ?? ''
  );

  const { data: scheduleRequestConfirmedTemplate } = ManualTemplatesQueries.useGetManualTemplateByType({
    templateType: ManualTemplateTypes.onlineSchedulingConfirmation,
    locationId: selectedScheduleRequest.locationId ?? '',
  });

  const paidAmount = !!selectedScheduleRequest?.bookingAmount ? selectedScheduleRequest.bookingAmount : 0;

  const [filteredProviderList, filteredWorkstationList] = useMemo(() => {
    const filteredProviders = isIntegratedOffice ? providers?.filter((provider) => provider.sourceId) : providers;
    const filteredWorkstations = isIntegratedOffice
      ? workstations?.filter((workstation) => workstation.sourceId)
      : workstations;
    return [filteredProviders, filteredWorkstations];
  }, [providers, workstations, isIntegratedOffice]);

  // Update source form field value when there is only one source location
  useEffect(() => {
    let source = '';

    if (isIntegratedOffice && appointmentWritebackSourceTenants.length > 1) {
      source =
        appointmentWritebackSourceTenants.find(
          ({ sourceTenantId }) => selectedPerson?.SourceTenantID === sourceTenantId
        )?.sourceTenantId || '';
    } else if (isIntegratedOffice && appointmentWritebackSourceTenants.length === 1) {
      source = appointmentWritebackSourceTenants[0].sourceTenantId || '';
    } else if (!isIntegratedOffice && syncAppDataSources.length === 1) {
      source = syncAppDataSources?.[0]?.SourceID;
    }

    seedValues({
      ...values,
      source,
    });
  }, [selectedPerson, isIntegratedOffice, appointmentWritebackSourceTenants.length, syncAppDataSources.length]);

  // Update workstation form field value when there is only one workstation
  useEffect(() => {
    if (filteredWorkstationList.length === 1) {
      seedValues({
        ...values,
        workstation: filteredWorkstationList[0]?.id,
      });
    }
  }, [filteredWorkstationList]);

  // Seed form values using selectedAppointmentDetails
  useEffect(() => {
    if (hasAppointmentDetails) {
      seedValues({
        ...values,
        provider: selectedAppointmentDetails.providerId,
        appointmentType: selectedAppointmentDetails.appointmentId,
        workstation: selectedAppointmentDetails.workstationId,
      });
    }
  }, [
    hasAppointmentDetails,
    selectedAppointmentDetails.providerId,
    selectedAppointmentDetails.appointmentId,
    selectedAppointmentDetails.workstationId,
  ]);

  const handleAddCustomContact = async (formValues: PersonSelectorFormValues) => {
    return await addCustomContact({
      first_name: formValues?.firstName ?? '',
      last_name: formValues?.lastName ?? '',
      phone_mobile: formValues?.phoneNumber ?? '',
      birthdate: formValues?.dateOfBirth ? convertToServerDate(formValues?.dateOfBirth) : '',
      email: formValues?.email ?? '',
      gender: formValues?.gender ?? '',
    });
  };

  const handleAddWritebackContact = async (formValues: PersonSelectorFormValues) => {
    const clientLocationId =
      formValues?.clientLocationId ||
      access?.ClientLocations?.find((clientLocation) => clientLocation?.SourceID === formValues?.source)
        ?.ClientLocationID;
    return await addWritebackCustomer({
      ClientLocationID: clientLocationId ?? '',
      LocationID: selectedScheduleRequest?.locationId ?? '',
      SourceID: formValues?.source ?? '',
      Person: {
        FirstName: formValues?.firstName ?? '',
        LastName: formValues?.lastName ?? '',
        MobilePhone: formValues?.phoneNumber ?? '',
        Email: formValues?.email ?? '',
        Gender: formValues?.gender ?? '',
        Birthdate: formValues?.dateOfBirth ? convertToServerDate(formValues?.dateOfBirth) : '',
        Address1: selectedScheduleRequest?.schedulee?.address?.street ?? '',
        City: selectedScheduleRequest?.schedulee?.address?.city ?? '',
        State: selectedScheduleRequest?.schedulee?.address?.state ?? '',
        Address2: selectedScheduleRequest?.schedulee?.address?.unit ?? '',
        PostalCode: selectedScheduleRequest?.schedulee?.address?.postalCode ?? '',
        HomePhone: '',
        MaritalStatus: '',
        MiddleName: '',
        PreferredName: '',
        ReferralSource: '',
        SSN: '',
        WorkPhone: '',
      },
    });
  };

  const handleAddCustomerContact = async (formValues: PersonSelectorFormValues) => {
    try {
      const isCustomContactSource =
        syncAppDataSources?.find((integration) => integration.SourceID === formValues.source)?.SourceType ===
        'CustomContact';

      const response =
        isIntegratedOffice && !isCustomContactSource
          ? await handleAddWritebackContact(formValues)
          : await handleAddCustomContact(formValues);
      if (response) {
        alert.success(t('Customer successfully created.'));
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) =>
            invalidateFilterForPersonSearch(queryKey, formValues, selectedScheduleRequest.locationId),
        });
        personSelectorProps.setPersonSelectorScreen(PersonSelectorTypes.PatientSelectorScreenEnum.PERSON_SEARCH);
      }
    } catch (e) {
      console.error(e);
      alert.error(t('We were unable to create the customer.'));
    }
  };

  // On changing source, clear the provider and workstation values if the selected value is not available in the new source
  useEffect(() => {
    if (values.source) {
      const currentProviderIndex = filteredProviderList?.findIndex(({ id }) => id === values.provider);
      const currentWorkstationIndex = filteredWorkstationList?.findIndex(({ id }) => id === values.workstation);

      seedValues({
        ...values,
        ...(currentProviderIndex === -1 && { provider: '' }),
        ...(currentWorkstationIndex === -1 && { workstation: '' }),
      });
    }
  }, [values.source, filteredProviderList, filteredWorkstationList]);

  const isLoading = isLoadingModalDetails || isSyncAppDetailsLoading || isLoadingSourceTenants;
  const showSourceLocationDropdown = isIntegratedOffice && appointmentWritebackSourceTenants.length > 1;
  const showDataSourceDropdown = !isIntegratedOffice && getShouldRenderSourceDropDown(syncAppDataSources ?? []);
  const showWorkstationDropdown = filteredWorkstationList.length > 1;
  const disabledForm = !selectedPerson?.PersonID;
  const patientName = selectedPerson
    ? PersonHelpers.getFullName(selectedPerson)
    : getFullName(selectedScheduleRequest.schedulee);

  return (
    <>
      <ReviewAppointmentRequestForm
        appointmentTypes={appointmentTypes}
        isLoading={isLoading}
        dateField={customDateField}
        timeField={customTimeField}
        disabledForm={disabledForm}
        getFieldProps={getFieldProps}
        patientName={patientName}
        patientPhone={formatPhoneNumber(selectedPerson?.MobilePhone ?? selectedScheduleRequest?.schedulee?.phoneNumber)}
        personDialogProps={personSelectorDialogProps}
        providerList={filteredProviderList}
        selectedScheduleRequest={selectedScheduleRequest}
        showSourceLocationDropdown={showSourceLocationDropdown}
        showDataSourceDropdown={showDataSourceDropdown}
        showWorkstationDropdown={showWorkstationDropdown}
        appointmentWritebackSourceTenants={appointmentWritebackSourceTenants}
        syncAppDataSources={syncAppDataSources}
        values={values}
        workstationList={filteredWorkstationList}
        template={scheduleRequestConfirmedTemplate?.Template ?? ''}
        templateTags={tags}
        isReadOnlyTemplate={!isComplete || disabledForm}
        onTemplateChange={(template: string) => setTemplate(template)}
        isPaymentsEnabled={isPaymentsEnabled}
        paidAmount={paidAmount}
      />
      <PersonSelector
        shouldAddAndLink={false} // TODO: To be updated later after finalizing the flow
        addContactScreenTitle={t('Add Patient')}
        defaultSearchValue={selectedScheduleRequest?.schedulee?.phoneNumber ?? ''}
        locationId={selectedScheduleRequest.locationId ?? ''}
        handleAddCustomerContact={handleAddCustomerContact}
        isLoading={isLoadingAddWritebackCustomer || isAddingCustomContact}
        personSelectorDialogProps={personSelectorDialogProps}
        selectedPerson={selectedPerson}
        showDataSources={true}
        addNewButtonTrackingId={ScheduleRequestsTrackingIds.personSelectorAddNewPersonBtn}
        addToListButtonTrackingId={ScheduleRequestsTrackingIds.personSelectorAddToListBtn}
        {...personSelectorProps}
      />
    </>
  );
};
