import { FC, useMemo } from 'react';
import { css } from '@emotion/react';
import {
  AddOn,
  PackageType,
  SetDemoLocationCustomizationsRequest,
} from '@weave/schema-gen-ts/dist/schemas/package-service/v1/package.pb';
import { OpsType } from '@weave/schema-gen-ts/dist/shared/organization/ops_type.pb';
import { Vertical } from '@weave/schema-gen-ts/dist/shared/vertical/vertical.pb';
import { useHotkeys } from 'react-hotkeys-hook';
import { SubscriptionsQueries } from '@frontend/api-subscription';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { generateEnumOptions } from '@frontend/types';
import { theme } from '@frontend/theme';
import {
  ContentLoader,
  DropdownField,
  Modal,
  RadioCardField,
  Text,
  useForm,
  useModalControl,
  useAlert,
} from '@frontend/design-system';
import {
  BundleOptionalsEnum,
  BundlePackagesEnum,
  BundlePackagesLabel,
  IntegrationPackagesEnum,
  IntegrationPackagesLabel,
  getSalesforceBundlePackage,
} from '../../utils/salesforce-bundle';

const DEMO_TOOL_HOTKEY = 'ctrl+shift+s';
const ALLOWED_LOCATION_TYPE_FOR_DEMO: Array<OpsType> = [OpsType.DEMO];
const integrationPackageOptions = Object.values(IntegrationPackagesEnum).map((value) => ({
  value,
  label: IntegrationPackagesLabel[value],
}));
const bundlePackageOptions = Object.values(BundlePackagesEnum).map((value) => ({
  value,
  label: BundlePackagesLabel[value],
}));
const verticalOptions = generateEnumOptions(Vertical, [Vertical.INVALID_VERTICAL]);
const packageOptions = generateEnumOptions(PackageType, [PackageType.PACKAGE_DEFAULT]);

export const SalesDemoFeatureModal: FC = () => {
  const { t } = useTranslation('base');
  const alerts = useAlert();
  const { modalProps, openModal, closeModal } = useModalControl();
  const { accessibleLocationData, selectedLocationIds } = useAppScopeStore();

  const filteredLocationIds = useMemo(() => {
    return selectedLocationIds.filter((id) => {
      const opsType = accessibleLocationData[id]?.opsType;
      return !!opsType && ALLOWED_LOCATION_TYPE_FOR_DEMO.includes(opsType);
    });
  }, [accessibleLocationData, selectedLocationIds]);

  const { mutate, isLoading } = SubscriptionsQueries.useUpdateDemoLocationCustomization({
    onSuccess: () => {
      closeModal();
      location.reload();
    },
    onError: () => {
      alerts.error(t('Failed to Update'));
    },
  });

  const { formProps, getFieldProps, isComplete } = useForm({
    fields: {
      integrationPackage: { type: 'radio', required: true },
      bundlePackage: { type: 'radio', required: true },
      vertical: {
        type: 'dropdown',
        required: true,
      },
      packageType: { type: 'radio', required: false },
      addons: { type: 'checklist', required: false, value: [] },
    },
    onSubmit: (data) => {
      const requestData: SetDemoLocationCustomizationsRequest = {
        locationIds: filteredLocationIds,
        bundle: getSalesforceBundlePackage({
          integrationPackage: data.integrationPackage as IntegrationPackagesEnum,
          bundlePackage: data.bundlePackage as BundlePackagesEnum,
          bundleOptional:
            data.packageType === BundleOptionalsEnum.SOFTWARE_ONLY ? BundleOptionalsEnum.SOFTWARE_ONLY : undefined,
        }),
        vertical: data.vertical as Vertical,
        packageTypes: data.packageType !== BundleOptionalsEnum.SOFTWARE_ONLY ? [data.packageType as PackageType] : [],
        addons: data.addons as AddOn[],
      };
      mutate(requestData);
    },
  });

  useHotkeys(
    DEMO_TOOL_HOTKEY,
    () => {
      if (filteredLocationIds.length > 0) {
        openModal();
      }
    },
    [filteredLocationIds]
  );

  return (
    <Modal asChild {...modalProps} minWidth={600}>
      <form {...formProps}>
        <ContentLoader show={isLoading} />
        <Modal.Header onClose={closeModal}>{t('Demo Features')}</Modal.Header>
        <Modal.Body css={modalContentStyles}>
          <div css={formFieldStyles}>
            <Text size='large' weight='bold' as='label'>
              {t('Select Integration')}
            </Text>
            <RadioCardField {...getFieldProps('integrationPackage')}>
              {integrationPackageOptions.map(({ value, label }) => (
                <RadioCardField.Option key={value} value={value} title={label} />
              ))}
            </RadioCardField>
          </div>
          <div css={formFieldStyles}>
            <Text size='large' weight='bold' as='label'>
              {t('Select Bundles')}
            </Text>
            <RadioCardField {...getFieldProps('bundlePackage')}>
              {bundlePackageOptions.map(({ value, label }) => (
                <RadioCardField.Option key={value} value={value} title={label} />
              ))}
            </RadioCardField>
          </div>
          <div css={[formFieldStyles, verticalFieldStyles]}>
            <Text size='large' weight='bold' as='label'>
              {t('Vertical')}
            </Text>
            <DropdownField label={t('Vertical')} {...getFieldProps('vertical')}>
              {verticalOptions.map(({ label, value }) => (
                <DropdownField.Option key={value} value={`${value}`}>
                  {label}
                </DropdownField.Option>
              ))}
            </DropdownField>
          </div>
          <div css={formFieldStyles}>
            <Text size='large' weight='bold' as='label'>
              {t('Packages')}
            </Text>
            <RadioCardField {...getFieldProps('packageType')}>
              {packageOptions.map(({ value, label }) => (
                <RadioCardField.Option key={value} value={`${value}`} title={label} />
              ))}
            </RadioCardField>
          </div>
        </Modal.Body>
        <Modal.Footer
          primary={{
            type: 'submit',
            label: t('Done'),
            disabled: !isComplete,
          }}
          secondary={{
            onClick: closeModal,
            label: t('Cancel'),
          }}
        />
      </form>
    </Modal>
  );
};

const modalContentStyles = css`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-top: ${theme.spacing(2)};
  gap: ${theme.spacing(2)};
`;

const formFieldStyles = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
`;

const verticalFieldStyles = css`
  width: 60%;
`;
