import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {
  ApiOverviewStatsParams,
  ApiPayloadProps,
  CallStatusEnum,
  CallTaskParams,
  DrillDownOptions,
  FilterPayload,
  Filters,
  FilterTypeEnum,
  TaskFilterTypeEnum,
  TaskSource,
} from './types';

dayjs.extend(utc);
dayjs.extend(timezone);

export const generateCommonFilterPayload = (filters: Filters): FilterPayload => {
  // Get the current timezone offset in minutes
  const timezoneOffsetMinutes = dayjs().utcOffset();
  const start = dayjs(filters.startDate)
    .startOf('day')
    .add(-timezoneOffsetMinutes, 'minute')
    .format('YYYY-MM-DD HH:mm:ss.SSS');

  const end = dayjs(filters.endDate)
    .endOf('day')
    .add(-timezoneOffsetMinutes, 'minute')
    .format('YYYY-MM-DD HH:mm:ss.SSS');

  return {
    filter: {
      [FilterTypeEnum.FILTER_BY_DATE_RANGE]: {
        date_range: {
          end,
          start,
        },
      },
      [FilterTypeEnum.FILTER_BY_LOCATION]: {
        locations: filters.locations,
      },
    },
  };
};

export const generateDrillDownPayload = (drillDownOptions: DrillDownOptions) => {
  const { index, key, value } = drillDownOptions;

  return {
    [index]: {
      [key]: value,
    },
  };
};

export const generateChartsPayload = ({ drillDownOptions, filters }: ApiPayloadProps) => {
  const payload = generateCommonFilterPayload(filters);

  if (filters.contactTypes?.length) {
    payload.filter[FilterTypeEnum.FILTER_BY_CONTACT_TYPE] = {
      contact_type: filters.contactTypes,
    };
  }

  payload.filter = {
    ...payload.filter,
    [FilterTypeEnum.FILTER_BY_CALL_STATUS]: {
      call_status: [CallStatusEnum.CALL_STATUS_COMPLETE],
    },
  };

  if (drillDownOptions) {
    payload.filter = {
      ...payload.filter,
      ...generateDrillDownPayload(drillDownOptions),
    };
  }

  return payload;
};

export const getDemoServiceQualityChartStats = ({ includes, filters }: ApiOverviewStatsParams) => {
  const { filter: chartsFilter } = generateChartsPayload({ filters });

  const payload = {
    request: {
      filter: {
        ...chartsFilter,
        [FilterTypeEnum.FILTER_BY_LOCATION]: {
          locations: ['40417eee-943e-4b57-80d8-794b41676885'], // This is hardcoded location id for demodata
        },
      },
      includes: {
        opportunities: includes.opportunities,
        service_quality: includes.serviceQuality,
      },
      timezone: dayjs.tz.guess(),
    },
  };

  return payload;
};

export const generateListTasksPayload = (callTaskParams: CallTaskParams) => {
  let payload = { filter: {}, include_metadata: false, sort_type: 2 };

  payload.filter = {
    ...payload.filter,
    [TaskFilterTypeEnum.FILTER_BY_LOCATION]: {
      location_id: callTaskParams.locations,
    },

    [TaskFilterTypeEnum.FILTER_BY_SOURCE]: {
      source: callTaskParams.source || TaskSource.CALL_INTELLIGENCE,
    },

    [TaskFilterTypeEnum.FILTER_BY_SOURCE_ID]: {
      source_id: callTaskParams.callIds,
    },

    ...(callTaskParams?.taskStatusFilters?.length && {
      [TaskFilterTypeEnum.FILTER_BY_STATUS]: {
        status: callTaskParams.taskStatusFilters,
      },
    }),

    ...(callTaskParams?.taskTypes?.length && {
      [TaskFilterTypeEnum.FILTER_BY_TASK_TYPE]: {
        type: callTaskParams.taskTypes,
      },
    }),
  };

  if (callTaskParams.includeMetaData) {
    payload = { ...payload, include_metadata: callTaskParams.includeMetaData };
  }

  if (callTaskParams.sortType) {
    payload = { ...payload, sort_type: callTaskParams.sortType };
  }

  return payload;
};

export const fakeApi = async <T>(value: T, delay = 200): Promise<T> => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(value), delay);
  });
};
