import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { TaskType } from '@weave/schema-gen-ts/dist/schemas/task-center/shared/v1/enums.pb';
import { useInView } from 'react-intersection-observer';
import { AnalyticsCommonTypes, CallIntelligenceApi } from '@frontend/api-analytics';
import { CallIntelTypes } from '@frontend/api-call-intel';
import { Chart, ScrollControl } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { useLastUsedVerticalShallowStore } from '@frontend/location-helpers';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  Chip,
  ChipVariants,
  emptyStateGraphics,
  NakedButton,
  NakedUl,
  SkeletonLoaders,
  Text,
  TextLink,
  useAlert,
  variantsTheme,
} from '@frontend/design-system';
import { URLs } from '../../constants';
import { queryKeys } from '../../query-keys';
import { trackingIds } from '../../tracking-ids';
import { callIntelligenceUtils, generateContactId, getFilteredEnumValues } from '../../utils';
import { DemoChip } from '../demo-chip';
import { UserCard } from '../user-card';
import { CallIntelInfoTipId, CallIntelInfoTips } from './call-intel-info-tips';
import { CallIntelMockData } from './demo-data';
import { useCallIntelDemoFlags, useCallIntelShallowStore } from './hooks';

type KnownTaskType = Exclude<TaskType, TaskType.TYPE_UNKNOWN>;

type TaskReasonCardHeadingProps = {
  background: string;
  color: string;
  count: number;
  infoTip: ReactNode;
  label: string;
  onNavigate: () => void;
};

type TaskPatientPreviewProps = {
  call: CallIntelTypes.Call;
  contactType: string;
  contactVariant: ChipVariants;
  totalTasks: number;
  onNavigate: (id: string) => void;
};

type TaskReasonCardProps = {
  count: number;
  isLoading: boolean;
  label: string;
  type: KnownTaskType;
};

type TaskReasonCardLoaderProps = {
  type: 'header' | 'body';
};

type EmptyTaskProps = {
  typeLabel: string;
};

type Props = {
  data: Record<Exclude<TaskType, TaskType.TYPE_UNKNOWN>, number>;
  infoTipId: CallIntelInfoTipId;
  isLoading: boolean;
  title: string;
};

// Extracted utility function for getting background and color from variant
const extractBackground = (variant: keyof typeof variantsTheme): { background: string; color: string } => {
  const cssString = variantsTheme[variant]?.styles;

  if (!cssString) return { background: 'transparent', color: 'inherit' };

  const backgroundMatch = cssString.match(/background:\s*([^;]+);/);
  const colorMatch = cssString.match(/color:\s*([^;]+);/);

  return {
    background: backgroundMatch ? backgroundMatch[1].trim() : 'transparent',
    color: colorMatch ? colorMatch[1].trim() : 'inherit',
  };
};

const TASK_TYPE_PREFIX = 'TYPE_';

const dataPoints = getFilteredEnumValues(TaskType, TaskType.TYPE_UNKNOWN);

const pageConfig: AnalyticsCommonTypes.PageConfig = {
  pageNumber: 1,
  pageSize: 3,
};

// Mapping TaskTypeEnum to InfoTipIds
const taskTypeInfoTipMapping: Record<KnownTaskType, CallIntelInfoTipId> = {
  [TaskType.TYPE_SCHEDULING]: 'taskTypeScheduling',
  [TaskType.TYPE_WAITLIST]: 'taskTypeWaitlist',
  [TaskType.TYPE_PATIENT_CARE]: 'taskTypePatientCare',
  [TaskType.TYPE_INSURANCE]: 'taskTypeInsurance',
  [TaskType.TYPE_BILLING]: 'taskTypeBilling',
  [TaskType.TYPE_OTHER]: 'taskTypeOther',
};

// Empty Task Component
const EmptyTask = ({ typeLabel }: EmptyTaskProps) => {
  const { t } = useTranslation('analytics');

  const EmptyStateGraphicNoTasks = emptyStateGraphics.noCallFollowUps;

  return (
    <div css={styles.emptyStateWrapper}>
      <EmptyStateGraphicNoTasks height={124} width={124} />
      <Text css={styles.emptyStateText}>{t('No {{typeLabel}} Follow-ups', { typeLabel })}</Text>
    </div>
  );
};

//Task Reason Card Component Loader
const TaskReasonCardLoader = ({ type }: TaskReasonCardLoaderProps) => {
  return (
    <>
      {type === 'header' ? (
        <SkeletonLoaders.Loader height={theme.spacing(5)} />
      ) : (
        <SkeletonLoaders.Loader height={theme.spacing(23)} />
      )}
    </>
  );
};

// Task Reason Card Heading Component
const TaskReasonCardHeading = ({
  background,
  color,
  count,
  label,
  infoTip,
  onNavigate,
}: TaskReasonCardHeadingProps) => {
  const { t } = useTranslation('analytics');

  return (
    <div css={styles.taskReasonCardHeadingWrapper(background, color)}>
      <div className='info-wrapper'>
        <Text className='info-count' weight='bold'>
          {count}
        </Text>
        <Text weight='bold'>{label}</Text>
        {infoTip}
      </div>
      <TextLink
        onClick={onNavigate}
        size='medium'
        trackingId={trackingIds.callIntel.followUpSummaryViewListBtn}
        weight='bold'
      >
        {t('View List')}
      </TextLink>
    </div>
  );
};

// Task Patient Preview Component
const TaskPatientPreview = ({ call, contactVariant, contactType, onNavigate, totalTasks }: TaskPatientPreviewProps) => {
  const { t } = useTranslation('analytics');

  return (
    <NakedButton
      css={styles.taskPatientPreviewWrapper}
      trackingId={trackingIds.callIntel.followUpSummaryRowClick}
      onClick={() => onNavigate(generateContactId(call))}
    >
      <UserCard
        css={styles.userCard}
        firstName={call.person?.firstName}
        lastName={call.person?.lastName}
        locationId={call.locationId}
        key={call.person?.id}
        openProfileOnClick={false}
        phoneNumber={callIntelligenceUtils.getPhoneNumber(call.phoneNumber)}
        rightElement={
          <Text size='small'> {t(`{{ totalTasks }} ${totalTasks > 1 ? 'Tasks' : 'Task'}`, { totalTasks })} </Text>
        }
        showOnlyName
        userId={call.person?.id}
      />
      <Chip variant={contactVariant}>{contactType}</Chip>
    </NakedButton>
  );
};

// Task Reason Card Component
const TaskReasonCard = ({ count, isLoading, label, type }: TaskReasonCardProps) => {
  const { t } = useTranslation('analytics');
  const navigate = useNavigate();
  const alert = useAlert();
  const { chipVariants, dataLabels, filters, headerChipVariants, isDemoAccount, setFiltersToRestore } =
    useCallIntelShallowStore(
      'chipVariants',
      'dataLabels',
      'filters',
      'headerChipVariants',
      'isDemoAccount',
      'setFiltersToRestore'
    );
  const { lastUsedVertical: vertical } = useLastUsedVerticalShallowStore('lastUsedVertical');

  const { inView: isVisible, ref } = useInView();

  const followupTaskTypeFilters: CallIntelTypes.FollowupTaskTypeFilters = {
    startDate: filters.startDate,
    endDate: filters.endDate,
    locations: filters.locations,
    officeUsers: filters.officeUsers,
    contactTypes: filters.contactTypes,
    taskTypes: [type],
    taskStatus: [],
  };

  const { color, background } = extractBackground(headerChipVariants[type]?.variant as ChipVariants);

  const queryString = useMemo(
    () => `calls-preview-followups-${JSON.stringify(followupTaskTypeFilters)}-isDemoAccount-${isDemoAccount}`,
    [followupTaskTypeFilters, isDemoAccount]
  );

  const { data, isLoading: isPatientLoading } = useScopedQuery({
    queryKey: queryKeys.callIntelligence(queryString),
    queryFn: () =>
      isDemoAccount
        ? CallIntelligenceApi.noopMutationFn(
            CallIntelMockData.getFollowUps({
              filters: followupTaskTypeFilters,
              pageConfig,
              sortBy: CallIntelTypes.FollowupSortFieldEnum.SORT_LAST_CALL,
              sortType: CallIntelTypes.SortTypeEnum.SORT_DESC,
              randomFollowUps: false,
              vertical,
            })
          )
        : CallIntelligenceApi.getFollowUps({
            filters: followupTaskTypeFilters,
            pageConfig,
            sortBy: CallIntelTypes.FollowupSortFieldEnum.SORT_LAST_CALL,
            sortType: CallIntelTypes.SortTypeEnum.SORT_DESC,
          }),
    onError: () => {
      alert.error(t('Failed to fetch follow-ups'));
    },
    enabled: isDemoAccount ? true : isVisible,
    select: (data) => data as CallIntelTypes.FollowUpResponse,
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const handleNavigation = useCallback(
    (route: string, searchParams: Record<string, string> = {}) => {
      setFiltersToRestore(filters);
      navigate({ to: `${URLs.CALL_INTEL_BASE}${route}`, search: searchParams });
    },
    [navigate, filters]
  );

  return (
    <div css={styles.taskReasonCardWrapper} ref={ref}>
      {isLoading ? (
        <TaskReasonCardLoader type='header' />
      ) : (
        <TaskReasonCardHeading
          background={background}
          color={color}
          count={count}
          label={label}
          infoTip={<CallIntelInfoTips tip={taskTypeInfoTipMapping[type]} />}
          onNavigate={() => handleNavigation(`/follow-ups/${type.split(TASK_TYPE_PREFIX)[1]?.toLowerCase()}`)}
        />
      )}

      {!isLoading && !isPatientLoading && <div css={styles.taskReasonDivider} />}

      {isLoading || isPatientLoading ? (
        <TaskReasonCardLoader type='body' />
      ) : (data?.totalFollowUps ?? 0) > 0 ? (
        <div css={styles.followUpsContainer}>
          {data?.followUps?.map((followUp) => (
            <TaskPatientPreview
              call={followUp?.calls[0]}
              contactType={
                dataLabels.contactType?.[followUp?.calls?.[0]?.contactType as keyof typeof dataLabels.contactType] ||
                t('Unknown')
              }
              contactVariant={chipVariants[followUp?.calls[0]?.contactType ?? 'neutral'].variant as ChipVariants}
              key={followUp?.calls?.[0]?.id}
              onNavigate={(id) =>
                handleNavigation(`/follow-ups/${type.split(TASK_TYPE_PREFIX)[1]?.toLowerCase()}`, { id })
              }
              totalTasks={followUp?.totalTasks}
            />
          ))}
        </div>
      ) : (
        <EmptyTask typeLabel={label} />
      )}
    </div>
  );
};

// Follow Up Summary Component
export const FollowUpSummary = ({ data, infoTipId, isLoading, title }: Props) => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const navigate = useNavigate();
  const { showDemoChipAndBanner } = useCallIntelDemoFlags();
  const { dataLabels, filterHintText, filters, isDemoAccount, setFiltersToRestore } = useCallIntelShallowStore(
    'dataLabels',
    'filterHintText',
    'filters',
    'isDemoAccount',
    'setFiltersToRestore'
  );
  const [showLeftScrollButton, setShowLeftScrollButton] = useState<boolean>(false);
  const [showRightScrollButton, setShowRightScrollButton] = useState<boolean>(false);
  const ulRef = useRef<HTMLUListElement>(null);
  const scrollView = ulRef.current;

  const totalFollowUps = useMemo(() => {
    return Object.values(data).reduce((total, count) => total + count, 0);
  }, [data]);

  const followupTaskTypeFilters: CallIntelTypes.FollowupTaskTypeFilters = {
    startDate: filters.startDate,
    endDate: filters.endDate,
    locations: filters.locations,
    officeUsers: filters.officeUsers,
    contactTypes: filters.contactTypes,
    taskTypes: dataPoints as TaskType[],
    taskStatus: [],
  };

  const queryString = useMemo(
    () => `calls-followups-count-${JSON.stringify(followupTaskTypeFilters)}-isDemoAccount-${isDemoAccount}`,
    [followupTaskTypeFilters, isDemoAccount]
  );

  const { data: actualFollowUpCount, isLoading: isCountLoading } = useScopedQuery({
    queryKey: queryKeys.callIntelligence(queryString),
    queryFn: () =>
      CallIntelligenceApi.getFollowUps({
        filters: followupTaskTypeFilters,
        pageConfig,
        sortBy: CallIntelTypes.FollowupSortFieldEnum.SORT_LAST_CALL,
        sortType: CallIntelTypes.SortTypeEnum.SORT_DESC,
      }),
    onError: () => {
      alert.error(t('Failed to fetch follow-ups'));
    },
    enabled: !isDemoAccount,
    select: (data) => data as CallIntelTypes.FollowUpResponse,
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const handleControlledScroll = useCallback(
    (direction: 'left' | 'right' | 'reset') => {
      if (!scrollView) {
        return;
      }

      const columns = dataPoints.length;

      const scrollLeft = scrollView.scrollWidth / columns;

      if (direction === 'left') {
        const scrollTo = scrollView.scrollLeft - scrollLeft;
        const hasMoreToScroll = scrollTo > 0;
        scrollView.scrollTo({
          left: scrollTo,
          behavior: 'smooth',
        });
        setShowRightScrollButton(true);
        setShowLeftScrollButton(hasMoreToScroll);
      } else if (direction === 'right') {
        const scrollTo = scrollView.scrollLeft + scrollLeft;
        const hasMoreToScroll = scrollView.scrollWidth - scrollTo > scrollView.clientWidth;
        scrollView.scrollTo({
          left: scrollTo,
          behavior: 'smooth',
        });
        setShowLeftScrollButton(true);
        setShowRightScrollButton(hasMoreToScroll);
      } else {
        const hasMoreToScroll = scrollView.scrollWidth > scrollView.clientWidth;
        scrollView.scrollTo({
          left: 0,
        });
        setShowLeftScrollButton(false);
        setShowRightScrollButton(hasMoreToScroll);
      }
    },
    [dataPoints.length, scrollView?.childElementCount]
  );

  useEffect(() => {
    // Debounce the scroll event to avoid performance issues
    let timeout: ReturnType<typeof setTimeout>;
    handleControlledScroll('reset');

    const handleScroll = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (!scrollView) {
          return;
        }

        const scrollWidth = scrollView.scrollWidth;
        const clientWidth = scrollView.clientWidth;
        const scrollLeft = scrollView.scrollLeft;

        setShowLeftScrollButton(scrollLeft > 0);
        setShowRightScrollButton(scrollLeft < scrollWidth - clientWidth);
      }, 100);
    };

    if (scrollView) {
      scrollView.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollView) {
        scrollView.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollView?.childElementCount]);

  return (
    <Chart>
      <Chart.Header
        actions={
          !isLoading || !isCountLoading
            ? [
                {
                  label: t('View All Follow-ups ({{totalFollowUps}})', {
                    totalFollowUps: !isDemoAccount ? actualFollowUpCount?.totalFollowUps ?? 0 : totalFollowUps,
                  }),
                  onClick: () => {
                    setFiltersToRestore(filters);
                    navigate({
                      to: `${URLs.CALL_INTEL_BASE}/follow-ups/all`,
                    });
                  },
                  trackingId: trackingIds.callIntel.followUpSummaryViewAllBtn,
                },
              ]
            : []
        }
        css={styles.header}
        infoTip={infoTipId ? <CallIntelInfoTips tip={infoTipId} /> : null}
        leftElement={showDemoChipAndBanner ? <DemoChip /> : null}
        subtitle={filterHintText}
        title={title}
      />

      <div css={styles.chartContainer}>
        <NakedUl css={styles.ul} ref={ulRef}>
          {Object.entries(data).map(([key, value]) => (
            <TaskReasonCard
              key={key}
              count={value}
              isLoading={isLoading}
              label={dataLabels?.taskTypes?.[key] ?? key}
              type={key as KnownTaskType}
            />
          ))}
        </NakedUl>

        <ScrollControl
          css={[styles.scrollButton, showLeftScrollButton ? styles.scrollButtonVisible : styles.scrollButtonInVisible]}
          direction='left'
          disabled={!showLeftScrollButton}
          isVisible
          onClick={() => handleControlledScroll('left')}
        />

        <ScrollControl
          css={[styles.scrollButton, showRightScrollButton ? styles.scrollButtonVisible : styles.scrollButtonVisible]}
          direction='right'
          disabled={!showRightScrollButton}
          isVisible
          onClick={() => handleControlledScroll('right')}
        />
      </div>
    </Chart>
  );
};

const styles = {
  header: css`
    .actions-wrapper {
      flex-direction: row-reverse;

      button {
        font-weight: ${theme.font.weight.bold};
      }
    }
  `,
  emptyStateText: css`
    color: ${theme.colors.neutral50};
  `,
  emptyStateWrapper: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${theme.spacing(0, 2)};
  `,
  taskReasonCardHeadingWrapper: (background: string, color: string) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacing(1, 0.5)};
    background: ${background};
    border-radius: ${theme.borderRadius.small};

    .info-wrapper {
      display: flex;
      align-items: center;

      .info-count {
        color: ${color};
        margin-right: ${theme.spacing(0.5)};
        font-size: ${theme.font.size.h3};
        line-height: ${theme.spacing(3)};
      }
    }
  `,
  taskPatientPreviewWrapper: css`
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing(0.75, 0.5, 0.75, 1)};
    align-items: center;

    > div:first-child {
      width: calc(100% - ${theme.spacing(2)});
    }

    :hover {
      background-color: ${theme.colors.neutral5};
    }
  `,
  userCard: css`
    > div {
      width: 100%;
      cursor: pointer !important;

      > figure {
        height: ${theme.spacing(4)};
        width: ${theme.spacing(4)};
        min-height: ${theme.spacing(4)};
        min-width: ${theme.spacing(4)};
        font-size: ${theme.font.size.small};
      }

      > div {
        width: 173px;
        display: flex;
        justify-content: space-between;
      }

      .name-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: ${theme.spacing(0.5)};
        width: 100%;

        > p {
          font-size: ${theme.font.size.medium};
          line-height: ${theme.spacing(2.5)};
        }

        > p:last-child {
          font-size: ${theme.font.size.small};
          line-height: ${theme.spacing(2)};
          color: ${theme.colors.neutral50};
        }

        span {
          font-size: ${theme.font.size.medium};
          line-height: ${theme.spacing(2.5)};
        }
      }
    }
  `,
  taskReasonCardWrapper: css`
    padding: ${theme.spacing(2)};
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    border: ${theme.spacing(0.1)} solid ${theme.colors.neutral20};
    border-radius: ${theme.borderRadius.medium};
    width: ${theme.spacing(50)};
    height: 272px;
    flex-shrink: 0;
  `,
  taskReasonDivider: css`
    height: ${theme.spacing(0.125)};
    background-color: ${theme.colors.neutral20};
  `,
  followUpsContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(0)};
  `,
  chartContainer: css`
    position: relative;
  `,
  ul: css`
    display: flex;
    gap: ${theme.spacing(2)};
    overflow: auto;
    padding: ${theme.spacing(0, 3)};
  `,
  scrollButton: css`
    bottom: 0;
    margin: auto;
    position: absolute;
    top: 0;
    height: auto;
    width: ${theme.spacing(3)};
    padding: ${theme.spacing(0)};

    &.left-scroll {
      left: 0px;
    }

    &.right-scroll {
      right: 0px;
    }
  `,
  scrollButtonVisible: css`
    box-shadow: ${theme.shadows.floating};
  `,
  scrollButtonInVisible: css`
    box-shadow: none;
  `,
};
