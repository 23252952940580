// eslint-disable-next-line @nx/enforce-module-boundaries -- I think we should allow this here to disallow any misuse on consuming libs to forget to purify their messages
import DOMPurify from 'dompurify';
import { StreamChat, type Channel, type ChannelFilters, type MessageFilters } from 'stream-chat';
import { dateCompare } from '@frontend/date';
import { http } from '@frontend/fetch';
import { sleep } from '@frontend/timer';
import type { Attachment, Conversation, User, Message } from '../types';
import {
  CHANNEL_TYPE_TEAM_DESIGNATION,
  CHANNELS_QUERY_LIMIT,
  DELETED_USER_DESIGNATION,
  MESSAGES_QUERY_LIMIT,
  USERS_QUERY_LIMIT,
} from './stream-constants';
import {
  constructConversationFromStreamChannel,
  constructMessageFromStreamSearchResult,
  constructStreamAttachmentFromAttachment,
  constructStreamMessageFromMessage,
  constructUserFromStreamUser,
  generateUniqueName,
  getStreamUserId,
  getTeamId,
} from './stream-utils';

export { StreamChat as Client };

export const getStreamChatToken = async () => {
  return http.get<{ token: string; apiKey: string }>('/team-chat/v1/token');
};

export const updateUserPresence = (status: 'online' | 'offline', expiry: Date) => {
  return http.post<unknown, { online: boolean; expires_at: string }>('/team-chat/v1/user-presence', {
    online: status === 'online',
    expires_at: expiry.toISOString(),
  });
};

/** Just stop the websocket connection, but doesn't lost client state or user login state */
export const disconnectStreamClient = (client: StreamChat | undefined) => {
  if (!client) {
    return Promise.resolve();
  }
  return client.closeConnection().catch((err) => {
    //catching and not re-throwing here because it usually means the user is already disconnected, so this should be an okay response
    console.error('Error disconnecting stream client', err);
  });
};

export const reconnectStreamClient = (client: StreamChat | undefined) => {
  if (!client) {
    throw new Error('Cannot Reconnect. No Stream client available');
  }
  if (
    client.wsConnection?.connectionOpen &&
    (client.wsConnection?.ws?.readyState === WebSocket.OPEN ||
      client.wsConnection?.ws?.readyState === WebSocket.CONNECTING)
  ) {
    return client.wsConnection.connectionOpen;
  }
  return client.connect().catch((err) => {
    console.error('Error reconnecting stream client', err);
  });
};

/**
 *  Coupling Client and User together for practical reasons (they'll always be used together)
 */
export const initializeStreamClient = async ({ orgId, weaveUserId }: { orgId: string; weaveUserId: string }) => {
  const streamUserId = getStreamUserId({ orgId, weaveUserId });
  const { token, apiKey } = await getStreamChatToken();
  const client = StreamChat.getInstance(apiKey, {
    persistUserOnConnectionFailure: true,
    recoverStateOnReconnect: false,
  });
  if (client.user) {
    await client.disconnectUser();
  }
  const connectUser = () => {
    return client.connectUser({ id: streamUserId }, token).then(async (_res) => {
      //There is yet another strange stream race condition here, where calling connect user doesn't always immediately set the user on the client :/
      for (let i = 0; i < 10; i++) {
        if (client.user) {
          break;
        }
        await sleep(100);
      }
      if (!client.user) {
        throw new Error('Stream client user is not connected');
      }
      return {
        client,
        user: constructUserFromStreamUser(client.user),
      };
    });
  };

  //Stream has some strange conditions. Stream chat seems to need a little extra time to realize the token they gave me is valid.
  //So I can't seem to fetch the token and then immediately use it. So I'm going to do a retry loop here to give it a few chances
  //TODO: Is there away around this?
  await sleep(200);
  const retries = 5;
  for (let i = 0; i < retries; i++) {
    try {
      return await connectUser();
    } catch (err) {
      if (i === retries - 1) {
        throw err;
      }
      await sleep(500);
    }
  }
  throw new Error('Stream client failed to connect');
};

/**
 * Finds the number of unread messages that have mentioned the current user
 */
export const getUnreadMentionsCount = (streamClient: StreamChat, currentUserId: string) => {
  const channelFilters: ChannelFilters = { members: { $in: [currentUserId] } };
  const messageFilters: MessageFilters = {
    'mentioned_users.id': { $contains: currentUserId },
  };
  return streamClient
    .search(channelFilters, messageFilters, {
      sort: [{ relevance: -1 }, { updated_at: 1 }],
    })
    .then((res) => res.results.map((result) => result.message).filter((message) => message.status === 'unread').length)
    .catch((err) => {
      console.error(err);
      return 0;
    });
};

/**
 * Finds all the messages that mention the current user within the provided limit and offset
 */
export const getMentions = (streamClient: StreamChat, currentUserId: string, limit: number, offset: number) => {
  const channelFilters: ChannelFilters = { members: { $in: [currentUserId] } };
  const messageFilters: MessageFilters = {
    'mentioned_users.id': { $contains: currentUserId },
  };

  return streamClient
    .search(channelFilters, messageFilters, {
      sort: [{ created_at: -1 }],
      limit,
      offset,
    })
    .then((res) => {
      return res.results.map(({ message }) => constructMessageFromStreamSearchResult(message));
    });
};

/**
 * Retrieves all of the users in the org.
 * Self paginates to aggregate all of them into one array
 */
export const getUsers = async (client: StreamChat, orgId: string) => {
  let hasMoreUsers = true;
  const uniqueUserIDs = new Set<string>();
  const uniqueUsers: User[] = [];
  let offset = 0;

  while (hasMoreUsers) {
    const { users } = await client.queryUsers(
      { teams: { $contains: getTeamId(orgId) } },
      { name: 1 },
      { presence: true, limit: USERS_QUERY_LIMIT, offset }
    );

    users.forEach((user) => {
      // during the migration, users which were not part of the location anymore were named as 'deleted user'
      // we are filtering out those users
      // This requires a permanent fix from the backend
      if (user.name !== DELETED_USER_DESIGNATION && user.id !== client.user?.id) {
        const formattedUser = constructUserFromStreamUser(user);

        // We need to dedupe the users as it has been causing issues in the UI
        if (!uniqueUserIDs.has(formattedUser.userID)) {
          uniqueUserIDs.add(formattedUser.userID);
          uniqueUsers.push(formattedUser);
        }
      }
    });

    offset += USERS_QUERY_LIMIT;
    hasMoreUsers = users.length === USERS_QUERY_LIMIT;
  }

  return uniqueUsers;
};

/**
 * Retrieves all of the conversations in the org
 * Self paginates to combine them all into a single array
 */
export const getConversations = async (client: StreamChat, selectedOrgId: string) => {
  if (!client.user?.id) {
    throw new Error('Cannot retrieve conversations. No current user');
  }

  const channels: Channel[] = [];
  let hasMoreChannels = true;
  let offset = 0;
  while (hasMoreChannels) {
    const paginatedChannels = await client.queryChannels(
      {
        type: CHANNEL_TYPE_TEAM_DESIGNATION,
        team: { $in: [getTeamId(selectedOrgId)] },
        members: { $in: [client.user?.id ?? ''] },
        frozen: false,
      },
      [{ last_updated: -1 }],
      {
        watch: true,
        state: true,
        presence: true,
        limit: CHANNELS_QUERY_LIMIT,
        offset,
        message_limit: MESSAGES_QUERY_LIMIT,
      }
    );
    channels.push(...paginatedChannels);
    offset += CHANNELS_QUERY_LIMIT;
    hasMoreChannels = paginatedChannels.length === CHANNELS_QUERY_LIMIT;
  }

  const conversations = channels.map((channel) =>
    constructConversationFromStreamChannel(channel, client.user?.id ?? '')
  );

  const unread = conversations.filter((c) => c.unreadCount > 0);
  const read = conversations.filter((c) => c.unreadCount === 0);

  const conversationsSortedByStatus = [
    ...unread.toSorted((c1, c2) => dateCompare(c2.lastMessageSentAt, c1.lastMessageSentAt)),
    ...read.toSorted((c1, c2) => (!c1.name || !c2.name ? 0 : c1.name.localeCompare(c2.name))),
  ];

  return {
    dm: conversationsSortedByStatus.filter((c) => c.type === 'DM'),
    groups: conversationsSortedByStatus.filter((c) => c.type === 'Group'),
  };
};

/**
 * Retrieves all the messages in the conversations within the provided limit and offset
 * @param cursor is not an offset, it is a message id
 */
export const getConversationMessages = async (
  client: StreamChat,
  conversationId: string,
  userId: string,
  cursor: string,
  limit: number
) => {
  const channel = client.getChannelById('team', conversationId, {});
  const messages = await channel
    ?.query({
      // i wish we could filter out the deleted messages on the query
      // afaict, stream does not provide a way to do this
      messages: { id_lt: cursor, limit: limit },
    })
    .then((res) => {
      return res.messages;
    });

  return messages.map((message) => constructMessageFromStreamSearchResult(message, userId));
};

type ReplyToSend = {
  text: string;
  attachments: Attachment[];
  mentionedUserIds: string[];
  parentMessageId: string;
};
export const sendReply = async (
  client: StreamChat,
  userId: string,
  conversationId: string,
  { text, attachments, mentionedUserIds, parentMessageId }: ReplyToSend
) => {
  return sendMessage({
    client,
    userId,
    conversationId,
    message: {
      text,
      attachments,
      mentionedUserIds,
      parentMessageId,
    },
  });
};

export const getReplies = async (
  client: StreamChat,
  conversationId: string,
  userId: string,
  parentMessageId: string,
  limit: number,
  cursor: string
) => {
  const channel = client.getChannelById(CHANNEL_TYPE_TEAM_DESIGNATION, conversationId, {});
  const replies = await channel?.getReplies(parentMessageId, { limit, id_lt: cursor });

  return replies?.messages.reduce((acc, message) => {
    if (message.type !== 'deleted') {
      acc.push(constructMessageFromStreamSearchResult(message, userId));
    }
    return acc;
  }, [] as ReturnType<typeof constructMessageFromStreamSearchResult>[]);
};

type MessageToSend = {
  text: string;
  attachments: Attachment[];
  mentionedUserIds: string[];
  // adding parentMessageId to the message to make it a thread
  parentMessageId?: string;
};
/**
 * Sends a message to provided conversation id
 */
export const sendMessage = async ({
  client,
  userId,
  conversationId,
  message: { text, attachments, mentionedUserIds, parentMessageId },
}: {
  client: StreamChat;
  userId: string;
  conversationId: string;
  message: MessageToSend;
}) => {
  return client
    .getChannelById(CHANNEL_TYPE_TEAM_DESIGNATION, conversationId, {})
    .sendMessage({
      text: DOMPurify.sanitize(text),
      mentioned_users: mentionedUserIds,
      attachments: attachments.map((attachment) => constructStreamAttachmentFromAttachment(attachment)),
      parent_id: parentMessageId,
    })
    .then((response) => constructMessageFromStreamSearchResult(response.message, userId));
};

export const editMessage = async (client: StreamChat, userId: string, message: Message) => {
  const streamMessage = constructStreamMessageFromMessage(message);
  return client
    .updateMessage(streamMessage, userId)
    .then((response) => constructMessageFromStreamSearchResult(response.message, userId));
};

export const deleteMessage = async (client: StreamChat, messageId: string, hardDelete?: boolean) => {
  // we only use hard delete for replies. we do this because hard deleting affects the `thread_participants` array behind the scenes. we want this behavior to show the correct number of participants in the thread
  return client.deleteMessage(messageId, hardDelete).then((res) => constructMessageFromStreamSearchResult(res.message));
};

export const addReactionToMessage = (client: StreamChat, channelId: string, messageId: string, reaction: string) => {
  return client
    .getChannelById('team', channelId, {})
    .sendReaction(messageId, { type: reaction, user: client.user })
    .then((res) => {
      return constructMessageFromStreamSearchResult(res.message, 'three');
    });
};

export const removeReactionFromMessage = (
  client: StreamChat,
  channelId: string,
  messageId: string,
  reaction: string
) => {
  return client
    .getChannelById(CHANNEL_TYPE_TEAM_DESIGNATION, channelId, {})
    .deleteReaction(messageId, reaction)
    .then((res) => {
      constructMessageFromStreamSearchResult(res.message, 'two');
    });
};

export const uploadAttachments = async (client: StreamChat, files: File[], channelId: string) => {
  const channel = client.getChannelById(CHANNEL_TYPE_TEAM_DESIGNATION, channelId, {});
  const uploads = files?.map((file) => channel.sendImage(file));
  const results = await Promise.allSettled(uploads);
  const attachments = results.reduce<Attachment[]>((acc, result) => {
    if (result.status === 'fulfilled' && result.value.file) {
      acc.push({
        title: 'Untitled',
        url: result.value.file,
        type: 'image',
      });
    }
    return acc;
  }, []);

  return attachments;
};

export const updateUser = async (client: StreamChat, user: User) => {
  // https://getstream.io/chat/docs/react/update_users/
  // const update = {
  //   id: "userID",
  //   set: {
  //     role: "admin",
  //     field: {
  //       text: "value",
  //     },
  //     "field2.subfield": "test",
  //   },
  //   unset: ["field.unset"],
  // };
  // // response will contain user object with updated users info
  // const response = await client.partialUpdateUser(update);

  return client
    .partialUpdateUser({
      id: user.userID,
      set: {
        name: `${user.firstName} ${user.lastName}`,
        userStatus: {
          statusText: user.status?.status?.text ?? null,
          statusDuration: user.status?.status?.duration ?? null,
          statusExpiration: user.status?.status?.expiry ?? null,
          emoji: user.status?.status?.emoji ?? null,
        },
        weavePresence: user.status?.presence,
      },
    })
    .then((_res) => user);
};

export const createConversation = async (
  client: StreamChat,
  orgId: string,
  conversation: { memberIds: string[] } & ({ name: undefined; type: 'dm' } | { name: string; type: 'group' })
) => {
  if (!client.user?.id) {
    throw new Error('Stream client is not connected.');
  }

  // We generate a new id with the help of this function. Because id is the only difference between the channels.
  // If we let stream generate it then it would be the same for all the channels and we would not be able
  // to differentiate between them.
  const channel = client.channel(
    CHANNEL_TYPE_TEAM_DESIGNATION,
    conversation.type === 'dm' ? null : generateUniqueName(conversation.name || ''),
    {
      members: [client.user.id, ...conversation.memberIds],
      created_by_id: client.user.id,
      team: getTeamId(orgId),
      name: conversation.type === 'group' ? conversation.name : undefined,
      is_dm: conversation.type === 'dm',
    }
  );
  await channel.create({ watch: true, presence: true });
  // according to stream we don't have to watch the channel if we included it in the create call https://getstream.io/chat/docs/javascript/watch_channel/
  // but according to experience, it seems like we have to...?
  await channel.watch();
  return constructConversationFromStreamChannel(channel, client.user.id);
};

export const updateConversation = (
  client: StreamChat,
  conversationId: string,
  updates: Partial<Pick<Conversation, 'description' | 'name' | 'topic' | 'isHidden' | 'isArchived'>>
) => {
  const channel = client.getChannelById(CHANNEL_TYPE_TEAM_DESIGNATION, conversationId, {});
  const set: Parameters<typeof channel.updatePartial>[0]['set'] = {};
  if (updates.description) {
    set['description'] = updates.description;
  }
  if (updates.name) {
    set['name'] = updates.name;
  }
  if (updates.topic) {
    set['topic'] = updates.topic;
  }
  if (updates.isArchived !== undefined) {
    set['frozen'] = updates.isArchived;
  }
  return channel
    .updatePartial({
      set: set,
    })
    .then((res) => {
      if (updates.isHidden !== undefined) {
        updates.isHidden ? channel.hide() : channel.show();
      }
      return res;
    })
    .then((_res) => {
      return constructConversationFromStreamChannel(channel, client.user?.id ?? '');
    });
};

export const sendConversationEvent = async (
  client: StreamChat,
  conversationId: string,
  event: 'clean' | 'read' | 'typing_start' | 'typing_stop'
) => {
  const channel = client.getChannelById(CHANNEL_TYPE_TEAM_DESIGNATION, conversationId, {});
  if (event === 'typing_start') {
    return channel.keystroke();
  }
  if (event === 'typing_stop') {
    return channel.stopTyping();
  }
  if (event === 'clean') {
    return channel.clean();
  }
  if (event === 'read') {
    return channel.markRead();
  }
};

export const deleteConversation = (client: StreamChat, conversationId: string) => {
  const channel = client.getChannelById(CHANNEL_TYPE_TEAM_DESIGNATION, conversationId, {});
  return channel.delete().then((_res) => {
    return constructConversationFromStreamChannel(channel, client.user?.id ?? '');
  });
};

export const getConversationByMemberIds = (client: StreamChat, orgId: string, memberIds: string[]) => {
  if (!client.user?.id) {
    return undefined;
  }
  return client
    .queryChannels(
      {
        type: CHANNEL_TYPE_TEAM_DESIGNATION,
        team: { $in: [getTeamId(orgId)] },
        members: { $eq: [...memberIds, client.user.id] },
      },
      [{ last_message_at: -1 }],
      { watch: false }
    )
    .then((channels) => {
      const channelsFilteredByMembers = channels.filter(
        (channel) => channel.id?.startsWith('!members') || channel.data?.is_dm
      );
      return channelsFilteredByMembers.length && client.user?.id
        ? constructConversationFromStreamChannel(channelsFilteredByMembers[0], client.user.id)
        : undefined;
    });
};

export const addMembersToConversation = async (
  client: StreamChat,
  conversationId: string,
  userIds: string[],
  message?: string
) => {
  const channel = client.getChannelById(CHANNEL_TYPE_TEAM_DESIGNATION, conversationId, {});
  return channel.addMembers(userIds, message ? { text: message } : undefined).then((res) => {
    return res.members.map((member) => member.user_id).filter((id): id is string => !!id);
  });
};

export const removeMembersFromConversation = async (
  client: StreamChat,
  conversationId: string,
  userIds: string[],
  message?: string
) => {
  const channel = client.getChannelById(CHANNEL_TYPE_TEAM_DESIGNATION, conversationId, {});
  return channel.removeMembers(userIds, message ? { text: message } : undefined).then((res) => {
    return res.members.map((member) => member.user_id).filter((id): id is string => !!id);
  });
};
