import { useMemo } from 'react';
import { QueryKey, UseQueryOptions } from 'react-query';
import { LimitedSchemaQueryOptions, SchemaQueryKey } from '@frontend/react-query-helpers';
import type { SchemaIO } from '@frontend/schema';
import {
  ApiOverviewStatsParams,
  DemoServiceQualityChartStatsResponse,
  DemoServiceQualityIO,
  GetOverviewStatsIO,
  ServiceStandardQueries,
} from '../types';
import { generateChartsPayload, getDemoServiceQualityChartStats } from '../utils';
import { DemoData, useAdaptiveQuery } from './adaptive-queries/use-adaptive-query';
import { useCallIntelV2Query } from './use-call-intel-v2-query';

export interface UseGetServiceQualityChartStatsArgs<E = unknown, T = GetOverviewStatsIO['output']> {
  demoQueryOptions?: Omit<UseQueryOptions<T, E, T>, 'queryKey' | 'queryFn'>;
  payload: ApiOverviewStatsParams;
  isDemoAccount: boolean;
  realQueryOptions?: LimitedSchemaQueryOptions<
    SchemaIO<ServiceStandardQueries['OverviewStats']>,
    E,
    T,
    SchemaQueryKey<ServiceStandardQueries, 'OverviewStats'>
  >;
}

export function useGetServiceQualityChartStats<
  E = unknown,
  T = GetOverviewStatsIO['output'] | DemoServiceQualityChartStatsResponse
>({ demoQueryOptions, payload, isDemoAccount, realQueryOptions }: UseGetServiceQualityChartStatsArgs<E, T>) {
  const { filters, includes } = payload;

  const isValidFilters = !!(filters?.startDate && filters?.endDate && filters?.locations?.length);

  const request = useMemo<GetOverviewStatsIO['input']>(() => {
    if (!isValidFilters) return {} as GetOverviewStatsIO['input'];

    return {
      ...generateChartsPayload({ filters }),
      includes: {
        opportunities: includes.opportunities,
        service_quality: includes.serviceQuality,
      },
    } as GetOverviewStatsIO['input'];
    //This is type-casted till we move all the utils to schema-gen compatible
  }, [payload]);

  const demoRequest = getDemoServiceQualityChartStats(payload);

  const { data: rawDemoData } = useCallIntelV2Query({
    endpointName: 'DemoServiceQuality',
    request: () => demoRequest as DemoServiceQualityIO['input'],
    options: {
      enabled: isDemoAccount && isValidFilters,
      select: (data) => data,
    },
  });

  const demoData: DemoData<T> = rawDemoData as T;

  return useAdaptiveQuery<'OverviewStats', E, T>({
    buildDemoQueryKey: ({ endpointName, request }) =>
      ['demo', endpointName, request, 'DemoServiceQuality', demoRequest] as QueryKey,
    demoData,
    demoQueryEnabled: !!demoData,
    demoQueryOptions,
    isDemoAccount,
    realQueryArgs: {
      endpointName: 'OverviewStats',
      request,
      options: realQueryOptions,
    },
    realQueryEnabled: isValidFilters,
  });
}
