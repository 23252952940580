import { useEffect, useState } from 'react';
import { EmailTemplate } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/email-template/v1/emailtemplate.pb';
import { TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import dayjs from 'dayjs';
import { DeviceSize } from '@frontend/device-size-field';
import { EmailPreviewModal, usePreviewModal } from '@frontend/email-preview-modal';
import { EmailThumbnail } from '@frontend/email-thumbnail';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { ListRow, NakedButton, PopoverMenu, Text, usePopoverMenu } from '@frontend/design-system';
import { TemplateActionsMenuOptions } from './types';

const DATE_FORMAT = 'MMMM D, YYYY';
const DATE_FORMAT_WITHOUT_YEAR = 'MMMM D';
const CUSTOM_BORDER_RADIUS = theme.spacing(0.75); // There's a weird gap when the border radius matches the container's border radius

type Props = EmailTemplate & {
  onTemplateClick: () => void;
  onTemplateFetch: () => Promise<EmailTemplate | undefined>;
};

export const DefaultTemplateCard = ({
  createdAt,
  onTemplateClick,
  onTemplateFetch,
  thumbnailUrl,
  title,
  updatedAt,
}: Props) => {
  const { t } = useTranslation('bulk-messaging');

  const currentYear = dayjs().year();
  const createdAtYear = dayjs(createdAt).year();
  const updatedAtYear = dayjs(updatedAt).year();

  const dateText =
    updatedAt === createdAt
      ? t('Created {{date}}', {
          date: dayjs(createdAt).format(currentYear === createdAtYear ? DATE_FORMAT_WITHOUT_YEAR : DATE_FORMAT),
        })
      : t('Updated {{date}}', {
          date: dayjs(updatedAt).format(currentYear === updatedAtYear ? DATE_FORMAT_WITHOUT_YEAR : DATE_FORMAT),
        });
  const {
    close: closePopoverMenu,
    getMenuProps,
    getTriggerProps,
  } = usePopoverMenu({
    placement: 'bottom-end',
    middlewareOptions: { offset: 0 },
  });

  const [previewHtml, setPreviewHtml] = useState<string>();
  const { deviceSizeProps, generatePreview, previewModalProps, transformedText } = usePreviewModal(
    previewHtml,
    TemplateType_Slug.BULK_MESSAGE
  );

  useEffect(() => {
    if (transformedText) {
      generatePreview();
      closePopoverMenu();
    }
  }, [transformedText]);

  useEffect(() => {
    if (!previewModalProps.show) setPreviewHtml(undefined);
  }, [previewModalProps.show]);

  const actions: TemplateActionsMenuOptions[] = [
    {
      Icon: <Icon name='view' />,
      label: t('Preview'),
      onClick: async () => {
        const template = await onTemplateFetch();
        if (!template) return;
        setPreviewHtml(template?.templateHtml);
      },
      trackingId: `${BulkEmailPrefixes.Templates}-default-preview-btn`,
    },
    {
      Icon: <Icon name='collapse-right' />,
      label: t('Continue With This Template'),
      onClick: onTemplateClick,
      trackingId: `${BulkEmailPrefixes.Templates}-default-continue-btn`,
    },
  ];

  return (
    <>
      <div
        css={{
          border: `2px solid ${theme.colors.neutral20}`,
          borderRadius: theme.borderRadius.medium,
          position: 'relative',
          cursor: 'pointer',
          '&:hover': {
            border: `2px solid ${theme.colors.primary50}`,
          },
        }}
      >
        <div
          onClick={onTemplateClick}
          css={{
            background: `linear-gradient(180deg, ${theme.colors.primary5} 0%, ${theme.colors.white} 100%)`,
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            padding: theme.spacing(2),
            borderRadius: CUSTOM_BORDER_RADIUS,
          }}
          data-trackingid={`${BulkEmailPrefixes.Templates}-default-template-card-btn`}
        >
          <EmailThumbnail src={thumbnailUrl} alt={t('Default Template')} />
        </div>
        <div
          css={{
            position: 'absolute',
            bottom: 0,
            background: 'white',
            padding: theme.spacing(2),
            borderTop: `2px solid ${theme.colors.neutral20}`,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            rowGap: theme.spacing(1.5),
            borderBottomLeftRadius: theme.borderRadius.medium,
            borderBottomRightRadius: theme.borderRadius.medium,
            cursor: 'pointer',
          }}
        >
          <Text weight='bold'>{title}</Text>
          <div
            css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            onClick={onTemplateClick}
          >
            <Text color='light' size='small'>
              {dateText}
            </Text>
            <NakedButton {...getTriggerProps()}>
              <Icon name='more' />
            </NakedButton>
          </div>
        </div>
        <PopoverMenu {...getMenuProps()}>
          {actions.map(({ Icon, label, onClick, textColor = 'default', trackingId }, index) => (
            <ListRow
              key={index}
              css={{
                border: 'unset',
                display: 'flex',
                columnGap: theme.spacing(1),
                padding: theme.spacing(1, 2),
                cursor: 'pointer',
              }}
              onClick={onClick}
              trackingId={trackingId}
            >
              {Icon}
              <Text color={textColor}>{label}</Text>
            </ListRow>
          ))}
        </PopoverMenu>
      </div>
      {previewModalProps.show && (
        <EmailPreviewModal
          deviceSizeProps={{ ...deviceSizeProps, value: deviceSizeProps?.value as DeviceSize }}
          modalProps={previewModalProps}
          previewHtml={transformedText}
          setPreviewHtml={setPreviewHtml}
        />
      )}
    </>
  );
};
