import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  PhoneHoursScheduleRule,
  ScheduleRule,
  ScheduleType,
} from '@weave/schema-gen-ts/dist/schemas/phone/callroute/beta/v1/phone_hours.pb';
import { genUUIDV4 } from '@frontend/string';
import { useForm, FieldConfigs } from '@frontend/design-system';
import { BreakRules } from './phone-hours-form';

export type DaysOfWeek = (typeof DAYS_OF_WEEK)[number];
export const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'] as const;

type DayNames = (typeof DAYS_OF_WEEK)[number];

type PhoneHoursScheduleFields = {
  [key in `selected-${DayNames}`]: FieldConfigs['checkbox'];
} & {
  [key in `range-${DayNames}`]: FieldConfigs['timeRange'];
};

const createTimeFields = (days: readonly DayNames[], scheduleRules: ScheduleRule[]): PhoneHoursScheduleFields => {
  const fields = {} as PhoneHoursScheduleFields;

  days.forEach((day, i) => {
    const range = ['9:00', '17:00'];
    const rule = scheduleRules.find((rule) => rule.dayOfWeek === i);
    if (rule?.startTime) {
      range[0] = rule.startTime;
    }

    if (rule?.endTime) {
      range[1] = rule.endTime;
    }

    fields[`selected-${day}`] = { type: 'checkbox', value: !!rule };
    fields[`range-${day}`] = {
      type: 'timeRange',
      interval: 60,
      value: range,
      required: true,
    };
  });

  return fields;
};

export const usePhoneHoursForm = (phoneHours: PhoneHoursScheduleRule[]) => {
  const scheduleRules = useMemo(() => phoneHours.find((hours) => hours.type === 'OPEN')?.rules ?? [], [phoneHours]);
  const existingBreaks = useMemo(() => phoneHours.filter((item) => item.type === 'BREAK'), [phoneHours]);

  const openSchedulesForm = useForm({
    fields: {
      ...createTimeFields(DAYS_OF_WEEK, scheduleRules),
    },
  });

  const [breakSchedules, setBreakSchedules] = useState<BreakRules>({});
  const [availableBreakNames, setAvailableBreakNames] = useState<Record<string, string>>({});

  useEffect(() => {
    if (!existingBreaks.length) {
      return;
    }

    const breaksMap: BreakRules = {};
    const uniqueBreakNames: Record<string, string> = {};

    existingBreaks.forEach((breakItem) => {
      const breakName = breakItem.name;
      const id = breakItem.scheduleId || genUUIDV4();
      breakItem?.rules?.forEach((rule) => {
        const day = rule.dayOfWeek;
        const dayName = DAYS_OF_WEEK[day];
        if (!breaksMap[dayName]) {
          breaksMap[dayName] = {};
        }

        breaksMap[dayName][id] = { startTime: rule.startTime, endTime: rule.endTime, breakName, id };
        uniqueBreakNames[id] = breakName;
      });
    });

    setBreakSchedules(breaksMap);
    setAvailableBreakNames(uniqueBreakNames);
  }, [existingBreaks]);

  const handleBreakChange = useCallback(
    ({
      day,
      breakName,
      startTime,
      endTime,
      id,
      isNew = false,
    }: {
      day: DaysOfWeek;
      breakName: string;
      startTime: string;
      endTime: string;
      previousName?: string;
      id: string;
      isNew?: boolean;
    }) => {
      setBreakSchedules((prev) => {
        const updatedBreaks = { ...prev };
        if (!updatedBreaks[day]) {
          updatedBreaks[day] = {};
        }

        const existingBreak = updatedBreaks[day][id];
        if (existingBreak) {
          existingBreak.startTime = startTime;
          existingBreak.endTime = endTime;
          existingBreak.breakName = breakName;
        } else {
          updatedBreaks[day][id] = { startTime, endTime, breakName, id };
        }
        return updatedBreaks;
      });

      if (isNew) {
        setAvailableBreakNames((prev) => {
          const updatedNames = { ...prev };
          if (!updatedNames[id]) {
            updatedNames[id] = breakName;
          }
          return updatedNames;
        });
      }
    },
    []
  );

  const handleAddBreak = useCallback((day: DaysOfWeek) => {
    setBreakSchedules((prev) => {
      const updatedBreaks = { ...prev };
      if (!updatedBreaks[day]) {
        updatedBreaks[day] = {};
      }
      const id = genUUIDV4();
      updatedBreaks[day][id] = { startTime: '12:00', endTime: '13:00', breakName: '', id };
      return updatedBreaks;
    });
  }, []);

  const handleRemoveBreak = useCallback(({ day, id }: { day: DaysOfWeek; id: string }) => {
    setBreakSchedules((prev) => {
      const updatedBreaks = { ...prev };
      if (updatedBreaks[day]) {
        delete updatedBreaks[day][id];
      }
      return updatedBreaks;
    });
  }, []);

  const handleEditBreak = useCallback((newName: string, oldName: string) => {
    setBreakSchedules((prev) => {
      const updatedBreaks = { ...prev };
      Object.entries(updatedBreaks).forEach(([day, dayObj]) => {
        Object.entries(dayObj).forEach(([breakId, breakObj]) => {
          if (breakObj.breakName === oldName) {
            const breakToUpdate = updatedBreaks[day as DaysOfWeek]?.[breakId];
            if (breakToUpdate) {
              breakToUpdate.breakName = newName;
            }
          }
        });
      });
      return updatedBreaks;
    });

    setAvailableBreakNames((prev) => {
      const updatedNames = { ...prev };
      Object.entries(updatedNames).forEach(([id, name]) => {
        if (name === oldName) {
          updatedNames[id] = newName;
        }
      });
      return updatedNames;
    });
  }, []);

  const handleDeleteBreak = useCallback((name: string) => {
    setBreakSchedules((prev) => {
      const updatedBreaks = { ...prev };
      Object.entries(updatedBreaks).forEach(([day, dayObj]) => {
        Object.entries(dayObj).forEach(([breakId, breakObj]) => {
          if (breakObj.breakName === name) {
            delete updatedBreaks?.[day as DaysOfWeek]?.[breakId];
          }
        });
      });
      return updatedBreaks;
    });
    setAvailableBreakNames((prev) => {
      const updatedNames = { ...prev };
      Object.entries(updatedNames).forEach(([id, nameValue]) => {
        if (nameValue === name) {
          delete updatedNames[id];
        }
      });
      return updatedNames;
    });
  }, []);

  const getPhoneHours = useCallback((): PhoneHoursScheduleRule[] => {
    // fisrt build the open schedules
    const openScheduleId = phoneHours.find((item) => item.type === 'OPEN')?.scheduleId ?? '';
    const openSchedules: PhoneHoursScheduleRule[] = [
      {
        type: ScheduleType.OPEN,
        name: 'Open',
        scheduleId: openScheduleId,
        rules: Object.entries(openSchedulesForm.values).reduce((acc, [key, value]) => {
          if (key.startsWith('selected-') && value) {
            const day = key.split('-')[1] as DaysOfWeek;
            const rangeKey = `range-${day}` as `range-${DaysOfWeek}`;
            const [startTime, endTime] = openSchedulesForm.values[rangeKey] ?? ['', ''];
            acc.push({ dayOfWeek: DAYS_OF_WEEK.indexOf(day), startTime, endTime });
          }
          return acc;
        }, [] as ScheduleRule[]),
      },
    ];

    // then build the break schedules
    const breakSchedulesArray = Object.entries(breakSchedules).reduce((acc, [day, dayObj]) => {
      Object.entries(dayObj).forEach(([id, breakObj]) => {
        const { startTime, endTime, breakName } = breakObj;
        const existingBreak = acc.find((item) => item.name === breakName);
        if (existingBreak) {
          if (!existingBreak.rules) {
            existingBreak.rules = [];
          }
          existingBreak.rules.push({
            dayOfWeek: DAYS_OF_WEEK.indexOf(day as DaysOfWeek),
            startTime,
            endTime,
          });
        }
        if (!existingBreak) {
          acc.push({
            type: ScheduleType.BREAK,
            name: breakName,
            // This ID should be the same as the one in the BE if it is already created, else
            // it should be empty to denote a new break.
            scheduleId: phoneHours.find((item) => item.scheduleId === id)?.scheduleId ?? '',
            rules: [
              {
                dayOfWeek: DAYS_OF_WEEK.indexOf(day as DaysOfWeek),
                startTime,
                endTime,
              },
            ],
          });
        }
      });
      return acc;
    }, [] as PhoneHoursScheduleRule[]);

    // check if there are any additional breaks (in availableBreakNames) that are not in the break schedules, if so, add them
    // with empty rules
    const emptyBreaks = Object.entries(availableBreakNames).reduce((acc, [id, name]) => {
      const existingBreak = breakSchedulesArray.find((item) => item.name === name);
      if (!existingBreak) {
        acc.push({
          type: ScheduleType.BREAK,
          name,
          // This ID should be the same as the one in the BE if it is already created, else
          // it should be empty to denote a new break.
          scheduleId: phoneHours.find((item) => item.scheduleId === id)?.scheduleId ?? '',
          rules: [],
        });
      }
      return acc;
    }, [] as PhoneHoursScheduleRule[]);

    // combine both schedules
    const combinedSchedules = [...openSchedules, ...breakSchedulesArray, ...emptyBreaks];

    return combinedSchedules;
  }, [phoneHours, openSchedulesForm.values, breakSchedules, availableBreakNames]);

  return {
    getPhoneHours,
    formProps: {
      openSchedulesForm,
      breakSchedules,
      availableBreakNames,
      onBreakChange: handleBreakChange,
      onBreakRemove: handleRemoveBreak,
      onBreakAdd: handleAddBreak,
      onEditBreak: handleEditBreak,
      onDeleteBreak: handleDeleteBreak,
    },
  };
};
