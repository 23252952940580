/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useRef } from 'react';
import { NotificationType } from '@weave/schema-gen-ts/dist/shared/notification/notifications.pb';
import { TeamChatSubscriptions } from '@frontend/api-team-chat';
import { useChatNotification, useNotificationSettingsStore } from '@frontend/notifications';
import { useWebsocketEventSubscription, type GetWebsocketEventHandler } from '@frontend/websocket';
import { getUserFullName } from '../../../utils';
import { useTeamChatSelector } from '../team-chat.provider';

type Props = { orgId: string; clientUserId: string };
export const _useWeaveWebsocketNotifications = ({ orgId, clientUserId }: Props) => {
  const { notificationSettings: globalNotificationSettings } = useNotificationSettingsStore();

  const openStaticConversation = useTeamChatSelector((ctx) => ctx.openStaticConversation);
  const openTeamChat = useTeamChatSelector((ctx) => ctx.openTeamChat);
  const conversations = useTeamChatSelector((ctx) => ctx.conversations);
  const helpers = useTeamChatSelector((ctx) => ctx.helpers);
  const isReady = useTeamChatSelector((ctx) => ctx.isReady);
  const isOpen = useTeamChatSelector((ctx) => ctx.isOpen);
  const activeConversationId = useTeamChatSelector((ctx) => ctx.activeConversationId);
  const cache = useTeamChatSelector((ctx) => ctx.cache);
  const isFocused = useTeamChatSelector((ctx) => ctx.isFocused);
  const isAsleep = useTeamChatSelector((ctx) => ctx.isAsleep);
  const notificationSettings = useTeamChatSelector((ctx) => ctx.notificationSettings);
  const refetchConversations = useTeamChatSelector((ctx) => ctx.refetchConversations);

  //Keep track of helpers and cache as refs, so we don't have to keep resub/unsubscribing every update
  const refs = {
    conversations: useStableRef(conversations),
    refetchConversations: useStableRef(refetchConversations),
    helpers: useStableRef(helpers),
    cache: useStableRef(cache),
    activeConversationId: useStableRef(activeConversationId),
    isOpen: useStableRef(isOpen),
    isReady: useStableRef(isReady),
    isFocused: useStableRef(isFocused),
    isAsleep: useStableRef(isAsleep),
    shouldShowWhenCurrentConversation: useStableRef(notificationSettings?.showOnActiveConversation),
    shouldShowWhenOpen: useStableRef(notificationSettings?.showWhenOpen),
  };

  const { create, remove } = useChatNotification({
    onView: useCallback(
      async (notification) => {
        const channelId = notification.payload.channelId;
        const conversation = refs.helpers.current.getConversation(channelId);
        // this handles the case where we get a weave websocket message for a conversation that we don't have in the cache
        if (!conversation) {
          const res = await refetchConversations();
          const conversations = [...(res.data?.dm ?? []), ...(res.data?.groups ?? [])];
          const newConversation = conversations.find((c) => c.channelId === channelId);
          if (!newConversation) {
            remove(notification.id);
            return;
          } else {
            openStaticConversation(newConversation.channelId);
            remove(notification.id);
            return;
          }
        }

        openStaticConversation(conversation.channelId);
        remove(notification.id);
      },
      [openTeamChat, openStaticConversation]
    ),
  });

  const showNotification = useStableRef(create);

  // Weave's WS subscription for chat messages
  const handler: GetWebsocketEventHandler<NotificationType.TEAM_CHAT> = useCallback(
    TeamChatSubscriptions.getWeaveWebsocketHandler((payload) => {
      const { message, channelId, user } = payload;
      const isCurrentUser = user?.userID === clientUserId;
      const isComingFromSameOrganization = user?.userID.includes(orgId);
      const isComingFromCurrentConversation = refs.activeConversationId.current === channelId;

      const isValid = !!(
        channelId &&
        clientUserId &&
        isComingFromSameOrganization &&
        !isCurrentUser &&
        refs.isReady.current
      );

      /**
       * The cache updates from our dedicated stream event handling in the subscription managers when the chat is not asleep
       * That is why we only update the cache here when the chat is asleep
       */
      const shouldUpdateCache = isValid && refs.isAsleep.current;

      /**
       * We need to respect the notification settings for when to show the notification
       */
      const shouldShowNotification =
        isValid &&
        (isComingFromCurrentConversation
          ? refs.shouldShowWhenOpen.current && refs.shouldShowWhenCurrentConversation.current
          : !refs.isAsleep.current
          ? refs.shouldShowWhenOpen.current
          : true);

      // TODO: can the backend add message.type to the weave payload?
      // to handle replies, we would also need parentMessageId
      // const isRegularMessage = message.type !== 'system';
      // const isReply = message.type === 'reply';
      if (shouldUpdateCache) {
        refs.cache.current.invalidateMessagesCache(channelId);
        refs.cache.current.updateConversation(channelId, (conversation) => {
          return {
            ...conversation,
            lastMessage: message,
            unreadCount: conversation.unreadCount ? conversation.unreadCount + 1 : 1,
          };
        });
      }
      // refresh conversation query
      // Updated the unread count when the chat is closed
      const partialUserId = clientUserId.split('_').at(-1);
      if (shouldShowNotification) {
        showNotification.current({
          id: message?.id ?? '',
          payload: {
            message: message?.text ?? '',
            authorName: getUserFullName(user.firstName, user.lastName),
            channelId: channelId ?? '',
            isMention: message?.mentionedUserIds?.includes(partialUserId ?? '') ?? false,
            reaction: payload?.reaction?.type ?? null,
            attachments: message?.attachments?.map((attachment) => {
              return { type: attachment.type };
            }),
          },
          timestamp: Date.now(),
          type: 'chat-message-new',
          state: {
            paused: false,
            timeout: globalNotificationSettings.durationMs,
            status: 'unread',
          },
        });
      }
    }),
    [clientUserId, orgId]
  );

  useWebsocketEventSubscription(NotificationType.TEAM_CHAT, handler);
};

const useStableRef = <T extends any>(param: T) => {
  const ref = useRef<T>(param);
  ref.current = param;
  return ref;
};
