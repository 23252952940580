import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, Modal, TextField, useFormField } from '@frontend/design-system';

export const EditBreakModal = ({
  name,
  canDelete,
  onCancel,
  onDone,
  onDelete,
}: {
  name: string;
  canDelete: boolean;
  onCancel: () => void;
  onDone: (newName: string) => void;
  onDelete: () => void;
}) => {
  const { t } = useTranslation('phone');

  const fieldProps = useFormField({
    type: 'text',
    value: name,
    required: true,
  });

  return (
    <>
      <Modal.Header onClose={onCancel}>{t('Edit Break')}</Modal.Header>
      <Modal.Label>{name}</Modal.Label>
      <Modal.Body
        css={css`
          margin-top: ${theme.spacing(2)};
        `}
      >
        <TextField {...fieldProps} name='breakName' label='Break name' />
      </Modal.Body>

      <Modal.Footer
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <Button iconName='trash' variant='tertiary' destructive disabled={!canDelete} onClick={onDelete}>
          {t('Delete Break')}
        </Button>
        <div
          css={css`
            display: flex;
            gap: ${theme.spacing(2)};
          `}
        >
          <Button variant='secondary' onClick={onCancel}>
            {t('Cancel')}
          </Button>
          <Button
            onClick={() => {
              onDone(fieldProps.value);
            }}
            disabled={!fieldProps.value}
          >
            {t('Done')}
          </Button>
        </div>
      </Modal.Footer>
    </>
  );
};
