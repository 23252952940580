import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const modalStyle = css`
  max-width: 1000px;
  height: 80vh;

  @media only screen and (min-width: 448px) {
    width: 100%;
  }
`;

export const containerStyle = css`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${theme.spacing(2)};
  height: 100%;
  overflow: hidden;
`;
