import {
  EmailTemplate,
  GetEmailTemplateRequest,
  GetEmailTemplateResponse,
  GetSystemEmailTemplateRequest,
  ListEmailTemplatesRequest,
  ListSystemTemplatesRequest,
} from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/email-template/v1/emailtemplate.pb';
import { useInfiniteQuery, useQuery, UseQueryOptions } from 'react-query';
import { SchemaEmailTemplate } from './services';

const DEFAULT_PAGE_SIZE = 20;

export const keys = {
  base: ['email-template'],
  getEmailTemplate: (req: GetEmailTemplateRequest) => [...keys.base, 'get-email-template', req],
  getSystemEmailTemplate: (req: GetSystemEmailTemplateRequest) => [...keys.base, 'get-system-email-template', req],
  listEmailTemplatesBase: () => [...keys.base, 'list-email-template'],
  listEmailTemplates: (req: ListEmailTemplatesRequest) => [...keys.listEmailTemplatesBase(), req],
  listSystemTemplates: (req: ListSystemTemplatesRequest) => [...keys.base, 'list-system-templates', req],
};

export const getEmailTemplate = (request: GetEmailTemplateRequest) => SchemaEmailTemplate.GetEmailTemplate(request);

export const useGetEmailTemplate = (
  request: GetEmailTemplateRequest,
  options: Omit<
    UseQueryOptions<GetEmailTemplateResponse, unknown, EmailTemplate | undefined, (string | GetEmailTemplateRequest)[]>,
    'queryKey' | 'queryFn' | 'select'
  > = {}
) =>
  useQuery({
    queryKey: keys.getEmailTemplate(request),
    queryFn: () => getEmailTemplate(request),
    select: (data) => data?.template,
    ...options,
  });

export const getSystemEmailTemplate = (request: GetSystemEmailTemplateRequest) =>
  SchemaEmailTemplate.GetSystemEmailTemplate(request);

export const useGetSystemEmailTemplate = (
  request: GetSystemEmailTemplateRequest,
  options: Omit<
    UseQueryOptions<
      GetEmailTemplateResponse,
      unknown,
      EmailTemplate | undefined,
      (string | GetSystemEmailTemplateRequest)[]
    >,
    'queryKey' | 'queryFn' | 'select'
  > = {}
) =>
  useQuery({
    queryKey: keys.getSystemEmailTemplate(request),
    queryFn: () => getSystemEmailTemplate(request),
    select: (data) => data?.template,
    ...options,
  });

export const useListEmailTemplates = (request: ListEmailTemplatesRequest) =>
  useInfiniteQuery({
    queryKey: keys.listEmailTemplates(request),
    queryFn: () => SchemaEmailTemplate.ListEmailTemplates(request),
    getNextPageParam: (lastPage) => {
      if (lastPage?.templateCount && lastPage.templateCount < (request?.pageSize ?? DEFAULT_PAGE_SIZE)) {
        return undefined;
      }
      return lastPage?.nextPageToken;
    },
  });

export const useListSystemTemplates = (request: ListSystemTemplatesRequest) =>
  useInfiniteQuery({
    queryKey: keys.listSystemTemplates(request),
    queryFn: () => SchemaEmailTemplate.ListSystemTemplates(request),
    getNextPageParam: (lastPage) => {
      if (lastPage?.templateCount && lastPage.templateCount < (request?.pageSize ?? DEFAULT_PAGE_SIZE)) {
        return undefined;
      }
      return lastPage?.nextPageToken;
    },
  });
