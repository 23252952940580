import dayjs from 'dayjs';
import { i18next } from '@frontend/i18n';
import { CustomDateRange, defaultTimePeriods, findExistingTimePeriodKey } from '../../../hooks';
import { formatters } from '../../../utils';

type DateRangeText = {
  endDate?: string;
  forDescription?: boolean;
  startDate?: string;
  timePeriods?: CustomDateRange;
};

export const getDateRangeText = ({
  endDate,
  forDescription,
  startDate,
  timePeriods = defaultTimePeriods,
}: DateRangeText) => {
  if (!startDate || !endDate) {
    return '';
  }

  const timePeriodKey = findExistingTimePeriodKey<typeof timePeriods>(startDate, endDate, timePeriods);

  if (timePeriodKey) {
    switch (timePeriodKey) {
      case 'today':
        return forDescription ? i18next.t('Today', { ns: 'analytics' }) : i18next.t('today', { ns: 'analytics' });

      case 'yesterday':
        return forDescription
          ? i18next.t('Yesterday', { ns: 'analytics' })
          : i18next.t('yesterday', { ns: 'analytics' });

      case 'thisWeek':
        return forDescription
          ? i18next.t('In this week', { ns: 'analytics' })
          : i18next.t('in this week', { ns: 'analytics' });

      case 'lastWeek':
        return forDescription
          ? i18next.t('In the last week', { ns: 'analytics' })
          : i18next.t('in the last week', { ns: 'analytics' });

      case 'thisMonth':
        return forDescription
          ? i18next.t('In this month', { ns: 'analytics' })
          : i18next.t('in this month', { ns: 'analytics' });

      case 'lastMonth':
        return forDescription
          ? i18next.t('In the last month', { ns: 'analytics' })
          : i18next.t('in the last month', { ns: 'analytics' });

      case 'last7Days':
        return forDescription
          ? i18next.t('In the last 7 days', { ns: 'analytics' })
          : i18next.t('in the last 7 days', { ns: 'analytics' });

      case 'last30Days':
        return forDescription
          ? i18next.t('In the last 30 days', { ns: 'analytics' })
          : i18next.t('in the last 30 days', { ns: 'analytics' });

      case 'thisQuarter':
        return forDescription
          ? i18next.t('In this quarter', { ns: 'analytics' })
          : i18next.t('in this quarter', { ns: 'analytics' });

      case 'lastQuarter':
        return forDescription
          ? i18next.t('In the last quarter', { ns: 'analytics' })
          : i18next.t('in the last quarter', { ns: 'analytics' });
    }
  }

  const isSameYear = dayjs(startDate).isSame(dayjs(endDate), 'year');

  if (dayjs(startDate).isSame(dayjs(endDate), 'day')) {
    return forDescription
      ? i18next.t('On {{date}}', { date: formatters.date.format(startDate, isSameYear) })
      : i18next.t('on {{date}}', { date: formatters.date.format(startDate, isSameYear) });
  }

  return forDescription
    ? i18next.t('During the period from {{startDate}} to {{endDate}}', {
        startDate: formatters.date.format(startDate, isSameYear),
        endDate: formatters.date.format(endDate, isSameYear),
      })
    : i18next.t('during {{startDate}} to {{endDate}}', {
        startDate: formatters.date.format(startDate, isSameYear),
        endDate: formatters.date.format(endDate, isSameYear),
      });
};
