import React from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  Text,
  CheckboxField,
  TimeRangeField,
  DropdownField,
  useControlledField,
  Button,
  PopoverMenu,
  PopoverMenuItem,
  usePopoverMenu,
  useModalControl,
  Modal,
} from '@frontend/design-system';
import { EditBreakModal } from './edit-break-modal';
import { NewBreakModal } from './new-break-modal';
import SunIcon from './sun-icon';
import { DAYS_OF_WEEK, DaysOfWeek, usePhoneHoursForm } from './use-phone-hours-form';

export type BreakRules = Partial<
  Record<DaysOfWeek, Record<string, { startTime: string; endTime: string; breakName: string; id: string }>>
>;

interface PhoneHoursFormProps {
  openSchedulesForm: ReturnType<typeof usePhoneHoursForm>['formProps']['openSchedulesForm'];
  breakSchedules: BreakRules;
  routingActionsEnabled?: boolean;
  availableBreakNames: Record<string, string>;
  onBreakChange: (props: {
    day: DaysOfWeek;
    breakName: string;
    startTime: string;
    endTime: string;
    id: string;
    isNew?: boolean;
  }) => void;
  onBreakRemove: (props: { day: DaysOfWeek; id: string }) => void;
  onBreakAdd: (day: DaysOfWeek) => void;
  onEditBreak: (newName: string, oldName: string) => void;
  onDeleteBreak: (name: string) => void;
}

export const PhoneHoursForm: React.FC<PhoneHoursFormProps> = ({
  openSchedulesForm,
  breakSchedules,
  routingActionsEnabled = false,
  availableBreakNames,
  onBreakChange,
  onBreakRemove,
  onBreakAdd,
  onEditBreak,
  onDeleteBreak,
}) => {
  const { t } = useTranslation('phone');
  const labels = [t('Day'), t('Start Time'), t('End Time'), t('Break')];

  const scheduleValues = openSchedulesForm.values;
  const scheduleGetFieldProps = openSchedulesForm.getFieldProps;

  return (
    <div style={{ display: 'grid', gap: theme.spacing(2) }}>
      {/* Header Row */}
      <div
        style={{
          padding: theme.spacing(2),
          display: 'grid',
          gridTemplateColumns: `${theme.spacing(2.5)} ${theme.spacing(27)} ${theme.spacing(20)} ${theme.spacing(
            20
          )} ${theme.spacing(8.5)}`,
          alignItems: 'center',
          borderBottom: `1px solid ${theme.colors.neutral20}`,
        }}
      >
        <div></div>
        {labels.map((label) => (
          <Text key={label} size='medium' color='subdued'>
            {label}
          </Text>
        ))}
      </div>

      {/* Days of the Week Rows */}
      {DAYS_OF_WEEK.map((day) => (
        <>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: `${theme.spacing(4.5)} ${theme.spacing(27)} ${theme.spacing(40.5)} ${theme.spacing(
                8.5
              )}`,
              alignItems: 'center',
            }}
            key={day}
          >
            {/* Checkbox */}
            <div>
              <CheckboxField {...scheduleGetFieldProps(`selected-${day}`)} label='' hideErrorText />
            </div>

            {/* Icon and Label */}
            <div
              style={{
                display: 'flex',
                gap: theme.spacing(1),
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  backgroundColor: !!scheduleValues[`selected-${day}`] ? theme.colors.warning5 : theme.colors.neutral10,
                  width: theme.spacing(4),
                  height: theme.spacing(3),
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '4px',
                }}
              >
                {!!scheduleValues[`selected-${day}`] ? <SunIcon /> : <Icon name='moon-small' />}
              </div>
              <Text>{day}</Text>
            </div>

            {/* TimeRangeField */}
            <TimeRangeField
              {...scheduleGetFieldProps(`range-${day}`)}
              label={day}
              interval={15}
              disabled={!scheduleValues[`selected-${day}`]}
              hideErrorText
              joiningText='to'
            />

            {/* Add Break Icon */}
            <Button
              iconName='add-small'
              variant='secondary'
              disabled={!Object.values(availableBreakNames).length && !routingActionsEnabled}
              css={css`
                width: 40px;
              `}
              onClick={() => {
                onBreakAdd(day);
              }}
            />
          </div>

          {/* Break Rows */}
          {Object.values(breakSchedules[day] ?? [])?.map((breakItem, index) => (
            <div
              key={`${day}-${index}`}
              css={css`
                display: grid;
                grid-template-columns: ${theme.spacing(4.5)} ${theme.spacing(27)} ${theme.spacing(40.5)} ${theme.spacing(
                    8.5
                  )};
                align-items: center;
                background-color: ${theme.colors.neutral5};
                padding: 10px 0;
              `}
            >
              <BreakRow
                name={breakItem.breakName}
                timeRange={[breakItem.startTime, breakItem.endTime]}
                breakNameOptions={Object.values(availableBreakNames)}
                routingActionsEnabled={routingActionsEnabled}
                day={day}
                id={breakItem.id}
                onBreakChange={onBreakChange}
                onBreakRemove={onBreakRemove}
                onEditBreak={onEditBreak}
                onDeleteBreak={onDeleteBreak}
              />
            </div>
          ))}
        </>
      ))}
    </div>
  );
};

const BreakRow = ({
  name,
  breakNameOptions,
  timeRange,
  day,
  id,
  routingActionsEnabled,
  onBreakChange,
  onBreakRemove,
  onEditBreak,
  onDeleteBreak,
}: {
  name: string;
  timeRange: [string, string];
  breakNameOptions: string[];
  day: DaysOfWeek;
  id: string;
  routingActionsEnabled: boolean;
  onBreakChange: (props: {
    day: DaysOfWeek;
    breakName: string;
    startTime: string;
    endTime: string;
    id: string;
    isNew?: boolean;
  }) => void;
  onBreakRemove: (props: { day: DaysOfWeek; id: string }) => void;
  onEditBreak: (newName: string, oldName: string) => void;
  onDeleteBreak: (name: string) => void;
}) => {
  const { t } = useTranslation('phone');

  const {
    modalProps: editBreakModalProps,
    closeModal: editBreakCloseModal,
    openModal: editBreakOpenModal,
  } = useModalControl();
  const {
    modalProps: newBreakModalProps,
    closeModal: newBreakCloseModal,
    openModal: newBreakOpenModal,
  } = useModalControl();

  const nameField = useControlledField({
    type: 'dropdown',
    required: true,
    onChange: (value: string) => {
      if (value === '') {
        return;
      }
      onBreakChange({ day, breakName: value, startTime: timeRange[0], endTime: timeRange[1], id });
    },
    value: name,
  });

  const timeRangeField = useControlledField({
    type: 'timeRange',
    required: true,
    interval: 60,
    onChange: (value: [string, string]) => {
      onBreakChange({ day, breakName: name, startTime: value[0], endTime: value[1], id });
    },
    value: timeRange,
  });

  const { getMenuProps, getTriggerProps, close: closePopupMenu } = usePopoverMenu();

  return (
    <>
      <div />
      <DropdownField
        {...nameField}
        name='breakName'
        label='Select Break'
        css={css`
          width: 200px;
          background-color: ${theme.colors.white};
        `}
      >
        <DropdownField.Option
          css={css`
            padding: 0;
          `}
          key={'new'}
          disabled={!routingActionsEnabled}
          value={''}
          isSelectable={false}
        >
          <Button
            iconName='plus'
            variant='tertiary'
            disabled={!routingActionsEnabled}
            onClick={() => newBreakOpenModal()}
            css={css`
              width: 100%;
              justify-content: start;
            `}
          >
            {t('New Break')}
          </Button>
        </DropdownField.Option>
        {breakNameOptions.map((breakName) => (
          <DropdownField.Option key={breakName} value={breakName}>
            {breakName}
          </DropdownField.Option>
        ))}
      </DropdownField>

      <TimeRangeField
        {...timeRangeField}
        name='breakTimeRange'
        label='Break Time Range'
        interval={15}
        hideErrorText
        joiningText='to'
        css={css`
          input {
            background-color: ${theme.colors.white} !important;
          }
        `}
      />

      {!name ? (
        <Button
          iconName='remove-small'
          variant='secondary'
          destructive
          css={css`
            width: 40px;
          `}
          onClick={() => {
            onBreakRemove({ day, id });
          }}
        />
      ) : (
        <>
          <Button
            iconName='more-small'
            variant='secondary'
            {...getTriggerProps()}
            css={css`
              width: 40px;
            `}
          />

          <PopoverMenu {...getMenuProps()}>
            <PopoverMenuItem
              css={css`
                cursor: pointer;
              `}
              Icon={() => <Icon name='edit-small' />}
              onClick={() => {
                editBreakOpenModal();
                closePopupMenu();
              }}
            >
              <Text as='span'> {t('Edit Break')}</Text>
            </PopoverMenuItem>
            <PopoverMenuItem
              css={css`
                cursor: pointer;
              `}
              Icon={() => <Icon name='remove-small' />}
              onClick={() => onBreakRemove({ day, id })}
            >
              <Text as='span'> {t('Remove Break')}</Text>
            </PopoverMenuItem>
          </PopoverMenu>
        </>
      )}

      <Modal {...editBreakModalProps} minWidth={400}>
        <EditBreakModal
          name={name}
          canDelete={routingActionsEnabled}
          onCancel={editBreakCloseModal}
          onDone={(newName) => {
            editBreakCloseModal();
            if (newName !== name) {
              onEditBreak(newName, name);
            }
          }}
          onDelete={() => {
            editBreakCloseModal();
            onDeleteBreak(name);
          }}
        />
      </Modal>

      <Modal {...newBreakModalProps} minWidth={400}>
        <NewBreakModal
          onCancel={newBreakCloseModal}
          currentBreakNames={breakNameOptions}
          onDone={(newName) => {
            onBreakChange({ day, breakName: newName, startTime: timeRange[0], endTime: timeRange[1], id, isNew: true });
            newBreakCloseModal();
          }}
        />
      </Modal>
    </>
  );
};
