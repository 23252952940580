import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

const defaultOptionStyle = css`
  box-shadow: ${theme.shadows.light};
  border-radius: ${theme.borderRadius.small};
  margin: ${theme.spacing(0.1, 0.5, 1)};

  .inner-container {
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
    padding: ${theme.spacing(1)};

    .drag-icon {
      flex-shrink: 0;
    }
  }
`;

interface GetOptionStyleConfig {
  isDragging?: boolean;
}

export function getOptionStyle(config: GetOptionStyleConfig = {}) {
  const { isDragging = false } = config;
  if (isDragging) {
    return [
      defaultOptionStyle,
      css`
        box-shadow: none;
        margin: 0;
        z-index: ${theme.zIndex.highest};

        .inner-container {
          background-color: ${theme.colors.neutral5};
          transform: rotate(5deg);
          box-shadow: ${theme.shadows.light};
          border-radius: ${theme.borderRadius.small};
        }
      `,
    ];
  }

  return defaultOptionStyle;
}
