import { CSSProperties } from 'react';
import { css } from '@emotion/react';
import { AnalyticsCommonTypes } from '@frontend/api-analytics';
import { AdaptoActions } from '@frontend/adapto-actions';
import { Actions as ContactActions } from '@frontend/contact-actions';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useCallTakeawayPanelShallowStore } from './call-intelligence/hooks';
import { UserCard } from '.';

type Props = AnalyticsCommonTypes.UserCard & {
  showContactActions?: boolean;
  trackingIdBase?: string;
  withBorder?: boolean;
  wrapperStyles?: CSSProperties;
};

export const ContactActionCard = ({
  showContactActions = false,
  trackingIdBase = 'contact-action',
  withBorder,
  wrapperStyles,
  ...rest
}: Props) => {
  const { t } = useTranslation('analytics');

  const { selectedCall: call } = useCallTakeawayPanelShallowStore('selectedCall');

  return (
    <div className={withBorder ? 'with-border' : ''} css={styles.wrapper} style={wrapperStyles}>
      <UserCard {...rest} key={rest.userId} style={{ width: '100%' }} />

      {showContactActions && (
        <AdaptoActions enableCloseEvent iconColor='default' variant='action-buttons' css={styles.actionsWrapper}>
          <ContactActions.Call
            context={{
              personId: call?.person?.id,
              phoneNumber: call?.phoneNumber?.nationalNumber,
            }}
            label={t('Call')}
            trackingIdSuffix={`${trackingIdBase}-call`}
          />
          <ContactActions.Message
            context={{
              personId: call?.person?.id,
              personPhone: call?.phoneNumber?.nationalNumber,
            }}
            label={t('Message')}
            trackingIdSuffix={`${trackingIdBase}-message`}
          />
        </AdaptoActions>
      )}
    </div>
  );
};

const styles = {
  wrapper: css`
    display: flex;
    justify-content: center;
    max-width: 100%;
    min-height: ${theme.spacing(8)};

    &.with-border {
      border-top: 1px solid ${theme.colors.neutral10};
      border-bottom: 1px solid ${theme.colors.neutral10};
    }
  `,
  actionsWrapper: css`
    display: flex;
    gap: ${theme.spacing(2)};
    align-items: center;

    button {
      border: none !important;
      background-color: inherit !important;
      width: ${theme.spacing(4)};
      height: ${theme.spacing(4)};
      min-width: ${theme.spacing(4)};
      min-height: ${theme.spacing(4)};
      svg {
        width: ${theme.spacing(3)};
        height: ${theme.spacing(3)};
      }
    }

    button:hover {
      color: inherit !important;
    }
  `,
};
