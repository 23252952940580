import { useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { InsuranceDetailQueries } from '@frontend/api-insurance-detail';
import { formatDate, getTodaysDate } from '@frontend/date';
import { SchemaInsuranceVerificationAPIService } from '@frontend/schema';
import { FormFieldActionTypes, useForm } from '@frontend/design-system';
import { OTHER_SUBSCRIBER_SELECTION_VALUE, RelationshipEnum } from '../constants';
import {
  getInsurancePayerDetails,
  getPayerCombinedId,
  prepareInsuranceDetails,
  removeNonAlphaNumericCharacters,
  sanitizeText,
  validateNationalProviderId,
  validateNationalProviderTaxId,
} from '../helpers';
import { usePersonInfoStore } from '../providers';
import { useGetFormData } from './use-get-form-data';

dayjs.extend(utc);

const MAX_DATE = getTodaysDate('YYYY-MM-DD');

export const useInsuranceForm = () => {
  const { personInfo, locationId } = usePersonInfoStore(['locationId', 'personInfo']);
  const personId = personInfo?.PersonID as string;
  const { isLoading, payerList, nationalProviderIdList, primSubscriberList, providerList, insuranceDetails } =
    useGetFormData();

  const form = useForm({
    fields: {
      payerId: { type: 'dropdown', required: true },
      groupName: { type: 'text' },
      groupNumber: { type: 'text' },
      patientMemberId: { type: 'text', required: true },
      patientFirstName: { type: 'text', required: true },
      patientLastName: { type: 'text', required: true },
      patientBirthDate: { type: 'datePicker', required: true, maxDate: MAX_DATE },
      nationalProviderId: {
        type: 'text',
        required: true,
        validator: ({ value }) => validateNationalProviderId(value),
      },
      taxId: { type: 'text', validator: ({ value }) => validateNationalProviderTaxId(value), required: true },
      relationship: { type: 'dropdown', required: true, value: RelationshipEnum.Self },
      primarySubscriber: {
        type: 'dropdown',
        required: primSubscriberList.length > 1 && insuranceDetails?.relationship !== 'se',
      },
      subscriberFirstName: { type: 'text' },
      subscriberLastName: { type: 'text' },
      subscriberBirthDate: {
        type: 'datePicker',
        maxDate: MAX_DATE,
      },
      subscriberMemberId: { type: 'text' },
    },
    fieldStateReducer: (state, action) => {
      const isUpdateAction = action.type === FormFieldActionTypes.Update;
      const isBlurAction = action.type === FormFieldActionTypes.Blur;
      const isFocusAction = action.type === FormFieldActionTypes.Focus;

      if (isUpdateAction && action.payload.name === 'nationalProviderId') {
        const taxId =
          providerList.find((provider) => provider.nationalProviderId === action.payload.value)?.taxId ?? '';
        if (taxId) {
          return {
            ...state,
            taxId: {
              ...state.taxId,
              value: taxId,
              error: validateNationalProviderTaxId(taxId),
            },
          };
        }
      }

      if (
        isUpdateAction &&
        (action.payload.name === 'taxId' ||
          action.payload.name === 'patientMemberId' ||
          action.payload.name === 'groupNumber')
      ) {
        return {
          ...state,
          [action.payload.name]: {
            ...state[action.payload.name],
            value: removeNonAlphaNumericCharacters(action.payload.value),
          },
        };
      }

      if (isUpdateAction || isBlurAction || isFocusAction) {
        const isShowPrimSubscriber = isUpdateAction
          ? action.payload.value !== RelationshipEnum.Self
          : state.relationship.value !== RelationshipEnum.Self;

        return {
          ...state,
          primarySubscriber: {
            ...state.primarySubscriber,
            value: isShowPrimSubscriber ? state?.primarySubscriber?.value : '',
            required: isShowPrimSubscriber,
          },
          subscriberFirstName: {
            ...state.subscriberFirstName,
            value: isShowPrimSubscriber ? state.subscriberFirstName.value : '',
            required: isShowPrimSubscriber,
          },
          subscriberLastName: {
            ...state.subscriberLastName,
            value: isShowPrimSubscriber ? state.subscriberLastName.value : '',
            required: isShowPrimSubscriber,
          },
          subscriberBirthDate: {
            ...state.subscriberBirthDate,
            value: isShowPrimSubscriber ? state.subscriberBirthDate.value : '',
            required: isShowPrimSubscriber,
          },
          subscriberMemberId: {
            ...state.subscriberMemberId,
            value: isShowPrimSubscriber ? state.subscriberMemberId.value : '',
            required: isShowPrimSubscriber,
          },
        };
      }

      return state;
    },
  });

  const { data: subscriberInfo, isLoading: isLoadingSubscriberInfo } =
    InsuranceDetailQueries.useGetSubscriberByDependentsPersonID(personId, locationId ? locationId : '', {
      disabled: isLoading || !form.values.relationship || form.values.relationship === RelationshipEnum.Self,
    });

  // seed subscriber details into form when relationship is changed and when we have only one subscriber
  useEffect(() => {
    const hasRelationshipAsSelf = form.values.relationship === RelationshipEnum.Self;
    const hasPrimarySubscriberValue = !!form.values.primarySubscriber;

    const shouldSeedPrimarySubscriber =
      !!form.values.relationship && !hasRelationshipAsSelf && !hasPrimarySubscriberValue;

    if (shouldSeedPrimarySubscriber) {
      // find guardian primary subscriber from the list
      const primarySubscriberDetailsAsGuardian = primSubscriberList?.find((subscriber) => subscriber?.isGuardian);

      const firstPrimarySubscriberValue = primSubscriberList?.[0]?.value || '';

      // If there is only one primary subscriber in the list, seed that value into the form
      // else seed the guardian primary subscriber value if available or else seed the first primary subscriber value
      const primarySubscriberValue =
        primSubscriberList?.length === 1
          ? firstPrimarySubscriberValue
          : primarySubscriberDetailsAsGuardian?.value || firstPrimarySubscriberValue;

      form.seedValues({
        primarySubscriber: primarySubscriberValue,
      });
    }

    // seed other primary subscriber when there is no primary subscriber value in the form which will allow users to add new subscriber manually in the form
    const shouldSeedOtherPrimarySubscriber =
      !!form.values.relationship &&
      !hasRelationshipAsSelf &&
      !hasPrimarySubscriberValue &&
      primSubscriberList.length === 0;

    if (shouldSeedOtherPrimarySubscriber) {
      form.seedValues({
        primarySubscriber: OTHER_SUBSCRIBER_SELECTION_VALUE,
      });
    }
  }, [form.values.relationship, primSubscriberList]);

  // validate form when relationship is changed and there is no primary subscriber value in the form
  useEffect(() => {
    const shouldValidateFormForSecondarySubscriber = !!form.values.relationship && !form.values.primarySubscriber;
    if (shouldValidateFormForSecondarySubscriber) {
      form.validate();
    }
  }, [form.values.relationship, form.values.primarySubscriber]);

  // seed insurance details into form
  useEffect(() => {
    if (isLoading) {
      return;
    }
    const details = prepareInsuranceDetails(insuranceDetails ?? {}, payerList);

    // Attach nationProviderId if we received it from the PMS
    let nationalProviderId = details.nationalProviderId;

    // Attach providerTaxId if we received it from the PMS
    let providerTaxId = details.providerTaxId || '';

    // If we have nationalProviderId, the find the providerTaxId from providerList
    if (nationalProviderId && !providerTaxId) {
      providerTaxId = providerList.find((provider) => provider.nationalProviderId === nationalProviderId)?.taxId ?? '';
    }

    // If we have providerTaxId, then find the nationalProviderId from providerList
    if (!nationalProviderId && providerTaxId) {
      nationalProviderId = providerList.find((provider) => provider.taxId === providerTaxId)?.nationalProviderId ?? '';
    }

    // If we don't have nationalProviderId and providerTaxId, we can use the first provider in the list
    // Since the DXC insurance verification API can check eligibility with any NPI or taxID
    if (!nationalProviderId && !providerTaxId && providerList.length) {
      nationalProviderId = providerList[0].nationalProviderId;
      providerTaxId = providerList[0].taxId;
    }

    const payerDetails = getInsurancePayerDetails(
      sanitizeText(insuranceDetails?.payerCompanyId || details?.payerId || ''),
      sanitizeText(details?.payerName || ''),
      payerList
    );

    const combinedPayerId = payerDetails?.payerName
      ? getPayerCombinedId(
          payerDetails?.payerID || insuranceDetails?.payerCompanyId || details?.payerId || '',
          payerDetails.payerName
        )
      : '';

    const birthDate = personInfo?.Birthdate || details.dateOfBirth;

    form.seedValues({
      patientFirstName: personInfo?.FirstName ?? details.firstName,
      patientLastName: personInfo?.LastName ?? details.lastName,
      patientBirthDate: birthDate ? dayjs.utc(birthDate).format('MM/DD/YYYY') : '',
      patientMemberId: details.memberId,
      groupName: details.groupName,
      groupNumber: details.groupNumber,
      payerId: combinedPayerId,
      relationship: details.relationship,
      nationalProviderId: nationalProviderId,
      taxId: providerTaxId,
    });

    const isProviderIdValid = validateNationalProviderId(nationalProviderId) === '';
    const isTaxIdValid = validateNationalProviderTaxId(providerTaxId) === '';
    if (isProviderIdValid && isTaxIdValid) {
      form.validate();
    }
  }, [isLoading, insuranceDetails, payerList, providerList]);

  // seed subscriber details into form
  useEffect(() => {
    // SP-TODO: need to validate as it keeps failing currently and returns 404 error
    if (subscriberInfo) {
      form.seedValues({
        // SP-TODO: ask BE to update UUID type to string
        primarySubscriber: subscriberInfo.personId as string,
        subscriberFirstName: subscriberInfo.firstName,
        subscriberLastName: subscriberInfo.lastName,
        subscriberBirthDate: subscriberInfo?.birthDate ? dayjs.utc(subscriberInfo?.birthDate).format('MM/DD/YYYY') : '',
        subscriberMemberId: sanitizeText(subscriberInfo.memberId) || undefined,
      });
      focusOnFormField('subscriberMemberId');
    }
  }, [subscriberInfo, isLoadingSubscriberInfo]);

  // seed subscriber details into form when primary subscriber is selected
  useEffect(() => {
    if (!form.values.primarySubscriber) {
      return;
    }

    // If user selects 'Other' as primary subscriber, clear the subscriber details
    if (form.values.primarySubscriber === OTHER_SUBSCRIBER_SELECTION_VALUE) {
      form.seedValues({
        subscriberFirstName: '',
        subscriberLastName: '',
        subscriberBirthDate: '',
        subscriberMemberId: '',
      });
      focusOnFormField('subscriberFirstName');
      return;
    }

    const shouldGetSubscriberDetails =
      !form.values.subscriberFirstName ||
      form.values.subscriberFirstName !== subscriberInfo?.firstName ||
      !form.values.subscriberBirthDate ||
      form.values.subscriberBirthDate !== subscriberInfo?.birthDate;

    if (shouldGetSubscriberDetails) {
      SchemaInsuranceVerificationAPIService.GetInsuranceByPersonID({ personId: form.values.primarySubscriber }).then(
        (res) => {
          form.seedValues({
            subscriberFirstName: res.firstName,
            subscriberLastName: res.lastName,
            subscriberBirthDate: formatDate(res.birthDate, 'MM/DD/YYYY'),
            subscriberMemberId: sanitizeText(res.memberId),
          });
        }
      );
      // Focus on subscriber member id field when primary subscriber is selected and validate the form
      focusOnFormField('subscriberMemberId');
    }

    form?.validate();
  }, [form.values.primarySubscriber, subscriberInfo]);

  const focusOnFormField = (formFieldName: string) => {
    const formField = form?.formProps?.ref?.current?.querySelector(`input[name="${formFieldName}"]`) as HTMLElement;
    if (formField) {
      formField?.focus();
    }
  };

  return {
    form,
    isLoading,
    payerList,
    nationalProviderIdList,
    primSubscriberList,
    providerList,
  };
};
