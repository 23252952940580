import { StatusType } from '@weave/schema-gen-ts/dist/schemas/auth-api/v3/auth.pb';
import { Vertical } from '@weave/schema-gen-ts/dist/schemas/platform-location-feature/v1/location_features.pb';
import { TaskStatus, TaskType } from '@weave/schema-gen-ts/dist/schemas/task-center/shared/v1/enums.pb';
import { InfiniteData } from 'react-query';
import { CallIntelTypes } from '@frontend/api-call-intel';
import { demoDataUtils } from '../../../utils';
import { verticalMapping } from '../hooks/use-options-provider';

type CallRecordings = {
  appointmentType?: CallIntelTypes.AppointmentTypeEnum;
  category?: CallIntelTypes.CategoryEnum;
  count: number;
  sentiment?: CallIntelTypes.SentimentEnum;
  showOnlyUnscheduled?: boolean;
  serviceQualityFlags?: CallIntelTypes.ServiceQualityFlagEnum[];
  vertical: Vertical | null;
};

// Constants

export const categories: CallIntelTypes.CategoryEnum[] = [
  CallIntelTypes.CategoryEnum.CATEGORY_BILLING,
  CallIntelTypes.CategoryEnum.CATEGORY_EMERGENCY,
  CallIntelTypes.CategoryEnum.CATEGORY_COMPLAINT,
  CallIntelTypes.CategoryEnum.CATEGORY_SCHEDULING,
];

export const contactTypes: CallIntelTypes.ContactTypeEnum[] = [
  CallIntelTypes.ContactTypeEnum.CONTACT_EXISTING_PATIENT,
  CallIntelTypes.ContactTypeEnum.CONTACT_NEW_PATIENT,
  CallIntelTypes.ContactTypeEnum.CONTACT_NOT_A_PATIENT,
];

const serviceQualityDetailsMapping: Partial<
  Record<Vertical, Partial<Record<CallIntelTypes.ServiceQualityFlagEnum, { summary: string; tsStart: string }[]>>>
> = {
  [Vertical.DENTAL]: {
    [CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE]: [
      {
        summary:
          'The patient, Frank David, had an $800 insurance claim for a root canal procedure that remains unpaid after two months.',
        tsStart: '00:41:00',
      },
      {
        summary:
          "The insurance company claimed they needed additional information from the dental office, which the office had reportedly sent but the insurance company said they hadn't received.",
        tsStart: '01:06:00',
      },
      {
        summary:
          'This back-and-forth had been going on for weeks, causing frustration for the patient who was still stuck with the unpaid $800 bill.',
        tsStart: '01:25:00',
      },
    ],
    [CallIntelTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION]: [
      {
        summary:
          "Joan effectively addressed the patient's concern by escalating the issue to the office manager for immediate follow-up.",
        tsStart: '01:35:00',
      },
      {
        summary: 'Joan also promised a callback with an update by 5pm the next day',
        tsStart: '02:50:00',
      },
      {
        summary: "and offered to apply a courtesy credit if the issue wasn't resolved by that time.",
        tsStart: '02:11:00',
      },
    ],
  },
  [Vertical.VET]: {
    [CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE]: [
      {
        summary: "Caller is experiencing delays in insurance processing for his dog's surgery.",
        tsStart: '00:05:00',
      },
      {
        summary:
          "Caller is having difficulty finding a suitable appointment time for his dog's check-up due to his work schedule.",
        tsStart: '03:06:00',
      },
    ],
    [CallIntelTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION]: [
      {
        summary:
          "The staff member acknowledged the caller's frustration and assured him that the issue would be escalated to the office manager for immediate follow-up. The staff member also offered a courtesy credit to the caller's account for the full amount of the disputed bill while the insurance issue was being resolved. The staff member also added the caller to a waitlist for earlier appointments and took down his preferred contact time.",
        tsStart: '01:35:00',
      },
    ],
  },
  [Vertical.OPTOMETRY]: {
    [CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE]: [
      {
        summary:
          'The caller is experiencing issues with an insurance claim for an eye exam and prescription lenses from two months ago.',
        tsStart: '00:05:00',
      },
      {
        summary: 'The caller is unable to schedule an appointment due to limited availability.',
        tsStart: '05:06:00',
      },
    ],
    [CallIntelTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION]: [
      {
        summary:
          "The staff member acknowledged the caller's frustration and took concrete steps to address the insurance claim issue. They assured the caller that the office manager would personally follow up with the insurance company and provide an update within a specific timeframe. Additionally, they offered a courtesy credit to the caller's account if the issue was not resolved by the deadline, demonstrating a proactive approach to resolving the issue and ensuring customer satisfaction. Although the staff member could not schedule an appointment for the caller immediately, they offered to add the caller to a waitlist and call them if an earlier or evening appointment became available, showing a willingness to accommodate the caller's needs.",
        tsStart: '01:01:00',
      },
    ],
  },
};

const taskDetailsMapping: Partial<
  Record<Vertical, Partial<Record<TaskType, { title: string; description: string; citation: string }>>>
> = {
  [Vertical.DENTAL]: {
    [TaskType.TYPE_INSURANCE]: {
      title: 'Call insurance company to follow up on claim.',
      description:
        'The insurance company may need additional information. Call the insurance company and follow up on claim for root canal procedure performed two months ago. Confirm receipt of information sent last week and escalate to Sarah. Sarah will call the patient by the end of the day tomorrow with an update.',
      citation:
        "I'm also going to escalate this to our office manager, Sarah. She'll personally follow up with the insurance company today to ensure they process your claim correctly.",
    },
    [TaskType.TYPE_WAITLIST]: {
      title: 'Add patient to the waitlist for an evening cleaning appointment.',
      description:
        'Call patient if an evening cleaning appointment opens up. His previous cleaning was five months ago. The office does not have any evening appointments available for three weeks. He prefers to be contacted after 5 PM on weekdays.',
      citation:
        "What I can do is add you to our waitlist for any earlier or evening appointments that might open up. If something becomes available, we'll give you a call.",
    },
  },
  [Vertical.VET]: {
    [TaskType.TYPE_INSURANCE]: {
      title:
        "Contact the insurance company to follow up on the status of the claim for Max's surgery and confirm receipt of the information.",
      description:
        "The office previously sent information to the insurance company but they have not received it. Resend the information to the insurance company and follow up to confirm receipt. Call Sarah at (XXX) XXX-XXXX to provide an update on the claim status by the end of the day tomorrow 11/09/2023. Apply a courtesy credit to the caller's account for the full amount of the claim if it has not been processed by the end of the day tomorrow 11/09/2023.",
      citation:
        "Here's what I'm going to do: I'm resending the information right now, and I'm also going to escalate this to our office manager, Sarah. She'll personally follow up with the insurance company today to ensure they process your claim correctly. We'll take care of this for you so you don't have to make any more calls about this issue.",
    },
    [TaskType.TYPE_WAITLIST]: {
      title: 'Add Max to the waitlist for an appointment.',
      description:
        'Add Max to the waitlist for an earlier appointment or an evening appointment after 5pm on weekdays. Call the caller back if an appointment becomes available.',
      citation:
        "What I can do is add you to our waitlist for any earlier or evening appointments that might open up. If something becomes available, we'll give you a call.",
    },
  },
  [Vertical.OPTOMETRY]: {
    [TaskType.TYPE_INSURANCE]: {
      title:
        'Contact the insurance company to follow up on the status of the claim and ensure they received the information.',
      description:
        'The insurance company may need to be contacted to confirm receipt of the claim information. The caller stated the insurance company told him they were missing information. The office resent the information to the insurance company while on the call with the caller.',
      citation:
        "I sincerely apologize for this delay, Mr. David. Let me look into this right away. I see here that we did send the additional information last week, but it seems there might have been an issue with the transmission. I'm going to resend it right now while we're on the phone, and I'll also call the insurance company to confirm they've received it.",
    },
    [TaskType.TYPE_WAITLIST]: {
      title: 'Contact Frank David with an update on the status of his insurance claim.',
      description:
        'Frank David is experiencing issues with his insurance claim not being processed. The office manager will be contacting the insurance company to resolve the issue and will contact the caller with an update by the end of the day tomorrow.',
      citation:
        "You're right to be skeptical given the delays you've experienced. I assure you that we take this very seriously. Sarah will personally call you by the end of the day tomorrow with an update, regardless of the outcome. If for any reason the insurance company still hasn't processed the claim by then, we'll apply a courtesy credit to your account for the full amount while we continue to work with the insurance company. This way, you won't have to worry about the bill anymore. Does that sound fair?",
    },
  },
};

export const officeUsers: CallIntelTypes.OfficeUser[] = demoDataUtils.generateRandomUserNames(20).map((name, id) => ({
  ...name,
  status: StatusType.ACTIVE,
  userId: `demo-${id}`,
}));

export const phoneNumbers: string[] = [
  '1234567890',
  '2345678901',
  '3456789012',
  '4567890123',
  '5678901234',
  '6789012345',
  '7890123456',
  '8901234567',
  '9012345678',
  '0123456789',
];

export const taskTypes: TaskType[] = [
  TaskType.TYPE_WAITLIST,
  TaskType.TYPE_INSURANCE,
  // TaskType.TYPE_BILLING,
];

export const serviceQualityFlags: CallIntelTypes.ServiceQualityFlagEnum[] = [
  CallIntelTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION,
  CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE,
];

export const sentiments: CallIntelTypes.SentimentEnum[] = [
  CallIntelTypes.SentimentEnum.SENTIMENT_NEGATIVE,
  CallIntelTypes.SentimentEnum.SENTIMENT_NEUTRAL,
  CallIntelTypes.SentimentEnum.SENTIMENT_POSITIVE,
  CallIntelTypes.SentimentEnum.SENTIMENT_UNKNOWN,
];

// Utility Functions
export const getRandomElement = <T>(array: T[]): T => array[Math.floor(Math.random() * array.length)];

export const getRandomElementNoMatch = <T>(array: T[], exclude?: T): T => {
  if (exclude == null) {
    return getRandomElement(array);
  }
  const filtered = array.filter((item) => item !== exclude);
  return filtered.length ? getRandomElement(filtered) : getRandomElement(array);
};

export const randomBooleanNoMatch = (exclude?: boolean) => {
  if (exclude == null) return Math.random() > 0.5;
  return !exclude;
};

export const getRandomSentiment = (): CallIntelTypes.SentimentEnum => getRandomElement(sentiments);

export const getRandomContactType = (): CallIntelTypes.ContactTypeEnum => getRandomElement(contactTypes);

export const getRandomDirection = (): CallIntelTypes.CallDirectionEnum =>
  getRandomElement([
    CallIntelTypes.CallDirectionEnum.DIRECTION_INBOUND,
    CallIntelTypes.CallDirectionEnum.DIRECTION_OUTBOUND,
  ]);

const getRandomServiceQualityFlags = (): CallIntelTypes.ServiceQualityFlagEnum[] => {
  const randomCount = Math.floor(Math.random() * serviceQualityFlags.length) + 1;
  return [...serviceQualityFlags].sort(() => 0.5 - Math.random()).slice(0, randomCount);
};

const getRandomTaskTypes = (count: number): TaskType[] =>
  [...taskTypes].sort(() => 0.5 - Math.random()).slice(0, count);

const getRandomTaskStatus = (allowedStatuses?: TaskStatus[]): TaskStatus => {
  const statuses =
    allowedStatuses && allowedStatuses.length > 0
      ? allowedStatuses
      : [TaskStatus.STATUS_TODO, TaskStatus.STATUS_IN_PROGRESS, TaskStatus.STATUS_COMPLETED];

  return getRandomElement(statuses);
};
const getRandomTasks = (
  taskTypes: TaskType[],
  callId: string,
  vertical: Vertical | null,
  taskStatusFilters?: TaskStatus[]
): CallIntelTypes.Task[] => {
  const defaultTaskDetails = {
    title: "Contact the insurance company to follow up on Frank David's claim and ensure they process it correctly.",
    description:
      "The insurance company may need additional information. Sarah will personally call Frank by the end of the day tomorrow with an update, regardless of the outcome. If for any reason the insurance company still hasn't processed the claim by then, we'll apply a courtesy credit to his account for the full amount while we continue to work with the insurance company.",
    citation:
      "I completely understand your frustration, Mr. David. This shouldn't have taken so long to resolve. Here's what I'm going to do: I'm resending the information right now, and I'm also going to escalate this to our office manager, Sarah. She'll personally follow up with the insurance company today to ensure they process your claim correctly. We'll take care of this for you so you don't have to make any more calls about this issue.",
  };

  return taskTypes.map((taskType, idx) => {
    const taskDetails =
      vertical && taskDetailsMapping[vertical]?.[taskType]
        ? taskDetailsMapping[vertical][taskType]
        : defaultTaskDetails;
    return {
      assignee: '',
      citation: '',
      description: taskDetails.description,
      id: `demo-${idx}`,
      locationId: '',
      metadata: JSON.stringify({ citation: taskDetails.citation }),
      sourceId: callId,
      status: getRandomTaskStatus(taskStatusFilters),
      title: taskDetails.title,
      type: taskType,
    };
  });
};

// **Get Appointment Types Based on Vertical**
const getAppointmentTypesForVertical = (vertical: Vertical): CallIntelTypes.AppointmentTypeEnum[] => {
  // If a match is found in the mapping, return the specific appointment types
  if (vertical in verticalMapping) {
    return [...(verticalMapping[vertical] ?? [])].sort();
  }

  // If the vertical is NOT Dental, Vet, or Optometry, return Generic Appointment Types
  const allSpecificAppointments = new Set<CallIntelTypes.AppointmentTypeEnum>([
    ...(Object.values(CallIntelTypes.AppointmentTypeDentalEnum) as unknown as CallIntelTypes.AppointmentTypeEnum[]),
    ...(Object.values(CallIntelTypes.AppointmentTypeVetEnum) as unknown as CallIntelTypes.AppointmentTypeEnum[]),
    ...(Object.values(CallIntelTypes.AppointmentTypeOptoEnum) as unknown as CallIntelTypes.AppointmentTypeEnum[]),
  ]);

  return Object.values(CallIntelTypes.AppointmentTypeEnum)
    .filter((type) => !allSpecificAppointments.has(type))
    .sort();
};

// Demo Data Function
export const callRecordings = ({
  appointmentType,
  category,
  count,
  sentiment,
  serviceQualityFlags,
  showOnlyUnscheduled,
  vertical,
}: CallRecordings): InfiniteData<CallIntelTypes.CallsResponse> => {
  // If vertical is null => treat it as generic
  let validApptTypes: CallIntelTypes.AppointmentTypeEnum[] = [];
  if (vertical) {
    validApptTypes = getAppointmentTypesForVertical(vertical);
  } else {
    // treat as generic if vertical is null
    validApptTypes = getAppointmentTypesForVertical(Vertical.INVALID_VERTICAL);
  }
  return {
    pages: [
      {
        calls: demoDataUtils.generateRandomUserNames(count).map((name, id) => {
          const schedulingOpportunity = Math.random() > 0.5;
          const taskCount = Math.floor(Math.random() * taskTypes.length) + 1;

          return {
            appointmentTypes: [appointmentType ?? getRandomElement(validApptTypes)],
            categories: [category ?? getRandomElement(categories)],
            contactType: getRandomContactType(),
            direction: getRandomDirection(),
            id: `demo-${id}`,
            locationId: '001',
            mosScore: Math.floor(Math.random() * 6),
            officeNumber: {
              countryCode: 1,
              nationalNumber: getRandomElement(phoneNumbers),
            },
            officeUser: getRandomElement(officeUsers),
            person: {
              ...name,
              id: `demo-${id}`,
            },
            phoneNumber: {
              countryCode: 1,
              nationalNumber: '234567890',
            },
            schedulingOpportunity: true,
            schedulingOutcome: showOnlyUnscheduled ? false : schedulingOpportunity ? Math.random() > 0.5 : false,
            sentiment: sentiment ?? getRandomSentiment(),
            serviceQualityFlags: serviceQualityFlags ?? getRandomServiceQualityFlags(),
            startTime: demoDataUtils.getRandomDateAndTime(),
            status: CallIntelTypes.CallStatusEnum.CALL_STATUS_COMPLETE,
            taskCount,
            taskTypes: getRandomTaskTypes(taskCount),
          };
        }),
        pageConfig: {
          pageNumber: 1,
          pageSize: 10,
        },
      },
    ],
    pageParams: [],
  };
};

export const callMetadataResponse = (vertical: Vertical | null): CallIntelTypes.CallMetadataResponse => {
  const verticalMetadata: Partial<Record<Vertical, { summary: string; transcript: string }>> = {
    [Vertical.DENTAL]: {
      summary:
        "[PERSON_NAME] contacted Happy Smiles Dental regarding a delayed insurance claim for a root canal procedure. The office staff acknowledged the issue and confirmed that they had sent the necessary information to the insurance company but would resend it immediately. To expedite resolution, the office manager, Sarah, will follow up with the insurance company and provide an update to [PERSON_NAME] by the end of the next day. Additionally, as a courtesy, if the claim is not processed by then, a credit will be applied to [PERSON_NAME]'s account for the full amount while the issue is resolved. While on the call, [PERSON_NAME] also requested to schedule a cleaning appointment, but due to limited availability, he was added to a waitlist for an earlier or evening appointment.",
      transcript: `
      [office] <0 --> 5> Happy Smiles Dental, this is Joan speaking. How may I assist you?
      [caller] <5 --> 15> Hi Joan, this is [PERSON_NAME]. I'm calling again about the insurance claim for my root canal procedure from two months ago. I've been trying to get this resolved for weeks now.
      [office] <16 --> 25> I apologize for the ongoing issue, Mr. [PERSON_NAME]. Let me pull up your file. Can you confirm your date of birth for me, please?
      [caller] <25 --> 28> It's December 13, 1976.
      [office] <28 --> 40> Thank you. I see your file here. Yes, I can see the notes from your previous calls about this claim. I understand how frustrating this must be for you. Can you remind me what the insurance company told you when you last spoke with them?
      [caller] <41 --> 65> They said they needed additional information from your office. I called you guys last week, and someone told me they would send it right away. But when I called the insurance company yesterday, they said they still hadn't received anything. This has been going on for almost two months now, and I'm still stuck with this $800 bill that should be covered.
      [office] <66 --> 85> I sincerely apologize for this delay, Mr. [PERSON_NAME]. Let me look into this right away. I see here that we did send the additional information last week, but it seems there might have been an issue with the transmission. I'm going to resend it right now while we're on the phone, and I'll also call the insurance company to confirm they've received it.
      [caller] <85 --> 95> I appreciate that, but I've heard similar things before. I really need this resolved. I can't keep calling back and forth between you and the insurance company.
      [office] <95 --> 120> I completely understand your frustration, Mr. [PERSON_NAME]. This shouldn't have taken so long to resolve. Here's what I'm going to do: I'm resending the information right now, and I'm also going to escalate this to our office manager, Sarah. She'll personally follow up with the insurance company today to ensure they process your claim correctly. We'll take care of this for you so you don't have to make any more calls about this issue.
      [caller] <121 --> 130> That sounds better, but I've been told before that it would be handled. How can I be sure it'll be different this time?
      [office] <131 --> 160> You're right to be skeptical given the delays you've experienced. I assure you that we take this very seriously. Sarah will personally call you by the end of the day tomorrow with an update, regardless of the outcome. If for any reason the insurance company still hasn't processed the claim by then, we'll apply a courtesy credit to your account for the full amount while we continue to work with the insurance company. This way, you won't have to worry about the bill anymore. Does that sound fair?
      [caller] <160 --> 170> Yes, that does sound better. I appreciate you taking this seriously. I'll look forward to hearing from Sarah by tomorrow evening then.
      [office] <170 --> 185> Excellent. I've made a note in your file about this conversation and our commitment. Sarah will call you by 5 PM tomorrow at the latest. If you don't hear from her by then, please call us back immediately and ask to speak with her.
      [caller] <186 --> 205> Thank you. While we're on the phone, I also wanted to schedule my next cleaning appointment. My last one was about five months ago, so I think I'm due for another one soon.
      [office] <206 --> 225> Of course, Mr. [PERSON_NAME]. I'd be happy to help you schedule your next cleaning. Let me check our availability. We have an opening next Tuesday at 2 PM with Dr. Johnson. Would that work for you?
      [caller] <225 --> 235> Tuesday afternoons are tough for me. Do you have anything later in the day or maybe on a Thursday or Friday?
      [office] <235 --> 255> I understand. Let me see what else we have. We have a Thursday slot at 4:30 PM two weeks from now, or a Friday morning appointment at 10 AM in three weeks. Would either of those be more convenient for you?
      [caller] <256 --> 275> The Thursday slot would be better, but two weeks is a bit far out. And Friday morning won't work because I have standing meetings at that time. Do you have anything sooner in the evenings, maybe after 5 PM?
      [office] <276 --> 300> I apologize, Mr. [PERSON_NAME], but we don't have any evening appointments available in the next three weeks. Our latest appointment is usually at 4:30 PM. We do occasionally have Saturday morning appointments, but the next available one isn't for about a month. Would you like me to book that for you?
      [caller] <300 --> 320> No, that's too far out. I was hoping to get something sooner. I guess I'll have to check my schedule and call back later to see if anything opens up. It's just been difficult to find a time that works with my current work schedule.
      [office] <320 --> 350> I completely understand, Mr. [PERSON_NAME]. I'm sorry we couldn't find a suitable time for you today. What I can do is add you to our waitlist for any earlier or evening appointments that might open up. If something becomes available, we'll give you a call. In the meantime, please feel free to check back with us in a week or so, and we'll see if we have any new openings that might work better for you. Is there a best time of day to reach you if an appointment opens up?
      [caller] <351 --> 360> You can try me anytime after 5 PM on weekdays. I appreciate you adding me to the waitlist.
      [office] <361 --> 385> You're welcome, Mr. [PERSON_NAME]. I've added you to our waiting list for evening appointments, noting your preference for after 5 PM on weekdays. We'll do our best to accommodate you as soon as possible. Is there anything else I can help you with today?
      [caller] <385 --> 390> No, that's all for now. Thank you for your help, Joan.
      [office] <390 --> 410> You're welcome, Mr. [PERSON_NAME]. Again, I apologize for the inconvenience with both the insurance issue and the scheduling. We appreciate your patience. Remember, you'll hear from Sarah about your insurance claim by 5 PM tomorrow, and we'll call you if a suitable cleaning appointment opens up. Have a good day, Mr. [PERSON_NAME].
      [caller] <411 --> 413> Thank you. Goodbye.
      [office] <413 --> 415> Goodbye, Mr. [PERSON_NAME].
  `,
    },
    [Vertical.VET]: {
      summary:
        'Mr. Frank David called Happy Paws Veterinary Clinic to follow up on an unresolved insurance claim for his dog Max’s surgery. Joan, the staff member, assured him she would resend the information and escalate the issue to the office manager, Sarah, who would provide an update by the next day. Mr. David also mentioned that Max was showing signs of illness and requested a sick pet appointment. However, due to limited availability, no suitable appointment could be scheduled. Joan added him to a waitlist for cancellations and confirmed his preferred contact time. The call ended with reassurances about both the insurance follow-up and scheduling concerns',
      transcript: `
      [office] <0 --> 5> Happy Paws Veterinary Clinic, this is Joan speaking. How may I assist you?
      [caller] <5 --> 15> Hi Joan, this is Frank David. I'm calling again about the insurance claim for my dog Max’s surgery from two months ago. I've been trying to get this resolved for weeks now.
      [office] <16 --> 25> I apologize for the ongoing issue, Mr. David. Let me pull up your file. Can you confirm Max’s date of birth for me, please?
      [caller] <25 --> 28> It’s December 13, 2015.
      [office] <28 --> 40> Thank you. I see your file here. Yes, I can see the notes from your previous calls about this claim. I understand how frustrating this must be for you. Can you remind me what the pet insurance company told you when you last spoke with them?
      [caller] <41 --> 65> They said they needed additional information from your office. I called you guys last week, and someone told me they would send it right away. But when I called the insurance company yesterday, they said they still hadn't received anything. This has been going on for almost two months now, and I’m still stuck with this $800 bill that should be covered.
      [office] <66 --> 85> I sincerely apologize for this delay, Mr. David. Let me look into this right away. I see here that we did send the additional information last week, but it seems there might have been an issue with the transmission. I'm going to resend it right now while we're on the phone, and I'll also call the insurance company to confirm they've received it.
      [caller] <85 --> 95> I appreciate that, but I've heard similar things before. I really need this resolved. I can't keep calling back and forth between you and the insurance company.
      [office] <95 --> 120> I completely understand your frustration, Mr. David. This shouldn't have taken so long to resolve. Here's what I'm going to do: I'm resending the information right now, and I'm also going to escalate this to our office manager, Sarah. She'll personally follow up with the insurance company today to ensure they process your claim correctly. We'll take care of this for you so you don't have to make any more calls about this issue.
      [caller] <121 --> 130> That sounds better, but I've been told before that it would be handled. How can I be sure it'll be different this time?
      [office] <131 --> 160> You're right to be skeptical given the delays you've experienced. I assure you that we take this very seriously. Sarah will personally call you by the end of the day tomorrow with an update, regardless of the outcome. If for any reason the insurance company still hasn't processed the claim by then, we'll apply a courtesy credit to your account for the full amount while we continue to work with the insurance company. This way, you won’t have to worry about the bill anymore. Does that sound fair?
      [caller] <160 --> 170> Yes, that does sound better. I appreciate you taking this seriously. I'll look forward to hearing from Sarah by tomorrow evening then.
      [office] <170 --> 185> Excellent. I've made a note in your file about this conversation and our commitment. Sarah will call you by 5 PM tomorrow at the latest. If you don’t hear from her by then, please call us back immediately and ask to speak with her.
      [caller] <186 --> 205> Thank you. While we're on the phone, I also wanted to schedule Max’s next check-up. He’s been acting a little off lately—he's been more lethargic than usual and not eating as much, so I want to get him checked out to make sure nothing serious is going on.
      [office] <206 --> 225> I understand, Mr. David. Since Max is showing signs of illness, I’d recommend scheduling a sick pet appointment rather than just a routine check-up. Let me check our availability. We have an opening next Tuesday at 2 PM with Dr. Johnson. Would that work for you?
      [caller] <225 --> 235> Tuesday afternoons are tough for me. Do you have anything later in the day or maybe on a Thursday or Friday?
      [office] <235 --> 255> I understand. Let me see what else we have. We have a Thursday slot at 4:30 PM two weeks from now, or a Friday morning appointment at 10 AM in three weeks. Would either of those be more convenient for you?
      [caller] <256 --> 275> The Thursday slot would be better, but two weeks is a bit far out. And Friday morning won’t work because I have standing meetings at that time. Do you have anything sooner in the evenings, maybe after 5 PM?
      [office] <276 --> 300> I apologize, Mr. David, but we don’t have any evening appointments available in the next three weeks. Our latest appointment is usually at 4:30 PM. We do occasionally have Saturday morning appointments, but the next available one isn't for about a month. Would you like me to book that for you?
      [caller] <300 --> 320> No, that’s too far out. I was hoping to get something sooner. I guess I'll have to check my schedule and call back later to see if anything opens up. It’s just been difficult to find a time that works with my current work schedule.
      [office] <320 --> 350> I completely understand, Mr. David. I’m sorry we couldn't find a suitable time for you today. What I can do is add you to our waitlist for any earlier or evening appointments that might open up. If something becomes available, we'll give you a call. In the meantime, please feel free to check back with us in a week or so, and we'll see if we have any new openings that might work better for you. Is there a best time of day to reach you if an appointment opens up?
      [caller] <351 --> 360> You can try me anytime after 5 PM on weekdays. I appreciate you adding me to the waitlist.
      [office] <361 --> 385> You're welcome, Mr. David. I've added you to our waiting list for evening appointments, noting your preference for after 5 PM on weekdays. We'll do our best to accommodate you as soon as possible. Is there anything else I can help you with today?
      [caller] <385 --> 390> No, that’s all for now. Thank you for your help, Joan.
      [office] <390 --> 410> You're welcome, Mr. David. Again, I apologize for the inconvenience with both the insurance issue and the scheduling. We appreciate your patience. Remember, you'll hear from Sarah about your insurance claim by 5 PM tomorrow, and we'll call you if a suitable appointment opens up. Have a good day, Mr. David.
      [caller] <411 --> 413> Thank you. Goodbye.
      [office] <413 --> 415> Goodbye, Mr. David.
      `,
    },
    [Vertical.OPTOMETRY]: {
      summary:
        'Mr. Frank David called Clear Vision Optometry to follow up on an unresolved insurance claim for his eye exam and prescription lenses, which had been pending for two months. The insurance company claimed they had not received the necessary documents, despite previous assurances from the clinic. Joan, the staff member, apologized, resent the claim information, and escalated the issue to the office manager, Sarah, who promised an update by 5 PM the next day. Mr. David also mentioned experiencing frequent headaches and blurry vision. Joan attempted to schedule a comprehensive eye exam for Mr. David, but due to limited availability, no appointment could be scheduled. Joan added him to the waitlist and confirmed his preferred contact time for any cancellations. The call concluded with reassurances about the insurance follow-up and appointment scheduling.',
      transcript: `
      [office] <0 --> 5> Clear Vision Optometry, this is Joan speaking. How may I assist you?
      [caller] <5 --> 15> Hi Joan, this is Frank David. I'm calling again about the insurance claim for my eye exam and prescription lenses from two months ago. I've been trying to get this resolved for weeks now.
      [office] <16 --> 25> I apologize for the ongoing issue, Mr. David. Let me pull up your file. Can you confirm your date of birth for me, please?
      [caller] <25 --> 28> It’s December 13, 1976.
      [office] <28 --> 40> Thank you. I see your file here. Yes, I can see the notes from your previous calls about this claim. I understand how frustrating this must be for you. Can you remind me what the vision insurance company told you when you last spoke with them?
      [caller] <41 --> 65> They said they needed additional information from your office. I called you guys last week, and someone told me they would send it right away. But when I called the insurance company yesterday, they said they still hadn't received anything. This has been going on for almost two months now, and I’m still stuck with this $400 bill that should be covered.
      [office] <66 --> 85> I sincerely apologize for this delay, Mr. David. Let me look into this right away. I see here that we did send the additional information last week, but it seems there might have been an issue with the transmission. I'm going to resend it right now while we're on the phone, and I'll also call the insurance company to confirm they've received it.
      [caller] <85 --> 95> I appreciate that, but I've heard similar things before. I really need this resolved. I can't keep calling back and forth between you and the insurance company.
      [office] <95 --> 120> I completely understand your frustration, Mr. David. This shouldn't have taken so long to resolve. Here's what I'm going to do: I'm resending the information right now, and I'm also going to escalate this to our office manager, Sarah. She'll personally follow up with the insurance company today to ensure they process your claim correctly. We'll take care of this for you so you don't have to make any more calls about this issue.
      [caller] <121 --> 130> That sounds better, but I've been told before that it would be handled. How can I be sure it'll be different this time?
      [office] <131 --> 160> You're right to be skeptical given the delays you've experienced. I assure you that we take this very seriously. Sarah will personally call you by the end of the day tomorrow with an update, regardless of the outcome. If for any reason the insurance company still hasn't processed the claim by then, we'll apply a courtesy credit to your account for the full amount while we continue to work with the insurance company. This way, you won’t have to worry about the bill anymore. Does that sound fair?
      [caller] <160 --> 170> Yes, that does sound better. I appreciate you taking this seriously. I'll look forward to hearing from Sarah by tomorrow evening then.
      [office] <170 --> 185> Excellent. I've made a note in your file about this conversation and our commitment. Sarah will call you by 5 PM tomorrow at the latest. If you don’t hear from her by then, please call us back immediately and ask to speak with her.
      [caller] <186 --> 205> Thank you. While we're on the phone, I also wanted to schedule my next eye exam. I've been experiencing frequent headaches and blurry vision, so I think I may need a new prescription.
      [office] <206 --> 225> I understand, Mr. David. Since you're noticing changes in your vision, I’d recommend scheduling a comprehensive eye exam rather than just a routine check-up. Let me check our availability. We have an opening next Tuesday at 2 PM with Dr. Johnson. Would that work for you?
      [caller] <225 --> 235> Tuesday afternoons are tough for me. Do you have anything later in the day or maybe on a Thursday or Friday?
      [office] <235 --> 255> I understand. Let me see what else we have. We have a Thursday slot at 4:30 PM two weeks from now, or a Friday morning appointment at 10 AM in three weeks. Would either of those be more convenient for you?
      [caller] <256 --> 275> The Thursday slot would be better, but two weeks is a bit far out. And Friday morning won’t work because I have standing meetings at that time. Do you have anything sooner in the evenings, maybe after 5 PM?
      [office] <276 --> 300> I apologize, Mr. David, but we don’t have any evening appointments available in the next three weeks. Our latest appointment is usually at 4:30 PM. We do occasionally have Saturday morning appointments, but the next available one isn't for about a month. Would you like me to book that for you?
      [caller] <300 --> 320> No, that’s too far out. I was hoping to get something sooner. I guess I'll have to check my schedule and call back later to see if anything opens up. It’s just been difficult to find a time that works with my current work schedule.
      [office] <320 --> 350> I completely understand, Mr. David. I’m sorry we couldn't find a suitable time for you today. What I can do is add you to our waitlist for any earlier or evening appointments that might open up. If something becomes available, we'll give you a call. In the meantime, please feel free to check back with us in a week or so, and we'll see if we have any new openings that might work better for you. Is there a best time of day to reach you if an appointment opens up?
      [caller] <351 --> 360> You can try me anytime after 5 PM on weekdays. I appreciate you adding me to the waitlist.
      [office] <361 --> 385> You're welcome, Mr. David. I've added you to our waiting list for evening appointments, noting your preference for after 5 PM on weekdays. We'll do our best to accommodate you as soon as possible. Is there anything else I can help you with today?
      [caller] <385 --> 390> No, that’s all for now. Thank you for your help, Joan.
      [office] <390 --> 410> You're welcome, Mr. David. Again, I apologize for the inconvenience with both the insurance issue and the scheduling. We appreciate your patience. Remember, you'll hear from Sarah about your insurance claim by 5 PM tomorrow, and we'll call you if a suitable appointment opens up. Have a good day, Mr. David.
      [caller] <411 --> 413> Thank you. Goodbye.
      [office] <413 --> 415> Goodbye, Mr. David.
        `,
    },
  };

  const defaultMetadata = {
    summary:
      'Mr. Frank David called Midtown Medical Clinic to follow up on an unresolved insurance claim for his recent medical consultation and lab tests, which had been pending for two months. Despite previous calls and assurances, the insurance company still hadn’t received the necessary documents. Joan, the clinic staff member, resent the claim information and escalated the issue to the office manager, Sarah, who promised an update by 5 PM the next day. Mr. David also requested a comprehensive medical check-up due to fatigue and dizziness, but no suitable appointment times were available. Joan added him to a waitlist for any cancellations and confirmed his preferred contact time. The call concluded with reassurances about both the insurance follow-up and appointment scheduling.',
    transcript: `
    [office] <0 --> 5> Midtown Medical Clinic, this is Joan speaking. How may I assist you?
    [caller] <5 --> 15> Hi Joan, this is Frank David. I'm calling again about the insurance claim for my recent medical consultation and lab tests from two months ago. I've been trying to get this resolved for weeks now.
    [office] <16 --> 25> I apologize for the ongoing issue, Mr. David. Let me pull up your file. Can you confirm your date of birth for me, please?
    [caller] <25 --> 28> It’s December 13, 1976.
    [office] <28 --> 40> Thank you. I see your file here. Yes, I can see the notes from your previous calls about this claim. I understand how frustrating this must be for you. Can you remind me what the insurance company told you when you last spoke with them?
    [caller] <41 --> 65> They said they needed additional information from your office. I called you guys last week, and someone told me they would send it right away. But when I called the insurance company yesterday, they said they still hadn't received anything. This has been going on for almost two months now, and I’m still stuck with this $600 bill that should be covered.
    [office] <66 --> 85> I sincerely apologize for this delay, Mr. David. Let me look into this right away. I see here that we did send the additional information last week, but it seems there might have been an issue with the transmission. I'm going to resend it right now while we're on the phone, and I'll also call the insurance company to confirm they've received it.
    [caller] <85 --> 95> I appreciate that, but I've heard similar things before. I really need this resolved. I can't keep calling back and forth between you and the insurance company.
    [office] <95 --> 120> I completely understand your frustration, Mr. David. This shouldn't have taken so long to resolve. Here's what I'm going to do: I'm resending the information right now, and I'm also going to escalate this to our office manager, Sarah. She'll personally follow up with the insurance company today to ensure they process your claim correctly. We'll take care of this for you so you don't have to make any more calls about this issue.
    [caller] <121 --> 130> That sounds better, but I've been told before that it would be handled. How can I be sure it'll be different this time?
    [office] <131 --> 160> You're right to be skeptical given the delays you've experienced. I assure you that we take this very seriously. Sarah will personally call you by the end of the day tomorrow with an update, regardless of the outcome. If for any reason the insurance company still hasn't processed the claim by then, we'll apply a courtesy credit to your account for the full amount while we continue to work with the insurance company. This way, you won’t have to worry about the bill anymore. Does that sound fair?
    [caller] <160 --> 170> Yes, that does sound better. I appreciate you taking this seriously. I'll look forward to hearing from Sarah by tomorrow evening then.
    [office] <170 --> 185> Excellent. I've made a note in your file about this conversation and our commitment. Sarah will call you by 5 PM tomorrow at the latest. If you don’t hear from her by then, please call us back immediately and ask to speak with her.
    [caller] <186 --> 205> Thank you. While we're on the phone, I also wanted to schedule my next check-up. I've been feeling fatigued and experiencing dizziness, so I think I should get a full evaluation.
    [office] <206 --> 225> I understand, Mr. David. Since you’re experiencing those symptoms, I’d recommend scheduling a comprehensive medical check-up. Let me check our availability. We have an opening next Tuesday at 2 PM with Dr. Johnson. Would that work for you?
    [caller] <225 --> 235> Tuesday afternoons are tough for me. Do you have anything later in the day or maybe on a Thursday or Friday?
    [office] <235 --> 255> I understand. Let me see what else we have. We have a Thursday slot at 4:30 PM two weeks from now, or a Friday morning appointment at 10 AM in three weeks. Would either of those be more convenient for you?
    [caller] <256 --> 275> The Thursday slot would be better, but two weeks is a bit far out. And Friday morning won’t work because I have standing meetings at that time. Do you have anything sooner in the evenings, maybe after 5 PM?
    [office] <276 --> 300> I apologize, Mr. David, but we don’t have any evening appointments available in the next three weeks. Our latest appointment is usually at 4:30 PM. We do occasionally have Saturday morning appointments, but the next available one isn't for about a month. Would you like me to book that for you?
    [caller] <300 --> 320> No, that’s too far out. I was hoping to get something sooner. I guess I'll have to check my schedule and call back later to see if anything opens up. It’s just been difficult to find a time that works with my current work schedule.
    [office] <320 --> 350> I completely understand, Mr. David. I’m sorry we couldn't find a suitable time for you today. What I can do is add you to our waitlist for any earlier or evening appointments that might open up. If something becomes available, we'll give you a call. In the meantime, please feel free to check back with us in a week or so, and we'll see if we have any new openings that might work better for you. Is there a best time of day to reach you if an appointment opens up?
    [caller] <351 --> 360> You can try me anytime after 5 PM on weekdays. I appreciate you adding me to the waitlist.
    [office] <361 --> 385> You're welcome, Mr. David. I've added you to our waiting list for evening appointments, noting your preference for after 5 PM on weekdays. We'll do our best to accommodate you as soon as possible. Is there anything else I can help you with today?
    [caller] <385 --> 390> No, that’s all for now. Thank you for your help, Joan.
    [office] <390 --> 410> You're welcome, Mr. David. Again, I apologize for the inconvenience with both the insurance issue and the scheduling. We appreciate your patience. Remember, you'll hear from Sarah about your insurance claim by 5 PM tomorrow, and we'll call you if a suitable appointment opens up. Have a good day, Mr. David.
    [caller] <411 --> 413> Thank you. Goodbye.
    [office] <413 --> 415> Goodbye, Mr. David.
    `,
  };

  const { summary, transcript } = verticalMetadata[vertical as Vertical] ?? defaultMetadata;

  return {
    summary,
    transcript,
    callMetadata: {
      caller: JSON.stringify({
        date_of_birth: '12/13/1986',
        insurance: {
          name: 'Delta Dental of Massachusetts',
          phone: null,
          fax: null,
          email: null,
          insurance_ids: ['111-000-1111'],
        },
        dependents: [],
      }),
      receptionist: JSON.stringify({}),
    },
  };
};

export const generateTasksForCalls = (
  calls: CallIntelTypes.Call[],
  vertical: Vertical | null,
  options?: {
    taskStatusFilters?: TaskStatus[];
    taskTypes?: TaskType[];
  }
): CallIntelTypes.CallTaskListResponse => {
  const tasks = calls.flatMap((call) => {
    const { id: callId, taskTypes: callTaskTypes } = call;

    const filteredTaskTypes = options?.taskTypes?.length
      ? callTaskTypes.filter((type) => options?.taskTypes?.includes(type))
      : callTaskTypes;

    return getRandomTasks(filteredTaskTypes, callId, vertical, options?.taskStatusFilters);
  });

  return { tasks };
};

export const callTaskListResponse = (
  taskCount: number,
  taskTypes: TaskType[],
  callId: string,
  vertical: Vertical | null
): CallIntelTypes.CallTaskListResponse => {
  return {
    tasks: taskCount > 0 ? getRandomTasks(taskTypes, callId, vertical) : [],
  };
};

export const getFilteredCallRecordings = (
  callRecordings: InfiniteData<CallIntelTypes.CallsResponse>,
  filters: CallIntelTypes.Filters
): InfiniteData<CallIntelTypes.CallsResponse> => {
  if (!callRecordings) return callRecordings;

  const filterCall = (call: CallIntelTypes.Call): boolean => {
    const contactType =
      filters?.contactTypes?.length && call.contactType ? filters.contactTypes.includes(call.contactType) : true;

    const officeUsers =
      filters?.officeUsers?.length && call.officeUser?.userId
        ? filters.officeUsers.includes(call.officeUser?.userId)
        : true;

    const schedulingOutcome = filters?.schedulingOutcomes?.length
      ? (filters?.schedulingOutcomes?.includes(CallIntelTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_SCHEDULED) &&
          call.schedulingOutcome === true) ||
        (filters?.schedulingOutcomes?.includes(CallIntelTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_UNSCHEDULED) &&
          call.schedulingOutcome === false)
      : true;

    return contactType && officeUsers && schedulingOutcome;
  };

  return {
    pages: callRecordings.pages.map((page) => ({
      calls: page.calls?.filter(filterCall),
      pageConfig: { ...page.pageConfig },
    })),
    pageParams: callRecordings.pageParams,
  };
};

export const callServiceQualityResponse = (
  serviceQualityFlags: CallIntelTypes.ServiceQualityFlagEnum[],
  vertical: Vertical | null
): CallIntelTypes.ServiceQualityResponse => {
  const issues: CallIntelTypes.ServiceQualityFlagData[] = [];
  const resolution: CallIntelTypes.ServiceQualityFlagData[] = [];

  const verticalDetails = (vertical ? serviceQualityDetailsMapping[vertical] : null) || {
    [CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE]: [
      {
        summary:
          'The caller is experiencing delays in the processing of their insurance claim and has not received the necessary information from the insurance company.',
        tsStart: '00:05:00',
      },
      {
        summary:
          'The caller is unable to find a suitable appointment time due to their work schedule and the limited availability of appointments.',
        tsStart: '05:06:00',
      },
    ],
    [CallIntelTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION]: [
      {
        summary:
          "Joan, the office staff member, addressed the patient's insurance claim issue by resending the information to the insurance company and escalating the case to the office manager, Sarah, for direct follow-up and resolution. Additionally, Joan offered a courtesy credit to the patient's account if the claim wasn't processed within a specific timeframe. Regarding the appointment scheduling issue, Joan added the patient to a waitlist for preferred time slots and offered to call back if an earlier appointment became available.",
        tsStart: '01:00:00',
      },
    ],
  };

  serviceQualityFlags.forEach((flag) => {
    const details = verticalDetails[flag];
    if (!details) return;

    if (flag === CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE) {
      issues.push(...details.map(({ summary, tsStart }) => ({ summary, tsStart })));
    } else if (flag === CallIntelTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION) {
      resolution.push(...details.map(({ summary, tsStart }) => ({ summary, tsStart })));
    }
  });

  return {
    isIssuesDisabled: false,
    isResolutionDisabled: false,
    issues,
    resolution,
  };
};
