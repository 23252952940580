import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Collections_Enum,
  CreateEmailTemplateRequest,
  EmailTemplate,
} from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/email-template/v1/emailtemplate.pb';
import { TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import dayjs from 'dayjs';
import { DeviceSize } from '@frontend/device-size-field';
import { EditableText } from '@frontend/editable-text';
import { EmailPreviewModal, usePreviewModal } from '@frontend/email-preview-modal';
import { EmailThumbnail } from '@frontend/email-thumbnail';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  ListRow,
  NakedButton,
  PopoverMenu,
  Text,
  useDebouncedValue,
  useFormField,
  useModalControl,
  usePopoverMenu,
} from '@frontend/design-system';
import { DeleteTemplateModal } from './delete-template-modal';
import { TemplateActionsMenuOptions } from './types';
import { useEmailTemplates } from './use-email-templates';

const DATE_FORMAT = 'MMMM D, YYYY';
const DATE_FORMAT_WITHOUT_YEAR = 'MMMM D';
const CUSTOM_BORDER_RADIUS = theme.spacing(0.75); // There's a weird gap when the border radius matches the container's border radius

type Props = EmailTemplate & {
  collection?: Collections_Enum;
  onTemplateClick: (isEdit?: boolean) => void;
  onTemplateFetch: () => Promise<EmailTemplate | undefined>;
  selectedLocationIds: string[];
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setTemplates: Dispatch<SetStateAction<EmailTemplate[]>>;
  templates: EmailTemplate[];
};

export const CustomTemplateCard = ({
  id: templateId,
  collection,
  createdAt,
  locationIds: associatedLocationIds, // locationIds associated with the template
  onTemplateClick,
  onTemplateFetch,
  orgId,
  selectedLocationIds,
  setIsLoading,
  setTemplates,
  subject,
  templates,
  thumbnailId,
  thumbnailUrl,
  title,
  updatedAt,
}: Props) => {
  const { t } = useTranslation('bulk-messaging');

  const currentYear = dayjs().year();
  const createdAtYear = dayjs(createdAt).year();
  const updatedAtYear = dayjs(updatedAt).year();

  const dateText =
    updatedAt === createdAt
      ? t('Created {{date}}', {
          date: dayjs(createdAt).format(currentYear === createdAtYear ? DATE_FORMAT_WITHOUT_YEAR : DATE_FORMAT),
        })
      : t('Updated {{date}}', {
          date: dayjs(updatedAt).format(currentYear === updatedAtYear ? DATE_FORMAT_WITHOUT_YEAR : DATE_FORMAT),
        });
  const {
    close: closePopoverMenu,
    getMenuProps,
    getTriggerProps,
  } = usePopoverMenu({
    placement: 'bottom-end',
    middlewareOptions: { offset: 0 },
  });
  const { selectedOrgId: appOrgId } = useAppScopeStore();

  const { createTemplate, deleteTemplate, updateTemplate } = useEmailTemplates();

  const [previewHtml, setPreviewHtml] = useState<string>();
  const { deviceSizeProps, generatePreview, previewModalProps, transformedText } = usePreviewModal(
    previewHtml,
    TemplateType_Slug.BULK_MESSAGE
  );

  useEffect(() => {
    if (transformedText) {
      generatePreview();
      closePopoverMenu();
    }
  }, [transformedText]);

  useEffect(() => {
    if (!previewModalProps.show) setPreviewHtml(undefined);
  }, [previewModalProps.show]);

  const titleFieldProps = useFormField({ type: 'text', value: title, placeholder: t('Enter a title') });
  const debouncedValue = useDebouncedValue(titleFieldProps.value, 1000);

  const handleUpdateTemplate = async () => {
    const template = await onTemplateFetch();
    if (!template) return;
    updateTemplate({
      templateId,
      title: debouncedValue,
      subject: template?.subject,
      templateHtml: template?.templateHtml,
      templateJson: template?.templateJson,
    });
    setTemplates((prev) =>
      prev.map((prevTemplate) =>
        prevTemplate.id === templateId ? { ...prevTemplate, title: debouncedValue } : prevTemplate
      )
    );
  };

  useEffect(() => {
    if (debouncedValue && debouncedValue !== title) {
      handleUpdateTemplate();
    }
  }, [debouncedValue]);

  const { modalProps: deleteTemplateModalProps, triggerProps: deleteTemplateTriggerProps } = useModalControl();

  const actions: TemplateActionsMenuOptions[] = [
    {
      Icon: <Icon name='view' />,
      label: t('Preview'),
      onClick: async () => {
        const template = await onTemplateFetch();
        if (!template) return;
        setPreviewHtml(template?.templateHtml);
      },
      trackingId: `${BulkEmailPrefixes.Templates}-custom-preview-btn`,
    },
    {
      Icon: <Icon name='edit' />,
      label: t('Edit Template'),
      onClick: () => onTemplateClick(true),
      trackingId: `${BulkEmailPrefixes.Templates}-custom-edit-btn`,
    },
    {
      Icon: <Icon name='collapse-right' />,
      label: t('Continue With This Template'),
      onClick: () => onTemplateClick(),
      trackingId: `${BulkEmailPrefixes.Templates}-custom-continue-btn`,
    },
    {
      Icon: <Icon name='copy' />,
      label: t('Duplicate Template'),
      onClick: async () => {
        setIsLoading(true);
        const template = await onTemplateFetch();
        if (!template) return;
        closePopoverMenu();
        let count = 2;
        const checkTitleExists = (proposedTitle: string) =>
          templates.find((template) => template.title === proposedTitle);

        let proposedTitle = t('Copy of {{title}}', { title });
        while (checkTitleExists(proposedTitle)) {
          proposedTitle = t('Copy of {{title}}', { title: `${title} (${count})` });
          count++;
        }

        const locationIds =
          associatedLocationIds && associatedLocationIds.length ? associatedLocationIds : selectedLocationIds;

        const newTemplate: CreateEmailTemplateRequest = {
          collection,
          orgId: orgId ?? appOrgId,
          locationIds,
          title: proposedTitle,
          subject,
          templateHtml: template?.templateHtml,
          templateJson: template?.templateJson,
        };

        // instead of updating query cache because of pagination, we'll just add the new template to the local state and refetch the
        const res = await createTemplate(newTemplate);
        setTemplates((prev) => [{ id: res.templateId, ...newTemplate, thumbnailUrl, thumbnailId }, ...prev]);
        setIsLoading(false);
      },
      trackingId: `${BulkEmailPrefixes.Templates}-custom-duplicate-btn`,
    },
    {
      Icon: <Icon name='trash' color='error' {...deleteTemplateTriggerProps} />,
      label: t('Delete Template'),
      onClick: () => {
        closePopoverMenu();
        deleteTemplateTriggerProps.onClick();
      },
      textColor: 'error',
      trackingId: `${BulkEmailPrefixes.Templates}-custom-delete-btn`,
    },
  ];

  return (
    <>
      <div
        css={{
          border: `2px solid ${theme.colors.neutral20}`,
          borderRadius: theme.borderRadius.medium,
          position: 'relative',
          cursor: 'pointer',
          '&:hover': {
            border: `2px solid ${theme.colors.primary50}`,
          },
        }}
      >
        <div
          onClick={() => onTemplateClick()}
          css={{
            background: `linear-gradient(180deg, ${theme.colors.primary5} 0%, ${theme.colors.white} 100%)`,
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            padding: theme.spacing(2),
            borderRadius: CUSTOM_BORDER_RADIUS,
          }}
          data-trackingid={`${BulkEmailPrefixes.Templates}-custom-template-card-btn`}
        >
          <EmailThumbnail src={thumbnailUrl} alt={t('Custom Template')} />
        </div>
        <div
          css={{
            position: 'absolute',
            bottom: 0,
            background: 'white',
            padding: theme.spacing(2),
            borderTop: `2px solid ${theme.colors.neutral20}`,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            rowGap: theme.spacing(1.5),
            borderBottomLeftRadius: theme.borderRadius.medium,
            borderBottomRightRadius: theme.borderRadius.medium,
            cursor: 'pointer',
          }}
        >
          <EditableText
            fieldProps={titleFieldProps}
            hideUntilHovered
            css={{
              'span, input': {
                fontSize: theme.fontSize(16),
                height: 'auto',
                paddingLeft: 0,
              },
            }}
          />
          <div
            css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            onClick={() => onTemplateClick()}
          >
            <Text color='light' size='small'>
              {dateText}
            </Text>
            <NakedButton {...getTriggerProps()}>
              <Icon name='more' />
            </NakedButton>
          </div>
        </div>
        <PopoverMenu {...getMenuProps()}>
          {actions.map(({ Icon, label, onClick, textColor = 'default', trackingId }, index) => (
            <ListRow
              key={index}
              css={{
                border: 'unset',
                display: 'flex',
                columnGap: theme.spacing(1),
                padding: theme.spacing(1, 2),
                cursor: 'pointer',
              }}
              onClick={onClick}
              trackingId={trackingId}
            >
              {Icon}
              <Text color={textColor}>{label}</Text>
            </ListRow>
          ))}
        </PopoverMenu>
        <DeleteTemplateModal
          modalProps={deleteTemplateModalProps}
          onDelete={() => {
            if (templateId) {
              deleteTemplate({ templateId });
              setTemplates((prev) => prev.filter((template) => template.id !== templateId));
              deleteTemplateModalProps.onClose();
            }
          }}
        />
      </div>
      {previewModalProps.show && (
        <EmailPreviewModal
          deviceSizeProps={{ ...deviceSizeProps, value: deviceSizeProps?.value as DeviceSize }}
          modalProps={previewModalProps}
          previewHtml={transformedText}
          setPreviewHtml={setPreviewHtml}
        />
      )}
    </>
  );
};
