import { useQuery } from 'react-query';
import { queryKeys } from './query-keys';
import { ManualTemplateRequest } from './types';
import { ManualTemplatesApi } from '.';

export const useGetManualTemplateByType = (request: ManualTemplateRequest) => {
  return useQuery({
    queryKey: queryKeys.manualTemplateByType(request),
    queryFn: () => ManualTemplatesApi.getManualTemplateByType(request.templateType, request.locationId),
    enabled: !!request.locationId && !!request.templateType,
    retry: 1,
  });
};
