import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
`;

export const inputFieldContainerStyle = css`
  flex: 1;
`;

export const inputFieldStyle = css`
  border: none;
  border-bottom: 1px solid ${theme.colors.neutral40};
  border-radius: 0;
  padding: 0;
  height: auto;
  padding-bottom: ${theme.spacing(0.5)};

  &:has(:is(input, textarea):focus) {
    box-shadow: none;
    border-color: ${theme.colors.primary50};
    border-bottom-width: 2px;
  }
`;
