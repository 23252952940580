import { css } from '@emotion/react';
import { ScheduleRequest } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import {
  Heading,
  MessageTemplate,
  Text,
  TagType,
  useFormField,
  DropdownField,
  FormRow,
  Chip,
  Button,
  DatePickerField,
  TimeField,
  numeric,
} from '@frontend/design-system';
import { defaultTemplate } from '../../views/Calendar/EventsPanel/ScheduleActions/ScheduleRequests/constant';
import { ReadOnlyField } from './ReadOnlyField';

type ReadOnlyReviewAppointmentRequestFormProps = {
  requestDetails: ScheduleRequest;
  locationName: string;
  providerName: string;
  requestedDateTimes: string[];
  requestedDateTimeProps: ReturnType<typeof useFormField<'dropdown'>>;
  hasCustomDateTimeFields: boolean;
  setHasCustomDateTimeFields: (value: boolean) => void;
  customDateField: ReturnType<typeof useFormField<'date'>>;
  customTimeField: ReturnType<typeof useFormField<'time'>>;
  template: string;
  templateTags: TagType[];
  onTemplateChange: (template: string) => void;
  isReadOnlyTemplate: boolean;
  isPaymentsEnabled: boolean;
  paidAmount?: number;
};

export const ReadOnlyReviewAppointmentRequestForm = ({
  requestDetails,
  locationName,
  providerName,
  requestedDateTimes,
  requestedDateTimeProps,
  hasCustomDateTimeFields,
  setHasCustomDateTimeFields,
  customDateField,
  customTimeField,
  template,
  templateTags,
  onTemplateChange,
  isReadOnlyTemplate,
  isPaymentsEnabled,
  paidAmount,
}: ReadOnlyReviewAppointmentRequestFormProps) => {
  const { t } = useTranslation('scheduleCalendarRequest');

  const isVerySmallDevice = useMatchMedia({ maxWidth: breakpoints.xsmall.max });

  return (
    <section css={{ backgroundColor: theme.colors.neutral5 }}>
      <section
        css={{
          backgroundColor: theme.colors.white,
          margin: theme.spacing(4),
          padding: theme.spacing(3),
          borderRadius: theme.borderRadius.medium,
          boxShadow: theme.shadows.light,
        }}
      >
        <Heading level={3} css={{ marginBottom: theme.spacing(2) }}>
          {t('Request Details')}
        </Heading>
        <div css={scheduleRequestGridStyles(isVerySmallDevice)}>
          <ReadOnlyField label={t('First Name')} value={requestDetails.schedulee?.firstName} />
          <ReadOnlyField label={t('Last Name')} value={requestDetails.schedulee?.lastName} />
          <ReadOnlyField label={t('Phone Number')} value={formatPhoneNumber(requestDetails.schedulee?.phoneNumber)} />
          <ReadOnlyField label={t('Email')} value={requestDetails.schedulee?.email} />
          {!!requestDetails.schedulee?.birthDate && (
            <ReadOnlyField
              label={t('Date of Birth')}
              value={formatDate(requestDetails.schedulee?.birthDate, 'MM/DD/YYYY')}
            />
          )}
          {!!requestDetails.schedulee?.gender && (
            <ReadOnlyField
              label={t('Gender')}
              value={requestDetails.schedulee?.gender}
              css={{ textTransform: 'capitalize' }}
            />
          )}
          <ReadOnlyField label={t('Appointment Type')} value={requestDetails.appointmentType} />
          <ReadOnlyField label={t('Provider')} value={providerName} />
          {isPaymentsEnabled && (
            <ReadOnlyField
              label={t('Paid')}
              value={
                !!paidAmount ? (
                  <Chip variant='warn' leftElement={<Icon name='dollar-sign-small' />}>
                    {numeric(paidAmount, { decimals: 2 })}
                  </Chip>
                ) : (
                  <>-</>
                )
              }
            />
          )}
        </div>
        <ReadOnlyField
          label={t('Location')}
          value={
            <Chip.Location variant='neutral' css={{ maxWidth: '100%' }}>
              {locationName}
            </Chip.Location>
          }
          css={{ width: '100%', marginBottom: theme.spacing(3), marginTop: theme.spacing(2) }}
        />
        <div css={scheduleRequestGridStyles(isVerySmallDevice)}>
          <div css={{ display: 'flex', flexDirection: 'column' }}>
            <Text size='small' color='subdued'>
              {t('Requested Date')}
            </Text>
            {requestedDateTimes?.map((dateTime) => (
              <Text key={dateTime}>{formatDate(dateTime, 'MMMM D, YYYY')}</Text>
            ))}
          </div>
          <div css={{ display: 'flex', flexDirection: 'column' }}>
            <Text size='small' color='subdued'>
              {t('Requested Time')}
            </Text>
            {requestedDateTimes?.map((dateTime) => (
              <Text key={dateTime}>{formatDate(dateTime, 'h:mm a')}</Text>
            ))}
          </div>
        </div>

        <form>
          <FormRow cols={[100]} css={{ marginTop: theme.spacing(3) }}>
            <DropdownField
              {...requestedDateTimeProps}
              label={t('Select Date & Time')}
              name='datetime'
              placeholder={t('Select Date & Time')}
              onBlur={(e) => {
                e?.preventDefault();
                setHasCustomDateTimeFields(false);
              }}
            >
              {requestedDateTimes?.map((dateTime) => (
                <DropdownField.Option key={dateTime} value={dateTime}>
                  {formatDate(dateTime, 'MMMM D, h:mm a')}
                </DropdownField.Option>
              ))}
            </DropdownField>
          </FormRow>
          {!hasCustomDateTimeFields ? (
            <Button variant='tertiary' iconName='plus' onClick={() => setHasCustomDateTimeFields(true)}>
              {t('Custom Date & Time')}
            </Button>
          ) : (
            <>
              <FormRow cols={[50, 50]} css={{ marginBottom: 0 }}>
                <DatePickerField name='customDate' label='Custom Date' {...customDateField} />
                <TimeField name='customTime' label='Custom Time' {...customTimeField} />
              </FormRow>
            </>
          )}
        </form>
      </section>
      <section
        css={{
          backgroundColor: theme.colors.white,
          margin: theme.spacing(4),
          padding: theme.spacing(3),
          borderRadius: theme.borderRadius.medium,
          boxShadow: theme.shadows.light,
        }}
      >
        <Heading level={3} css={{ marginBottom: theme.spacing(2) }}>
          {t('Approval Message')}
        </Heading>
        <MessageTemplate
          initialTemplate={template || defaultTemplate}
          tags={templateTags}
          draggableTags={true}
          onChange={onTemplateChange}
          readOnly={isReadOnlyTemplate}
        >
          <MessageTemplate.Editor />
          <Text weight='light' color='light' css={{ marginBottom: theme.spacing(1), marginTop: theme.spacing(1) }}>
            {t('You can also drag and drop, or click a tag below, to add your message')}
          </Text>
          <MessageTemplate.TagList />
        </MessageTemplate>
      </section>
    </section>
  );
};

const scheduleRequestGridStyles = (isVerySmallDevice: boolean) =>
  css({
    display: 'grid',
    gap: theme.spacing(2),
    gridTemplateColumns: isVerySmallDevice ? '100%' : '50% 50%',
  });
