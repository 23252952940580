import { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { useSearch } from '@tanstack/react-location';
import { useTranslation } from '@frontend/i18n';
import { Page } from '@frontend/page';
import { useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { BreadcrumbPathProps } from '@frontend/design-system';
import { URLs } from '../../../../constants';
import { featureFlags } from '../../../../feature-flags';
import { DemoDataBanner } from '../../../demo-data-banner';
import {
  MessagingCharts,
  MessagingMultiSubView,
  MessagingSingleSubView,
  MessagingSubView,
} from '../../components/messaging-roi';
import { customROITimePeriods, REVENUE_MULTIPLIER } from '../../constants';
import { messagingOverviewFormatter } from '../../helpers';
import { useFetchMessagingROIOOverview } from '../../hooks';
import { MessagingOverview, MessagingRoi } from '../../types';
import { getDateRangeText } from '../../utils/date-range-text';
import { BTRoiScheduleRateChart } from '../charts';
import { BTSummary, Filters, HighlightBTValue } from '../components';
import { useBTRoiShallowStore } from '../hooks';

export const BTRoiDashboard = () => {
  const { t } = useTranslation('analytics');
  const {
    definitionsHelperDateRange,
    filterHintText: subtitle,
    filters,
    setDefinitionsHelperDateRange,
  } = useBTRoiShallowStore('definitionsHelperDateRange', 'filterHintText', 'filters', 'setDefinitionsHelperDateRange');

  const search = useSearch<{ Search: { view: MessagingRoi } }>();
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isDemoAccount = getFeatureFlagValue(featureFlags.bulkTextRoiDemoData);

  const subViewTitle = useMemo(() => {
    let title = '';

    switch (search.view) {
      case 'leadsContacted':
        title = t('Patients Contacted');
        break;

      case 'patientsScheduled':
        title = t('Patients Scheduled');
        break;

      case 'revenueGenerated':
        title = t('Production Value');
        break;
    }

    return title;
  }, [search.view]);

  const breadcrumbs: BreadcrumbPathProps[] = useMemo(() => {
    const values: BreadcrumbPathProps[] = [
      {
        label: t('Analytics'),
        to: URLs.ANALYTICS_DASHBOARD,
      },
      {
        label: t('Bulk Texting'),
        to: URLs.ROI_BULK_TEXT,
      },
    ];

    if (subViewTitle) {
      values.push({
        label: subViewTitle,
      });
    }

    return values;
  }, [subViewTitle]);

  const overviewFetchProps = useFetchMessagingROIOOverview({
    apiParams: {
      messagingCategory: 'bulkmessaging',
      payload: {
        end_date: filters?.endDate,
        location_id: filters?.locationIds,
        start_date: filters?.startDate,
      },
    },
    isDemoAccount,
  });

  const { aggregated, isLoading } = overviewFetchProps;

  const overviewData: MessagingOverview = useMemo(() => {
    return messagingOverviewFormatter(aggregated);
  }, [aggregated]);

  useEffect(() => {
    setDefinitionsHelperDateRange(
      getDateRangeText({
        startDate: filters?.startDate,
        endDate: filters?.endDate,
        timePeriods: customROITimePeriods,
      })
    );
  }, [filters?.startDate, filters?.endDate]);

  return (
    <>
      {isDemoAccount && (
        <DemoDataBanner title={t("You're taking Bulk Texting Conversion for a test drive with demo data!")} />
      )}
      <Page>
        <Page.Header>
          <Page.Header.Breadcrumbs breadcrumbs={breadcrumbs} />
          <Page.Header.Heading>
            <Page.Header.Title title={subViewTitle || t('Bulk Texting')} />
            <Page.Header.Subtitle subtitle={t('Showing results for {{subtitle}}', { subtitle })} />
          </Page.Header.Heading>
        </Page.Header>

        <Page.Body css={styles.body}>
          <div css={styles.body}>
            <Filters isLoading={isLoading} />

            {subViewTitle ? (
              <MessagingSubView filters={filters} isDemoAccount={isDemoAccount} messagingCategory='bulkmessaging'>
                {(filters?.locationIds?.length || 0) > 1 ? (
                  <MessagingMultiSubView {...overviewFetchProps} overviewData={overviewData} />
                ) : (
                  <MessagingSingleSubView
                    dateRangeHelperText={definitionsHelperDateRange}
                    filters={filters}
                    isDemoAccount={isDemoAccount}
                    isLoading={isLoading}
                    overviewData={overviewData}
                    subViewUrl={URLs.ROI_BULK_TEXT}
                  />
                )}
              </MessagingSubView>
            ) : (
              <>
                <HighlightBTValue value={overviewData.revenueGenerated.totalCompletedAppts * REVENUE_MULTIPLIER} />
                <BTSummary data={overviewData} />
                <MessagingCharts
                  data={overviewData}
                  dateRangeHelperText={definitionsHelperDateRange}
                  filteredLocationIds={filters?.locationIds ?? []}
                  isDemoAccount={isDemoAccount}
                  isLoading={isLoading}
                  subViewUrl={URLs.ROI_BULK_TEXT}
                />
                <BTRoiScheduleRateChart />
              </>
            )}
          </div>
        </Page.Body>
      </Page>
    </>
  );
};

const styles = {
  body: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(3)};
  `,
};
