import { useTranslation } from '@frontend/i18n';
import { TextField, useFormField } from '@frontend/design-system';
import { PhoneHoursForm, usePhoneHoursForm } from '../../../phone-hours/phone-hours-form';
import { AddStepPanelProps, ButtonBar, HeaderBar } from './add-step-panel';

export const PhoneHoursStep = ({ initialState, onClose, onProceedClick, onBackClick }: AddStepPanelProps) => {
  const { t } = useTranslation('phone');

  const fieldProps = useFormField({
    required: true,
    type: 'text',
    value: initialState?.callObject?.primitiveName,
  });

  const { formProps, getPhoneHours } = usePhoneHoursForm(
    // @ts-expect-error type doesn't know that this is a phoneHours type node
    initialState?.callObject?.phoneHoursExpansion?.phoneHours ?? []
  );

  const handleProceedClick = () => {
    const phoneHours = getPhoneHours();

    onProceedClick({
      callObject: {
        primitiveId: initialState?.callObject.primitiveId ?? '',
        primitiveName: fieldProps.value,
        instructionId: initialState?.callObject.instructionId ?? '',
        instructionSetId: initialState?.callObject.instructionSetId ?? '',
        phoneHoursExpansion: {
          phoneHours,
        },
      },
    });
  };

  return (
    <>
      <HeaderBar title={t('Phone Hours')} onClose={onClose} />

      <TextField {...fieldProps} name='phoneHours' label={t('Phone hours Name')} />

      <PhoneHoursForm {...formProps} routingActionsEnabled />

      <ButtonBar
        primaryButtonLabel={t('Done')}
        primaryButtonDisabled={!fieldProps.value}
        backButtonLabel={initialState?.callObject.primitiveId ? t('Change Step') : t('Back')}
        onPrimaryButtonClick={handleProceedClick}
        onCancelClick={onClose}
        onBackClick={onBackClick}
      />
    </>
  );
};
