import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import Badge, { BadgeProps } from './badge';

type BadgeBannerProps = { description: string } & BadgeProps;

const BadgeBanner = ({ description, ...rest }: BadgeBannerProps) => {
  return (
    <div css={styles.wrapper}>
      <Badge {...rest} />
      <Text size='medium'>{description}</Text>
    </div>
  );
};

export default BadgeBanner;

const styles = {
  wrapper: css`
    display: flex;
    gap: ${theme.spacing(2)};
    align-items: center;
    border: 1px solid ${theme.colors.secondary.seaweed50};
    padding: ${theme.spacing(2)};
    border-radius: ${theme.borderRadius.medium};
    background-color: ${theme.colors.secondary.seaweed5};
    width: fit-content;
  `,
};
