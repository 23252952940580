import { CallIntelligenceService } from '@weave/schema-gen-ts/dist/schemas/call-intelligence/v2/call_intelligence.pb';
import { TaskService } from '@weave/schema-gen-ts/dist/schemas/task-center/v1/task_service.pb';
import { QueryKey } from 'react-query';
import { bindHTTP } from '@frontend/schema';

export const SchemaCallIntelV2Service = bindHTTP(CallIntelligenceService);
export const serviceV2Key: QueryKey = ['call-intel-v2'];
export const serviceName = 'call-intel-v2' as const;

export const SchemaTaskService = bindHTTP(TaskService);
export const taskServiceKey: QueryKey = ['task-service-v1'];
export const taskServiceName = 'task-service-v1' as const;
