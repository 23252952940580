import { useCallback } from 'react';
import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { RelatedType } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { ManualSmsScheduledV1 } from '@frontend/api-manual-scheduled-sms';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { SMSSendV3 } from '@frontend/api-sms-send';
import { getUser } from '@frontend/auth-helpers';
import { useMessageAction } from '@frontend/contact-actions-send-message';
import { useTranslation } from '@frontend/i18n';
import { MiniChatHooks } from '@frontend/mini-chat';
import { useSmsMessageNotification } from '@frontend/notifications';
import { ThreadSendingAreaStores, ThreadSendingAreaUtils } from '@frontend/thread-sending-area';
import { GetWeavePopNotificationByType } from '@frontend/types';
import { GetWebsocketEventHandler, useWebsocketEventSubscription } from '@frontend/websocket';

/**
 * A hook that returns a function to execute when a SMSNotification event is received.
 * This doesn't have any WebSocket subscription logic, just the handler. Useful for testing.
 */
export const useSMSDataV3WSHandler = () => {
  const { t } = useTranslation('notifications');
  const user = getUser();
  const sendMutation = SMSSendV3.Mutations.useSendMutation();
  const unknownNumberString = t('Unknown');
  const { onClick: onView } = useMessageAction();
  const { create: createNotification } = useSmsMessageNotification({
    onRespond: (notification, response) => {
      return sendMutation.mutateAsync({
        locationId: notification.payload.locationId,
        personPhone: notification.payload.number,
        body: response,
        media: [],
        programSlugId: 'manual-messages',
        personId: notification.payload.personId,
        createdBy: user?.userID,
        departmentId: notification.payload.departmentId,
        messageType: ThreadSendingAreaUtils.getMessageTypeFromRelatedIds([]),
      });
    },
    onView: (notification) => {
      onView({
        threadId: notification.payload.threadId,
        threadGroupId: notification.payload.locationId,
        personPhone: notification.payload.number === unknownNumberString ? undefined : notification.payload.number,
        personId: notification.payload.personId,
        departmentId: notification.payload.departmentId,
        groupIds: [notification.payload.locationId],
      });
    },
  });

  const { upsertSMS, updateSMS, updateThread, updateThreadsArchivedStatus } =
    SMSDataV3._QueryUpdaters.useQueryUpdaters();
  const { updateScheduledSms } = ManualSmsScheduledV1._QueryUpdaters.useQueryUpdaters();
  const { setThreadTypingStatus } = ThreadSendingAreaStores.useTypingIndicatorStore();
  const { incrementThreadUnreadCount, resetThreadUnreadCount } = MiniChatHooks.useMiniChatShallowStore(
    'incrementThreadUnreadCount',
    'resetThreadUnreadCount'
  );

  const wsEventHandler = useCallback<GetWebsocketEventHandler<'SMSNotification'>>(
    (event) => {
      const { params } = event;

      switch (params.action) {
        case 'inbound':
          createNotification({
            id: String(event.id),
            type: 'sms-message-new',
            payload: {
              body: params.body,
              number: params.patient_number || params.phone_mobile || unknownNumberString,
              personId: params.person_id,
              threadId: params.thread_ids[0] || '',
              departmentId: params.department_id,
              locationId: params.location_id,
              firstName: params.first_name,
              lastName: params.last_name,
            },
            timestamp: Date.now(),
            state: {
              paused: false,
              status: 'unread',
            },
            flags: {
              shouldAllowReply: true,
            },
          } satisfies GetWeavePopNotificationByType<'sms-message-new'>);
          upsertSMS({
            sms: SMSDataV3.Utils.convertWSPayloadToSMS(params),
          });
          incrementThreadUnreadCount(params.thread_ids[0]);
          break;
        case 'outbound':
          upsertSMS({
            sms: SMSDataV3.Utils.convertWSPayloadToSMS(params),
          });

          // Update scheduled messages that have been sent in cache
          params.related_ids?.forEach((relatedId) => {
            if (relatedId.type !== RelatedType.RELATED_TYPE_SCHEDULED_MESSAGE_ID) return;
            const newSms = SMSDataV3.Utils.convertWSPayloadToSMS(params);
            updateScheduledSms({
              matchValues: {
                id: relatedId.id,
                locationId: newSms.locationId,
                threadId: newSms.threadId,
              },
              newValues: {
                status: OutboundMessageStatus.SENT,
              },
            });
          });
          break;
        case 'status':
          updateSMS({
            matchValues: {
              id: params.uuid,
              locationId: params.location_id,
            },
            newValues: {
              status: SMSDataV3.Utils.convertStringToStatus(params.status),
            },
          });
          break;
        case 'smsTagApplied':
          updateSMS({
            matchValues: {
              id: params.sms_id,
              threadId: params.thread_ids?.length ? params.thread_ids[0] : undefined,
              locationId: params.location_id,
            },
            newValues: (oldSms) => {
              const newTags = Array.from(new Set([...oldSms.tags, params.tag_id]));
              return {
                ...oldSms,
                tags: newTags,
                tagsDetailed: newTags.map((tagId) => ({
                  tagId,
                  smsId: oldSms.id,
                  smsCreatedAt: oldSms.createdAt,
                  appliedBy: params.weave_user_id,
                })),
              };
            },
          });
          break;
        case 'smsTagDismissed':
          updateSMS({
            matchValues: {
              id: params.sms_id,
              threadId: params.thread_ids?.length ? params.thread_ids[0] : undefined,
              locationId: params.location_id,
            },
            newValues: (oldSms) => ({
              ...oldSms,
              tags: oldSms.tags.filter((tagId) => tagId !== params.tag_id),
              tagsDetailed: oldSms.tagsDetailed?.filter(({ tagId }) => tagId !== params.tag_id),
            }),
          });
          break;
        case 'archived':
        case 'unarchived':
          if (params.thread_ids?.length && !!params.location_id) {
            updateThreadsArchivedStatus({
              threadIds: params.thread_ids,
              locationId: params.location_id,
              groupIds: [params.location_id],
              archived: params.action === 'archived',
            });
          }
          break;
        case 'bulkMarkRead':
        case 'markedRead':
          params.thread_ids?.forEach((threadId) => {
            updateThread({
              matchValues: {
                id: threadId,
                locationId: params.location_id,
              },
              newValues: {
                status: 'read',
              },
            });
            resetThreadUnreadCount(threadId);
          });
          break;
        case 'bulkMarkUnread':
        case 'markedUnread':
          params.thread_ids?.forEach((threadId) =>
            updateThread({
              matchValues: {
                id: threadId,
                locationId: params.location_id,
              },
              newValues: {
                status: 'new',
              },
            })
          );
          break;
        case 'readStatusChange':
          params.thread_ids?.forEach((threadId) => {
            updateThread({
              matchValues: {
                id: threadId,
                locationId: params.location_id,
              },
              newValues: {
                status: params.status,
              },
            });
            if (params.status === 'read') {
              resetThreadUnreadCount(threadId);
            }
          });
          break;
        case 'inboundThreadUpdate':
          upsertSMS({
            sms: SMSDataV3.Utils.convertWSPayloadToSMS(params),
          });
          break;
        case 'typing':
          if (user?.userID && params.weave_user_id === user?.userID) break;
          setThreadTypingStatus({
            locationId: params.location_id,
            threadId: params.thread_id,
            userId: params.weave_user_id,
            firstName: params.first_name,
            lastName: params.last_name,
            isTyping: params.is_typing,
          });
          break;
      }
    },
    [
      createNotification,
      upsertSMS,
      SMSDataV3.Utils.convertWSPayloadToSMS,
      updateSMS,
      SMSDataV3.Utils.convertStringToStatus,
      updateThread,
      setThreadTypingStatus,
      user?.userID,
    ]
  );

  return wsEventHandler;
};

export const useSMSDataV3WSSubscription = () => {
  const handler = useSMSDataV3WSHandler();
  return useWebsocketEventSubscription('SMSNotification', handler);
};
