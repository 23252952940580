import React from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export type BadgeProps = {
  label: string;
  animated?: boolean;
} & ExtendedCssProps;

type ExtendedCssProps = {
  animationSpeed?: number;
  bgColor?: string;
  blurIntensity?: string;
  highlightColor?: string;
  primaryColor?: string;
  textColor?: string;
};

const Badge: React.FC<BadgeProps> = ({
  animated = true,
  animationSpeed = 1.8,
  bgColor = theme.colors.secondary.seaweed50,
  blurIntensity = theme.spacing(2),
  highlightColor = theme.colors.secondary.seaweed20,
  label,
  primaryColor = theme.colors.secondary.seaweed70,
  textColor = theme.colors.white,
}) => {
  return animated ? (
    <div css={styles.animatedBadge({ animationSpeed, blurIntensity, highlightColor, primaryColor, textColor })}>
      <span css={styles.animatedBadgeContent({ bgColor, blurIntensity, textColor })}>{label}</span>
    </div>
  ) : (
    <span css={styles.badgeContent({ bgColor, textColor })}>{label}</span>
  );
};

export default Badge;

const styles = {
  animatedBadge: ({ animationSpeed, blurIntensity, highlightColor, primaryColor, textColor }: ExtendedCssProps) => css`
    padding: ${theme.spacing(0.25, 1)};
    height: ${theme.spacing(3)};
    width: fit-content;
    color: ${textColor};
    font-size: ${theme.fontSize(14)};
    border-radius: ${theme.borderRadius.small};
    overflow: hidden;
    position: relative;
    padding: ${theme.spacing(0, 0.125)};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: ${theme.font.weight.regular};
    flex-shrink: 0;

    @property --animate-duration {
      syntax: '<angle>';
      initial-value: 0deg;
      inherits: false;
    }

    @keyframes rotate {
      0% {
        --animate-duration: 0deg;
      }
      100% {
        --animate-duration: 360deg;
      }
    }

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0px;
      left: 0;
      width: 100%;
      overflow: hidden;
      height: 100%;
      border-radius: ${theme.borderRadius.small};
      background-size: 100% 100%;
      background-position: 0px 0px;
      background-image: conic-gradient(
        from var(--animate-duration, 0deg) at 50% 50%,
        ${highlightColor} 8%,
        ${primaryColor} 20%,
        ${primaryColor} 80%,
        ${highlightColor} 92%,
        ${highlightColor} 100%
      );
      animation: rotate ${animationSpeed}s linear infinite;
    }
    &::before {
      animation: rotate ${animationSpeed}s infinite linear;
      filter: blur(${blurIntensity});
    }
  `,
  animatedBadgeContent: ({ bgColor, blurIntensity, textColor }: ExtendedCssProps) => css`
    position: relative;
    z-index: 1;
    height: ${theme.spacing(2.75)};
    background-color: ${bgColor};
    border-radius: ${theme.borderRadius.small};
    color: ${textColor};
    font-size: ${theme.fontSize(14)};
    border-radius: ${theme.borderRadius.small};
    text-align: center;
    white-space: nowrap;
    backdrop-filter: blur(${blurIntensity});
    font-weight: ${theme.font.weight.regular};
    padding: ${theme.spacing(0, 0.875)};
    display: inline-flex;
    align-items: center;
    width: fit-content;
  `,
  badgeContent: ({ bgColor, textColor }: ExtendedCssProps) => css`
    height: ${theme.spacing(3)};
    background-color: ${bgColor};
    border-radius: ${theme.borderRadius.small};
    color: ${textColor};
    font-size: ${theme.fontSize(14)};
    border-radius: ${theme.borderRadius.small};
    text-align: center;
    white-space: nowrap;
    font-weight: ${theme.font.weight.regular};
    padding: ${theme.spacing(0.25, 1)};
    width: fit-content;
    flex-shrink: 0;
  `,
};
