import { useAppScopeStore } from '@frontend/scope';
import { useIsIntegratedOffice } from '../../../hooks';

export const useHasAppointmentAnalyticsFeature = () => {
  const { isIntegratedOffice } = useIsIntegratedOffice();
  const { scopeHierarchy, selectedOrgId } = useAppScopeStore();

  const scoppedLocationIds = Object.values(scopeHierarchy?.[selectedOrgId] ?? {}).flat();

  if (scoppedLocationIds.length > 1) {
    // Appointment analytics is not supported for:
    // 1. multi-locations and
    // 2. multiple single locations within the same org
    return false;
  }

  return !!isIntegratedOffice;
};
