import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.colors.primary30};
  border-radius: ${theme.borderRadius.medium};
  background-color: ${theme.colors.primary5};
  min-height: 140px;
  height: fit-content;
  padding: ${theme.spacing(2)};
`;

export const titleStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing(0.5)};
`;

export const dropZoneStyle = css`
  margin-top: ${theme.spacing(1)};
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed ${theme.colors.primary40};
  border-radius: ${theme.borderRadius.medium};
  background-color: ${theme.colors.white};
  cursor: pointer;
  padding: ${theme.spacing(1)};
`;
