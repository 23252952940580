/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useRef } from 'react';
import { TeamChatSubscriptions } from '@frontend/api-team-chat';
import { useActiveConversationSelector } from '../../active-conversation.provider/active-conversation.provider';
import { useTeamChatApi } from '../team-chat-api-providers/use-team-chat-api';
import { useTeamChatSelector } from '../team-chat.provider';

type ChannelSubscriptionHandler = Parameters<typeof TeamChatSubscriptions.subscribeToChannel>[2];

export const _useConversationSubscriptions = (activeConversationId: string | undefined) => {
  const api = useTeamChatSelector((ctx) => ctx.api);
  const user = useTeamChatSelector((ctx) => ctx.user);
  const refetchConversations = useTeamChatSelector((ctx) => ctx.refetchConversations);
  const isAsleep = useTeamChatSelector((ctx) => ctx.isAsleep);
  const _cache = useTeamChatSelector((ctx) => ctx.cache);
  const _conversations = useTeamChatSelector((ctx) => ctx.conversations);
  const _isFocused = useTeamChatSelector((ctx) => ctx.isFocused);
  const _messagesCache = useActiveConversationSelector((ctx) => ctx.cache);
  const refetchMessages = useActiveConversationSelector((ctx) => ctx.refetchMessages);
  const sendConversationEvent = useActiveConversationSelector((ctx) => ctx.sendConversationEvent);
  const { subscribeToConversation } = useTeamChatApi(api);

  //Using refs to avoid unusb/resubscribing every time these change
  const refs = {
    conversations: useStableRef(_conversations),
    cache: useStableRef(_cache),
    isFocused: useStableRef(_isFocused),
    messagesCache: useStableRef(_messagesCache),
    activeConversationId: useStableRef(activeConversationId),
  };

  const conversationsCount =
    (refs.conversations.current?.dm.length ?? 0) + (refs.conversations.current?.groups.length ?? 0);

  useEffect(() => {
    if (!user) {
      return;
    }
    if (isAsleep) {
      return;
    }

    if (conversationsCount === 0) {
      return;
    }

    /**
     *
     * For a full list of events, look here https://getstream.io/chat/docs/javascript/event_object/
     */
    const handler: ChannelSubscriptionHandler = (event, payload) => {
      try {
        const { message, user, conversation, currentUser } = payload;
        switch (event) {
          case 'notification.thread_message_new':
            if (!message) {
              return;
            }
            refs.messagesCache.current.addReply(message);
            break;
          case 'notification.message_new':
          case 'message.new':
            {
              if (!message) {
                return;
              }
              const isReply = message.type === 'reply';
              const isActiveConversation = refs.activeConversationId.current === message.channelId;
              if (!isReply) {
                if (isActiveConversation) {
                  refs.messagesCache.current.addMessage(message);
                  //If it's the current conversation, we want to make sure it doesn't show up as an unread message, so we mark it as 'read'
                  sendConversationEvent({ conversationId: message.channelId, event: 'read' });
                } else {
                  refs.cache.current.invalidateMessagesCache(message.channelId);
                }
                refs.cache.current.updateConversation(message.channelId, (conversation) => {
                  return {
                    ...conversation,
                    lastReadMessageId:
                      refs.isFocused && isActiveConversation ? message.id : conversation.lastReadMessageId,
                    unreadCount:
                      isActiveConversation || conversation.lastReadMessageId === message?.id
                        ? 0
                        : conversation.unreadCount
                        ? conversation.unreadCount + 1
                        : 1,
                  } satisfies typeof conversation;
                });
              }

              const isMention = message.mentionedUserIds?.includes(currentUser?.userID);
              if (isMention) {
                refs.cache.current.addUnreadMentionedMessage(message);
              }
            }
            break;

          case 'message.undeleted':
            //Rather than trying to put this in the right place, in the messages array, I think I'll just rehydrate for this edge case
            refetchConversations();
            break;

          case 'message.updated':
            if (message) {
              const isReply = !!message.parentId;
              if (isReply) {
                refs.messagesCache.current.editReply(message);
              }
              refs.messagesCache.current.editMessage(message.id, message);
            }
            break;
          case 'reaction.new':
          case 'reaction.deleted':
          case 'reaction.updated':
            if (message && user) {
              const isSelf = user.userID === currentUser?.userID;
              // don't need to listen to subscription messages for own messages, bc we update those in the cache directly
              if (isSelf) {
                return;
              }
              const isReply = !!message.parentId;

              if (isReply) {
                refs.messagesCache.current.editReply(message);
              } else {
                refs.messagesCache.current.editMessage(message.id, message);
              }
            }
            break;
          case 'message.deleted':
            if (message) {
              const isReply = !!message.parentId;
              if (isReply) {
                refs.messagesCache.current.deleteReply(message.id);
              } else {
                refs.messagesCache.current.deleteMessage(message.id);
              }
              if (message.mentionedUserIds?.includes(currentUser?.userID)) {
                refs.cache.current.removeMentionedMessage(message.id);
              }
            }
            break;

          case 'channel.deleted':
            if (conversation?.channelId) {
              refs.cache.current.removeConversation(conversation.channelId);
            }
            break;
          case 'member.removed':
            if (conversation?.channelId && currentUser?.userID === user?.userID) {
              refs.cache.current.removeConversation(conversation.channelId);
            } else if (conversation?.channelId && user?.userID) {
              refs.cache.current.removeUserFromConversation(conversation.channelId, user?.userID);
            }
            break;
          case 'member.added':
            if (conversation?.channelId && currentUser?.userID === user?.userID) {
              //just re fetch all the conversations, I guess... I could add the channel to the cache,  but I think this would be a good point to rehydrate
              refetchConversations();
              if (activeConversationId === conversation.channelId) {
                refetchMessages();
              }
              break;
            } else if (conversation?.channelId && user?.userID) {
              refs.cache.current.addUserToConversation(conversation.channelId, user?.userID);
            }
            break;
          case 'channel.updated':
            if (conversation?.channelId) {
              //Note: only updating conversation essentials here. If it turns out that we need more from this update, we can add it to the essentials
              //We don't actually get a lot of info from the event payload, so we can't do much here. I guess name is the only thing we'll update from the websocket message
              refs.cache.current.updateConversation(conversation?.channelId, {
                name: conversation.name,
                topic: conversation.topic,
                description: conversation.description,
              });
            }
            break;
          case 'typing.start': {
            if (conversation && user && user.userID !== currentUser?.userID) {
              refs.cache.current.addUserTypingToConversation(conversation.channelId, user.userID);
            }
            break;
          }

          case 'typing.stop': {
            if (conversation && user && user.userID !== currentUser?.userID) {
              refs.cache.current.removeUserTypingFromConversation(conversation.channelId, user.userID);
            }
            break;
          }

          //implement handlers as needed
          // case 'channel.deleted': // when a channel is deleted - clients watching the channel - channel event
          // case 'channel.hidden': // when a channel is mark as hidden - clients from the user that marked the user as hidden (see hiding channels) - channel event
          // case 'channel.truncated': // when a channels’ history is truncated - clients watching the channel - channel event
          // case 'channel.updated': // when a channel is updated - clients watching the channel - channel event
          // case 'channel.visible': // when a channel is made visible - clients from the user that marked the user as visible (see hiding channels) - channel event
          // case 'member.added': // when a member is added to a channel - clients watching the channel - channel event
          // case 'member.removed': // when a member is removed from a channel - clients watching the channel - channel event
          // case 'member.updated': // when a channel member is updated (promoted to moderator/accepted/.rejected the invite) - clients watching the channel - channel event
          // case 'message.deleted': // when a message is deleted - clients watching the channel - channel event
          // case 'message.new': // when a new message is added on a channel - clients watching the channel - channel event
          // case 'message.read': // when a channel is marked as read - clients watching the channel - channel event
          // case 'message.updated': // when a message is updated - clients watching the channel - channel event
          // case 'reaction.deleted': // when a message reaction is deleted - clients watching the channel - channel event
          // case 'reaction.new': // when a message reaction is added - clients watching the channel - channel event
          // case 'reaction.updated': // when a message reaction is updated - clients watching the channel - channel event
          // case 'typing.start': // when a user starts typing - clients watching the channel - channel event
          // case 'typing.stop': // when a user stops typing - clients watching the channel - channel event
          case 'user.watching.start': // when a user starts watching a channel - clients watching the channel - channel event
          case 'user.watching.stop': // when a user stops watching a channel - clients watching the channel - channel event
          case 'notification.mark_unread': // when the user marks a message as unread - clients from the user with the new unread count
            break;
        }
      } catch (error) {
        console.error(error);
      }
    };

    const conversationsArray = refs.conversations.current?.dm.concat(refs.conversations.current?.groups) ?? [];
    const unsubscribers = conversationsArray?.map((conversation) => {
      return subscribeToConversation(conversation.channelId, handler);
    });
    return () => {
      unsubscribers.forEach((unsubscribe) => {
        unsubscribe();
      });
    };
  }, [isAsleep, conversationsCount, user]);

  return null;
};

const useStableRef = <T extends any>(param: T) => {
  const ref = useRef<T>(param);
  ref.current = param;
  return ref;
};
