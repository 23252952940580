import { useAppScopeStore } from '@frontend/scope';

export const useIsMulti = () => {
  const { accessibleLocationData, isSingleTypeScope, selectedLocationIds } = useAppScopeStore();

  return {
    isMulti: !isSingleTypeScope,
    orgId: accessibleLocationData?.[selectedLocationIds[0]]?.groupId,
  };
};
