import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.colors.neutral30};
  border-radius: ${theme.borderRadius.medium};
  background-color: ${theme.colors.neutral5};
  min-height: 140px;
  height: fit-content;
  max-height: 500px;
  padding: ${theme.spacing(2)};
`;
