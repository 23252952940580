import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.borderRadius.medium};
  padding: ${theme.spacing(1, 2)};
`;

export const titleStyle = css`
  margin-bottom: ${theme.spacing(1)};
`;
