import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed ${theme.colors.neutral40};
  border-radius: ${theme.borderRadius.medium};
  background-color: ${theme.colors.white};
  cursor: pointer;
  padding: ${theme.spacing(2, 1)};
  margin-top: ${theme.spacing(2)};
`;
