import { ScheduleCallRoute } from '@weave/schema-gen-ts/dist/schemas/phone/callroute/beta/v1/phone_hours.pb';
import { useTranslation } from '@frontend/i18n';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { TextLink } from '@frontend/design-system';

export const CallRoutesLink = ({ scheduleCallRoutes }: { scheduleCallRoutes: ScheduleCallRoute[] }) => {
  const { t } = useTranslation('phone');
  const { navigate } = useSettingsNavigate();

  return (
    <>
      {scheduleCallRoutes.length === 1 && (
        <TextLink
          onClick={() =>
            navigate({
              to: '/phone/call-routes/:id',
              params: { id: scheduleCallRoutes[0].id ?? '' },
            })
          }
        >
          {scheduleCallRoutes[0].name}
        </TextLink>
      )}

      {scheduleCallRoutes.length > 1 && (
        <TextLink
          onClick={() => {
            // TODO: Open up the call route side panel
            console.log('Open call route side panel', scheduleCallRoutes);
          }}
        >
          {t('{{count}} Call Routes', { count: scheduleCallRoutes.length })}
        </TextLink>
      )}
    </>
  );
};
