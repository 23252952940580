import { ComponentProps, useCallback } from 'react';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { AdaptoActions } from '@frontend/adapto-actions';
import { checkAggregateACL } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore, useScopedAppFlagStore } from '@frontend/scope';
import { ContactActionPrefixes } from '@frontend/tracking-prefixes';
import { ActionButton, useTooltip } from '@frontend/design-system';
import { ThreadContextAndOptions, useMessageAction } from './use-message-action';

export type MessageButtonProps<T extends ThreadContextAndOptions | undefined | never> =
  (T extends ThreadContextAndOptions
    ? {
        context: T;
        onClick?: ComponentProps<typeof ActionButton>['onClick'] | undefined | never;
        trackingIdSuffix?: string;
      }
    : {
        context?: T;
        onClick: NonNullable<ComponentProps<typeof ActionButton>['onClick']>;
        trackingIdSuffix?: string;
      }) &
    Omit<ComponentProps<typeof ActionButton>, 'trackingId' | 'onClick'>;

export const MessageButton = <T extends ThreadContextAndOptions | undefined | never>({
  context,
  trackingIdSuffix,
  onClick,
  ...rest
}: MessageButtonProps<T>) => {
  const { t } = useTranslation('contact-actions-send-message');
  const { onClick: onClickWithContext } = useMessageAction();
  const { selectedLocationIds } = useAppScopeStore();
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const aclEnforcementEnabled = getFeatureFlagValue('nwx:messaging-inbox-fe-acl-enforcement', 'ALL');
  const disabled = aclEnforcementEnabled && !checkAggregateACL(selectedLocationIds, Permission.SMS_INBOX_MANAGE, 'any');

  const handleClick = useCallback<NonNullable<ComponentProps<typeof ActionButton>['onClick']>>(
    async (...args) => {
      if (context) {
        await onClickWithContext(context);
      }
      onClick?.(...args);
    },
    [!!context, onClick, onClickWithContext]
  );

  const { Tooltip, tooltipProps, triggerProps } = useTooltip();

  return (
    <>
      {disabled && (
        <Tooltip {...tooltipProps}>{t('You do not have permission to view and send text messages')}</Tooltip>
      )}
      <ActionButton
        onClick={handleClick}
        trackingId={`${ContactActionPrefixes.Message}:${trackingIdSuffix}`}
        {...rest}
        disabled={disabled || rest.disabled}
        {...(disabled ? triggerProps : {})}
      >
        <Icon name={'message'} size={16} />
      </ActionButton>
    </>
  );
};

export type MessageActionProps<T extends ThreadContextAndOptions | undefined | never> =
  (T extends ThreadContextAndOptions
    ? {
        context: T;
        onClick?: ComponentProps<typeof AdaptoActions.Action>['onClick'] | undefined | never;
        trackingIdSuffix?: string;
      }
    : {
        context?: T;
        onClick: NonNullable<ComponentProps<typeof AdaptoActions.Action>['onClick']>;
        trackingIdSuffix?: string;
      }) &
    Omit<ComponentProps<typeof AdaptoActions.Action>, 'trackingId' | 'onClick' | 'icon'>;

export const MessageAction = <T extends ThreadContextAndOptions | undefined | never>({
  context,
  trackingIdSuffix,
  onClick,
  ...rest
}: MessageActionProps<T>) => {
  const { t } = useTranslation('contact-actions-send-message');
  const { onClick: onClickWithContext } = useMessageAction();
  const { selectedLocationIds } = useAppScopeStore();
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const aclEnforcementEnabled = getFeatureFlagValue('nwx:messaging-inbox-fe-acl-enforcement', 'ALL');
  const disabled = aclEnforcementEnabled && !checkAggregateACL(selectedLocationIds, Permission.SMS_INBOX_MANAGE, 'any');

  const handleClick = useCallback<NonNullable<ComponentProps<typeof AdaptoActions.Action>['onClick']>>(
    async (...args) => {
      if (context) {
        await onClickWithContext?.(context);
      }
      onClick?.(...args);
    },
    [!!context, onClick, onClickWithContext]
  );

  const { Tooltip, tooltipProps, triggerProps } = useTooltip();

  return (
    <>
      {disabled && (
        <Tooltip {...tooltipProps}>{t('You do not have permission to view and send text messages')}</Tooltip>
      )}
      <AdaptoActions.Action
        trackingId={`${ContactActionPrefixes.Message}:${trackingIdSuffix}`}
        icon='messaging'
        onClick={handleClick}
        {...rest}
        disabled={disabled || rest.disabled}
        {...(disabled ? triggerProps : {})}
      />
    </>
  );
};
