import { useCallback } from 'react';
import { useNavigate } from '@tanstack/react-location';
import { Chart } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore, useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { URLs } from '../../../constants';
import { featureFlags } from '../../../feature-flags';
import { trackingIds } from '../../../tracking-ids';
import { formatters } from '../../../utils';
import { useFetchMessagingROIOOverview } from '../hooks';
import { useHasMCTRoiFeature } from '../missed-call-text/hooks';
import { emptyStateConfigForGauge, gaugeAppearance } from './constants';
import { renderCustomCenterLabel } from './custom-gauge-label';
import { RoiOverviewCard } from './roi-overview-card';
import { OverviewCardProps } from './types';

export const MCTRoiOverviewCard = ({ hideSMSCount, timePeriod }: OverviewCardProps) => {
  const { t } = useTranslation('analytics');
  const { selectedLocationIds } = useAppScopeStore();
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isDemoAccount = getFeatureFlagValue(featureFlags.missedCallTextRoiDemoData);
  const { isMCTRoiEligibleLocation } = useHasMCTRoiFeature();
  const navigate = useNavigate();

  const { aggregated, isLoading } = useFetchMessagingROIOOverview({
    apiParams: {
      messagingCategory: 'missedcalltext',
      payload: {
        start_date: timePeriod.value.startDate,
        end_date: timePeriod.value.endDate,
        location_id: selectedLocationIds,
      },
    },
    isDemoAccount,
    isEnabled: isMCTRoiEligibleLocation,
  });

  const messagesSent = aggregated?.leads_contacted?.sms_count || 0;
  const appointmentsScheduled = aggregated?.patients_scheduled?.appointments_count || 0;

  const handleNavigate = useCallback(() => {
    navigate({
      to: URLs.ROI_MISSED_CALL_TEXT,
    });
  }, []);

  return (
    <RoiOverviewCard
      filteredDate={timePeriod.label}
      iconName='message'
      isLoading={isLoading}
      onClick={handleNavigate}
      summaryValues={
        hideSMSCount
          ? [
              {
                label: t('Appts Scheduled'),
                value: formatters.value.format(appointmentsScheduled),
              },
            ]
          : [
              {
                label: t('Messages Sent'),
                value: formatters.value.format(messagesSent),
              },
              {
                label: t('Appts Scheduled'),
                value: formatters.value.format(appointmentsScheduled),
              },
            ]
      }
      title={t('Missed Call Text Conversion')}
      trackingId={trackingIds.analytics.overviewMissedCallTextRoi}
    >
      <Chart
        colors={{ value: theme.colors.primary20 }}
        emptyStateConfig={emptyStateConfigForGauge}
        noBorder
        style={{ marginBottom: 0 }}
      >
        <Chart.GaugeChart
          appearance={gaugeAppearance}
          customCenterLabel={() =>
            renderCustomCenterLabel(
              t('{{count}} Appts', { count: appointmentsScheduled }),
              t('from {{count}} Mgs', { count: messagesSent })
            )
          }
          value={(appointmentsScheduled / (messagesSent || 1)) * 100}
        />
      </Chart>
    </RoiOverviewCard>
  );
};
