export interface ManualTemplate {
  DefaultValue: string;
  Template: string;
  Type: string;
  TypeDescription: string;
}

export interface ManualTemplateResponse {
  data: ManualTemplate[];
}

export interface UpdateManualTemplateResponse {
  records_updated: number;
}

export enum ManualTemplateTypes {
  onlineScheduleRequest = 'online-schedule-request',
  onlineSchedulingConfirmation = 'online-scheduling-confirmation',
}

export interface ManualTemplateRequest {
  templateType: ManualTemplateTypes;
  locationId: string;
}
