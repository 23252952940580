import { FC, useEffect, useMemo } from 'react';
import { css, Interpolation, Theme } from '@emotion/react';
import { SubscriptionsQueries } from '@frontend/api-subscription';
import { getUser } from '@frontend/auth-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { useAppFlagStore } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { SkeletonLoader } from '@frontend/design-system';

const SCHEDULE_CALL_CHILLIPIPER_URL = 'https://getweave.chilipiper.com/book/welcome-call-software-only-plg1';
const SCHEDULE_CALL_V2_CHILLIPIPER_URL = 'https://getweave.chilipiper.com/round-robin/welcome-call-software-only-plg1';

const SCHEDULE_CALL_V2_FF = 'onboarding:wic-schedule-call-chillipiper-v2';

type ChilliPiperEvent = {
  action?:
    | 'booked'
    | 'booking-confirmed'
    | 'availability-loaded'
    | 'rescheduled'
    | 'prospect_cancel'
    | 'no-free-slots'
    | 'close'
    | 'phone-selected'
    | 'meeting-selected'
    | 'video-selected'
    | 'phoneClose'
    | 'timeslot-selected';
};

interface WeaveInitialCallScheduleIframeProps {
  locationId: string;
  show: boolean;
  styles?: Interpolation<Theme>;
  onClose: () => void;
  onAvailabilityLoaded?: () => void;
  onBookingConfirmed?: () => void;
}

export const WeaveInitialCallScheduleIframe: FC<WeaveInitialCallScheduleIframeProps> = ({
  locationId,
  show,
  styles,
  onClose,
  onAvailabilityLoaded,
  onBookingConfirmed,
}) => {
  const { accessibleLocationData } = useAppScopeStore();
  const { getFeatureFlagValue } = useAppFlagStore();
  const locationSlug = accessibleLocationData?.[locationId]?.slug ?? '';
  const hasV2ChilliPiper = getFeatureFlagValue({
    locationIds: [locationId],
    flagName: SCHEDULE_CALL_V2_FF,
  });

  const scheduleCallLink = hasV2ChilliPiper ? SCHEDULE_CALL_V2_CHILLIPIPER_URL : SCHEDULE_CALL_CHILLIPIPER_URL;

  const userInfo = getUser();

  const sfAccountInfoQuery = SubscriptionsQueries.useGetSalesforceAccountBySlug({
    enabled: show && !!locationSlug,
    slug: locationSlug,
  });

  useEffect(() => {
    const chilliPiperOrigin = new URL(scheduleCallLink).origin;
    const messageListener = (event?: MessageEvent<ChilliPiperEvent>) => {
      if (event?.origin !== chilliPiperOrigin) {
        return;
      }

      switch (event?.data?.action) {
        case 'close':
          onClose();
          break;
        case 'booking-confirmed':
          onBookingConfirmed?.();
          break;
        case 'availability-loaded':
          onAvailabilityLoaded?.();
          break;
        default:
          break;
      }
    };
    window.addEventListener('message', messageListener);

    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, [scheduleCallLink]);

  const scheduleCallIFrameUrl = useMemo(() => {
    const scheduleCallUrl = new URL(scheduleCallLink);
    scheduleCallUrl.searchParams.set('email', userInfo?.username ?? '');
    scheduleCallUrl.searchParams.set('firstname', userInfo?.firstName ?? '');
    scheduleCallUrl.searchParams.set('lastname', userInfo?.lastName ?? '');
    scheduleCallUrl.searchParams.set('phone', userInfo?.mobileNumber ?? '');
    scheduleCallUrl.searchParams.set('accountId', sfAccountInfoQuery.data?.account?.id ?? '');
    return scheduleCallUrl.toString();
  }, [userInfo, scheduleCallLink, sfAccountInfoQuery.data, show]);

  if (!show) {
    return null;
  }

  if (sfAccountInfoQuery.isLoading) {
    return <SkeletonLoader />;
  }

  return <iframe src={scheduleCallIFrameUrl} css={[iframeStyle, styles]} />;
};

const iframeStyle = css({
  width: '100%',
  minWidth: 400,
  height: 'calc(100% - 5px)',
  border: `1px solid ${theme.colors.neutral10}`,
});
