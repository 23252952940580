import { useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import { isWeaveUser } from '@frontend/auth-helpers';
import { ErrorBoundary } from '@frontend/error-boundary';
import { PanelPortal } from '@frontend/panel-engine';
import { useMatchMedia } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Button, Text, useOnClickOutside, WIDTH_DIMS } from '@frontend/design-system';
import { MainPanel } from '../components/panels/main-panel';
import { NavPanel } from '../components/panels/nav-panel';
import { TrayTopBar } from '../components/tray-top-bar/tray-top-bar';
import { useTeamChatSelector } from '../providers/team-chat.provider';

export const ChatPanel = () => {
  /** Separating this into a HOC because I want to catch any instance of errors happening inside the provider */
  return (
    <ErrorBoundary>
      <ChatPanelInner />
    </ErrorBoundary>
  );
};

/**
 * @requires to be placed under a TeamChatProvider or else it will fail
 */
export const ChatPanelInner = () => {
  const isOpen = useTeamChatSelector((ctx) => ctx.isOpen);
  const closeTeamChat = useTeamChatSelector((ctx) => ctx.closeTeamChat);
  const isNavExpanded = useTeamChatSelector((ctx) => ctx.isNavExpanded);
  const mainPanelView = useTeamChatSelector((ctx) => ctx.mainPanelView);
  const collapseNav = useTeamChatSelector((ctx) => ctx.collapseNav);
  const isSmallSpace = useMatchMedia({ maxWidth: WIDTH_DIMS.xlarge + 40 });

  useEffect(() => {
    if (isSmallSpace) {
      collapseNav();
    }
  }, [isSmallSpace]);

  const width = isSmallSpace || mainPanelView === 'none' || isNavExpanded === false ? 'smallXXL' : 'xlarge';
  const chatRef = useRef<HTMLDivElement | null>(null);
  useFocusManaager({ ref: chatRef });

  return (
    isOpen && (
      <PanelPortal
        enableAnimations
        id='teamchat'
        width={width}
        onClose={() => closeTeamChat}
        css={panelStyle}
        ref={chatRef}
      >
        {isWeaveUser() ? (
          <div css={paddedStyle}>
            <div css={weaveUserContainerStyle}>
              <Button iconName='x' aria-label='close' variant='secondary' onClick={() => closeTeamChat()} />
            </div>
            <Text>Weave users do not have access to Team Chat</Text>
          </div>
        ) : (
          <>
            <TrayTopBar onClose={() => closeTeamChat()} />
            <div css={chatsContainerStyle}>
              <NavPanel />
              <MainPanel />
            </div>
          </>
        )}
      </PanelPortal>
    )
  );
};

type UseTeamChatFocusProps = {
  ref: React.MutableRefObject<HTMLDivElement | null>;
};

const useFocusManaager = ({ ref }: UseTeamChatFocusProps) => {
  const isFocused = useTeamChatSelector((ctx) => ctx.isFocused);
  const focus = useTeamChatSelector((ctx) => ctx.focus);
  const unfocus = useTeamChatSelector((ctx) => ctx.unfocus);

  const handler = (e: MouseEvent) => {
    if (
      ref.current &&
      e.target &&
      !isFocused &&
      (ref.current.contains(e.target as Node) || ref.current.isSameNode(e.target as Node))
    ) {
      focus();
    }
  };

  useOnClickOutside({
    ref: ref,
    handler: () => isFocused && unfocus(),
  });

  useEffect(() => {
    document.addEventListener('mousedown', handler);
    () => {
      document.removeEventListener('mousedown', handler);
    };
  }, []);
};

const paddedStyle = css({
  padding: theme.spacing(4),
  width: '434px',
  borderLeft: `1px solid ${theme.colors.neutral20}`,
});

const weaveUserContainerStyle = css({ display: 'flex', justifyContent: 'flex-end' });

const panelStyle = css({
  display: 'flex',
  flexDirection: 'column',
  borderColor: theme.colors.neutral20,

  '> section': {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    minWidth: 'min-content !important',
    overflow: 'hidden',
    borderColor: theme.colors.neutral10,
    height: '100%',
  },
});
const chatsContainerStyle = css({
  display: 'flex',
  flexDirection: 'row',
  overflow: 'hidden',
  flex: 1,
});
