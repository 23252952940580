import { PaymentsFieldConstants } from '../constants';
import { FieldTypes, PrimaryMedicalHistoryFieldTypes } from '../types';
import { genUID } from './uuid.utils';

/**
 * Generate a field to add to the form with a new ID.
 */
export function generateFieldToAdd(field: FieldTypes.Field): FieldTypes.Field {
  const newFieldId = genUID();
  const meta = { ...field.meta };

  // isEmpty and isError are added in medical history fields after data fetching
  if (Object.prototype.hasOwnProperty.call(meta, 'isEmpty')) {
    delete meta.isEmpty;
  }

  if (Object.prototype.hasOwnProperty.call(meta, 'isError')) {
    delete meta.isError;
  }

  return {
    ...field,
    meta,
    id: newFieldId,
  };
}

export function checkIfPaymentField(fieldId: string, fields: FieldTypes.Fields): boolean {
  return fields[fieldId].meta.dataGroup === PaymentsFieldConstants.PaymentDataGroupName;
}

export function checkForPaymentFieldsInSection(fieldIds: string[], fields: FieldTypes.Fields): boolean {
  return fieldIds.some((fieldId) => checkIfPaymentField(fieldId, fields));
}

export function checkForPaymentFieldsInForm(fields: FieldTypes.Fields): boolean {
  for (const fieldId in fields) {
    if (checkIfPaymentField(fieldId, fields)) {
      return true;
    }
  }

  return false;
}

interface ResetCategorizedPrimaryMedicalHistoryFieldOptionsPayload {
  field: FieldTypes.Field;
  categorizedPrimaryMedicalHistoryFieldOptions: PrimaryMedicalHistoryFieldTypes.CategorizedOptions;
}

const CATEGORIZED_PRIMARY_MEDICAL_HISTORY_FIELD_OPTION_SETS: PrimaryMedicalHistoryFieldTypes.OptionSetType[] = [
  'allergies',
  'disease',
  'medications',
];

export function resetCategorizedPrimaryMedicalHistoryFieldOptions({
  field,
  categorizedPrimaryMedicalHistoryFieldOptions,
}: ResetCategorizedPrimaryMedicalHistoryFieldOptionsPayload): PrimaryMedicalHistoryFieldTypes.CategorizedOptions {
  // Check if the field is a categorized primary medical history field
  if (field.meta.type !== 'categorizedChecklist') {
    return categorizedPrimaryMedicalHistoryFieldOptions;
  }

  const optionSet = field.meta.optionSet as PrimaryMedicalHistoryFieldTypes.OptionSetType;
  if (!CATEGORIZED_PRIMARY_MEDICAL_HISTORY_FIELD_OPTION_SETS.includes(optionSet)) {
    return categorizedPrimaryMedicalHistoryFieldOptions;
  }

  // Reset the categorized options for the field
  return {
    ...categorizedPrimaryMedicalHistoryFieldOptions,
    [optionSet]: {},
  };
}
