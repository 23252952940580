import { useEffect, useState } from 'react';
import { useMatch } from '@tanstack/react-location';
import { EmailTemplate } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/email-template/v1/emailtemplate.pb';
import { CampaignType_Enum as CampaignType } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { EmailTemplateQueries } from '@frontend/api-email-template';
import { DefaultTemplateCard, EmailTemplates, useEmailTemplates } from '@frontend/email-templates';
import { useBulkEmailEditorShallowStore, useBulkMessagingNavigator } from '../../hooks';
import { CONTAINER_HEIGHT } from './constants';

export const DefaultListTemplates = () => {
  const [pageToken, setPageToken] = useState<string | undefined>(undefined);
  const [templates, setTemplates] = useState<EmailTemplate[]>([]);

  const listRequest = { pageToken };

  const { data, fetchNextPage, hasNextPage, isFetched, isFetchingNextPage } =
    EmailTemplateQueries.useListSystemTemplates(listRequest);

  useEffect(() => {
    const newData = data?.pages?.[0]?.templates ?? [];
    const newTemplates = [...newData, ...templates];
    const filteredTemplates = newTemplates.filter(
      (template, index, arr) => arr.findIndex((t) => t.id === template.id) === index
    );
    setTemplates(filteredTemplates);
    return () => {
      setTemplates([]);
    };
  }, [pageToken, isFetched]);

  const getNextPage = () => {
    if (hasNextPage && !isFetchingNextPage) {
      setPageToken(data?.pages?.[0]?.nextPageToken);
      fetchNextPage();
    }
  };

  const store = useBulkEmailEditorShallowStore('setSubject', 'setTemplateHtml', 'setTemplateJson');
  const navigateTo = useBulkMessagingNavigator(CampaignType.EMAIL, true);
  const { params } = useMatch();
  const campaignId = params.id;

  const { fetchSystemTemplate } = useEmailTemplates();

  const initializeTemplateStoreData = async (templateId?: string) => {
    if (!templateId) return;
    const template = await fetchSystemTemplate(templateId);
    if (template?.subject) store.setSubject(template.subject);
    if (template?.templateHtml) store.setTemplateHtml(template?.templateHtml);
    if (template?.templateJson) store.setTemplateJson(template?.templateJson);
  };

  const onTemplateClick = async (templateId?: string) => {
    if (!templateId) return;
    await initializeTemplateStoreData(templateId);
    if (templateId) navigateTo.composerWithTemplateId(templateId, campaignId, undefined, true);
  };

  return (
    <EmailTemplates<EmailTemplate>
      DisplayedCard={(_index, template) => (
        <DefaultTemplateCard
          key={template.id}
          {...template}
          onTemplateClick={() => onTemplateClick(template.id)}
          onTemplateFetch={() => fetchSystemTemplate(template.id)}
        />
      )}
      getNextPage={getNextPage}
      hasNextPage={hasNextPage}
      height={CONTAINER_HEIGHT}
      isFetched={isFetched}
      isFetchingNextPage={isFetchingNextPage}
      templates={templates}
    />
  );
};
