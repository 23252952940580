import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { checkAggregateACL } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { useGetWeaveInsuranceVerificationCustomizationFlagDetails } from '@frontend/insurance-verification';
import { useAppScopeStore, useScopedAppFlagStore } from '@frontend/scope';
import { SettingsRoutes } from '@frontend/settings-routing';

export type AllPathsMap = Record<SettingsRoutes, { label: string; show?: boolean }>;
type NavItemsPaths = SettingsRoutes;

type NavItem = {
  label: string;
  path: NavItemsPaths;
  show?: boolean;
  hasBottomSpacing?: boolean;
  description?: string;
};

function useSettingsNavItems() {
  const { t } = useTranslation('settingsNav');
  const { selectedLocationIds } = useAppScopeStore();
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const hasContactSettingsAccess = getFeatureFlagValue('contact-settings-v2');
  const { isFeatureHiddenInAllLocation, isDental } = useGetWeaveInsuranceVerificationCustomizationFlagDetails();
  const { aggregateValue: hasReviewSettingsAccess } = checkAggregateACL(
    selectedLocationIds,
    Permission.REVIEW_SETTINGS
  );

  const navItems: NavItem[] = [
    {
      label: t('Tags') /* AUTO-GENERATED */,
      path: '/tags',
      hasBottomSpacing: true,
    },
    {
      label: t('Contacts') /* AUTO-GENERATED */,
      path: '/contacts',
      show: hasContactSettingsAccess,
    },
    { label: t('Email') /* AUTO-GENERATED */, path: '/email' },
    { label: t('Fax') /* AUTO-GENERATED */, path: '/fax' },
    {
      label: t('Forms') /* AUTO-GENERATED */,
      path: '/digital-forms',
    },
    {
      label: t('Insurance'),
      path: '/insurance',
      show: isDental && !isFeatureHiddenInAllLocation,
    },
    { label: t('Messaging') /* AUTO-GENERATED */, path: '/messaging' },
    { label: t('Payments') /* AUTO-GENERATED */, path: '/payments' },
    { label: t('Phone'), path: '/phone/main' },
    {
      label: t('Social') /* AUTO-GENERATED */,
      path: '/reviews',
      show: hasReviewSettingsAccess,
    },
    {
      label: t('Schedule') /* AUTO-GENERATED */,
      path: '/schedule',
      hasBottomSpacing: true,
    },
    { label: t('Image Gallery') /* AUTO-GENERATED */, path: '/image-gallery' },
    { label: t('Subscriptions') /* AUTO-GENERATED */, path: '/subscriptions' },
  ];

  return navItems;
}

export function useHidePickerConditions(): ((matches: { pathname: string; id: string }[]) => boolean)[] {
  return [];
}

export { useSettingsNavItems };
