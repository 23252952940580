import { useQueryClient } from 'react-query';
import { EmailTemplateMutations, EmailTemplateQueries } from '@frontend/api-email-template';
import { useTranslation } from '@frontend/i18n';
import { useAlert } from '@frontend/design-system';

export const useEmailTemplates = () => {
  const { t } = useTranslation('bulk-messaging');
  const alert = useAlert();
  const queryClient = useQueryClient();

  const { mutateAsync: createTemplate } = EmailTemplateMutations.useCreateEmailTemplate({
    onSettled: (_res, err) => {
      if (err) {
        alert.error(t('There was an error creating this template. Please try again later.'));
      } else {
        // invalidating all the email template queries in favor of just refetching the list
        // since this is used in multiple places outside of the templates page and don't know which query to add it to
        const queryKeys = queryClient
          .getQueriesData<EmailTemplateMutations.PaginationData>(EmailTemplateQueries.keys.listEmailTemplatesBase())
          .map(([keys]) => keys);
        queryKeys.forEach((key) => queryClient.invalidateQueries(key));
        alert.success(t('Created template successfully.'));
      }
    },
  });

  const { mutate: deleteTemplate } = EmailTemplateMutations.useDeleteEmailTemplate({
    onSettled: (_res, err) => {
      if (err) {
        alert.error(t('There was an error deleting this template. Please try again later.'));
      } else {
        alert.success({
          message: t('Deleted template successfully.'),
          // TODO: waiting for the BE to support undo
          // action: {
          //   label: t('Undo'),
          //   onClick: () => {
          //     // call undo delete endpoint
          //   },
          // },
        });
      }
    },
  });

  const fetchOrgTemplate = async (templateId?: string) => {
    const request = { templateId: templateId ?? '' };
    const data = await queryClient.fetchQuery(EmailTemplateQueries.keys.getEmailTemplate(request), {
      queryFn: () => EmailTemplateQueries.getEmailTemplate(request),
      retry: false,
    });
    return data?.template;
  };

  const fetchSystemTemplate = async (templateId?: string) => {
    const request = { templateId: templateId ?? '' };
    const data = await queryClient.fetchQuery(EmailTemplateQueries.keys.getSystemEmailTemplate(request), {
      queryFn: () => EmailTemplateQueries.getSystemEmailTemplate(request),
      retry: false,
    });
    return data?.template;
  };

  const { mutate: updateTemplate } = EmailTemplateMutations.useUpdateEmailTemplate({
    onSettled: (_res, err) => {
      if (err) {
        alert.error(t('There was an error updating this template. Please try again later.'));
      } else {
        alert.success(t('Updated template successfully.'));
      }
    },
  });

  const { mutate: updateTemplateLocations } = EmailTemplateMutations.useUpdateEmailTemplateLocations({
    onSettled: (_res, err) => {
      if (err) {
        alert.error(t("There was an error updating this template's locations. Please try again later."));
      } else {
        alert.success(t("Updated template's location successfully."));
      }
    },
  });

  return {
    createTemplate,
    deleteTemplate,
    fetchOrgTemplate,
    fetchSystemTemplate,
    updateTemplate,
    updateTemplateLocations,
  };
};
