import { FC, ReactNode, useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { TaskStatus } from '@weave/schema-gen-ts/dist/schemas/task-center/shared/v1/enums.pb';
import { AnalysisType } from '@weave/schema-gen-ts/dist/shared/call-intelligence/enums.pb';
import { Vertical } from '@weave/schema-gen-ts/dist/shared/vertical/vertical.pb';
import { AnalyticsCommonTypes } from '@frontend/api-analytics';
import { CallIntelTypes } from '@frontend/api-call-intel';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useLastUsedVerticalShallowStore } from '@frontend/location-helpers';
import { theme } from '@frontend/theme';
import {
  CheckIcon,
  Chip,
  FeedbackBadIcon,
  FeedbackIcon,
  IconProps,
  statusSwitcherVariantsColor,
  Text,
  XIcon,
} from '@frontend/design-system';
import { Thumb } from '../../thumb';
import CallIntelChip from '../call-intel-chip';
import { FIELD_MAPPING } from '../modal/edit-history-modal';
import { ServiceQualityBadge } from '../service-quality-badge';
import { useCallIntelShallowStore } from './use-call-intel-store';

type Variant = 'array' | 'filterRecord' | 'record';
type Options = { id: string; label: string }[];
export type RecordOptions = Record<string, ReactNode> | AnalyticsCommonTypes.StringRecord;
type OptionsReturnType<V extends Variant> = V extends 'array' ? Options : RecordOptions;

export type OptionType =
  | 'appointmentTypes'
  | 'callDirection'
  | 'categories'
  | 'contactType'
  | 'feedbackIssueTypes'
  | 'schedulingOutcome'
  | 'schedulingOpportunity'
  | 'sentiment'
  | 'serviceQualityFlags'
  | 'taskStatus';

export const verticalMapping: Partial<Record<Vertical, CallIntelTypes.AppointmentTypeEnum[]>> = {
  [Vertical.DENTAL]: Object.values(
    CallIntelTypes.AppointmentTypeDentalEnum
  ) as unknown as CallIntelTypes.AppointmentTypeEnum[],
  [Vertical.VET]: Object.values(
    CallIntelTypes.AppointmentTypeVetEnum
  ) as unknown as CallIntelTypes.AppointmentTypeEnum[],
  [Vertical.OPTOMETRY]: Object.values(
    CallIntelTypes.AppointmentTypeOptoEnum
  ) as unknown as CallIntelTypes.AppointmentTypeEnum[],
};

const renderOpportunityOption = (
  mainText: string,
  subText: string,
  Icon: FC<IconProps>,
  variant: 'primary' | 'neutral'
) => (
  <>
    <div css={styles.chipLabel}>
      <Chip css={styles.chip} variant={variant}>
        <Icon size={16} />
      </Chip>
      <Text>{mainText}</Text>
    </div>
    <Text color='subdued' size='small'>
      {subText}
    </Text>
  </>
);
export const useOptionsProvider = () => {
  const { t } = useTranslation('analytics');
  const { availableCategories, chipVariants, dataLabels } = useCallIntelShallowStore(
    'availableCategories',
    'chipVariants',
    'dataLabels'
  );

  const { lastUsedVertical } = useLastUsedVerticalShallowStore('lastUsedVertical');

  const feedbackIssueTypesOptions = useCallback(<V extends Variant>(variant: V): OptionsReturnType<V> => {
    const options = [
      { id: AnalysisType.ANALYSIS_TYPE_APPOINTMENT_TYPE, label: t('Appointment Type') },
      { id: AnalysisType.ANALYSIS_TYPE_CATEGORY, label: t('Category') },
      { id: AnalysisType.ANALYSIS_TYPE_CONTACT_TYPE, label: t('Contact Type') },
      { id: AnalysisType.ANALYSIS_TYPE_SENTIMENT, label: t('Customer Sentiment') },
      { id: AnalysisType.ANALYSIS_TYPE_OTHER, label: t('Other') },
      { id: AnalysisType.ANALYSIS_TYPE_SCHEDULING_OPPORTUNITY, label: t('Scheduling Opportunity') },
      { id: AnalysisType.ANALYSIS_TYPE_SUMMARY, label: t('Summary') },
      { id: AnalysisType.ANALYSIS_TYPE_TASKS, label: t('Task') },
      { id: AnalysisType.ANALYSIS_TYPE_TRANSCRIPT, label: t('Transcript') },
    ];

    if (variant === 'array') {
      return options as OptionsReturnType<V>;
    }

    return options.reduce((acc, { id, label }) => {
      acc[id] = label;
      return acc;
    }, {} as RecordOptions) as OptionsReturnType<V>;
  }, []);

  const getAppointmentTypesForVertical = useCallback((vertical: Vertical): CallIntelTypes.AppointmentTypeEnum[] => {
    // If a match is found in the mapping, return the specific appointment types
    if (vertical in verticalMapping) {
      return [...(verticalMapping[vertical] ?? [])].sort();
    }

    // If the vertical is NOT Dental, Vet, or Optometry, return Generic Appointment Types
    const allSpecificAppointments = new Set<CallIntelTypes.AppointmentTypeEnum>([
      ...(Object.values(CallIntelTypes.AppointmentTypeDentalEnum) as unknown as CallIntelTypes.AppointmentTypeEnum[]),
      ...(Object.values(CallIntelTypes.AppointmentTypeVetEnum) as unknown as CallIntelTypes.AppointmentTypeEnum[]),
      ...(Object.values(CallIntelTypes.AppointmentTypeOptoEnum) as unknown as CallIntelTypes.AppointmentTypeEnum[]),
    ]);

    return Object.values(CallIntelTypes.AppointmentTypeEnum)
      .filter((type) => !allSpecificAppointments.has(type))
      .sort();
  }, []);

  const appointmentTypesOptions = useCallback(
    <V extends Variant>(
      variant: V,
      additionalProps?: { extraAppointmentTypes?: CallIntelTypes.AppointmentTypeEnum[] }
    ): OptionsReturnType<V> => {
      let verticals = getAppointmentTypesForVertical(lastUsedVertical as Vertical);
      if (additionalProps?.extraAppointmentTypes?.length) {
        verticals = [...new Set([...verticals, ...additionalProps.extraAppointmentTypes])].sort();
      }
      const options = verticals.filter((type) => type !== CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_UNKNOWN);

      if (variant === 'record') {
        return options.reduce((acc, type) => {
          acc[type] = (
            <CallIntelChip
              as='category'
              chipVariants={chipVariants}
              dataKey={type}
              label={dataLabels.appointmentTypes?.[type] || type}
            />
          );
          return acc;
        }, {} as RecordOptions) as OptionsReturnType<V>;
      }

      return options.map((type) => ({
        id: type,
        label: dataLabels.appointmentTypes?.[type] || type,
      })) as OptionsReturnType<V>;
    },
    [chipVariants, dataLabels, getAppointmentTypesForVertical, lastUsedVertical]
  );

  const callDirectionOptions: RecordOptions = useMemo(() => {
    return {
      [CallIntelTypes.CallDirectionEnum.DIRECTION_INBOUND]: t('Inbound Calls'),
      [CallIntelTypes.CallDirectionEnum.DIRECTION_OUTBOUND]: t('Outbound Calls'),
    };
  }, []);

  const categoryOptions = useCallback(
    <V extends Variant>(variant: V): OptionsReturnType<V> => {
      const options = availableCategories.filter((type) => type !== CallIntelTypes.CategoryEnum.CATEGORY_UNKNOWN);

      if (variant === 'record') {
        return options.reduce((acc, type) => {
          acc[type] = (
            <CallIntelChip
              as='category'
              chipVariants={chipVariants}
              dataKey={type}
              label={dataLabels.categories?.[type] || type}
            />
          );
          return acc;
        }, {} as RecordOptions) as OptionsReturnType<V>;
      }

      return options.map((type) => ({
        id: type,
        label: dataLabels.categories?.[type] || type,
      })) as OptionsReturnType<V>;
    },
    [availableCategories, chipVariants, dataLabels]
  );

  const contactTypeOptions: RecordOptions = useMemo(() => {
    return {
      [CallIntelTypes.ContactTypeEnum.CONTACT_NEW_PATIENT]: (
        <Chip css={styles.chip} variant='warn'>
          {t('New Patient')}
        </Chip>
      ),
      [CallIntelTypes.ContactTypeEnum.CONTACT_EXISTING_PATIENT]: (
        <Chip css={styles.chip} variant='primary'>
          {t('Existing Patient')}
        </Chip>
      ),
      [CallIntelTypes.ContactTypeEnum.CONTACT_NOT_A_PATIENT]: (
        <Chip css={styles.chip} variant='neutral'>
          {t('Not a Patient')}
        </Chip>
      ),
    };
  }, []);

  const schedulingOutcomesOptions = useCallback(
    (variant: Variant, additionalProps?: Record<string, any>): RecordOptions => {
      const { withoutLabel, includeEmptyOption } = additionalProps ?? {};
      //This could be simplified more but it has been done intentionally as in future filterrecords will look differently as normal records
      if (variant === 'filterRecord') {
        return {
          [CallIntelTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_SCHEDULED]: (
            <div css={styles.chipLabel}>
              <Chip css={styles.chip} variant='success'>
                <CheckIcon size={16} />
              </Chip>
              {!withoutLabel && <Text>{t('Scheduled Appointment')}</Text>}
            </div>
          ),
          [CallIntelTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_UNSCHEDULED]: (
            <div css={styles.chipLabel}>
              <Chip css={styles.chip} variant='warn'>
                <XIcon size={16} />
              </Chip>
              {!withoutLabel && <Text>{t('Unscheduled Appointment')}</Text>}
            </div>
          ),
        };
      }

      return {
        [CallIntelTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_SCHEDULED]: (
          <div css={styles.chipLabel}>
            <Chip css={styles.chip} variant='success'>
              <CheckIcon size={16} />
            </Chip>
            {!withoutLabel && <Text>{t('Scheduled')}</Text>}
          </div>
        ),
        [CallIntelTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_UNSCHEDULED]: (
          <div css={styles.chipLabel}>
            <Chip css={styles.chip} variant='warn'>
              <XIcon size={16} />
            </Chip>
            {!withoutLabel && <Text>{t('Unscheduled')}</Text>}
          </div>
        ),
        ...(includeEmptyOption && {
          none: FIELD_MAPPING[CallIntelTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SCHEDULING_OUTCOME].emptyValue,
        }),
      };
    },
    []
  );

  const schedulingOpportunityOptions = useCallback(
    (variant: Variant, additionalProps?: Record<string, any>): RecordOptions => {
      const { withoutLabel } = additionalProps ?? {};

      if (variant === 'filterRecord') {
        return {
          [CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED]: renderOpportunityOption(
            t('Identified Scheduling Opportunity'),
            t('There was potential to schedule an appointment during the call.'),
            FeedbackIcon,
            'primary'
          ),
          [CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_NOT_IDENTIFIED]: renderOpportunityOption(
            t('No Scheduling Opportunity'),
            t("There wasn't potential to schedule an appointment during the call."),
            FeedbackBadIcon,
            'neutral'
          ),
        };
      }

      return {
        [CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED]: (
          <div css={styles.chipLabel}>
            <Thumb up />
            {!withoutLabel && <Text>{t('Opportunity')}</Text>}
          </div>
        ),
        [CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_NOT_IDENTIFIED]: (
          <div css={styles.chipLabel}>
            <Thumb up={false} />
            {!withoutLabel && <Text>{t('No Opportunity')}</Text>}
          </div>
        ),
      };
    },
    []
  );

  const sentimentOptions = useCallback(
    <V extends Variant>(variant: V): OptionsReturnType<V> => {
      const options: CallIntelTypes.SentimentEnum[] = [
        CallIntelTypes.SentimentEnum.SENTIMENT_POSITIVE,
        CallIntelTypes.SentimentEnum.SENTIMENT_NEUTRAL,
        CallIntelTypes.SentimentEnum.SENTIMENT_NEGATIVE,
      ];

      if (variant === 'record') {
        return options.reduce((acc, type) => {
          acc[type] = (
            <CallIntelChip
              as='chip'
              chipVariants={chipVariants}
              css={styles.chip}
              dataKey={type}
              label={dataLabels.sentimentsWithEmoji?.[type] || type}
            />
          );
          return acc;
        }, {} as RecordOptions) as V extends 'array' ? Options : RecordOptions;
      }

      return options.map((type) => ({
        id: type,
        label: dataLabels.sentimentsWithEmoji?.[type] || type,
      })) as V extends 'array' ? Options : RecordOptions;
    },
    [chipVariants, dataLabels]
  );

  const serviceQualityOptions = useCallback(
    <V extends Variant>(_: V, additionalProps?: Record<string, any>): RecordOptions => {
      return {
        [CallIntelTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION]: (
          <ServiceQualityBadge
            label={
              dataLabels?.serviceQualityFlag?.[CallIntelTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION] ||
              CallIntelTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION
            }
            type={CallIntelTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION}
            {...additionalProps}
          />
        ),
        [CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE]: (
          <ServiceQualityBadge
            label={
              dataLabels?.serviceQualityFlag?.[CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE] ||
              CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE
            }
            type={CallIntelTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE}
            {...additionalProps}
          />
        ),
      };
    },
    [dataLabels]
  );

  const taskStatusOptions = useCallback(<V extends Variant>(_: V): RecordOptions => {
    return {
      [TaskStatus.STATUS_TODO]: (
        <div css={styles.chipLabel}>
          <Icon name='pending-small' css={{ color: statusSwitcherVariantsColor['critical'].color }} />
          <Text>{t('To-Do')}</Text>
        </div>
      ),
      [TaskStatus.STATUS_IN_PROGRESS]: (
        <div css={styles.chipLabel}>
          <Icon name='in-progress-small' css={{ color: statusSwitcherVariantsColor['warning'].color }} />
          <Text>{t('In Progress')}</Text>
        </div>
      ),
      [TaskStatus.STATUS_COMPLETED]: (
        <div css={styles.chipLabel}>
          <Icon name='check-small' css={{ color: statusSwitcherVariantsColor['success'].color }} />
          <Text>{t('Completed')}</Text>
        </div>
      ),
    };
  }, []);

  const getOptions = useCallback(
    <T extends OptionType, V extends Variant = 'record'>(
      type: T,
      variant?: V,
      additionalProps?: Record<string, any>
    ): OptionsReturnType<V> => {
      const resolvedVariant = variant || 'record';

      switch (type) {
        case 'appointmentTypes':
          return appointmentTypesOptions(resolvedVariant, additionalProps) as OptionsReturnType<V>;
        case 'callDirection':
          return callDirectionOptions as OptionsReturnType<V>;
        case 'categories':
          return categoryOptions(resolvedVariant) as OptionsReturnType<V>;
        case 'feedbackIssueTypes':
          return feedbackIssueTypesOptions(resolvedVariant) as OptionsReturnType<V>;
        case 'schedulingOutcome':
          return schedulingOutcomesOptions(resolvedVariant, additionalProps) as OptionsReturnType<V>;
        case 'contactType':
          return contactTypeOptions as OptionsReturnType<V>;
        case 'schedulingOpportunity':
          return schedulingOpportunityOptions(resolvedVariant, additionalProps) as OptionsReturnType<V>;
        case 'sentiment':
          return sentimentOptions(resolvedVariant) as OptionsReturnType<V>;
        case 'serviceQualityFlags':
          return serviceQualityOptions(resolvedVariant, additionalProps) as OptionsReturnType<V>;
        case 'taskStatus':
          return taskStatusOptions(resolvedVariant) as OptionsReturnType<V>;
        default:
          throw new Error(`Unsupported option type: ${type}`);
      }
    },
    [
      appointmentTypesOptions,
      callDirectionOptions,
      categoryOptions,
      feedbackIssueTypesOptions,
      schedulingOutcomesOptions,
      contactTypeOptions,
      schedulingOpportunityOptions,
      sentimentOptions,
      serviceQualityOptions,
    ]
  );

  return { getOptions };
};

const styles = {
  chip: css`
    max-width: none;
  `,
  chipLabel: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(0.5)};
  `,
};
