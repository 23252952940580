import { TemplatesImg } from '@frontend/create-email-landing';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { NakedButton, Text } from '@frontend/design-system';

type Props = {
  onClick: () => void;
};

export const EmptyCustomTemplateState = ({ onClick }: Props) => {
  const { t } = useTranslation('messages');

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(5),
        rowGap: theme.spacing(4),
      }}
    >
      <img src={TemplatesImg} alt={t('templates')} css={{ width: 600 }} />
      <div css={{ display: 'flex', flexDirection: 'column', rowGap: theme.spacing(2), alignItems: 'center' }}>
        <Text color='light' textAlign='center' css={{ width: 250 }}>
          {t("Looks like you haven't created any templates yet.")}
        </Text>
        <NakedButton onClick={onClick}>
          <Text color='primary'>{t('Create New Template')}</Text>
        </NakedButton>
      </div>
    </div>
  );
};
