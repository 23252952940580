import { useSchemaQuery } from '@frontend/react-query-helpers';
import type { SchemaIO } from '@frontend/schema';
import { defaultOptions } from '../config';
import { SchemaTaskService, serviceName } from '../service';
import { ServiceTaskStandardQueries, StandardTaskQueryEndpointName, TaskServiceQueries } from '../types';

export type UseTaskQueryQueryArgs<
  EndpointName extends StandardTaskQueryEndpointName,
  E = unknown,
  D = SchemaIO<TaskServiceQueries[EndpointName]>['output']
> = Omit<
  Parameters<typeof useSchemaQuery<ServiceTaskStandardQueries, EndpointName, E, D>>[0],
  'service' | 'serviceName'
>;

export function useTaskQuery<
  EndpointName extends StandardTaskQueryEndpointName,
  E = unknown,
  D = SchemaIO<TaskServiceQueries[EndpointName]>['output']
>({ options, ...args }: UseTaskQueryQueryArgs<EndpointName, E, D>) {
  return useSchemaQuery<ServiceTaskStandardQueries, EndpointName, E, D>({
    service: SchemaTaskService,
    serviceName,
    ...args,
    options: {
      ...defaultOptions,
      ...options,
    },
  });
}
