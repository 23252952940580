import { CallRouteV1 } from '@frontend/api-call-route-v1';
import { useTranslation } from '@frontend/i18n';
import { WeaveLocation, WeaveLocationGroup } from '@frontend/scope';
import { DropdownField, Modal, Text, useAlert, useFormField } from '@frontend/design-system';
import { trackingId } from '../../tracking';
import { ExtendedCallRoute } from './types';

export const ChangeLocationModal = ({
  callRoute,
  settingsTenantLocation,
  onClose,
}: {
  callRoute: ExtendedCallRoute;
  settingsTenantLocation: WeaveLocation | WeaveLocationGroup;
  onClose: () => void;
}) => {
  const { t } = useTranslation('phone');
  const alerts = useAlert();

  const fieldProps = useFormField({
    type: 'dropdown',
    required: true,
    value: callRoute.locationId,
  });

  const { mutate: updateCallRoute, isLoading: updateCallRouteIsLoading } = CallRouteV1.Mutations.useUpdateMutation({
    options: {
      onSuccess: () => {
        alerts.success(t('Location updated successfully.'));
        onClose();
      },
      onError: () => {
        alerts.error(t('Failed to update Location.'));
      },
    },
  });

  return (
    <>
      <Modal.Header onClose={onClose}>{t('Change Location')}</Modal.Header>
      <Modal.Body>
        <Text color='light'>{callRoute.name}</Text>
        <Text>{t('Assign a location to this Call Route so that staff can manage overrides and phone hours.')}</Text>
        <DropdownField
          name='location-id'
          label={t('Location')}
          {...fieldProps}
          data-trackingid={trackingId({
            context: 'setting',
            feature: 'call-routes',
            details: 'change-location::dropdown',
          })}
        >
          {settingsTenantLocation &&
            'children' in settingsTenantLocation &&
            settingsTenantLocation.children.map((location) => (
              <DropdownField.Option
                key={location.locationId}
                value={location.locationId ?? ''}
                searchValue={location.name}
                trackingId={trackingId({
                  context: 'setting',
                  feature: 'call-routes',
                  details: 'change-location::dropdown-option',
                })}
              >
                {location.name}
              </DropdownField.Option>
            ))}
        </DropdownField>
      </Modal.Body>

      <Modal.Actions
        primaryLabel={t('Save')}
        onPrimaryClick={() => {
          updateCallRoute({
            callRouteId: callRoute.callRouteId,
            name: callRoute.name,
            locationId: fieldProps.value,
          });
        }}
        primaryTrackingId={trackingId({
          context: 'setting',
          feature: 'call-routes',
          details: 'change-location::save-btn',
        })}
        disablePrimary={updateCallRouteIsLoading || !fieldProps.value}
        secondaryLabel={t('Cancel')}
        onSecondaryClick={onClose}
        secondaryTrackingId={trackingId({
          context: 'setting',
          feature: 'call-routes',
          details: 'change-location::cancel-btn',
        })}
      />
    </>
  );
};
