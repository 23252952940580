import {
  useSMSManualScheduledWSSubscription,
  useSMSDataV3WSSubscription,
  useSMSPreferenceEventSubscription,
  useSMSTagNotificationSubscription,
} from './hooks';

export const useAllMessagingWebsockets = () => {
  useSMSManualScheduledWSSubscription();
  useSMSDataV3WSSubscription();
  useSMSPreferenceEventSubscription();
  useSMSTagNotificationSubscription();
};
