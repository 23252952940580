import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { CallIntelTypes } from '@frontend/api-call-intel';
import { http } from '@frontend/fetch';
import { validateUUIDV4 } from '@frontend/string';

dayjs.extend(utc);
dayjs.extend(timezone);

const baseUrlV2 = 'call-intelligence/v2';

const generateCommonFilterPayload = (filters: CallIntelTypes.Filters): CallIntelTypes.FilterPayload => {
  // Get the current timezone offset in minutes
  const timezoneOffsetMinutes = dayjs().utcOffset();
  const start = dayjs(filters.startDate)
    .startOf('day')
    .add(-timezoneOffsetMinutes, 'minute')
    .format('YYYY-MM-DD HH:mm:ss.SSS');

  const end = dayjs(filters.endDate)
    .endOf('day')
    .add(-timezoneOffsetMinutes, 'minute')
    .format('YYYY-MM-DD HH:mm:ss.SSS');

  return {
    filter: {
      [CallIntelTypes.FilterTypeEnum.FILTER_BY_DATE_RANGE]: {
        date_range: {
          end,
          start,
        },
      },
      [CallIntelTypes.FilterTypeEnum.FILTER_BY_LOCATION]: {
        locations: filters.locations,
      },
    },
  };
};

const generateCallsFilterPayload = (filters: CallIntelTypes.Filters) => {
  const {
    appointmentTypes,
    callDirection,
    categories,
    contactTypes,
    officeNumbers,
    officeUsers,
    schedulingOutcomes,
    schedulingOpportunity,
    sentiments,
  } = filters;
  const payload = generateCommonFilterPayload(filters);
  payload.filter[CallIntelTypes.FilterTypeEnum.FILTER_BY_CALL_STATUS] = {
    call_status: filters.hideNonAnalyzedCalls
      ? // As we need to hide calls which are not analyzed, we need to exclude failed and skipped calls
        Object.values(CallIntelTypes.CallStatusEnum).filter(
          (status) =>
            status !== CallIntelTypes.CallStatusEnum.CALL_STATUS_FAILED &&
            status !== CallIntelTypes.CallStatusEnum.CALL_STATUS_ERROR &&
            status !== CallIntelTypes.CallStatusEnum.CALL_STATUS_SKIPPED
        )
      : Object.values(CallIntelTypes.CallStatusEnum),
  };

  if (appointmentTypes?.length) {
    payload.filter[CallIntelTypes.FilterTypeEnum.FILTER_BY_APPOINTMENT_TYPE] = {
      appointment_types: appointmentTypes,
    };
  }

  if (callDirection) {
    payload.filter[CallIntelTypes.FilterTypeEnum.FILTER_BY_CALL_DIRECTION] = {
      call_direction: [callDirection],
    };
  }

  if (categories?.length) {
    payload.filter[CallIntelTypes.FilterTypeEnum.FILTER_BY_CATEGORY] = {
      categories,
    };
  }

  if (contactTypes?.length) {
    payload.filter[CallIntelTypes.FilterTypeEnum.FILTER_BY_CONTACT_TYPE] = {
      contact_type: contactTypes,
    };
  }

  if (sentiments?.length) {
    payload.filter[CallIntelTypes.FilterTypeEnum.FILTER_BY_SENTIMENT] = {
      sentiments,
    };
  }

  // If a value is selected for schedulingOpportunity, then only "opportunity" should be included in request
  if (schedulingOpportunity) {
    payload.filter[CallIntelTypes.FilterTypeEnum.FILTER_BY_OPPORTUNITY] = {
      opportunity: schedulingOpportunity === CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED,
    };
  }

  // When both options are or none is checked, we don't send any value to the api
  if (schedulingOutcomes?.length === 1) {
    payload.filter[CallIntelTypes.FilterTypeEnum.FILTER_BY_SCHEDULING_OUTCOME] = {
      scheduling_outcome: schedulingOutcomes[0] === CallIntelTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_SCHEDULED,
    };
  }

  if (officeNumbers?.length) {
    payload.filter[CallIntelTypes.FilterTypeEnum.FILTER_BY_OFFICE_NUMBER] = {
      office_number: officeNumbers,
    };
  }

  if (officeUsers?.length) {
    payload.filter[CallIntelTypes.FilterTypeEnum.FILTER_BY_OFFICE_USER] = {
      office_user_id: officeUsers,
    };
  }

  return payload;
};

const generateFollowupsFilterPayload = (filters: CallIntelTypes.FollowupTaskTypeFilters) => {
  const { officeUsers, contactTypes, taskTypes, taskStatus } = filters;
  const payload = generateCommonFilterPayload(filters);

  if (officeUsers?.length) {
    payload.filter[CallIntelTypes.FollowupTaskFilterEnum.FILTER_BY_OFFICE_USER] = {
      office_user_id: officeUsers,
    };
  }

  if (contactTypes?.length) {
    payload.filter[CallIntelTypes.FollowupTaskFilterEnum.FILTER_BY_CONTACT_TYPE] = {
      contact_type: contactTypes,
    };
  }

  if (taskTypes?.length) {
    payload.filter[CallIntelTypes.FollowupTaskFilterEnum.FILTER_BY_TASK_TYPE] = {
      task_type: taskTypes,
    };
  }

  if (taskStatus?.length) {
    payload.filter[CallIntelTypes.FollowupTaskFilterEnum.FILTER_BY_TASK_STATUS] = {
      task_status: taskStatus,
    };
  }

  return payload;
};

const generateDrillDownPayload = (drillDownOptions: CallIntelTypes.DrillDownOptions) => {
  const { index, key, value } = drillDownOptions;

  return {
    [index]: {
      [key]: value,
    },
  };
};

const generateChartsPayload = ({ drillDownOptions, filters }: CallIntelTypes.ApiPayloadProps) => {
  const payload = generateCommonFilterPayload(filters);

  if (filters.contactTypes?.length) {
    payload.filter[CallIntelTypes.FilterTypeEnum.FILTER_BY_CONTACT_TYPE] = {
      contact_type: filters.contactTypes,
    };
  }

  payload.filter = {
    ...payload.filter,
    [CallIntelTypes.FilterTypeEnum.FILTER_BY_CALL_STATUS]: {
      call_status: [CallIntelTypes.CallStatusEnum.CALL_STATUS_COMPLETE],
    },
  };

  if (drillDownOptions) {
    payload.filter = {
      ...payload.filter,
      ...generateDrillDownPayload(drillDownOptions),
    };
  }

  return payload;
};

const generateSearchPayload = (searchQuery: string): CallIntelTypes.ApiSearchPayloadProps => {
  let searchType: CallIntelTypes.SearchTypeEnum = CallIntelTypes.SearchTypeEnum.SEARCH_UNKNOWN;

  if (/^\d+$/.test(searchQuery)) {
    searchType = CallIntelTypes.SearchTypeEnum.SEARCH_BY_NUMBER;
  } else if (/[a-zA-Z0-9]/.test(searchQuery)) {
    searchType = CallIntelTypes.SearchTypeEnum.SEARCH_BY_NAME;
  }

  return {
    search_type: searchType,
    search: searchQuery,
  };
};

export const getCalls = async ({
  drillDownOptions,
  filters,
  pageConfig,
  showOnlyUnscheduled,
  searchQuery,
  sortType,
}: CallIntelTypes.GetCalls) => {
  if (!filters.locations?.length || !filters.startDate || !filters.endDate) {
    return;
  }

  let isUUID = false;

  if (searchQuery) {
    isUUID = validateUUIDV4(searchQuery);
  }

  const payload = {
    ...generateCallsFilterPayload(filters),
    limit: pageConfig.pageSize + 1, // Manipulating limit to know if there is more data to fetch
    offset: (pageConfig.pageNumber - 1) * pageConfig.pageSize,
    sort_type: sortType ?? CallIntelTypes.SortTypeEnum.SORT_DESC,
    ...(searchQuery && !isUUID && { search_query: generateSearchPayload(searchQuery) }),
  };

  if (drillDownOptions) {
    payload.filter = {
      ...payload.filter,
      ...generateDrillDownPayload(drillDownOptions),
    };
  }

  if (showOnlyUnscheduled) {
    payload.filter = {
      ...payload.filter,
      [CallIntelTypes.FilterTypeEnum.FILTER_BY_OPPORTUNITY]: {
        opportunity: true,
      },
      [CallIntelTypes.FilterTypeEnum.FILTER_BY_SCHEDULING_OUTCOME]: {
        scheduling_outcome: false,
      },
    };
  }

  if (isUUID) {
    payload.filter = {
      ...payload.filter,
      [CallIntelTypes.FilterTypeEnum.FILTER_BY_CALL_ID]: {
        call_id: [searchQuery],
      },
    };
  }

  const data = await http.post<CallIntelTypes.CallsResponse>(`${baseUrlV2}/calls`, payload);

  return {
    calls: data.calls?.slice(0, pageConfig.pageSize),
    hasNextPage: (data.calls || []).length > pageConfig.pageSize,
    pageConfig,
  };
};

export const getCallMetaData = (callId: string, locationId: string) =>
  http.get<CallIntelTypes.CallMetadataResponse>(`${baseUrlV2}/call/${callId}/metadata?locationId=${locationId}`);

export const downloadCallRecording = (locationId: string, callId: string, isDemoAccount?: boolean): Promise<Blob> =>
  http.get<Blob>(
    isDemoAccount ? `${baseUrlV2}/demo/call-recording` : `${baseUrlV2}/calls/${locationId}/${callId}/recording`,
    {
      responseType: 'blob',
    }
  );

export const getDemoServiceQualityChartStats = async ({ includes, filters }: CallIntelTypes.ApiOverviewStatsParams) => {
  if (!filters.locations?.length || !filters.startDate || !filters.endDate) {
    return;
  }

  const { filter: chartsFilter } = generateChartsPayload({ filters });

  const payload = {
    request: {
      filter: {
        ...chartsFilter,
        [CallIntelTypes.FilterTypeEnum.FILTER_BY_LOCATION]: {
          locations: ['40417eee-943e-4b57-80d8-794b41676885'], // This is hardcoded location id for demodata
        },
      },
      includes: {
        opportunities: includes.opportunities,
        service_quality: includes.serviceQuality,
      },
      timezone: dayjs.tz.guess(),
    },
  };

  return http.post(`${baseUrlV2}/demo/calls/service-quality`, payload);
};

export const getCallTasks = (callTaskParams: CallIntelTypes.CallTaskParams) => {
  let payload = { filter: {}, include_metadata: false, sort_type: 2 };

  payload.filter = {
    ...payload.filter,
    [CallIntelTypes.TaskFilterTypeEnum.FILTER_BY_LOCATION]: {
      location_id: callTaskParams.locations,
    },

    [CallIntelTypes.TaskFilterTypeEnum.FILTER_BY_SOURCE]: {
      source: callTaskParams.source || CallIntelTypes.TaskSource.CALL_INTELLIGENCE,
    },

    [CallIntelTypes.TaskFilterTypeEnum.FILTER_BY_SOURCE_ID]: {
      source_id: callTaskParams.callIds,
    },
  };

  if (callTaskParams.includeMetaData) {
    payload = { ...payload, include_metadata: callTaskParams.includeMetaData };
  }

  if (callTaskParams.sortType) {
    payload = { ...payload, sort_type: callTaskParams.sortType };
  }

  return http.post<CallIntelTypes.CallTaskListResponse>(`task-center/v1/tasks/list`, payload);
};

export const getAllStats = ({ filters }: CallIntelTypes.GetFollowUpsStatsParams) => {
  if (!filters.locations?.length || !filters.startDate || !filters.endDate) {
    return;
  }
  const payload = {
    ...generateFollowupsFilterPayload(filters),
  };
  return http.post<CallIntelTypes.FollowUpStatsResponse>(`${baseUrlV2}/calls/followups/stats`, payload);
};

export const getFollowUps = async ({
  filters,
  pageConfig,
  sortBy,
  sortType,
  searchQuery,
}: CallIntelTypes.GetFollowUpsParams) => {
  if (!filters.locations?.length || !filters.startDate || !filters.endDate) {
    return;
  }

  let isUUID = false;

  if (searchQuery) {
    isUUID = validateUUIDV4(searchQuery);
  }

  const payload = {
    ...generateFollowupsFilterPayload(filters),
    limit: pageConfig.pageSize + 1,
    offset: (pageConfig.pageNumber - 1) * pageConfig.pageSize,
    sort_by: sortBy,
    sort_type: sortType ?? CallIntelTypes.SortTypeEnum.SORT_DESC,
    ...(searchQuery && !isUUID && { search_query: generateSearchPayload(searchQuery) }),
  };

  const data = await http.post<CallIntelTypes.FollowUpResponse>(`${baseUrlV2}/calls/followups`, payload);

  return {
    followUps: data.followUps.slice(0, pageConfig.pageSize),
    hasNextPage: (data.followUps || []).length > pageConfig.pageSize,
    pageConfig,
    totalFollowUps: data.totalFollowUps,
  };
};

export const getServiceQuality = (callId: string, locationId: string) =>
  http.get<CallIntelTypes.ServiceQualityResponse>(
    `${baseUrlV2}/call/${callId}/service-quality?locationId=${locationId}`
  );

export const getServiceQualityCallVolume = (filters: CallIntelTypes.Filters) => {
  if (!filters.locations?.length || !filters.startDate || !filters.endDate) {
    return;
  }

  const payload = {
    ...generateChartsPayload({ filters }),
    timezone: dayjs.tz.guess(),
  };

  return http.post<CallIntelTypes.ServiceQualityCallVolumeResponse>(
    `${baseUrlV2}/calls/service-quality/call-volume`,
    payload
  );
};

export const getStatsByLocations = ({ drillDownOptions, filters }: CallIntelTypes.ApiPayloadProps) => {
  if (!filters.locations?.length || !filters.startDate || !filters.endDate) {
    return;
  }

  return http.post<CallIntelTypes.LocationsStats>(
    `${baseUrlV2}/locations`,
    generateChartsPayload({ drillDownOptions, filters })
  );
};

export const getCallsStats = (filters: CallIntelTypes.Filters, searchQuery?: string) => {
  if (!filters.locations?.length || !filters.startDate || !filters.endDate) {
    return;
  }

  let isUUID = false;

  if (searchQuery) {
    isUUID = validateUUIDV4(searchQuery);
  }

  const payload = {
    ...generateCallsFilterPayload(filters),
    ...(searchQuery && !isUUID && { search_query: generateSearchPayload(searchQuery) }),
  };

  payload.filter = {
    ...payload.filter,
    // Override the call status filter to only include failed, error and skipped calls
    [CallIntelTypes.FilterTypeEnum.FILTER_BY_CALL_STATUS]: {
      call_status: [
        CallIntelTypes.CallStatusEnum.CALL_STATUS_ERROR,
        CallIntelTypes.CallStatusEnum.CALL_STATUS_FAILED,
        CallIntelTypes.CallStatusEnum.CALL_STATUS_SKIPPED,
      ],
    },
  };

  if (isUUID) {
    payload.filter = {
      ...payload.filter,
      [CallIntelTypes.FilterTypeEnum.FILTER_BY_CALL_ID]: {
        call_id: [searchQuery],
      },
    };
  }

  return http.post<CallIntelTypes.CallsStats>(`${baseUrlV2}/calls/stats`, payload);
};

export const getLocations = () => http.get<CallIntelTypes.Locations>(`${baseUrlV2}/location/siblings`);

export const getCallEditHistory = async (callId: string, locationId: string) =>
  http.post<CallIntelTypes.GetCallEditHistoryResponse>(`${baseUrlV2}/call/${callId}/edit-history`, {
    location_id: locationId,
  });

export const transformEditableFields = (
  fields: Partial<CallIntelTypes.CallEditableFields>,
  type: CallIntelTypes.TransformEditableFieldType = 'edit'
): Record<string, any> => {
  const transformed: Record<string, any> = {};

  if (fields.contactType) {
    transformed.contact_type = fields.contactType;
  }
  if (fields.sentiment) {
    transformed.sentiment = fields.sentiment;
  }
  if (fields.schedulingOutcome !== undefined) {
    transformed.scheduling_outcome = fields.schedulingOutcome;
  }
  if (fields.schedulingOpportunity !== undefined) {
    transformed.scheduling_opportunity = fields.schedulingOpportunity;
  }
  if (fields.categories) {
    transformed.categories = fields.categories;
  }
  if (fields.appointmentTypes) {
    transformed.appointment_types = {
      appointment_type: type === 'edit' ? fields.appointmentTypes : fields.appointmentTypes.appointmentType,
    };
  }
  if (fields.serviceQualityFlags) {
    transformed.service_quality_flags = fields.serviceQualityFlags.map((flag) => ({
      service_quality_flag: flag.serviceQualityFlag,
      is_disabled: flag.isDisabled,
    }));
  }

  return transformed;
};

export const editAIOutput = async (payload: CallIntelTypes.EditAIOutputRequest) => {
  const { callId, type = 'edit', ...rest } = payload;

  const transformedPayload = {
    ...rest,
    current_fields: transformEditableFields(rest.current_fields, type),
    edited_fields: transformEditableFields(rest.edited_fields, type),
  };

  return http.put(`${baseUrlV2}/call/${callId}`, transformedPayload);
};

export const createSuggestion = async (payload: CallIntelTypes.CreateSuggestionRequest) => {
  const { callId, ...rest } = payload;

  return http.post(`${baseUrlV2}/call/${callId}/suggestion`, rest);
};

export const noopMutationFn = async (value: any) => Promise.resolve(value);

export const fakeApi = async <T>(value: T, delay = 200): Promise<T> => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(value), delay);
  });
};
