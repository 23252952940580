import { css } from '@emotion/react';
import { AppointmentReminders } from '@frontend/empty-states#svg';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { formatters } from '../../../../utils';
import { highlightValueStyles } from '../../styles';
import { useARRoiShallowStore } from '../hooks';

interface Props {
  value: number;
}

export const HighlightARValue = ({ value }: Props) => {
  const { t } = useTranslation('analytics');
  const { definitionsHelperDateRange } = useARRoiShallowStore('definitionsHelperDateRange');

  return (
    <div css={[highlightValueStyles, styles.wrapper]}>
      <Text style={{ maxWidth: '620px', zIndex: 1 }}>
        <Trans t={t}>
          <Text as='span'>You saved</Text>{' '}
          <Text as='strong' weight='bold' style={{ color: theme.colors.tangerine40 }}>
            {formatters.currency.format(value)}
          </Text>{' '}
          <Text as='span'>in production value</Text> <Text as='span'>by using</Text>{' '}
          <Text as='strong' weight='bold'>
            Appointment Reminders
          </Text>{' '}
          <Text as='span'>{definitionsHelperDateRange}</Text>
        </Trans>
      </Text>
      <AppointmentReminders className='graphic' />
    </div>
  );
};

const styles = {
  wrapper: css`
    background-color: ${theme.colors.tangerine5};
    border: 1px solid ${theme.colors.tangerine40};
  `,
};
