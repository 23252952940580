import { useEffect, useState } from 'react';
import { useMatch } from '@tanstack/react-location';
import {
  Collections_Enum,
  EmailTemplate,
} from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/email-template/v1/emailtemplate.pb';
import { CampaignType_Enum as CampaignType } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { EmailTemplateQueries } from '@frontend/api-email-template';
import {
  CustomTemplateCard,
  EmailTemplates,
  EmptyCustomTemplateState,
  useEmailTemplates,
} from '@frontend/email-templates';
import { useAppScopeStore } from '@frontend/scope';
import { useBulkEmailEditorShallowStore, useBulkEmailEditorStore, useBulkMessagingNavigator } from '../../hooks';
import { CONTAINER_HEIGHT } from './constants';

export const CustomListTemplates = () => {
  const { selectedOrgId: orgId, selectedLocationIds: appSelectedLocationIds } = useAppScopeStore();
  const { locationIds, setLocationIds, setSubject, setTemplateHtml, setTemplateJson } = useBulkEmailEditorShallowStore(
    'locationIds',
    'setLocationIds',
    'setSubject',
    'setTemplateHtml',
    'setTemplateJson'
  );
  const selectedLocationIds = locationIds.length ? locationIds : appSelectedLocationIds;
  const [isLoading, setIsLoading] = useState(false);

  const [pageToken, setPageToken] = useState<string | undefined>(undefined);
  const [templates, setTemplates] = useState<EmailTemplate[]>([]);
  const listRequest = {
    orgId,
    locationIds: selectedLocationIds,
    pageToken,
    // collection: Collections_Enum.BULK_MESSAGING, // TODO: work with Ethan to make sure that the default collection is set to BULK_MESSAGING
  };

  const { data, fetchNextPage, hasNextPage, isFetched, isFetchingNextPage } =
    EmailTemplateQueries.useListEmailTemplates(listRequest);

  useEffect(() => {
    const newData = data?.pages?.[0]?.templates ?? [];
    const newTemplates = [...newData, ...templates];
    const filteredTemplates = newTemplates.filter(
      (template, index, arr) => arr.findIndex((t) => t.id === template.id) === index
    );
    setTemplates(filteredTemplates);
    return () => {
      setTemplates([]);
    };
  }, [pageToken, isFetched]);

  const getNextPage = () => {
    if (hasNextPage && !isFetchingNextPage) {
      setPageToken(data?.pages?.[0]?.nextPageToken);
      fetchNextPage();
    }
  };

  const navigateTo = useBulkMessagingNavigator(CampaignType.EMAIL, true);
  const { params } = useMatch();
  const campaignId = params.id;

  const navigateToNewTemplate = () => {
    useBulkEmailEditorStore.reset();
    navigateTo.composer();
  };

  const { fetchOrgTemplate } = useEmailTemplates();

  const initializeTemplateStoreData = async (templateId?: string) => {
    if (!templateId) return;
    const template = await fetchOrgTemplate(templateId);
    if (!template?.locationIds?.length) setLocationIds(selectedLocationIds);
    if (template?.subject) setSubject(template?.subject);
    if (template?.templateHtml) setTemplateHtml(template?.templateHtml);
    if (template?.templateJson) setTemplateJson(template?.templateJson);
  };

  const onTemplateClick = async (isEdit?: boolean, templateId?: string) => {
    if (!templateId) return;
    await initializeTemplateStoreData(templateId);
    if (templateId) navigateTo.composerWithTemplateId(templateId, campaignId, !!isEdit);
  };

  return (
    <EmailTemplates<EmailTemplate>
      DisplayedCard={(_index, template) => (
        <CustomTemplateCard
          key={template.id}
          {...template}
          collection={Collections_Enum.BULK_MESSAGING}
          onTemplateClick={(isEdit) => onTemplateClick(isEdit, template.id)}
          onTemplateFetch={() => fetchOrgTemplate(template.id)}
          selectedLocationIds={selectedLocationIds}
          setIsLoading={setIsLoading}
          setTemplates={setTemplates}
          templates={templates}
        />
      )}
      EmptyCardState={<EmptyCustomTemplateState onClick={navigateToNewTemplate} />}
      getNextPage={getNextPage}
      hasNextPage={hasNextPage}
      height={CONTAINER_HEIGHT}
      isFetched={isFetched}
      isFetchingNextPage={isFetchingNextPage}
      isLoading={isLoading}
      templates={templates}
    />
  );
};
